// @flow

import React, { Fragment } from "react";
import clsx from "clsx";
import intersection from "lodash.intersection";
import isEmpty from "lodash.isempty";
import kebabCase from "lodash.kebabcase";
import styled from "@emotion/styled";
import { Button, Menu, MenuItem, Position } from "@blueprintjs/core";
import { isValid, parseISO } from "date-fns";
import { MultiSelect } from "@blueprintjs/select";
import { observer } from "mobx-react";

import formatValueWithUnit from "../helpers/FormatValueWithUnit";

type CollectionItem = {
  label: string,
  value: string | number
};

type SelectItem = {
  items: Array<string | CollectionItem>,
  label: string
};

const StyledSidebarSelect = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  className: ?string,
  computedDateFormat: string,
  computedDateMonthFormat: string,
  dataTestId: ?string,
  filterItems: ?Function,
  fuzzySearch: boolean,
  isDisabled: ?boolean,
  isLoading: ?boolean,
  itemRenderer: ?Function,
  items: Array<SelectItem | string>,
  maxItems: number,
  onChange: Function,
  placeholder: ?string,
  popoverClassName: ?string,
  popoverProps: ?Object,
  portalClassName: ?string,
  selectedItems: Array<any>,
  sortFn: ?Function,
  tagInputProps: ?Object,
  title: string
};

function SidebarSelect(props: Props) {
  const {
    className,
    computedDateFormat = "yyyy-MM-dd",
    computedDateMonthFormat = "yyyy-MM",
    dataTestId,
    fuzzySearch = false,
    isDisabled = false,
    isLoading = false,
    items = [],
    maxItems = 50,
    onChange,
    placeholder = "Search...",
    popoverClassName = "sidebar-select",
    popoverProps = {},
    portalClassName,
    selectedItems = [],
    sortFn,
    tagInputProps = {},
    title = ""
  } = props;

  const testId = dataTestId || kebabCase(`${title}-select`);
  const isGrouped = items.every(item => typeof item === "object" && item.groupItems);
  const isCollection = isGrouped
    ? items.every(({ groupItems }) => groupItems.every(groupItem => typeof groupItem === "object"))
    : items.every(item => typeof item === "object" && !item.groupItems);
  const isDate = typeof items[0] === "string" && isValid(parseISO(items[0]));

  const getValue = item => (typeof item === "object" && item !== null ? item.value : item);

  const dateFormats = {
    "departure-week": computedDateFormat,
    "departure-month": computedDateMonthFormat
  };

  const flatItems = isGrouped ? items.flatMap(({ groupItems }) => groupItems.map(item => item)) : items;
  const visibleItems = flatItems.filter(item => !selectedItems.map(getValue).includes(getValue(item)));

  const addItem = (itemValue: string | number) => {
    const isSelected = selectedItems.includes(itemValue);
    if (!isSelected) {
      const list = flatItems.map(getValue).filter(item => {
        return item === itemValue || selectedItems.includes(item);
      });
      onChange(list, itemValue);
    }
  };

  const noResults = () => <MenuItem disabled text="No results." />;

  const itemRenderer = (item: Object | string, itemProps: Object) => {
    const { handleClick, query, modifiers } = itemProps;
    let text = typeof item === "object" ? item.label : item;

    if (isDate) {
      text = formatValueWithUnit(item, kebabCase(title), dateFormats);
    }

    const queriedText = query
      ? text.replace(new RegExp(query, "ui"), match => {
          return `<strong>${match}</strong>`;
        })
      : text;

    const itemClassName = clsx("bp3-menu-item", {
      "bp3-active bp3-intent-primary": modifiers.active
    });

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li className={itemClassName} key={text} onClick={handleClick}>
        <div
          className="bp3-text-overflow-ellipsis bp3-fill"
          dangerouslySetInnerHTML={{ __html: queriedText }} // eslint-disable-line react/no-danger
        />
      </li>
    );
  };

  const renderItemGroup = (group: Array<SelectItem>, renderItem: Function, listProps) => {
    const { label, groupItems } = group;
    let itemsToRender = [...groupItems];
    let renderInfoItem = false;
    if (typeof sortFn === "function") {
      sortFn(itemsToRender, listProps);
    }

    if (groupItems.length > maxItems) {
      itemsToRender = itemsToRender.splice(0, maxItems);
      renderInfoItem = true;
    }

    return (
      <Fragment key={`group-${label}`}>
        <li className="bp3-menu-header">
          <h4 className="my-2">{label}</h4>
        </li>
        {itemsToRender.map(renderItem)}
        {isEmpty(groupItems) && noResults()}
        {renderInfoItem && <MenuItem text={`${groupItems.length - maxItems} more...`} key="info-item" disabled />}
      </Fragment>
    );
  };

  const itemListRenderer = listProps => {
    const { renderItem, filteredItems, itemsParentRef } = listProps;

    if (isEmpty(filteredItems)) {
      return <Menu ulRef={itemsParentRef}>{noResults()}</Menu>;
    }

    if (!isGrouped) {
      const itemsToRender = intersection(visibleItems, filteredItems);
      if (sortFn) {
        sortFn(itemsToRender, listProps);
      }
      return <Menu ulRef={itemsParentRef}>{itemsToRender.map(renderItem)}</Menu>;
    }

    const groupsToRender = items.reduce((acc, group) => {
      const { label, groupItems } = group;

      if (isEmpty(groupItems)) {
        return acc;
      }
      return [...acc, { label, groupItems: intersection(groupItems, filteredItems) }];
    }, []);

    return (
      <Menu ulRef={itemsParentRef}>{groupsToRender.map(group => renderItemGroup(group, renderItem, listProps))}</Menu>
    );
  };

  const removeItem = (item: string, index: number) => {
    if (index >= 0) {
      const list = selectedItems.filter((_, i) => i !== index);
      onChange(list);
    }
  };

  const filterItems = (query, item, index, exactMatch) => {
    const normalizedQuery = query.toLowerCase();
    let normalizedTitle;

    if (typeof item === "object") {
      normalizedTitle = item.label.toLowerCase();
    } else if (isDate) {
      normalizedTitle = formatValueWithUnit(item, kebabCase(title), dateFormats);
    } else {
      normalizedTitle = item.toLowerCase();
    }

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    }

    const foundIndex = normalizedTitle.indexOf(normalizedQuery);
    return fuzzySearch ? foundIndex > -1 : foundIndex === 0;
  };

  const clearAllButton = selectedItems.length ? (
    <Button data-testid="clear-all-button" icon="cross" minimal onClick={() => onChange([])} />
  ) : null;

  const renderTagName = tag => {
    if (isCollection) {
      const foundItemTag = flatItems.find(item => getValue(item) === tag) || {};
      return foundItemTag.label || "Undefined";
    }
    if (isDate) {
      return formatValueWithUnit(tag, kebabCase(title), dateFormats);
    }
    return tag;
  };

  return (
    <StyledSidebarSelect className="mb-3" data-testid={testId}>
      <h6 className="bp3-heading">{title}</h6>
      <MultiSelect
        className={className}
        fill
        itemListRenderer={itemListRenderer}
        itemPredicate={props.filterItems || filterItems}
        itemRenderer={props.itemRenderer || itemRenderer}
        items={visibleItems}
        noResults={noResults}
        onItemSelect={item => addItem(getValue(item))}
        placeholder={isLoading ? "Loading..." : placeholder}
        popoverProps={{
          minimal: true,
          portalClassName,
          popoverClassName,
          position: Position.BOTTOM,
          modifiers: {
            offset: 100
          },
          ...popoverProps
        }}
        resetOnSelect
        scrollToActiveItem={false}
        selectedItems={selectedItems}
        tagRenderer={renderTagName}
        tagInputProps={{
          disabled: isDisabled || isLoading,
          onRemove: removeItem,
          rightElement: clearAllButton,
          ...tagInputProps
        }}
      />
    </StyledSidebarSelect>
  );
}

export default observer(SidebarSelect);
