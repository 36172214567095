// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Spinner } from "@blueprintjs/core";

import EventsManagementSidebar from "../components/EventsManagement/EventsManagementSidebar";
import EventsManagementTable from "../components/EventsManagement/EventsManagementTable";
import EventsManagementTopBar from "../components/EventsManagement/EventsManagementTopBar";
import StyledScrollableContainer from "../components/StyledScrollableContainer";
import { Status } from "../helpers/Status";
import { useStores } from "../store/Store";

function EventsManagement() {
  const { appStore, eventsManagementStore, modalStore, systemUnitsStore, analysisMappingsStore } = useStores();

  const { eventsMappings, page } = eventsManagementStore;
  const { computedDateFormat, computedDateMonthFormat } = systemUnitsStore;
  const { auth = {} } = appStore;
  const { user = {} } = auth;

  useEffect(() => {
    eventsManagementStore.getEventsData();
    eventsManagementStore.getEventMappings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarProps = {
    computedDateFormat,
    computedDateMonthFormat,
    mappings: analysisMappingsStore
  };

  const isUserAllowedToModify = ["Admin", "Analyst"].includes(user.group);

  const tableProps = {
    ...page.eventsTable,
    computedDateFormat,
    deleteEvents: params => eventsManagementStore.deleteEvents(params),
    fetchData: params => eventsManagementStore.getEventsData(params),
    isUserAllowedToModify,
    onRowToggle: (selectedRows: Array<string>) => eventsManagementStore.updateSelectedRows(selectedRows),
    onShiftToggle: eventsManagementStore.shiftToggleRows,
    setModal: modalStore.setModal
  };

  if (eventsMappings.status === Status.INIT) {
    return <div />;
  }

  if (eventsMappings.status === Status.LOADING) {
    return (
      <div className="m-5">
        <Spinner />
      </div>
    );
  }

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <EventsManagementTopBar />
      <EventsManagementSidebar {...sidebarProps} />
      <div className="d-flex flex-column flex-shrink-1 px-2 pt-2 mh-0">
        <EventsManagementTable {...tableProps} />
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(EventsManagement);
