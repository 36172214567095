// @flow

import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

const TopBarComponent = styled("div")`
  min-height: 48px;
  height: 48px;
  background: white;
  border-bottom: 1px solid #ced9e0;
`;

type Props = {
  children: ?Element
};

function TopBar(props: Props) {
  const { children = null } = props;
  return (
    <TopBarComponent data-testid="topbar" className="d-flex align-items-center p-2">
      {children}
    </TopBarComponent>
  );
}

export default observer(TopBar);
