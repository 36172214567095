// @flow

import cloneDeep from "lodash.clonedeep";
import difference from "lodash.difference";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import keyBy from "lodash.keyby";
import omit from "lodash.omit";
import union from "lodash.union";
import { action, computed, observable, toJS } from "mobx";
import { Intent } from "@blueprintjs/core";

import api from "../services/Api";
import normalizeFilters from "../helpers/normalizeFilters";
import removeFilterValue from "../helpers/removeFilterValue";
import type { RootStore } from "./Root.model";
import { AppToaster } from "../services/Toaster";
import { prepareEventObject, getPlaceCodes } from "../helpers/EventsManagementHelpers";
import { Status } from "../helpers/Status";

const sortLabel = (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

type ConditionalFilterValue = {
  func: "gt" | "gte" | "lt" | "lte" | "eq" | "neq",
  name: string,
  value: string
};

type FilterValue = string | number | { start: string, end: string } | ConditionalFilterValue;

type SuggestedEvents = {
  data: Array<{
    categoryId: number,
    locations: {} | null,
    name: string,
    nonWorkingDay: boolean,
    provenances: {} | null
  }>,
  extraEvents: number,
  status: string
};

export type PageContextFilters = {
  categoryIds: Array<number>,
  dateRange: {
    start: string | null,
    end: string | null
  },
  userIds: Array<number>
};

export type BaseEventsFields = {
  isLocationGlobal: boolean,
  isProvenanceGlobal: boolean,
  name: string,
  nonWorkingDay: boolean
};

export type TypesEventsMappings = {
  data: {
    categories: Array<{ id: number, name: string }>,
    countries: Array<{
      code: string,
      metros: Array<{ airports: Array<string>, code: string, name: string }>,
      name: string
    }>,
    owMarkets: Array<string>,
    users: Array<{ id: number, name: string }>
  },
  status: string
};

export const INIT_EVENT_BASE_FIELDS: BaseEventsFields = {
  isLocationGlobal: true,
  isProvenanceGlobal: true,
  name: "",
  nonWorkingDay: false
};

const INIT_SUGGESTED_EVENT: SuggestedEvents = {
  data: [],
  extraEvents: 0,
  status: Status.INIT
};

export const FILTERS_INIT: PageContextFilters = {
  categoryIds: [],
  dateRange: {
    end: null,
    start: null
  },
  destination: [],
  origin: [],
  owMarket: [],
  rtMarket: [],
  userIds: []
};

export const PAGE_INIT = {
  applied: {
    filters: { ...FILTERS_INIT }
  },
  clonedModalEvent: {},
  eventsTable: {
    status: Status.INIT,
    columns: [
      [
        "events",
        [
          "id",
          "name",
          "categoryName",
          "nonWorkingDay",
          "startDate",
          "endDate",
          "locations",
          "provenances",
          "updatedBy",
          "updatedOn"
        ]
      ]
    ],
    data: [],
    selectedRows: [],
    pagination: {
      pageIndex: 0,
      pageSize: 25,
      pageCount: 1,
      totalRows: 0
    },
    sortBy: {
      field: "startDate",
      direction: "asc"
    }
  },
  filters: { ...FILTERS_INIT },
  filtersEnabled: true,
  modalEvent: { ...INIT_EVENT_BASE_FIELDS },
  modalSuggestStatus: Status.INIT,
  selectedEvent: {},
  sidebar: {
    isOpen: false,
    filterKey: "",
    filterQuery: ""
  },
  suggestedEvents: { ...INIT_SUGGESTED_EVENT },
  upcomingEvents: true
};

export const EVENTS_MAPPINGS_INIT: TypesEventsMappings = {
  data: {},
  status: Status.INIT
};

const filterItemsWithConflicts = (items = [], indexes) => items.filter((value, index) => !indexes.includes(index));
const reorganizeEventsAutosuggestion = (rows = [], type = "") =>
  rows.map(({ id, name, ...item }) => ({ ...item, type, value: id, label: name }));
const sumExtraMatches = data =>
  Object.values(data)
    .map(b => b?.moreMatches?.value || 0)
    .reduce((acc, val) => acc + val, 0);

const fieldMap = {
  categoryName: "category",
  endDate: "dateRange.end",
  startDate: "dateRange.start"
};

export class EventsManagementStore {
  @observable page = cloneDeep(PAGE_INIT);
  @observable eventsMappings = cloneDeep(EVENTS_MAPPINGS_INIT);
  @observable activeSuggestionType = "";

  rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setDefaultSorting() {
    this.page.eventsTable.sortBy = PAGE_INIT.eventsTable.sortBy;
  }

  prepareSelectItem(item) {
    return { label: item.name, value: item.id };
  }

  @action.bound
  initEventModal(isAddingEvent) {
    this.page.modalSuggestStatus = Status.INIT;
    this.page.modalEvent = prepareEventObject(isAddingEvent ? this.page.modalEvent : this.page.selectedEvent);
  }

  @action.bound
  toggleUpcomingEvents() {
    this.page.upcomingEvents = !this.page.upcomingEvents;
    this.getEventsData();
  }

  @action.bound
  clearEvent() {
    this.page.modalEvent = {};
    this.page.clonedModalEvent = {};
    this.clearExistingEvents();
  }

  @action
  getEventsData(params: Object) {
    const { applied, eventsTable, upcomingEvents } = this.page;
    const { pageIndex = 0, pageSize = eventsTable.pagination.pageSize, sortBy = eventsTable.sortBy } = params || {};

    eventsTable.status = Status.LOADING;
    eventsTable.pagination.pageSize = pageSize;
    eventsTable.pagination.pageIndex = pageIndex;
    eventsTable.sortBy = sortBy;
    this.page.clonedModalEvent = {};
    this.page.modalEvent = { ...INIT_EVENT_BASE_FIELDS };

    api
      .getEventsManagementEvents({
        filters: normalizeFilters(applied.filters, FILTERS_INIT),
        pagination: {
          size: pageSize,
          offset: pageIndex * pageSize
        },
        sortBy: {
          ...sortBy,
          field: fieldMap[sortBy.field] || sortBy.field
        },
        upcomingEvents
      })
      .then(({ data }) => {
        const { rows, pagination } = data;
        const pageCount = Math.ceil(pagination.totalRows / pageSize);

        eventsTable.data = rows;
        eventsTable.pagination.totalRows = pagination.totalRows;
        eventsTable.pagination.pageCount = pageCount;
        eventsTable.status = Status.DONE;
      })
      .catch(() => {
        eventsTable.status = Status.ERROR;
      });
  }

  @action
  deleteEvents(data: { eventIds: Array<number> }) {
    this.page.eventsTable.status = Status.LOADING;

    api.deleteEventManagement(data).then(() => {
      this.page.eventsTable.selectedRows.clear();
      this.getEventsData();
    });
  }

  @action.bound
  submitEvent(params: Object, id?: number) {
    this.page.eventsTable.status = Status.LOADING;
    const newParams = { ...params };

    if (newParams?.locations?.airports) {
      newParams.locations.airports = filterItemsWithConflicts(
        newParams.locations.airports,
        this.conflictedLocationsAirport
      );
    }

    if (newParams?.locations?.metros) {
      newParams.locations.metros = filterItemsWithConflicts(newParams.locations.metros, this.conflictedLocationsMetros);
    }

    const endpoint = id ? api.editEventManagement(id, newParams) : api.addEventManagement(newParams);

    return endpoint
      .then(() => {
        this.page.eventsTable.selectedRows.clear();
        this.page.selectedEvent = {};
        this.page.clonedModalEvent = {};
        this.clearExistingEvents();
        return this.getEventsData();
      })
      .catch(error => {
        if (error.response.status === 409) {
          this.page.clonedModalEvent = params;
          this.page.eventsTable.status = Status.DONE;
          return Promise.reject("Error name and dateRange conflicts");
        }

        this.page.eventsTable.status = Status.ERROR;
        return error;
      });
  }

  @action
  getEventMappings() {
    this.eventsMappings.status = Status.LOADING;
    api.getEventsMappings().then(response => {
      this.eventsMappings.data = response.data;
      this.eventsMappings.status = Status.DONE;
    });
  }

  @action.bound
  getEventById(id) {
    this.page.modalSuggestStatus = Status.LOADING;

    api.getEventById(id).then(({ data }) => {
      const { id, dateRange, updatedBy, updatedOn, ...restData } = data;

      this.setEventModalWithSuggestedEvent(restData);
      this.page.modalSuggestStatus = Status.DONE;
    });
  }

  @action.bound
  clearExistingEvents() {
    this.page.suggestedEvents.data.clear();
  }

  @action.bound
  getExistingEvents(params: { name: string } | { dateRange: { start: string, end: string } }, type: string) {
    this.activeSuggestionType = type;
    this.page.suggestedEvents.status = Status.LOADING;
    api
      .getExistingEvents(params)
      .then(({ data }) => {
        if (isEmpty(data.future?.rows) && isEmpty(data.past?.rows)) {
          this.page.suggestedEvents.data = [];
        }
        this.page.suggestedEvents.status = Status.LOADING;

        const pastEvents = (data.past && reorganizeEventsAutosuggestion(data.past.rows, "past")) || [];
        const futureEvents = (data.future && reorganizeEventsAutosuggestion(data.future.rows, "future")) || [];

        this.page.suggestedEvents.data = [...pastEvents, ...futureEvents];
        this.page.suggestedEvents.extraEvents = sumExtraMatches(data);
        this.page.suggestedEvents.status = Status.DONE;
      })
      .catch(() => {
        AppToaster.show({
          message: "Something went wrong and event data cannot be pre-populated",
          intent: Intent.DANGER
        });
        this.page.suggestedEvents.status = Status.ERROR;
      });
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { data, selectedRows } = this.page.eventsTable;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.map(row => row.id);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.page.eventsTable.selectedRows = !isToggleOn
      ? union(selectedRows, actionRows)
      : difference(selectedRows, actionRows);
  }

  @action.bound
  setEventModalWithSuggestedEvent(newModalProps) {
    const newEvent = {
      ...omit(toJS(this.page.modalEvent), ["provenanceType", "isLocationGlobal", "isProvenanceGlobal"]),
      ...toJS(newModalProps)
    };
    this.page.modalEvent = prepareEventObject(newEvent);
  }

  @action.bound
  submitSearchForm() {
    const { page } = this;

    page.applied.filters = cloneDeep(toJS(page.filters));
    this.getEventsData();

    this.page.eventsTable.selectedRows.clear();
  }

  @action.bound
  clearSearchParam(name: string) {
    const { page } = this;

    if (name in page.filters) {
      page.filters[name] = FILTERS_INIT[name];
    }
    this.submitSearchForm();
  }

  @action
  updateSelectedRows(selectedRows: Array<string>) {
    this.page.eventsTable.selectedRows.replace(selectedRows);
    this.page.selectedEvent =
      selectedRows.length === 1 ? this.page.eventsTable.data.find(event => event.id === selectedRows[0]) : {};
  }

  @action.bound
  changeFilter(filterKey: string, filterValue: FilterValue) {
    const { filters } = this.page;
    filters[filterKey] = filterValue;
  }

  @action.bound
  removeFilterValue(filterKey: string, valueToRemove: string) {
    const { filters } = this.page;
    if (filters[filterKey]) {
      filters[filterKey] = removeFilterValue(filters[filterKey], valueToRemove, FILTERS_INIT[filterKey]);
      this.submitSearchForm();
    }
  }

  @action.bound
  setSidebarOpen(isOpen) {
    const { sidebar } = this.page;
    sidebar.isOpen = isOpen;
    if (!isOpen) {
      sidebar.filterQuery = "";
      sidebar.filterKey = null;
    }
  }

  @action.bound
  setSidebarFilterQuery(filterQuery: string, filterKey?: string) {
    const { sidebar } = this.page;
    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action.bound
  changeField(fieldKey: string, fieldValue: string | number | Array<number> | Array<string>, parentKey: string) {
    const { modalEvent } = this.page;
    if (parentKey) {
      if (!modalEvent[parentKey]) {
        modalEvent[parentKey] = { [fieldKey]: fieldValue };
      } else {
        modalEvent[parentKey][fieldKey] = fieldValue;
      }
    } else {
      modalEvent[fieldKey] = fieldValue;
    }
  }

  @computed
  get countries() {
    return this.eventsMappings.data.countries
      ?.map(({ name: label, code: value }) => ({ label, value }))
      .sort(sortLabel);
  }

  @computed
  get metros() {
    return this.eventsMappings.data.countries
      ?.flatMap(country =>
        country?.metros?.map(({ name: label, code: value }) => ({ label, value, country: country.code }))
      )
      .sort(sortLabel);
  }

  @computed
  get airports() {
    return this.eventsMappings.data.countries
      ?.flatMap(country =>
        country?.metros?.flatMap(metro =>
          metro.airports.map(airport => ({
            country: country.code,
            label: airport,
            metro: metro.code,
            value: airport
          }))
        )
      )
      .sort(sortLabel);
  }

  @computed
  get decoratedPage() {
    const { changeFilter } = this;
    return {
      ...this.page,
      changeFilter
    };
  }

  @computed
  get eventCategories() {
    return this.eventsMappings.data.categories.map(this.prepareSelectItem);
  }

  @computed
  get eventCategoriesById() {
    return keyBy(this.eventCategories, "value");
  }

  @computed
  get eventAnalysts() {
    return this.eventsMappings.data.users.map(this.prepareSelectItem);
  }

  @computed
  get isEventEqual() {
    return isEqual(prepareEventObject(toJS(this.page.selectedEvent)), prepareEventObject(toJS(this.page.modalEvent)));
  }

  @computed
  get metrosGroupedByCode() {
    return keyBy(this.metros, "value");
  }

  @computed
  get airportGroupedByCode() {
    return keyBy(this.airports, "value");
  }

  @computed
  get conflictedLocationsMetros() {
    const { locations } = this.page.modalEvent;
    const conflicts = [];

    if (isEmpty(locations?.metros) || isEmpty(locations?.countries)) {
      return conflicts;
    }

    const countries = getPlaceCodes(locations.countries);
    locations.metros.forEach((metro, index) => {
      const metroCode = typeof metro === "string" ? metro : metro.code;
      if (countries.includes(this.metrosGroupedByCode[metroCode]?.country)) {
        conflicts.push(index);
      }
    });
    return conflicts;
  }

  @computed
  get conflictedLocationsAirport() {
    const { locations } = this.page.modalEvent;
    const conflicts = [];

    if (
      isEmpty(locations?.airports) ||
      (!isEmpty(locations?.airports) && isEmpty(locations?.metros) && isEmpty(locations?.countries))
    ) {
      return conflicts;
    }

    const metros = getPlaceCodes(locations.metros);
    const countries = getPlaceCodes(locations.countries);
    locations.airports.forEach((airport, index) => {
      const airportCode = typeof airport === "string" ? airport : airport.code;
      if (
        (!isEmpty(locations?.countries) && countries.includes(this.airportGroupedByCode[airportCode]?.country)) ||
        (!isEmpty(locations?.metros) && metros.includes(this.airportGroupedByCode[airportCode]?.metro))
      ) {
        conflicts.push(index);
      }
    });
    return conflicts;
  }

  @computed
  get isConflicted(): boolean {
    return !isEmpty(this.conflictedLocationsMetros) || !isEmpty(this.conflictedLocationsAirport);
  }

  @computed
  get isSuggestModalLoading(): boolean {
    return this.page.modalSuggestStatus === Status.LOADING;
  }
}

export default EventsManagementStore;
