const commonRows = [
  { key: "numberOfImpactedFlights" },
  { key: "sellingPrice" },
  { key: "pricePercentile" },
  { key: "lacRank" }
];

const priceValueAdjustment = {
  disabled: true,
  isPreview: false,
  rows: [...commonRows],
  name: "Price Adjustment ($)",
  type: "PD"
};

const pricePercentAdjustment = {
  disabled: false,
  isPreview: true,
  rows: [...commonRows, { key: "priceAdjustment" }],
  name: "Price Adjustment (%)",
  type: "PA"
};

const competitorSensitivityAdjustment = {
  disabled: true,
  isPreview: false,
  rows: [...commonRows],
  name: "Competitor Sensitivity",
  type: "CS"
};

const priceLimitsAdjustment = {
  disabled: false,
  isPreview: true,
  rows: [...commonRows, { key: "minmaxAdjustmentMin" }, { key: "minmaxAdjustmentMax" }],
  name: "Price Limits (Min/Max)",
  type: "MM"
};

export const INFLUENCES_LIST = [
  priceValueAdjustment,
  pricePercentAdjustment,
  competitorSensitivityAdjustment,
  priceLimitsAdjustment
];

export const influenceTypeNames = Object.fromEntries(INFLUENCES_LIST.map(({ type, name }) => [type, name]));

export const influenceStatusNames = {
  active: "Active",
  expired: "Expired",
  deleted: "Deleted"
};

export const HIGHEST_START_NDO = 500;
