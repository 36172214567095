// @flow

import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import * as queryString from "query-string";
import { Button, Card, Colors } from "@blueprintjs/core";

import VerifyEmailCallout from "../components/VerifyEmailCallout";
import { useStores } from "../store/Store";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";

const LoginStyled = styled("div")`
  height: 100vh;
  background: ${Colors.LIGHT_GRAY4};
`;

const CardStyled = styled(Card)`
  padding: 0;
  width: 300px;
`;

const LoginLogo = styled(LogoIcon)`
  height: 100px;
  margin: 20px;
  width: auto;
`;

const Login = observer(() => {
  const { appStore } = useStores();

  const search = queryString.parse(document.location.search);
  const { accessToken, page, success, message, email } = search;

  if (page === "verify") {
    appStore.resetPassword(email);

    return (
      <LoginStyled className="d-flex">
        <div className="d-flex align-items-center justify-content-center w-100">
          <CardStyled className="d-flex flex-column p-2">
            <LoginLogo alt="Cirrus logo" />
            <VerifyEmailCallout isSuccess={success === "true"} message={message} />
          </CardStyled>
        </div>
      </LoginStyled>
    );
  }

  if (accessToken) {
    appStore.logInWithToken(accessToken);
  }

  return (
    <LoginStyled className="d-flex">
      <div className="d-flex align-items-center justify-content-center w-100">
        <CardStyled className="d-flex flex-column p-2">
          <LoginLogo alt="Cirrus logo" />
          <Button className="mt-4" disabled={appStore.isLoading} onClick={() => appStore.logIn()} large fill>
            {appStore.isLoading ? "Loading..." : "Authorize Access"}
          </Button>
        </CardStyled>
      </div>
    </LoginStyled>
  );
});

export default Login;
