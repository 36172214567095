// @flow

import React from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import FiltersSwitch from "./FiltersSwitch";
import { ReactComponent as FilterIcon } from "../assets/filter-icon.svg";

type Props = {
  buttonProps: {
    isLoading: boolean,
    onClick: Function,
    testId: string
  },
  filterSwitchProps: {
    checked: boolean,
    disabled: boolean,
    onChange: Function
  },
  withSwitchButton: boolean
};

function FilterInteractionsWrapper({ filterSwitchProps, buttonProps, withSwitchButton = true }: Props) {
  const { isLoading, onClick, testId, ...restButtonProps } = buttonProps;

  return (
    <div className="ml-auto d-flex align-items-center">
      {withSwitchButton && <FiltersSwitch {...filterSwitchProps} />}
      <Button
        data-testid={testId}
        icon={<FilterIcon alt="Filters toggle" />}
        loading={isLoading}
        minimal
        onClick={() => onClick(true)}
        {...restButtonProps}
      />
    </div>
  );
}

export default observer(FilterInteractionsWrapper);
