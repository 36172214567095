// @flow

import React from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import pluralize from "pluralize";

import { Button } from "@blueprintjs/core";
import { countAppliedFilters } from "../helpers/sidebarCounters";
import groupConditionalFilters from "../helpers/groupConditionalFilters";

type Props = {
  onSubmit: Function,
  pageContext: Object,
  type: string
};

function SidebarApplyButton(props: Props) {
  const { onSubmit, pageContext = { applied: {} }, type = "" } = props;
  const searchParamsApplied = Object.keys(pageContext).includes("searchParams");

  const appliedFilters = {
    ...pageContext.applied.filters,
    ...groupConditionalFilters(pageContext.applied.conditionalFilters)
  };

  const appliedEntries = Object.keys(appliedFilters).reduce(
    (obj, key) => ({
      ...obj,
      [key]: appliedFilters[key]
    }),
    {}
  );

  const pageFilters = {
    applied: {
      conditionalFilters: searchParamsApplied ? {} : pageContext.applied && pageContext.applied.conditionalFilters,
      filters: searchParamsApplied ? pageContext.searchParams : pageContext.applied.filters
    },
    conditionalFilters: pageContext.conditionalFilters || {},
    filters: pageContext.filters
  };

  const currentFilters = {
    ...pageFilters.filters,
    ...groupConditionalFilters(pageFilters.conditionalFilters)
  };

  const currentEntries = Object.keys(currentFilters).reduce(
    (obj, key) => ({
      ...obj,
      [key]: currentFilters[key]
    }),
    {}
  );

  const isDisabled = isEqual(appliedEntries, currentEntries);
  const numberOfChanges = countAppliedFilters(type, currentEntries);

  return (
    <Button
      disabled={isDisabled}
      fill
      intent="primary"
      onClick={onSubmit}
      text={`Apply (${pluralize("filter", numberOfChanges, true)})`}
    />
  );
}

export default observer(SidebarApplyButton);
