// @flow

import React from "react";
import { observer } from "mobx-react";

import styled from "@emotion/styled";
import { Button, Colors, Icon, Menu, Popover, Position, Tooltip } from "@blueprintjs/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { toJS } from "mobx";

import { useStores } from "../store/Store";
import HeaderTabs from "./HeaderTabs";
import HeaderLogo from "../components/HeaderLogo";
import { ReactComponent as LogoIcon } from "../assets/logo-header.svg";
import { isLocalhost, isNotCypress } from "../helpers/constants";
import { ReactComponent as FlyrLogo } from "../assets/flyr-logotype.svg";

const StyledCoyprightText = styled("span")`
  color: ${Colors.DARK_GRAY5};
  font-size: 10px;
  line-height: 1;
`;

export const StyledHeader = styled("nav")`
  min-height: 40px;
  background-color: #000340;
`;

const StyledMenu = styled(Menu)`
  width: 200px;
`;

const StyledSettingsButton = styled(Button)`
  border-radius: 2px 2px 0 0;
  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px ${Colors.BLUE3}99 !important;
  }
`;

type Props = {
  isAdmin: boolean
};

const Header = ({ isAdmin = false }: Props) => {
  const rootStore = useStores();
  const { appStore, templatesStore } = rootStore;

  const history = useHistory();
  const location = useLocation();
  const { show } = useIntercom();

  const menu = (
    <StyledMenu className="p-0 pt-1">
      <Menu.Item
        className="px-3 py-1"
        data-testid="influence-history-link"
        icon={<Icon className="mr-2" icon="gantt-chart" />}
        text="Influence History"
        onClick={() => history.push("/influence-history")}
      />
      <Menu.Item
        className="px-3 py-1"
        data-testid="events-link"
        icon={<Icon className="mr-2" icon="timeline-events" />}
        onClick={() => history.push("/events-management")}
        text="Events Management"
      />
      {isAdmin && (
        <>
          <Menu.Item
            className="px-3 py-1"
            data-testid="user-management-link"
            icon={<Icon className="mr-2" icon="people" />}
            text="User Management"
            onClick={() => history.push("/user-management")}
          />
          <Menu.Item
            className="px-3 py-1"
            data-testid="settings-link"
            icon={<Icon className="mr-2" icon="wrench" />}
            text="Admin Tools"
            onClick={() => history.push("/system-units")}
          />
        </>
      )}
      <Menu.Divider className="mx-3" />
      <Menu.Item
        className="px-3 py-1"
        data-testid="logout-link"
        icon={<Icon className="mr-2" icon="power" />}
        text="Log Out"
        onClick={appStore.logOut}
      />
      <div className="d-flex justify-content-between align-items-baseline pb-2 px-3" data-testid="copyright">
        <StyledCoyprightText>© 2021 - FLYR, INC.</StyledCoyprightText>
        <FlyrLogo className="ml-auto" height="18" />
      </div>
    </StyledMenu>
  );

  const isRoot = location.pathname === "/" || location.pathname === "/dashboard";

  return (
    <StyledHeader className="d-flex align-items-end px-2">
      <HeaderLogo active={isRoot}>
        <Link className="navbar-brand pt-1" data-testid="dashboard-link" to="/dashboard">
          <LogoIcon alt="Cirrus logo" />
        </Link>
      </HeaderLogo>
      <HeaderTabs className="flex-grow-1" />
      <Tooltip boundary="viewport" content="Browse Saved Analyses and available Templates" hoverOpenDelay={1000}>
        <StyledSettingsButton
          className="mr-1"
          data-testid="templates-sidebar-btn"
          icon={<Icon color={Colors.GOLD5} icon="bookmark" />}
          minimal
          onClick={templatesStore.setSidebarOpen}
        />
      </Tooltip>
      {isLocalhost ? (
        <StyledSettingsButton
          className="mr-1"
          icon={<Icon className="opacity-50" color={Colors.WHITE} icon="code" />}
          minimal
          onClick={() =>
            // eslint-disable-next-line no-console
            console.log(toJS(rootStore, { recurseEverything: true }))
          }
          title="Log store to console"
        />
      ) : null}
      {isNotCypress ? (
        <StyledSettingsButton
          data-testid="help-link"
          className="mr-1"
          icon={<Icon className="opacity-50" color={Colors.WHITE} icon="help" />}
          minimal
          onClick={show}
          title="Get help"
        />
      ) : null}
      <div className="mr-1" data-testid="user-menu">
        <Popover content={menu} position={Position.BOTTOM_RIGHT}>
          <StyledSettingsButton icon={<Icon className="opacity-50" color={Colors.WHITE} icon="cog" />} minimal />
        </Popover>
      </div>
    </StyledHeader>
  );
};

export default observer(Header);
