// @flow

import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";

import Module from "./Module";
import DataTable from "./DataTable";
import { extraMetrics, metricsTypes } from "../helpers/constants";
import metricLabels from "../helpers/metricLabels/metricLabels";
import columnTextWidths from "../helpers/ColumnTextWidths";
import MileTransformationContext from "../services/contexts/MileTransformationContext";
import { mergeContextualColumns } from "../helpers/flightsTable";
import { Status } from "../helpers/Status";
import { formatLastUpdatedText } from "../helpers/textUtils";
import { FILTERS_INIT, Tab } from "../models/Tab.model";
import FlightsTableTools from "./FlightsTableTools";
import NumberOfFlights from "./NumberOfFlights";

type Props = {
  baseMetrics: Array<string | Array<string>>,
  columnSelectDisabled: boolean,
  computedDateFormat: string,
  isPreviewActive: boolean,
  subpage: ?string,
  tab: Tab
};

export const columnWidths = {
  depDate: 85
};

function FlightsTable(props: Props) {
  const {
    baseMetrics = [],
    columnSelectDisabled,
    computedDateFormat = "yyyy-MM-dd",
    isPreviewActive,
    subpage,
    tab
  } = props;
  const { flightsTable, flightsCount, xDayBuild } = tab;
  const { aggregations, data, influenceImpactGroupColumns, lastUpdated, pagination, status } = flightsTable;
  const { selectedRowsNumberOfFlights } = flightsCount;
  const isLoading = status === Status.LOADING;
  const isInfluence = subpage === "influence";

  const fixedColumns = {
    ...flightsTable.fixedColumns,
    influenceGroup: isInfluence ? "right" : flightsTable.fixedColumns.influenceGroup,
    influenceImpactGroup: "right",
    selection: "left"
  };

  const transformToMile = useContext(MileTransformationContext);
  const columnLabels = {
    ...extraMetrics,
    ...metricLabels({ transformToMile, withUnits: true, xDayBuild }),
    depDate: "Dep Date",
    depDow: "Dep DOW",
    depMonth: "Dep Month",
    depTime: "Dep Time",
    depTimeBucket: "Dep Time Bucket",
    depWeek: "Dep Week",
    departureDayOfWeek: "Dep DOW",
    destination: "Dest",
    distance: "Dist",
    flightCount: "Flt Count",
    flightNumber: "Flight Num",
    flightNumber_1: "Flight Num",
    ndo: "NDO",
    odPairs: "ODs",
    numberOfFlights: "Flt Count"
  };

  const columnDescriptions = metricLabels({
    longVersion: true,
    transformToMile,
    xDayBuild
  });

  const filterLabels = {
    ...extraMetrics,
    ...metricLabels({ transformToMile: tab.distanceUnit === "mi", withParent: true, withUnits: true, xDayBuild }),
    depTime: "Departure Time"
  };

  const columns = useMemo(
    () => {
      return mergeContextualColumns(flightsTable.columns, influenceImpactGroupColumns);
    },
    [influenceImpactGroupColumns, flightsTable.columns] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const sortedColumns = useMemo(() => {
    return columns.slice().sort(([columnIdA], [columnIdB]) => {
      if (fixedColumns[columnIdA] === "left") {
        if (fixedColumns[columnIdB] === "left") {
          return 0;
        }
        return -1;
      }
      return 1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fixedColumns), columns]);

  const getRowIdCallback = (row: Row) =>
    aggregations
      .filter(aggregationKey => aggregationKey !== "numberOfFlights")
      .map(aggr => row[aggr])
      .filter(Boolean)
      .join("_");

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), [columnLabels]);

  const onColumnFilter = columnId => {
    tab.setSidebarOpen(true);
    tab.setSidebarFilterQuery(filterLabels[columnId], columnId);
  };

  const onSortedChange = currentSort => {
    pagination.pageIndex = 0;

    if (currentSort) {
      tab.flightsTable.sortBy = {
        direction: currentSort[0].desc ? "desc" : "asc",
        field: currentSort[0].id
      };
    }
  };

  const tableProps = {
    ...tab.flightsTable,
    baseMetrics,
    selectedRowsNumberOfFlights,
    fetchData: tab.refetchFlightsTableData,
    cellRenderers: metricsTypes,
    columnAccessors: {
      analystId: "analyst",
      regionId: "region",
      subregionId: "subregion"
    },
    columnConfig: {
      columnsWithoutFixedNumber: [
        "finalLoadFactorBaseline",
        "finalLoadFactorExpected",
        "priceAdjustment",
        "pricePercentile",
        "pricePercentileFrm",
        "xDayFinalLoadFactorBuildExpected",
        "xDayFinalLoadFactorExpected",
        "xDayPricePercentile",
        "xDayPricePercentileBuild",
        "xDayPricePercentileFrm"
      ],
      columnsAligned: {
        numberOfFlights: "right",
        depDow: "left"
      }
    },
    columnDescriptions,
    columnLabels,
    columns: [["aggregations", [...aggregations, "numberOfFlights"]], ...sortedColumns],
    columnTextWidth,
    columnWidths,
    fixedColumns,
    getRowIdCallback,
    hideColumn: tab.hideColumn,
    isAdjustable: true,
    isPreviewActive,
    initialFilters: FILTERS_INIT,
    nonFilterableColumns: ["numberOfFlights", "numberOfImpactedFlights"],
    onColumnFilter,
    onRowToggle: tab.updateSelectedRows,
    onShiftToggle: tab.shiftToggleRows,
    onSortedChange,
    toggleFixedColumn: tab.toggleFixedColumn
  };

  const lastUpdatedText = lastUpdated ? formatLastUpdatedText(lastUpdated, computedDateFormat) : "";

  const subtitle = !isLoading ? [lastUpdatedText].filter(Boolean).join(" · ") : null;
  const extraSubContent = (
    <NumberOfFlights
      selectedRowsNumberOfFlights={tab.selectedRowsNumberOfFlights}
      flightsCount={tab.flightsCount}
      flightsTable={tab.flightsTable}
    />
  );

  return (
    <div className="d-flex flex-column flex-shrink-1 mh-0">
      <Module
        minHeight={data.length > 7 ? 315 : 0}
        subtitle={[extraSubContent, subtitle && `· ${subtitle}`]}
        title="Flights"
        titleTools={
          <FlightsTableTools
            tab={tab}
            aggregationsDisabled={isLoading || isInfluence}
            columnSelectDisabled={isLoading || columnSelectDisabled}
            baseMetrics={baseMetrics}
            columnLabels={columnLabels}
          />
        }
        className="flex-shrink-1 mb-2"
        childrenClassName="d-flex flex-column h-100"
      >
        <div data-testid="flights-table" className="d-flex flex-column h-100">
          <DataTable {...tableProps} />
        </div>
      </Module>
    </div>
  );
}

export default observer(FlightsTable);
