import { Card, Colors, NumericInput } from "@blueprintjs/core";
import styled from "@emotion/styled";

const disabledColor = `${Colors.LIGHT_GRAY1}80`; // opacity 50%

export const StyledButtonIcon = styled("div")`
  background: ${({ disabled }) => (disabled ? disabledColor : Colors.LIGHT_GRAY4)};
  border: ${({ disabled }) => `1px solid ${disabled ? disabledColor : Colors.GRAY5}`};
  border-right: 0;
  box-sizing: border-box;
  border-radius: 3px 0px 0px 3px;
  color: ${Colors.GRAY1};
  padding: 5px 10px;
`;

export const StyledInfluenceInput = styled(NumericInput)`
  &,
  .bp3-input-group {
    border-radius: 0;
  }
  .bp3-button:focus {
    outline-offset: 0;
  }
`;

export const StyledCard = styled(Card)`
  border: 1px solid ${Colors.LIGHT_GRAY2};
  box-shadow: none;
  overflow: hidden;
`;

export const StyledHeaderTitle = styled("strong")`
  letter-spacing: 0.5px;
`;
