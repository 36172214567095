import React from "react";
import { observer } from "mobx-react";

import SidebarSingleSelect from "../SidebarSingleSelect";
import { useStores } from "../../store/Store";

type Props = {
  onChange: Function,
  selectedAnalyst: { label: string, key: number } | null
};

function MarketMappingsAnalystSelect(props: Props) {
  const { onChange, selectedAnalyst } = props;
  const { usersStore } = useStores();
  const analystList = usersStore.analystList;

  return (
    <div className="d-flex">
      <SidebarSingleSelect
        items={analystList}
        selectedItem={selectedAnalyst}
        selectItem={item => onChange(item)}
        title="Analyst"
      />
    </div>
  );
}

export default observer(MarketMappingsAnalystSelect);
