// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, Icon, InputGroup, Menu, MenuDivider, MenuItem, Popover, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";

import SidebarFilter from "./SidebarFilter";

const MenuStyled = styled(Menu)`
  max-width: 180px;
  max-height: 350px !important;
`;

const ApplyButton = styled(MenuItem)`
  bottom: 5px;
  left: 5px;
  right: 5px;
`;

type Props = {
  aggregations: Array<string>,
  changeAggregations: Function,
  disabled: boolean
};

const groupTitles = {
  departure: "Departure",
  other: "Additional",
  route: "Route"
};

function AggregationSelect(props: Props) {
  const { aggregations = [], changeAggregations, disabled = false } = props;
  const [aggregationsState, setAggregationsState] = useState([...aggregations]);
  const [filterQuery, setFilterQuery] = useState("");
  const items = [
    {
      label: "Region",
      value: "regionId",
      group: "route"
    },
    {
      label: "Subregion",
      value: "subregionId",
      group: "route"
    },
    {
      label: "RT Market",
      value: "rtMarket",
      group: "route"
    },
    {
      label: "OW Market",
      value: "owMarket",
      group: "route"
    },
    {
      label: "Origin",
      value: "origin",
      group: "route"
    },
    {
      label: "Destination",
      value: "destination",
      group: "route"
    },
    {
      label: "Quarter",
      value: "quarter",
      group: "departure"
    },
    {
      label: "Month",
      value: "depMonth",
      group: "departure"
    },
    {
      label: "Week",
      value: "depWeek",
      group: "departure"
    },
    {
      label: "Day of Week",
      value: "depDow",
      group: "departure"
    },
    {
      label: "Date",
      value: "depDate",
      group: "departure"
    },
    {
      label: "Airline",
      value: "airline",
      group: "other"
    },
    {
      label: "Flight Number",
      value: "flightNumber",
      group: "other"
    },
    {
      label: "Analyst",
      value: "analystId",
      group: "other"
    },
    {
      label: "Cabin Class",
      value: "cabinClass",
      group: "other"
    },
    {
      label: "Dep Time Bucket",
      value: "depTimeBucket",
      group: "other"
    }
  ];

  const groups = ["route", "departure", "other"];

  function addAggregation(name: string) {
    const flatItems = items.map(item => item.value);
    setAggregationsState(
      [...aggregationsState, name].sort((a, b) => (flatItems.indexOf(a) > flatItems.indexOf(b) ? 1 : -1))
    );
  }

  function removeAggregation(name: string) {
    setAggregationsState(aggregationsState.filter(aggr => aggr !== name));
  }

  function mapItem(item: { label: string, value: string }) {
    const isSelected = aggregationsState.includes(item.value);
    const checkbox = (
      <Checkbox
        className="m-0 py-1"
        checked={isSelected}
        label={item.label}
        onChange={e => (e.target.checked ? addAggregation(item.value) : removeAggregation(item.value))}
      />
    );

    return (
      <SidebarFilter key={item.value} name={item.label} filter={filterQuery}>
        <MenuItem
          className="p-0"
          text={<div className="px-2">{checkbox}</div>}
          shouldDismissPopover={false}
          data-testid={`checkbox-${item.value}`}
        />
      </SidebarFilter>
    );
  }

  function applyChanges() {
    setFilterQuery("");
    changeAggregations(aggregationsState);
  }

  const itemGroups = groups.map(group => {
    return (
      <React.Fragment key={group}>
        <MenuDivider title={groupTitles[group]} key={group} />
        {items.filter(item => item.group === group).map(mapItem)}
      </React.Fragment>
    );
  });

  const menu = (
    <MenuStyled data-testid="aggregation-menu" className="mb-5">
      <InputGroup
        autoComplete="off"
        data-testid="search-aggregations"
        autoFocus
        id="text-input"
        className="m-1 mb-2"
        leftIcon="search"
        placeholder="Search..."
        value={filterQuery}
        onChange={({ target }) => setFilterQuery(target.value.toLowerCase())}
        rightElement={filterQuery.length ? <Button icon="cross" minimal onClick={() => setFilterQuery("")} /> : null}
      />
      {itemGroups}
      <ApplyButton
        className="position-fixed"
        text={
          <Button intent="primary" fill onClick={() => applyChanges()}>
            Apply
          </Button>
        }
      />
    </MenuStyled>
  );

  const button = (
    <Button
      className="d-flex"
      icon="layout-hierarchy"
      data-testid="aggregations-select"
      disabled={disabled}
      onClick={() => setAggregationsState([...aggregations])}
    >
      <span>
        Aggregations: <strong>{aggregations && aggregations.length}</strong>
      </span>
      <Icon className="ml-3" icon="caret-down" />
    </Button>
  );

  return (
    <Popover content={menu} disabled={disabled} position={Position.BOTTOM} minimal>
      {button}
    </Popover>
  );
}

export default observer(AggregationSelect);
