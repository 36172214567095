import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import Row from "../Row";
import { useStores } from "../../store/Store";
import DistanceControl from "./DistanceControl";
import { SystemSettingsDescription, SystemSettingsSectionHeader } from "./SystemSettingsUI";

type Props = {
  distanceUnit: string,
  onChange(): void
};

function SystemSettingsDistance(props: Props) {
  const { systemUnitsStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    systemUnitsStore
      .getDistanceUnit()
      .then(distanceUnit => props.onChange({ distanceUnit }))
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SystemSettingsSectionHeader className="mt-4">Distance Units</SystemSettingsSectionHeader>
      <Row>
        <div className="col-6">
          <SystemSettingsDescription className="m-0">
            Select between metric and imperial units as default. This will update all distance-related metrics in the
            system.
          </SystemSettingsDescription>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-start">
          <DistanceControl onChange={props.onChange} distanceUnit={props.distanceUnit} isLoading={isLoading} />
        </div>
      </Row>
    </>
  );
}

export default observer(SystemSettingsDistance);
