const focusOnFilter = (filterKey, filterQuery, sidebarRef) => {
  if (filterKey && filterQuery) {
    const input = sidebarRef.current.querySelector(".visible-filter input");
    const conditionalFilterButton = sidebarRef.current.querySelector(
      ".visible-filter [data-testid='sidebar-add-condition-button']"
    );

    if (input) {
      setTimeout(() => input.focus(), 200);
    } else if (conditionalFilterButton) {
      conditionalFilterButton.click();
    } else {
      const button = sidebarRef.current.querySelector(".visible-filter button");
      button && button.focus();
    }
  }
};

export default focusOnFilter;
