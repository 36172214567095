// @flow

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { useExpanded, useFlexLayout, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { Button, Colors, Icon, NonIdealState, Spinner } from "@blueprintjs/core";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import clsx from "clsx";

import DataTableCell from "./DataTableCell";
import DataTableHeader from "./DataTableHeader";
import DataTablePagination from "./DataTablePagination";
import getColumnWidth from "../helpers/getColumnWidth";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { Status } from "../helpers/Status";
import { ThBoldTextWidth } from "../helpers/ColumnTextWidths";
import { usePrevious } from "../helpers/usePrevious";
import { isMacOs } from "../helpers/isMacOs";
import isNumber from "../helpers/isNumber";

const StyledDataTableContainer = styled("div")`
  max-height: 100%;
  overflow: auto;
  width: 100%;
`;

const StyledDataTable = styled("table")`
  border-spacing: 0;
  width: 100%;

  tbody {
    position: relative;
    z-index: 0;

    tr:last-child {
      margin-bottom: ${({ isMac, showPagination }) => (isMac && showPagination ? "0.5rem" : "0")};
    }
  }

  td {
    font-size: 12px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  th,
  td {
    border-bottom: 1px solid ${Colors.LIGHT_GRAY1};
    border-right: 1px solid ${Colors.LIGHT_GRAY1};
    margin: 0;
    padding: 0.2rem 0.25rem;

    &:last-child {
      border-right: 0;
    }
  }

  th {
    align-items: center;
    display: flex;
    padding: 0.35rem 0.25rem;
  }

  [data-sticky-td] {
    position: sticky;
  }

  [data-sticky-last-left-td] {
    border-right: 2px solid ${Colors.LIGHT_GRAY1};
    box-shadow: 4px 0 3px -1px #75757533;
  }

  [data-sticky-first-right-td] {
    border-left: 2px solid ${Colors.LIGHT_GRAY1};
    box-shadow: -4px 0 3px -1px #75757533;
  }

  input:disabled {
    cursor: not-allowed !important;
  }
`;

const StyledTableRow = styled("tr")`
  background: ${({ isSelected, isNested }) => {
    if (isSelected) return "#E1F1FD";
    if (isNested) return Colors.LIGHT_GRAY5;
    return Colors.WHITE;
  }};

  &:hover {
    background: #fff4db;
    cursor: ${({ onClick }) => (onClick ? "pointer" : "normal")};
  }
`;

const StyledTableCell = styled("td")`
  background: inherit;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const flattenRows = row => [row, ...(row.subRows || [])];

type Props = {
  cellRenderers: ?Object,
  columnAccessors: ?Object,
  columnConfig: ?Object,
  columnDescriptions: ?Object,
  columnLabels: ?Object,
  columns: Array<Object>,
  columnSortType: ?Object,
  columnTextWidth: ?Object,
  columnWidths: ?Object,
  data: Array<Object>,
  disabledRows: Array<String> | Array<Number>,
  expandableRows: boolean, // requires valid rowIdAccessor
  fetchData: Function,
  filters: ?Object,
  fixedColumns: ?Object,
  getRowIdCallback: ?Function,
  headers: ?Object,
  hideColumn: Function,
  initialFilters: ?Object,
  isAdjustable: boolean,
  isPreviewActive: boolean,
  nonFilterableColumns: Array<string>,
  onColumnFilter: ?Function,
  onRowClick: ?Function,
  onRowToggle: ?Function,
  onShiftToggle: ?Function,
  onSortedChange: ?Function,
  pagination: {
    pageIndex: number,
    pageSize: number,
    pageCount: number,
    totalRows: number
  },
  rowIdAccessor: string,
  selectableSubRows: boolean,
  selectedRows: ?Array<string>,
  showPagination: boolean,
  sortable: boolean | string,
  sortBy: {
    field: string,
    direction: string
  },
  status: string
};

const ARROW_SIZE = 16;

function DataTable(props: Props & Row & Cell) {
  const {
    cellRenderers = {},
    columnAccessors = {},
    columnConfig = {},
    columnDescriptions = {},
    columnLabels = {},
    columnSortType = {},
    columnTextWidth = {},
    columnWidths = {},
    data = [],
    disabledRows = [],
    expandableRows = false,
    fetchData = null,
    filters = {},
    fixedColumns = {},
    getRowIdCallback,
    headers = {},
    hideColumn,
    initialFilters,
    isAdjustable = false,
    isPreviewActive = false,
    nonFilterableColumns = [],
    onColumnFilter = null,
    onRowClick = null,
    onRowToggle,
    onShiftToggle,
    onSortedChange,
    pagination = {},
    rowIdAccessor = "id",
    selectableSubRows = false,
    selectedRows = null,
    showPagination = true,
    sortable = "backend",
    sortBy = {},
    status,
    toggleFixedColumn
  } = props;

  const [lastSelectedRow, setLastSelectedRow] = useState("");

  const getAccessor = name => {
    return columnAccessors && columnAccessors[name] ? columnAccessors[name] : name;
  };

  const createColumn = (column, depth = 0, parentColumn, columnIndex) => {
    let accessor,
      accessorName,
      columns,
      id,
      sortType = "alphanumeric";

    if (Array.isArray(column)) {
      const firstColumnName = column[0];
      accessor = getAccessor(firstColumnName);
      accessorName = firstColumnName;
      id = firstColumnName;

      columns = column[1].map((subcolumn, index) => createColumn(subcolumn, depth + 1, column, index));
    } else {
      accessor = getAccessor(column);
      accessorName = column;
      id = column;
      sortType = columnSortType[column] ? columnSortType[column] : "alphanumeric";
    }
    const headerText = columnLabels[accessorName] ? columnLabels[accessorName] : accessorName;

    const Header = <div className="text-truncate">{headerText}</div>;

    const isFixed = !depth && fixedColumns[accessorName];
    const sticky = isFixed ? fixedColumns[accessorName] : undefined;
    const isActiveColumn = sortBy.field === accessorName;
    let textWidth = columnWidths[accessorName] || columnTextWidth[accessorName];

    if (isActiveColumn) {
      textWidth = ThBoldTextWidth(headerText);
    }
    const customRender = cellRenderers && cellRenderers[column];
    const isCustomRendererFunction = typeof customRender === "function";

    let size = getColumnWidth({
      data,
      accessor,
      headerWidth: textWidth,
      fixedWidth: columnWidths[accessorName],
      ...(!isCustomRendererFunction && { renderType: customRender })
    });

    if (parentColumn) {
      const parentColumnTitle = parentColumn[0];
      const parentColumnChildren = parentColumn[1];
      const isLast = parentColumnChildren.length - 1 === columnIndex;

      const groupParentWidth = ThBoldTextWidth(columnLabels[parentColumnTitle]);
      columnTextWidth[parentColumnTitle] = groupParentWidth;

      const wholeGroupWidth = parentColumnChildren.reduce(
        (summed, columnNameInParent) => summed + columnTextWidth[columnNameInParent],
        0
      );

      if (groupParentWidth > wholeGroupWidth && isLast) {
        size = size + groupParentWidth - wholeGroupWidth;
      }
    }
    let width = size;
    if (isActiveColumn) width += ARROW_SIZE;
    return { Header, accessor, columns, sticky, width, id, sortType };
  };

  const selectRow = (row: Row) => {
    const rowNumberOfFlights = (row.values && row.values.numberOfFlights) || 0;
    onRowToggle && onRowToggle([...selectedRows, row.id], { [row.id]: rowNumberOfFlights });
  };

  const deselectRow = (row: Row) => {
    const elementIndex = selectedRows.indexOf(row.id);
    const copiedSelectedRows = selectedRows.slice(0);
    copiedSelectedRows.splice(elementIndex, 1);

    onRowToggle && onRowToggle(copiedSelectedRows, { [row.id]: undefined });
  };

  const toggleRow = (event, row: Row) => {
    if (event.shiftKey && lastSelectedRow) {
      onShiftToggle(lastSelectedRow, row.id);
    } else if (selectedRows.includes(row.id)) {
      deselectRow(row);
    } else {
      selectRow(row);
    }
    setLastSelectedRow(row.id);
  };

  const isSelectableRow = row => (selectableSubRows || !row.depth) && !disabledRows.includes(row.id);

  const isEveryRowSelected = (rows: Array<Row>) => {
    if (selectedRows) {
      return rows
        .flatMap(flattenRows)
        .filter(isSelectableRow)
        .every(row => selectedRows.includes(row.id));
    }
  };

  const isAnyRowSelected = (rows: Array<Row>) => {
    if (selectedRows) {
      if (isEveryRowSelected(rows)) {
        return false;
      }
      return rows.flatMap(flattenRows).some(row => selectedRows.includes(row.id));
    }
  };

  const toggleAllRows = (rows: Array<Row>) => {
    const flatRows = rows.flatMap(flattenRows);
    if (selectedRows) {
      if (isEveryRowSelected(rows)) {
        flatRows.forEach(row => {
          if (isSelectableRow(row)) {
            deselectRow(row);
          }
        });
      } else {
        flatRows.forEach(row => {
          if (isSelectableRow(row) && !selectedRows.includes(row.id)) {
            selectRow(row);
          }
        });
      }
    }
  };

  const createExpanderColumn = () => {
    const expanderCell = {
      accessor: "expander",
      width: 24,
      Header: "",
      Cell: ({ row }: Cell) =>
        row.canExpand ? (
          <div className="d-flex justify-content-center align-items-center h-100" {...row.getToggleRowExpandedProps()}>
            <Icon icon={`chevron-${row.isExpanded ? "down" : "right"}`} />
          </div>
        ) : null
    };
    return {
      accessor: "expand",
      sticky: fixedColumns && fixedColumns.expand,
      Header: "",
      columns: [expanderCell]
    };
  };

  const createCheckboxColumn = () => {
    const checkboxCell = {
      accessor: "checkbox",
      width: 24,
      Header: ({ page }: Cell) => (
        <div className="d-flex justify-content-center align-items-center h-100 flex-grow-1">
          <IndeterminateCheckbox
            checked={!isPreviewActive && isEveryRowSelected(page)}
            disabled={isPreviewActive}
            indeterminate={isAnyRowSelected(page)}
            onChange={() => toggleAllRows(page)}
          />
        </div>
      ),
      Cell: ({ row }: Cell) =>
        (selectableSubRows || !row.depth) && selectedRows ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <IndeterminateCheckbox
              checked={selectedRows.includes(row.id)}
              disabled={isPreviewActive || disabledRows.includes(row.id)}
              onClick={event => toggleRow(event, row)}
              readOnly
            />
          </div>
        ) : null
    };
    return {
      accessor: "selection",
      sticky: fixedColumns && fixedColumns.selection,
      Header: "",
      columns: [checkboxCell]
    };
  };

  const createColumns = () => {
    const array = [];
    if (isEmpty(data)) {
      return array;
    }

    if (expandableRows) {
      array.push(createExpanderColumn());
    }
    if (selectedRows) {
      array.push(createCheckboxColumn());
    }
    array.push(...props.columns.map(column => createColumn(column, 0)));
    return array;
  };

  const columns = useMemo(
    () => createColumns(),
    [lastSelectedRow, props.columns, props.data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // if expandableRows === true, rowIdAccessor must be valid
  const getRowId = useCallback(
    (row: Row, relativeIndex: number) => row[rowIdAccessor] || relativeIndex,
    [lastSelectedRow, rowIdAccessor] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    state: { pageIndex, pageSize, sortBy: currentSort }
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: sortable === "backend",
      disableMultiSort: true,
      disableSortRemove: true,
      disableSortBy: !sortable,
      pageCount: pagination.pageCount,
      initialState: {
        pageIndex: pagination.pageIndex,
        selectedRowIds: selectedRows ? selectedRows.reduce((result, rowId) => ({ ...result, [rowId]: true }), {}) : [],
        pageSize: pagination.pageSize,
        sortBy: sortBy ? [{ id: sortBy.field, desc: sortBy.direction === "desc" }] : []
      },
      getRowId: getRowIdCallback || getRowId
    },
    useFlexLayout,
    useSticky,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const isInitialized = useRef(false);
  const isBackendSort = sortable === "backend";
  const previousSorting = usePrevious(currentSort);

  useLayoutEffect(() => {
    if (!isInitialized.current) {
      // fetch data on first mount if there is no data
      if (fetchData && status !== Status.LOADING && !data.length) {
        fetchData({ pageIndex, pageSize }, { saveOptions: false });
      }
      isInitialized.current = true;
      return;
    }

    // fetch data on deps change
    if (fetchData) {
      const sortBy = isBackendSort
        ? {
            field: currentSort[0].id,
            direction: currentSort[0].desc ? "desc" : "asc"
          }
        : undefined;

      if (onSortedChange && !isEqual(previousSorting, currentSort)) {
        onSortedChange(currentSort);
      }

      fetchData({ pageIndex, pageSize, sortBy }, { saveOptions: !!onSortedChange });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [pageIndex, pageSize, isBackendSort && currentSort[0].id, isBackendSort && currentSort[0].desc]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (sortable) {
      setSortBy([{ id: sortBy.field, desc: sortBy.direction === "desc" }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy.field, sortBy.direction]);

  if (status === Status.INIT) {
    return <div className="m-5" />;
  }

  if (status === Status.LOADING) {
    return (
      <div className="m-5">
        <Spinner />
      </div>
    );
  }

  if (status === Status.ERROR) {
    return (
      <div className="m-5">
        <NonIdealState
          icon="issue"
          title="Error getting data"
          description="Please retry if possible. If this problem reoccurs, please contact FLYR."
          action={
            <Button
              icon="refresh"
              onClick={() => fetchData && fetchData({ pageIndex, pageSize }, { saveOptions: false })}
            >
              Retry Now
            </Button>
          }
        />
      </div>
    );
  }

  if (status === Status.DONE && !page.length && !pagination.totalRows) {
    return (
      <div className="m-5">
        <NonIdealState
          icon="geosearch"
          title="No data matching filters"
          description="Please change your search criteria."
        />
      </div>
    );
  }

  function renderCell(cell: Object) {
    const customRender = cellRenderers && cellRenderers[cell?.column?.id];
    const isCustomRendererFunction = typeof customRender === "function";
    const value = customRender ? cell.value : cell.render("Cell");
    const cellProps = cell.getCellProps();
    const columnId = cellProps ? cellProps.key.split("_").slice(-1).join() : "";
    const cellColumnId = cell?.column?.id;
    const withFixedNumber = !columnConfig?.columnsWithoutFixedNumber?.includes(cellColumnId);

    const content =
      customRender && isCustomRendererFunction ? (
        customRender(cell)
      ) : (
        <DataTableCell type={customRender} value={value} withFixedNumber={withFixedNumber} />
      );

    const contentType = content?.props?.type;
    const isPercent = ["percent", "percent-relative"].includes(contentType);
    const isInteger = contentType === "integer";

    const columnAligned = columnConfig?.columnsAligned?.[cellColumnId];
    const isPercentValue = isNumber(cell.value) || isPercent || isInteger;

    return (
      <StyledTableCell
        {...cellProps}
        className={clsx({
          "text-right": columnAligned === "right" || (columnAligned === undefined && isPercentValue),
          [`text-${columnAligned}`]: columnAligned
        })}
        data-testid={`data-table-cell-${columnId}`}
      >
        {content}
      </StyledTableCell>
    );
  }

  const tableBody = page.map((row: Row) => {
    prepareRow(row);

    const isSelected = selectedRows && selectedRows.includes(row.id);
    const rowProps = {
      isSelected,
      isNested: row.depth > 0,
      ...row.getRowProps(),
      onClick: onRowClick ? () => onRowClick(row) : null
    };

    return (
      <StyledTableRow {...rowProps} data-testid="data-table-row">
        {row.cells.map(renderCell)}
      </StyledTableRow>
    );
  });

  const headerProps = {
    columnDescriptions,
    columnSortType,
    filters,
    gotoPage,
    headerGroups,
    headers,
    hideColumn,
    initialFilters,
    isAdjustable,
    nonFilterableColumns,
    onColumnFilter,
    sortable: isPreviewActive ? false : sortable,
    toggleFixedColumn
  };

  const paginationProps = {
    pagination,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    previousPage,
    nextPage,
    isPreviewActive
  };

  const isMac = isMacOs();

  return (
    <div className="d-flex flex-column h-100">
      <StyledDataTableContainer>
        <StyledDataTable
          className="table d-flex flex-column"
          isMac={isMac}
          showPagination={showPagination}
          {...getTableProps()}
        >
          <thead className="header">
            <DataTableHeader {...headerProps} />
          </thead>
          <tbody className="body" {...getTableBodyProps()}>
            {tableBody}
          </tbody>
        </StyledDataTable>
      </StyledDataTableContainer>
      {showPagination ? <DataTablePagination {...paginationProps} /> : null}
    </div>
  );
}

export default observer(DataTable);
