// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Classes, Dialog, TextArea } from "@blueprintjs/core";
import styled from "@emotion/styled";

export const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
`;

type Props = {
  modal: Object
};

function DeleteInfluenceModal(props: Props) {
  const { modal } = props;
  const { deleteInfluences, selectedRows = [] } = modal.data;

  const [deleteComment, setDeleteComment] = useState("");

  const influencesCountText = selectedRows.length === 1 ? "Influence" : "Influences";

  return (
    <Dialog
      className="p-0"
      isOpen={modal.modalActive === "deleteInfluence"}
      onClose={() => modal.setModal("")}
      title={`Confirm Deleting ${influencesCountText}`}
    >
      <div className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="em-user-details">
        <div className="d-flex flex-column w-100">
          <span className="mb-2">
            <span className="font-weight-bold">Comment</span> (required)
          </span>
          <TextArea
            autoFocus
            data-testid="delete-influence-comment"
            growVertically
            onChange={({ target }) => setDeleteComment(target.value)}
            value={deleteComment}
          />
          <span className="mt-2">
            You are going to delete {selectedRows.length} {influencesCountText}.
          </span>
        </div>
      </div>

      <DialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" type="button" onClick={() => modal.setModal(null)}>
            Cancel
          </Button>
          <Button
            data-testid="delete-influence-button"
            disabled={!deleteComment.length}
            icon="trash"
            intent="primary"
            onClick={() => {
              deleteInfluences({
                influenceIds: [...selectedRows],
                comment: deleteComment
              });
              selectedRows.length = 0;
              modal.setModal(null);
            }}
          >
            Delete {influencesCountText}
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default observer(DeleteInfluenceModal);
