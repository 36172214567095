// @flow

import React from "react";
import { css, Global } from "@emotion/core";
import { AnchorButton, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";

type Props = {
  influencesList: Array<Object>,
  isFlightTableReady: boolean,
  setInfluenceType: Function,
  shouldShowTooltip: boolean
};

const globalStyle = (
  <Global
    styles={css`
      .bp3-disabled {
        outline: none;
      }
    `}
  />
);

function InfluenceControlSelection(props: Props) {
  const { influencesList, isFlightTableReady, setInfluenceType, shouldShowTooltip } = props;

  const buttons = influencesList.map(influence => {
    const { type, name, disabled: isInfluenceDisabled } = influence;

    const disabled = isInfluenceDisabled || !isFlightTableReady;
    const button = (
      <AnchorButton
        className="justify-content-between mb-2 w-100"
        disabled={disabled || shouldShowTooltip}
        intent="primary"
        key={type}
        large
        onClick={() => setInfluenceType(type)}
        outlined
        rightIcon="chevron-right"
      >
        {name}
      </AnchorButton>
    );

    if (disabled) {
      return button;
    }

    if (shouldShowTooltip) {
      return (
        <Popover
          content={<div>Please, specify flights you&apos;d like to influence in the table below.</div>}
          hoverCloseDelay={0}
          hoverOpenDelay={0}
          interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
          key={type}
          popoverClassName="bp3-popover-content-sizing"
          position={Position.BOTTOM}
          targetClassName="w-100"
        >
          {button}
        </Popover>
      );
    }

    return button;
  });

  return (
    <div className="p-2" data-testid="influence-control-selection">
      {globalStyle}
      {buttons}
    </div>
  );
}

export default InfluenceControlSelection;
