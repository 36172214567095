import type { RowObjectId } from "../types/Table.types";

export default function getRowObjectIds(
  aggregations: Array<string> = [],
  selectedRows: Array<string> = []
): RowObjectId[] {
  const numericKeys = ["analystId", "depDow", "regionId", "subregionId"];
  const toNumber = (value: string) => Number(value) || null;

  return selectedRows.map(selectedId => {
    const splitId = selectedId.split("_");
    return aggregations.reduce(
      (accumulator, aggregationKey, i) => ({
        ...accumulator,
        [aggregationKey]: numericKeys.includes(aggregationKey) ? toNumber(splitId[i]) : splitId[i]
      }),
      {}
    );
  });
}
