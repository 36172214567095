// @flow

import { action, observable } from "mobx";

export class ModalStore {
  @observable modalActive = "";
  @observable data = {};

  @action.bound
  setModal(modalName: string, data: Object) {
    this.modalActive = modalName;
    this.data = data;
  }
}
