// @flow

import { extraMetrics } from "./constants";
import metricLabels from "./metricLabels/metricLabels";

const influenceHistory = {
  analystName: "Analyst",
  createdOn: "Infl Created On",
  status: "Infl Status",
  type: "Infl Type",
  updatedOn: "Infl Modified On",
  userIds: "Analyst"
};

export const filterNamesOverrides = {
  aircraftCapacity: "Cap",
  sellableCapacity: "Cap Sell",
  xDayAircraftCapacity: "XD Cap",
  xDaySellableCapacity: "XD Cap Sell",
  xDayAircraftCapacityBuild: "Cap XD Bld",
  xDaySellableCapacityBuild: "Cap Sell XD Bld",
  netBookings: "Bkgs Net Act",
  netBookingsBaseline: "Bkgs Net Base",
  netBookingsDiffToBaseline: "Bkgs Net Δ to Base",
  xDayNetBookings: "Bkgs XD Net Act",
  xDayNetBookingsBaseline: "Bkgs XD Net Base",
  xDayNetBookingsDiffToBaseline: "Bkgs XD Net Δ to Base",
  xDayNetBookingsBuild: "Bkgs XD Bld Net Act",
  xDayNetBookingsBuildBaseline: "Bkgs XD Bld Net Base",
  xDayNetBookingsBuildDiffToBaseline: "Bkgs XD Bld Δ to Base"
};

export default function FilterNamesMap(transformToMile: boolean, additionalOptions = {}, xDayBuild: number): Object {
  const columnLabels = {
    ...extraMetrics,
    ...metricLabels({ transformToMile, shortVersionByGroup: true, xDayBuild })
  };

  return {
    ...columnLabels,
    departureDayOfWeek: "Dep DOW",
    depMonth: "Dep Month",
    depWeek: "Dep Week",
    depDow: "Dep DOW",
    depDate: "Dep Date",
    depTime: "Dep Time",
    depTimeBucket: "Dep Time Bucket",
    destination: "Dest",
    distance: "Dist",
    flightNumber: "Flt Num",
    flightNumber_1: "Flt Num",
    numberOfFlights: "Flt Count",
    ndo: "NDO",
    odPairs: "ODs",
    ...influenceHistory,
    ...additionalOptions
  };
}
