// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import isEqual from "lodash.isequal";

import InfluenceHistorySidebar from "../components/InfluenceHistorySidebar";
import InfluenceHistoryTable from "../components/InfluenceHistoryTable";
import InfluenceHistoryTopBar from "../components/InfluenceHistoryTopBar";
import Module from "../components/Module";
import StyledScrollableContainer from "../components/StyledScrollableContainer";
import { previousPageWasDifferent } from "../helpers/spyPathNames";
import { Status } from "../helpers/Status";
import { useStores } from "../store/Store";

const typeAllowingToSortByValue = ["PA"];

function InfluenceHistory() {
  const { appStore, influenceHistoryStore, modalStore, systemUnitsStore, analysisMappingsStore } = useStores();

  const { page, creators } = influenceHistoryStore;
  const { computedDateFormat, computedDateMonthFormat } = systemUnitsStore;
  const { auth = {} } = appStore;
  const { user = {} } = auth;
  const prevPageWasDifferent = previousPageWasDifferent();

  useEffect(() => {
    if (prevPageWasDifferent) {
      influenceHistoryStore.getInfluenceHistoryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevPageWasDifferent]);

  useEffect(() => {
    if (page.status === Status.INIT) {
      influenceHistoryStore.initPage(user.influenceHistory || {});
    }
  }, [page.status, user, influenceHistoryStore]);

  useEffect(() => {
    influenceHistoryStore.getInfluenceHistoryCreators();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sidebarProps = {
    computedDateFormat,
    computedDateMonthFormat,
    creators,
    mappings: analysisMappingsStore
  };

  const valueSortType = isEqual(page.applied.filters.type, typeAllowingToSortByValue) ? "alphanumeric" : null;

  const tableProps = {
    ...page.historyTable,
    computedDateFormat,
    deleteInfluences: params => influenceHistoryStore.deleteInfluences(params),
    fetchData: (params, options) => {
      influenceHistoryStore.getInfluenceHistoryData(params);
      if (options && options.saveOptions) {
        influenceHistoryStore.saveView();
      }
    },
    onShiftToggle: influenceHistoryStore.shiftToggleRows,
    onRowToggle: (selectedRows: Array<string>) => influenceHistoryStore.updateSelectedRows(selectedRows),
    setModal: modalStore.setModal,
    sortByConfig: {
      value: valueSortType
    }
  };

  if (page.status === Status.INIT) {
    return <div />;
  }

  if (page.status === Status.LOADING || creators.status === Status.LOADING) {
    return (
      <div className="m-5">
        <Spinner />
      </div>
    );
  }

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <InfluenceHistoryTopBar />
      <InfluenceHistorySidebar {...sidebarProps} />
      <div className="d-flex flex-column flex-shrink-1 px-2 pt-2 mh-0">
        <Module
          className="mb-2"
          childrenClassName="placeholder-center"
          isCollapsed={page.influenceTimeline.isCollapsed}
          setCollapsed={isCollapsed => {
            influenceHistoryStore.setTimelineCollapsed(isCollapsed);
          }}
          title="Influence Timeline"
        >
          <NonIdealState className="py-5 text-center d-flex align-items-center" icon="timeline-line-chart" />
        </Module>
        <div data-testid="influence-history-table" className="flex-shrink-1 d-flex flex-column mh-0">
          <InfluenceHistoryTable {...tableProps} />
        </div>
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(InfluenceHistory);
