// @flow

import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import groupConditionalFilters from "../helpers/groupConditionalFilters";
import ChipCounter from "./ChipCounter";
import { countAppliedFilters, countUpdatedFilters } from "../helpers/sidebarCounters";

type Props = {
  filterNames: Array<string>,
  isApplied: boolean,
  pageFilters: Object,
  type: ?string
};

function ActiveFilterCounter(props: Props) {
  const { filterNames = [], isApplied = false, pageFilters = {}, type = "" } = props;

  const appliedFilters = {
    ...pageFilters.applied.filters,
    ...groupConditionalFilters(pageFilters.applied.conditionalFilters)
  };

  const appliedEntries = Object.keys(appliedFilters)
    .filter(key => filterNames.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: appliedFilters[key]
      }),
      {}
    );

  const appliedConditionalFiltersBase = Object.keys(
    groupConditionalFilters(pageFilters.applied.conditionalFilters)
  ).reduce(
    (conditionalObject, key) => ({
      ...conditionalObject,
      [key]: []
    }),
    {}
  );

  const currentFilters = {
    ...pageFilters.filters,
    ...appliedConditionalFiltersBase,
    ...groupConditionalFilters(pageFilters.conditionalFilters)
  };

  const currentEntries = Object.keys(currentFilters)
    .filter(key => filterNames.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: currentFilters[key]
      }),
      {}
    );

  const numberOfChanges = isApplied
    ? countAppliedFilters(type, appliedEntries)
    : countUpdatedFilters(type, currentEntries, appliedEntries);

  const hideClass = numberOfChanges > 0 ? "" : "d-none";
  const appliedClass = isApplied ? "sidebar--applied" : "";
  return <ChipCounter className={clsx(hideClass, appliedClass)}>{numberOfChanges}</ChipCounter>;
}

export default observer(ActiveFilterCounter);
