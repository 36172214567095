// @flow

import isEmpty from "lodash.isempty";
import { mergeContextualColumns } from "../../helpers/flightsTable";
import { exploreMetrics } from "../../helpers/metrics";
import type { GroupStatus } from "./FlightsTable.types";

export const initColumns = [
  ["finalRevenueGroup", ["xDayRevenuePotential"]],
  ["raskGroup", ["rask", "raskDiffToBaseline", "xDayRaskBuild", "xDayRaskBuildDiffToBaseline"]],
  ["loadFactorGroup", ["loadFactor", "diffToElb", "xDayLoadFactorBuild", "xDayLoadFactorBuildDiffToBaseline"]],
  ["yieldGroup", ["yield", "yieldDiffToBaseline", "xDayYieldBuild", "xDayYieldBuildDiffToBaseline"]],
  ["sellingFareGroup", ["sellingPrice"]],
  ["pricePercentileGroup", ["pricePercentile", "xDayPricePercentileBuild"]],
  ["lacGroup", ["lacRank", "xDayLacBuildDiff"]],
  ["influenceGroup", ["priceAdjustment"]]
];

export const groupStatuses: GroupStatus[] = exploreMetrics.map(([label]) => ({
  label,
  isOpen: !isEmpty(initColumns.filter(([groupTitle, metrics]) => label === groupTitle && !isEmpty(metrics)))
}));

export const columnsToRequest = (flightsTable): string[] => {
  const flatColumns = mergeContextualColumns(flightsTable.columns, flightsTable.influenceImpactGroupColumns).flatMap(
    ([, columns]) => columns
  );

  return [...flatColumns, ...flightsTable.aggregations, "numberOfFlights"];
};

export const getName = (id: string, idsList: Array<number>, labelKey: string) => {
  const idsExists = !isEmpty(idsList);
  let name;

  if (id && idsExists) {
    const nameByIds = idsList[id];
    if (labelKey) {
      name = nameByIds ? nameByIds[labelKey] : "";
    } else {
      name = nameByIds || "";
    }
  }

  if (id && idsExists && !idsList[id]) {
    name = "Undefined";
  }
  return name;
};
