// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import isEmpty from "lodash.isempty";

import MarketMappingsSidebar, { marketMappingsMetrics } from "../components/MarketMappings/MarketMappingsSidebar";
import MarketMappingsTopBar from "../components/MarketMappings/MarketMappingsTopBar";
import MarketsTable from "../components/MarketMappings/MarketsTable";
import StyledScrollableContainer from "../components/StyledScrollableContainer";
import { extraMetrics } from "../helpers/constants";
import { FILTERS_INIT } from "../models/Markets.model";
import { useStores } from "../store/Store";

function MarketMappings() {
  const { marketsStore, regionsStore, usersStore } = useStores();

  useEffect(() => {
    marketsStore.getMarkets();
    marketsStore.getMarketList();
    usersStore.fetchUsers();
    regionsStore.getRegionsAndSubregions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { searchParams, table } = marketsStore;

  const analystList = usersStore.analystList;
  const filterOrder = marketMappingsMetrics.map(({ filters }) => filters.map(({ key }) => key)).flat();
  const getAnalystLabelFromId = analystId =>
    (analystList && analystList.find(analyst => analyst.value === analystId).label) || analystId;

  const onTagClick = (filterId: string) => {
    marketsStore.setSidebarOpen(true);
    marketsStore.setSidebarFilterQuery(extraMetrics[filterId], filterId);
  };

  const tagsListProps = {
    disabled: !marketsStore.filtersEnabled,
    filterOrder,
    initialFilters: FILTERS_INIT,
    onChangeParam: marketsStore.resetSearchParam,
    onTagClick,
    onValueRemove: marketsStore.removeFilterValue,
    params: searchParams,
    valueRenderers: {
      analyst: value => getAnalystLabelFromId(value),
      region: value => regionsStore.regionsById[value],
      subregion: value => regionsStore.subregionsById[value]
    }
  };

  const tableProps = {
    ...table,
    ...(!isEmpty(table.data) && {
      pagination: {
        pageIndex: 0,
        pageSize: table.data.length,
        pageCount: 1,
        totalRows: table.data.length
      }
    }),
    sortBy: {
      field: "rtMarket",
      direction: "asc"
    },
    onShiftToggle: marketsStore.shiftToggleRows,
    onRowToggle: selectedRows => marketsStore.changeSelectedRows(selectedRows)
  };

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <MarketMappingsTopBar tagsListProps={tagsListProps} />
      <MarketMappingsSidebar analystList={analystList} />

      <div className="p-2 d-flex flex-column flex-shrink-1 mh-0" data-testid="markets-mappings-table">
        <MarketsTable {...tableProps} />
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(MarketMappings);
