// @flow

import React from "react";
import { observer } from "mobx-react";

import { PriceLimit } from "../../models/Influence.model";

import PriceAdjustmentForm from "./PriceAdjustmentForm";
import PriceLimits from "./PriceLimits";

type Props = {
  data: {
    base: Object,
    influenceId: string,
    preview: Object,
    previewCounters: Object
  },
  readOnly: boolean,
  state: {
    influenceStep: number,
    influenceType: string
  },
  status: string,
  parameters: {
    comment: string,
    inputValue: number | Array<PriceLimit>,
    value: ?number | Array<PriceLimit>
  },
  updateParameters: Function
};

function InfluenceControlForm(props: Props) {
  const { parameters, readOnly = false, state, status, updateParameters } = props;
  const { influenceType } = state;

  const renderControls = () => {
    switch (influenceType) {
      case "PA":
        return (
          <PriceAdjustmentForm
            parameters={parameters}
            readOnly={readOnly}
            status={status}
            updateParameters={updateParameters}
          />
        );
      case "MM": {
        return <PriceLimits parameters={parameters} readOnly={readOnly} status={status} />;
      }
      default:
        return "";
    }
  };

  return renderControls();
}

export default observer(InfluenceControlForm);
