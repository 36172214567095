// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Spinner } from "@blueprintjs/core";

import { useStores } from "../store/Store";

import Module from "../components/Module";
import Row from "../components/Row";
import TopBar from "../components/TopBar";
import UserDetails from "../components/UserDetails";
import UsersTable from "../components/UsersTable";
import StyledScrollableContainer from "../components/StyledScrollableContainer";
import { Status } from "../helpers/Status";

function Users() {
  const { usersStore, appStore, modalStore } = useStores();
  const { usersList, userGroups } = usersStore;
  const user = appStore.auth && appStore.auth.user;

  useEffect(() => {
    usersStore.fetchUserGroups();
  }, [usersStore]);

  if (userGroups.status === Status.LOADING) {
    return (
      <div className="m-5">
        <Spinner />
      </div>
    );
  }

  const usersTableProps = {
    currentUser: user.userId,
    ...usersList,
    fetchData: () => usersStore.fetchUsers(),
    onRowClick: row => modalStore.setModal("editUser", row.original)
  };

  return (
    <>
      <TopBar />
      <StyledScrollableContainer className="d-flex flex-column flex-shrink-1 p-2" data-testid="users-view">
        <Row className="h-100">
          <div className="col-4" data-testid="your-profile-card">
            {usersList.currentUserStatus === Status.LOADING ? (
              <Module title="Your Profile">
                <div className="m-5">
                  <Spinner />
                </div>
              </Module>
            ) : (
              <UserDetails
                title="Your Profile"
                key={user}
                user={user}
                userGroups={userGroups.data}
                editUser={(userId, metadata) => {
                  usersStore.editCurrentUser(metadata.name, appStore.auth.editCurrentUserName);
                }}
              />
            )}
          </div>
          <div className="col-8 h-100" data-testid="users-table">
            <UsersTable {...usersTableProps} />
          </div>
        </Row>
      </StyledScrollableContainer>
    </>
  );
}

export default observer(Users);
