// @flow

import { isCurrency, isPercent } from "./constants";
import { metricsTypes } from "../constants";

type UnitProps = {
  group: Object,
  withUnits: ?boolean | ?Array<string>
};

export default function metricWithUnit(props: UnitProps) {
  const { group, withUnits } = props;
  const newGroup = group;

  const valueWithUnit = (key: string, value: string) => {
    const labelUnit = metricsTypes[key];
    const unit = !value.includes("%ile") && (isPercent(labelUnit) || isCurrency(labelUnit));

    return `${value}${unit ? ` ${unit}` : ""}`;
  };

  if (Array.isArray(withUnits))
    Object.entries(group).forEach((props: Object) => {
      const [key: string, value: string] = props;

      if (withUnits.includes(key)) {
        newGroup[key] = valueWithUnit(key, value);
      }
    });

  if (withUnits) {
    Object.entries(group).forEach((props: Object) => {
      const [key, value] = props;
      newGroup[key] = valueWithUnit(key, value);
    });
  }

  return newGroup;
}
