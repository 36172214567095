// @flow

import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";

import { FILTERS_INIT as CIRRUS_FILTERS_INIT } from "../models/Cirrus.model";
import { FILTERS_INIT as IH_FILTERS_INIT } from "../models/InfluenceHistory.model";
import { FILTERS_INIT as MARKET_FILTERS_INIT } from "../models/Markets.model";
import { FILTERS_INIT as TAB_FILTERS_INIT } from "../models/Tab.model";
import { FILTERS_INIT as EVENTS_FILTERS_INIT } from "../models/EventsManagement.model";

const getFiltersInit = (type: string) => {
  switch (type) {
    case "analysis":
      return TAB_FILTERS_INIT;
    case "cirrus":
      return CIRRUS_FILTERS_INIT;
    case "history":
      return IH_FILTERS_INIT;
    case "market":
      return MARKET_FILTERS_INIT;
    case "events":
      return EVENTS_FILTERS_INIT;
    default:
      return {};
  }
};

export const countAppliedFilters = (type: string, appliedFilters: Object): Number => {
  const filtersInit = getFiltersInit(type);
  return Object.keys(appliedFilters).reduce((counter, key) => {
    if (!Object.keys(filtersInit).includes(key)) {
      return counter + 1;
    }

    if (typeof appliedFilters[key] === "boolean" && !isEqual(appliedFilters[key], filtersInit[key])) {
      return counter + 1;
    }

    // performance improve
    if (isEmpty(appliedFilters[key])) {
      return counter;
    }
    if (
      typeof appliedFilters[key] === "object" &&
      appliedFilters[key] &&
      !Array.isArray(appliedFilters[key]) &&
      "start" in appliedFilters[key] &&
      "end" in appliedFilters[key] &&
      !isEqual(appliedFilters[key], filtersInit[key])
    ) {
      return counter + 1;
    }

    if (
      Array.isArray(appliedFilters[key]) &&
      !isEmpty(appliedFilters[key]) &&
      !isEqual(appliedFilters[key], filtersInit[key])
    ) {
      return counter + 1;
    }

    return counter;
  }, 0);
};

export const countUpdatedFilters = (type: string, currentFilters: Object, appliedFilters: Object): Number => {
  const filtersInit = getFiltersInit(type);
  return Object.keys(currentFilters).reduce((counter, key) => {
    const isChangedComparedToAppliedFilters = !isEqual(currentFilters[key], appliedFilters[key]);
    const isChangedComparedToInitFilters = !isEqual(currentFilters[key], filtersInit[key]);
    const isChanged = isChangedComparedToAppliedFilters && isChangedComparedToInitFilters;
    const isConditionalFilter =
      Array.isArray(currentFilters[key]) &&
      !isEmpty(currentFilters[key]) &&
      typeof currentFilters[key][0] === "object" &&
      currentFilters[key][0] !== null;

    if (!Object.keys(appliedFilters).includes(key) && !Object.keys(filtersInit).includes(key)) {
      return counter + 1;
    }

    if (typeof currentFilters[key] === "boolean" && isChangedComparedToAppliedFilters) {
      return counter + 1;
    }

    if (
      typeof currentFilters[key] === "object" &&
      appliedFilters[key] &&
      !Array.isArray(currentFilters[key]) &&
      "start" in currentFilters[key] &&
      "end" in currentFilters[key] &&
      isChanged
    ) {
      return counter + 1;
    }

    if (
      isConditionalFilter &&
      !isEqual(
        currentFilters[key].slice().sort((conditionA, conditionB) => conditionA.value - conditionB.value),
        appliedFilters[key].slice().sort((conditionA, conditionB) => conditionA.value - conditionB.value)
      )
    ) {
      return counter + 1;
    }

    if (
      Array.isArray(currentFilters[key]) &&
      !isConditionalFilter &&
      !isEqual(currentFilters[key], appliedFilters[key])
    ) {
      return counter + 1;
    }

    if (!Array.isArray(currentFilters[key]) && isChangedComparedToAppliedFilters) {
      return counter + 1;
    }

    return counter;
  }, 0);
};
