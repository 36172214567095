// @flow

import React from "react";
import { observer } from "mobx-react";
import pick from "lodash.pick";
import difference from "lodash.difference";

import SidebarSelect from "../SidebarSelect";
import SidebarFilter from "../SidebarFilter";
import { useStores } from "../../store/Store";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  analystList: Array,
  pageContext: Object
};

function MarketMappingsSidebarGroup(props: Props) {
  const { pageContext, analystList = [] } = props;
  const { regionsStore, marketsStore } = useStores();

  const { filters, sidebar } = pageContext;

  const regionFilters = pick(filters, ["subregion"]);
  const subregionFilters = pick(filters, ["region"]);
  const matchingRegions = regionsStore.getRegionsFromSelectedSubregions(regionFilters, "subregion");
  const regions = difference(regionsStore.regionsForFilter, matchingRegions);

  const matchingSubregions = regionsStore.getSubregionsFromSelectedRegions(subregionFilters, "region");
  const subregions = difference(regionsStore.subregionsForFilter, matchingSubregions);

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("rt market", "rtMarket", sidebar)}>
        <SidebarSelect
          fuzzySearch
          title="RT Market"
          items={marketsStore.rtMarkets}
          selectedItems={filters.rtMarket}
          onChange={filterValue => pageContext.changeFilter("rtMarket", filterValue)}
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("analyst", "analyst", sidebar)}>
        <SidebarSelect
          title="Analyst"
          items={analystList}
          selectedItems={filters.analyst}
          onChange={filterValue => pageContext.changeFilter("analyst", filterValue)}
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("region", "region", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: "Matching pre-selected", groupItems: matchingRegions },
            { label: "All regions", groupItems: regions }
          ]}
          onChange={filterValue => pageContext.changeFilter("region", filterValue)}
          selectedItems={filters.region}
          title="Region"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("subregion", "subregion", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: "Matching pre-selected", groupItems: matchingSubregions },
            { label: "All subregions", groupItems: subregions }
          ]}
          onChange={filterValue => pageContext.changeFilter("subregion", filterValue)}
          selectedItems={filters.subregion}
          title="Subregion"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(MarketMappingsSidebarGroup);
