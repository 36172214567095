import isEmpty from "lodash.isempty";

import getRowId from "./getRowId";
import { capitalize } from "./textUtils";

function transformToIDsList(list: Array<Object>, aggregations: Array<string>) {
  if (isEmpty(list)) {
    return [];
  }
  return list.map(row => getRowId(aggregations, row));
}

export function transformInfluenceData({ columns = [], influenceData, flightsTable }) {
  const { base, preview } = influenceData;
  const { aggregations = [], data = [], selectedRows = [] } = flightsTable;

  const baseInfluenceRowIds = transformToIDsList(base.rows, aggregations);
  const previewInfluenceRowIds = transformToIDsList(preview.rows, aggregations);

  return data.map(dataRow => {
    const rowId = getRowId(aggregations, dataRow);
    // if row is not selected, ignore
    if (!isEmpty(selectedRows) && !selectedRows.includes(rowId)) {
      return dataRow;
    }

    const baseIndex = baseInfluenceRowIds.indexOf(rowId);
    const previewIndex = previewInfluenceRowIds.indexOf(rowId);

    const extendedRow = {
      ...dataRow
    };

    columns.forEach(key => {
      const influenceKey = key && `influence${capitalize(key)}`;
      const baseRow = base.rows && base.rows[baseIndex];
      const previewRow = preview.rows && preview.rows[previewIndex];

      extendedRow[influenceKey] = {
        base: baseRow && baseRow[key],
        preview: previewRow && previewRow[key]
      };
    });

    return extendedRow;
  });
}
