// @flow

import React from "react";
import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import { ButtonGroup } from "@blueprintjs/core";

type Props = {
  tabId: string
};

function SwitchButtons(props: Props) {
  const { tabId } = props;
  const analysisViews = ["Explore", "Influence"];

  return (
    <ButtonGroup className="mr-2">
      {analysisViews.map(viewName => {
        const view = viewName.toLowerCase();
        return (
          <NavLink
            activeClassName="bp3-active"
            className="d-flex align-items-center bp3-button"
            key={viewName}
            to={`/analysis/${tabId}/${view}`}
          >
            {viewName}
          </NavLink>
        );
      })}
    </ButtonGroup>
  );
}

export default observer(SwitchButtons);
