import union from "lodash.union";

export const sharedSeries = [
  "raskBaseline",
  "revenueBaseline",
  "loadFactorBaseline",
  "revenuePerBookingBaseline",
  "yieldBaseline"
];

export default function mergePastAndFutureTimelines(collection) {
  const [historicalCollection, expectedCollection] = collection;
  const { maxLacRank, scenarioDate: historicalScenarioDate, ...historicalSeries } = historicalCollection.data;
  const { scenarioDate: forecastedScenarioDate, today, ...forecastedSeries } = expectedCollection.data;
  const historicalSeriesKeys = Object.keys(historicalSeries);
  const forecastedSeriesKeys = Object.keys(forecastedSeries);

  const lastHistoricalScenarioDate = historicalScenarioDate[historicalScenarioDate.length - 1];
  const firstForecastedScenarioDate = forecastedScenarioDate[0];

  const isOverlap = historicalScenarioDate.includes(firstForecastedScenarioDate);
  const overlappedDays =
    historicalScenarioDate.indexOf(lastHistoricalScenarioDate) -
    historicalScenarioDate.indexOf(firstForecastedScenarioDate);

  const adjustForcastedData = (data: number[] | string[], isSharedSeries: boolean) => {
    if (!isOverlap) {
      return data;
    }
    return data.slice(isSharedSeries ? overlappedDays + 1 : overlappedDays);
  };

  const allSeries = union(historicalSeriesKeys, forecastedSeriesKeys);
  const timeline = [...historicalScenarioDate, ...adjustForcastedData(forecastedScenarioDate, true)];

  const historicalFill = new Array(historicalScenarioDate.length - 1).fill(null);
  const expectedFill = new Array(forecastedScenarioDate.length - 1).fill(null);

  const mergedSeries = allSeries.reduce((accumulator, currentSerie) => {
    const isHistorical = historicalSeriesKeys.includes(currentSerie);
    const isForecast = forecastedSeriesKeys.includes(currentSerie);
    const historyData = isHistorical ? historicalSeries[currentSerie] : historicalFill;

    const data = isForecast ? forecastedSeries[currentSerie] : expectedFill;
    const isSharedSeries = sharedSeries.includes(currentSerie);
    const expectedData = adjustForcastedData(data, isSharedSeries);

    return {
      ...accumulator,
      [currentSerie]: [...historyData, ...expectedData]
    };
  }, {});
  return {
    maxLacRank,
    today,
    scenarioDate: timeline,
    ...mergedSeries
  };
}
