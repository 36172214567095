// @flow

import React from "react";
import { observer } from "mobx-react";

import SidebarSelect from "../SidebarSelect";
import SidebarFilter from "../SidebarFilter";
import { useStores } from "../../store/Store";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  pageContext: Object
};

function CirrusMarketFilters(props: Props) {
  const { pageContext } = props;
  const { filters, changeFilter, sidebar } = pageContext;

  const { mappingsStore } = useStores();

  return (
    <SidebarFilter isVisible={isSidebarFilterVisible("ow market one-way market", "odPairs", sidebar)}>
      <SidebarSelect
        items={mappingsStore.ods}
        onChange={filterValue => changeFilter("odPairs", filterValue)}
        selectedItems={filters.odPairs}
        title="OW Market"
      />
    </SidebarFilter>
  );
}

export default observer(CirrusMarketFilters);
