// @flow

import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Button, ControlGroup, HTMLSelect, Intent, NumericInput } from "@blueprintjs/core";
import styled from "@emotion/styled";
import kebabCase from "lodash.kebabcase";

import { formatPreciseNumber } from "../helpers/formatPreciseNumber";
import type { Condition } from "../types/Sidebar.types";
import isNumber from "../helpers/isNumber";

type Props = {
  conditionalFilters: Condition[],
  inputHint: ?string,
  isNonNegative: boolean,
  name: string,
  onAddCondition: Function,
  onChangeCondition: Function,
  onDeleteCondition: Function,
  title: string
};

const ConditionInputStyled = styled(NumericInput)`
  .input--right-icon + .bp3-input {
    padding-left: 10px;
    padding-right: 30px;
  }
`;

const InputHintStyled = styled("div")`
  left: auto !important;
  right: 0;
`;

const AddConditionButtonStyled = styled(Button)`
  font-size: 13px;
`;

function SidebarConditionalFilter(props: Props) {
  const {
    conditionalFilters = [],
    inputHint,
    isNonNegative = false,
    name = "",
    onAddCondition,
    onChangeCondition,
    onDeleteCondition,
    title = ""
  } = props;
  const conditions = conditionalFilters.filter(filter => filter.name === name);
  const testId = kebabCase(`conditional-filter-${name}-${title}`);

  const functions = [
    { label: ">", value: "gt" },
    { label: "≥", value: "gte" },
    { label: "<", value: "lt" },
    { label: "≤", value: "lte" },
    { label: "=", value: "eq" },
    { label: "≠", value: "neq" }
  ];

  const lastInputRef = useRef();
  const previousConditionsLengthRef = useRef();

  useEffect(() => {
    const input = lastInputRef.current;
    const previousConditionsLength = previousConditionsLengthRef.current;
    if (previousConditionsLength != null && previousConditionsLength < conditions.length && input != null) {
      previousConditionsLengthRef.current = conditions.length;
      input.inputElement && input.inputElement.focus();
    }
  });

  function addCondition() {
    previousConditionsLengthRef.current = conditions.length;
    const func = functions[0].value;
    onAddCondition({ name, func, value: "" });
  }

  function isDefinedValue(value: any) {
    return value !== null && value !== undefined;
  }

  function renderCondition(condition: Condition) {
    const conditionIndex = conditionalFilters.indexOf(condition);
    const inputHintIcon = inputHint && (
      <InputHintStyled className="input--right-icon bp3-icon">{inputHint}</InputHintStyled>
    );

    const value = isDefinedValue(condition.value) ? condition.value : "";
    const isValidNumber = value ? isNumber(Number(value)) : true;

    const inputProps = {
      ...(isNonNegative && { min: 0 }),
      "data-testid": "conditional-filter-input",
      intent: !isValidNumber ? Intent.DANGER : undefined,
      leftIcon: inputHintIcon,
      maxLength: "16",
      minorStepSize: 0.005,
      onValueChange: (numberValue: number, stringValue: string) => {
        let conditionValue = numberValue === Number(stringValue) ? numberValue : stringValue;
        if (isNonNegative && numberValue < 0) {
          conditionValue = 0;
        }
        onChangeCondition(conditionIndex, { ...condition, value: conditionValue });
      },
      value: formatPreciseNumber(value)
    };

    return (
      <div className="d-flex flex-column mb-1" key={conditionIndex}>
        <div className="d-flex">
          <ControlGroup>
            <HTMLSelect
              options={functions}
              value={condition.func}
              onChange={e => onChangeCondition(conditionIndex, { ...condition, func: e.currentTarget.value })}
            />
            <ConditionInputStyled {...inputProps} ref={lastInputRef} />
          </ControlGroup>
          <Button
            className="ml-2"
            minimal
            icon="cross"
            onClick={() => onDeleteCondition(conditionIndex)}
            data-testid="conditional-filter-delete-btn"
          />
        </div>
        {!isValidNumber ? <span className="mt-1 text-danger bp3-text-small">Value is not numeric</span> : null}
      </div>
    );
  }

  return (
    <div className="d-flex flex-column mb-3" data-testid={testId}>
      <h6 className="bp3-heading">{title || name}</h6>
      <div className="d-flex flex-column">
        {conditions.map(renderCondition)}
        <AddConditionButtonStyled
          className="px-0 justify-content-start"
          data-testid="sidebar-add-condition-button"
          disabled={conditions.length >= 5}
          icon="plus"
          minimal
          onClick={addCondition}
        >
          Add Condition
        </AddConditionButtonStyled>
      </div>
    </div>
  );
}

export default observer(SidebarConditionalFilter);
