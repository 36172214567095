import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import TopBar from "../components/TopBar";
import SystemUnitsDistance from "../components/SystemSettings/SystemSettingsDistance";
import SystemUnitsDate from "../components/SystemSettings/SystemSettingsDate";
import { useStores } from "../store/Store";
import Module from "../components/Module";
import { SystemSettingsDescription } from "../components/SystemSettings/SystemSettingsUI";

function SystemUnits() {
  const { systemUnitsStore, modalStore, tabsStore } = useStores();
  const [unitsState, setUnitsState] = useState({});

  const showChangeConfirmation = () => {
    const config = {
      title: "Confirm Unit Change",
      text: "You are going to change your system units. Please, confirm this action",
      buttonIcon: "floppy-disk",
      buttonText: "Save",
      action: () => {
        tabsStore.flushTabs();
        systemUnitsStore.saveAll(unitsState);
      }
    };
    modalStore.setModal("confirmation", config);
  };

  const updateUnitsState = newState => setUnitsState(state => ({ ...state, ...newState }));

  return (
    <div data-testid="system-units">
      <TopBar />
      <Module
        className="min-h-50 w-50 m-2"
        headerClassName="px-3"
        title="System Settings"
        childrenClassName="mx-3 my-4"
        footerClassName="p-2 px-3 text-right"
        footer={
          <Button
            className="px-3"
            disabled={!systemUnitsStore.hasInitialStateChanged(unitsState)}
            intent="primary"
            onClick={showChangeConfirmation}
          >
            Save
          </Button>
        }
        minHeight={296}
      >
        <SystemSettingsDescription className="m-0">
          System settings will take effect for all users in your organization.
        </SystemSettingsDescription>
        <SystemUnitsDistance distanceUnit={unitsState.distanceUnit} onChange={updateUnitsState} />
        <SystemUnitsDate
          dateDelimiter={unitsState.dateDelimiter}
          dateFormat={unitsState.dateFormat}
          onChange={updateUnitsState}
        />
      </Module>
    </div>
  );
}

export default observer(SystemUnits);
