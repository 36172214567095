// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Classes, Colors, Dialog, FormGroup, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import clsx from "clsx";
import isEmpty from "lodash.isempty";

import SidebarSingleSelect from "./SidebarSingleSelect";

import { useStores } from "../store/Store";

const FormGroupStyled = styled("div")`
  .bp3-form-group {
    .bp3-label {
      min-width: 150px;
    }

    .bp3-form-content {
      flex-grow: 1;
    }
  }
`;

const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 ${Colors.BLACK}26;
`;

const SelectWrapper = styled("div")`
  width: 220px;
`;

function AddRegionsModal() {
  const { modalStore, regionsStore } = useStores();

  const { existingRegionNames, table } = regionsStore;
  const parentOptions = table.data.map(region => ({ value: region.regionId, label: region.regionName }));

  const [name, setName] = useState("");
  const [type, setType] = useState("region");
  const [parent, setParent] = useState(undefined);

  const trimmedName = name.trim();
  const isValidName = !isEmpty(trimmedName) && !existingRegionNames.includes(trimmedName);

  const parentId = parent && parent.value ? Number(parent.value) : undefined;
  const isValid = isValidName && (type === "region" || parentId);

  function submitForm(event: Event) {
    event.preventDefault();
    modalStore.setModal("");

    if (type === "region") {
      regionsStore.createRegion(name);
    } else {
      regionsStore.createSubregion(name, parentId);
    }
  }

  return (
    <Dialog className="p-0" isOpen onClose={() => modalStore.setModal("")} title="Add item">
      <form className="p-0" data-testid="add-regions-modal" onSubmit={event => submitForm(event)}>
        <div className={clsx(Classes.DIALOG_BODY, "m-0", "p-3")}>
          <FormGroupStyled className="d-flex flex-column w-100">
            <FormGroup className="font-weight-bold mb-3" label="Name" labelFor="name-input">
              <input
                autoFocus
                className={clsx(Classes.INPUT, "w-100")}
                id="name-input"
                onChange={e => setName(e.target.value)}
                placeholder="Enter name"
                required
                type="text"
                value={name}
              />
            </FormGroup>
            <RadioGroup
              className="font-weight-bold mb-3"
              label="Type"
              inline
              onChange={e => setType(e.currentTarget.value)}
              selectedValue={type}
            >
              <Radio className="font-weight-normal" label="Region" value="region" />
              <Radio className="font-weight-normal" label="Subregion" value="subregion" />
            </RadioGroup>
            {type === "subregion" && (
              <SelectWrapper>
                <SidebarSingleSelect
                  items={parentOptions}
                  selectedItem={parent}
                  selectItem={setParent}
                  title="Parent Region"
                />
              </SelectWrapper>
            )}
          </FormGroupStyled>
        </div>
        <DialogFooter className={clsx(Classes.DIALOG_FOOTER, "mx-0 px-3 py-2")}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              data-testid="confirm-regions-modal-button"
              disabled={!isValid}
              intent={Intent.PRIMARY}
              type="submit"
            >
              Create
            </Button>
          </div>
        </DialogFooter>
      </form>
    </Dialog>
  );
}

export default observer(AddRegionsModal);
