import React, { useMemo } from "react";
import { observer } from "mobx-react";

import TagsList from "../TagsList";
import { FILTERS_INIT } from "../../models/EventsManagement.model";
import groupConditionalFilters from "../../helpers/groupConditionalFilters";
import { filterOrder } from "../../helpers/eventsManagementMetrics";
import { useStores } from "../../store/Store";
import { extraMetrics } from "../../helpers/constants";

function EventsManagementTagList() {
  const { eventsManagementStore } = useStores();
  const {
    clearSearchParam,
    eventAnalysts,
    eventCategories,
    page,
    removeFilterValue,
    setSidebarFilterQuery,
    setSidebarOpen
  } = eventsManagementStore;

  const analystLabels = useMemo(() => {
    return eventAnalysts.reduce((acc, analyst) => {
      acc[analyst.value] = analyst.label;
      return acc;
    }, {});
  }, [eventAnalysts]);

  const categoryLabels = useMemo(() => {
    return eventCategories.reduce((acc, category) => {
      acc[category.value] = category.label;
      return acc;
    }, {});
  }, [eventCategories]);

  const onTagClick = (filterId: string) => {
    setSidebarOpen(true);
    setSidebarFilterQuery(extraMetrics[filterId], filterId);
  };

  const tagsListProps = {
    additionalTagLabels: { ...analystLabels, ...categoryLabels },
    disabled: !page.filtersEnabled,
    filterOrder,
    initialFilters: FILTERS_INIT,
    onChangeParam: clearSearchParam,
    onTagClick,
    onValueRemove: removeFilterValue,
    params: {
      ...page.applied.filters,
      ...groupConditionalFilters(page.applied.conditionalFilters)
    }
  };

  return <TagsList {...tagsListProps} />;
}

export default observer(EventsManagementTagList);
