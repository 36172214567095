// @flow

import axios, { AxiosInstance, AxiosPromise } from "axios";
import { Intent } from "@blueprintjs/core";
import * as Sentry from "@sentry/react";
import { setupCache } from "axios-cache-adapter";

import { AppToaster } from "./Toaster";
import { skipCustomParams } from "../helpers/skipCustomParams";
import { baseInfluenceImpactGroup } from "../helpers/metricLabels/constants";
import type { RegionEntry } from "../models/Regions.model";
import type { FlightCountParams, FlightCountResponse, FlightsResponse } from "../types/Flights.types";
import { SharedAnalysis } from "../types/Tab.types";
import { mockApiEnv } from "../helpers/constants";

export const isCancel = axios.isCancel;

const flightsSkippedColumns = Object.keys(baseInfluenceImpactGroup());

class API {
  api: AxiosInstance;
  cancelTokens: Object;

  init(env: Object, authToken: string, onUnauthorized: ?Function) {
    const cache = setupCache({ maxAge: 0, exclude: { methods: ["put", "patch", "delete"] } });
    this.api = axios.create({
      baseURL: env.apiUrl
    });
    this.cancelTokens = {};

    // Overriden by AxiosMockAdapter
    if (env?.clientId !== mockApiEnv.clientId) {
      this.api.defaults.adapter = cache.adapter;
    }

    this.api.defaults.headers.common.Authorization = `Bearer ${authToken}`;

    this.api.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          switch (error.response.status) {
            case 400:
            case 500: {
              AppToaster.show({
                message: "Something went wrong. Please contact support",
                intent: Intent.DANGER
              });
              return Promise.reject(error);
            }
            case 401:
              if (typeof onUnauthorized === "function") {
                onUnauthorized();
              }
              AppToaster.show({
                message: "Authorization failed. Please refresh the page and login",
                intent: Intent.DANGER
              });
              return Promise.reject(error);
            case 403:
              return AppToaster.show({
                message: "You don't have permission to access this resource",
                intent: Intent.DANGER
              });
            case 404:
              AppToaster.show({
                message: "Requested resource was not found",
                intent: Intent.DANGER
              });
              return Promise.reject(error);
            case 409:
              AppToaster.show({
                message:
                  (error.response.data && error.response.data.message) ||
                  "Something went wrong. Please contact support",
                intent: Intent.DANGER
              });
              return Promise.reject(error);
            case 502:
            case 504:
              return AppToaster.show({
                message: "Server could not complete request. Try again later",
                intent: Intent.DANGER
              });
            default:
              return;
          }
        }

        const { config } = error;
        if (config != null) {
          // Errors without response, i.e. from requests that didn't reach backend
          // Known example: Network Error
          const method = String(config.method).toUpperCase();
          const path = config.url;
          const message = `${method} ${path} ${error.message || "No message"}`;

          Sentry.addBreadcrumb({ level: "error", message });
        }

        return Promise.reject(error);
      }
    );
  }

  cancelPreviousCall(name: string, groupId: number | string) {
    const CancelToken = axios.CancelToken;

    if (!this.cancelTokens[groupId]) {
      this.cancelTokens[groupId] = {};
    }

    if (this.cancelTokens[groupId][name]) {
      this.cancelTokens[groupId][name].cancel();
    }

    this.cancelTokens[groupId][name] = CancelToken.source();

    return { cancelToken: this.cancelTokens[groupId][name].token };
  }

  getCirrusStatus(params: Object) {
    return this.api.post(`/pricing-statuses/get`, params);
  }

  getDistanceUnit() {
    return this.api.get("/registers/distance_unit");
  }

  setDistanceUnit(unit: string) {
    return this.api.put("/registers/distance_unit", { value: unit });
  }

  getDateFormat() {
    const { cancelToken } = this.cancelPreviousCall("getDateFormat", 1);
    return this.api.get("/registers/date-format", cancelToken);
  }

  setDateFormat(dateFormat: string) {
    return this.api.put("/registers/date-format", { value: dateFormat });
  }

  switchCirrusStatus(params: Object, fusionrmStatus: boolean) {
    return this.api.put("/pricing-statuses", {
      ...params,
      update: { fusionrmStatus }
    });
  }

  getMappings() {
    return this.api.get("/mappings");
  }

  getMarkets(params) {
    return this.api.post("/markets/config/mappings/get", params);
  }

  updateMarkets(data) {
    return this.api.put("/markets/config/mappings", data);
  }

  getUsers() {
    return this.api.get("/users");
  }

  getUserGroups() {
    return this.api.get("/groups");
  }

  addUser(user: Object) {
    return this.api.post("/users", user);
  }

  getCurrentUser() {
    return this.api.get(`/users/me`);
  }

  editCurrentUser(metadata: Object) {
    return this.api.patch(`/users/me`, metadata);
  }

  editUser(userId: string, metadata: Object) {
    return this.api.patch(`/users/${userId}`, metadata);
  }

  deleteUser(userId: string) {
    return this.api.delete(`/users/${userId}`);
  }

  resetPassword(email: string) {
    return this.api.post("/users/reset-password", { email });
  }

  getExploreMappings() {
    return this.api.get("/explore/mappings");
  }

  getFlights(params: Object, tabId: number, identifier: string = "getFlights"): AxiosPromise<FlightsResponse> {
    const { cancelToken } = this.cancelPreviousCall(identifier, tabId);

    // cancel loading build curves before getting departure date extremes
    if (identifier === "minDepDate" || identifier === "maxDepDate") {
      this.cancelPreviousCall("getBuildCurves", tabId);
    }
    const validParams = skipCustomParams(params, { columns: flightsSkippedColumns });

    if (validParams.sortBy.field === "analystId") {
      validParams.sortBy.field = "analystName";
    }

    return this.api.post("/explore/metrics/get", validParams, { cancelToken });
  }

  getFlightsCount(params: FlightCountParams, tabId: number): AxiosPromise<FlightCountResponse> {
    const { cancelToken } = this.cancelPreviousCall("getFlightsCount", tabId);
    return this.api.post("/explore/metrics/count/get", params, { cancelToken });
  }

  getForecastedBuildCurves(params: Object, tabId: number) {
    const { cancelToken } = this.cancelPreviousCall("getForecastedBuildCurves", tabId);
    return this.api.post("/explore/curve/forecast/get", params, { cancelToken });
  }

  getBuildCurves(params: Object, tabId: number) {
    const { cancelToken } = this.cancelPreviousCall("getBuildCurves", tabId);
    return this.api.post("/explore/curve/get", params, { cancelToken });
  }

  getKPIsTimestamp() {
    return this.api.get("/explore/metrics/kpis-timestamp");
  }

  getSavedViews() {
    return this.api.get("/saved-views");
  }

  addSavedView(view: Object) {
    return this.api.post("/saved-views", view);
  }

  patchSavedView(viewId: number, view: Object) {
    return this.api.put(`/saved-views/${viewId}`, view);
  }

  removeSavedView(viewId: string) {
    return this.api.delete(`/saved-views/${viewId}`);
  }

  getSystemTemplates() {
    return this.api.get("/system-templates");
  }

  savePercentInfluence(influence: Object) {
    return this.api.post("/influences/percent-adj/", influence);
  }

  savePriceLimitsInfluence(influence: Object) {
    return this.api.post("/influences/minmax-adj/", influence);
  }

  previewPercentInfluence(influence: Object) {
    const { cancelToken } = this.cancelPreviousCall("preview", "influence");
    return this.api.post("influences/percent-adj/preview", influence, { cancelToken });
  }

  previewPriceLimitsInfluence(influence: Object) {
    const { cancelToken } = this.cancelPreviousCall("preview", "influence");
    return this.api.post("influences/minmax-adj/preview", influence, { cancelToken });
  }

  getInfluenceHistory(params: Object) {
    return this.api.post("/influence-history/get", params);
  }

  getInfluenceHistoryCreators() {
    return this.api.get("/influence-history/creators");
  }

  deleteInfluences(params: Object) {
    return this.api.delete("/influences", { data: params });
  }

  getEventsManagementEvents(params: Object) {
    return this.api.post("/events/get", params);
  }

  getEventsMappings() {
    return this.api.get("/events/mappings");
  }

  getEventById(id) {
    return this.api.get(`/events/${id}`);
  }

  addEventManagement(params: Object) {
    return this.api.post("/events", params);
  }

  editEventManagement(eventId: number, params: Object) {
    return this.api.put(`/events/${eventId}`, params);
  }

  deleteEventManagement(data: { eventIds: Array<number> }) {
    return this.api.delete("/events", { data });
  }

  getExistingEvents(data: { name: string } | { dateRange: { start: string, end: string } }) {
    return this.api.post("/events/autocomplete/get", data);
  }

  getRegionsSubregions(): AxiosPromise<RegionEntry[]> {
    return this.api.get("/markets/config/region-subregion");
  }

  addRegion(regionName: string) {
    return this.api.post("/markets/config/region", { regionName });
  }

  editRegion(regionId: number, data: { regionName: string }) {
    return this.api.put(`/markets/config/region/${regionId}/`, data);
  }

  addSubregion(regionId: number, subregionName: string) {
    return this.api.post("/markets/config/subregion", { regionId, subregionName });
  }

  editSubregion(subregionId: number, data: { regionId: ?number, subregionName: ?string }) {
    return this.api.patch(`/markets/config/subregion/${subregionId}/`, data);
  }

  deleteRegionSubregion(data: { regionId: ?Array<number>, subregionId: ?Array<number> }) {
    return this.api.delete("/markets/config/region-subregion", { data });
  }

  shareAnalysis(analysis): AxiosPromise<{ createdOn: string, shareId: string }> {
    return this.api.post("/share-analysis", { analysis }, { cache: { maxAge: 10 * 60 * 1000 } });
  }

  getSharedAnalysis(shareId: string): AxiosPromise<{ createdOn: string, analysis: SharedAnalysis }> {
    return this.api.get(`/share-analysis/${shareId}`);
  }
}

const api = new API();

export default api;
