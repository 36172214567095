// @flow

import React from "react";
import ChipCounter from "./ChipCounter";

type Props = {
  list: string[]
};

function ListCellRenderer(props: Props) {
  const { list } = props;
  const listTitle = props.list.slice().sort().join(", ");

  return (
    <span title={listTitle}>
      {list.length > 1 && (
        <>
          <ChipCounter width="22">{list.length}</ChipCounter>{" "}
        </>
      )}
      {list.join(", ")}
    </span>
  );
}

export default ListCellRenderer;
