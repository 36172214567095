export const FULLSTORY_ID = "WG5N3";
export const INTERCOM_APP_ID = "lok1x2ke";
export const HEAP_ID = {
  integration: "1906242136",
  production: "2662965322"
};
export const LOGROCKET_ID = "s0xmes/web-app-xmqpy";

const apiConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  domain: process.env.REACT_APP_DOMAIN,
  apiUrl: process.env.REACT_APP_API_URL
};

export default apiConfig;
