// @flow

import React from "react";
import { endOfToday, startOfToday, subYears } from "date-fns";
import { observer } from "mobx-react";

import ButtonSelector from "../ButtonSelector";
import SidebarDateRangeSelect from "../SidebarDateRangeSelect";
import SidebarFilter from "../SidebarFilter";
import SidebarSelect from "../SidebarSelect";
import { INFLUENCES_LIST, influenceStatusNames } from "../../helpers/influenceConstants";
import { Status } from "../../helpers/Status";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  computedDateFormat: string,
  creators: {
    analystCreators: Array<Object>,
    status: string
  },
  pageContext: Object
};

const namesToOptions = (names: Object = {}) =>
  Object.entries(names).map(([key, value]) => ({
    label: value,
    value: key
  }));

const filterItems = (query, item, index, exactMatch) => {
  const normalizedQuery = query.toLowerCase();
  const normalizedTitle = item.label.toLowerCase();
  const normalizedEmail = item.email.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  }

  const regexpPrefix = "";
  return (
    normalizedTitle.match(new RegExp(`${regexpPrefix}${normalizedQuery}`)) ||
    normalizedEmail.match(new RegExp(`${regexpPrefix}${normalizedQuery}`))
  );
};

function IHInfluenceFilters(props: Props) {
  const { computedDateFormat, creators = {}, pageContext } = props;
  const { filters, changeFilter, sidebar } = pageContext;
  const { status, analystCreators } = creators;

  const types = INFLUENCES_LIST.filter(influence => !influence.disabled).map(influence => ({
    label: influence.name,
    value: influence.type
  }));
  const statuses = namesToOptions(influenceStatusNames);
  const minDate = subYears(startOfToday(), 1);
  const maxDate = endOfToday();

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("type of influence", "type", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={types}
          onChange={filterValue => changeFilter("type", filterValue)}
          selectedItems={filters.type}
          title="Type of Influence"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("influence status", "status", sidebar)}>
        <ButtonSelector
          items={statuses}
          onSelect={filterValue => changeFilter("status", filterValue)}
          selectedItems={filters.status}
          title="Influence Status"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("influences created on", "createdOn", sidebar)}>
        <SidebarDateRangeSelect
          computedDateFormat={computedDateFormat}
          dates={filters.createdOn}
          maxDate={maxDate}
          minDate={minDate}
          onChange={filterValue => changeFilter("createdOn", filterValue)}
          title="Influences created on"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("influences modified on", "updatedOn", sidebar)}>
        <SidebarDateRangeSelect
          computedDateFormat={computedDateFormat}
          dates={filters.updatedOn}
          maxDate={maxDate}
          minDate={minDate}
          onChange={filterValue => changeFilter("updatedOn", filterValue)}
          title="Influences modified on"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("analyst", "userIds", sidebar)}>
        <SidebarSelect
          filterItems={filterItems}
          fuzzySearch
          isLoading={status === Status.LOADING}
          items={analystCreators}
          onChange={filterValue => changeFilter("userIds", filterValue)}
          selectedItems={filters.userIds}
          title="Analyst"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(IHInfluenceFilters);
