// @flow

import { action, computed, observable, toJS } from "mobx";
import { Intent } from "@blueprintjs/core";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";
import union from "lodash.union";
import difference from "lodash.difference";
import pluralize from "pluralize";

import api from "../services/Api";
import { AppToaster } from "../services/Toaster";
import { Status } from "../helpers/Status";
import removeFilterValue from "../helpers/removeFilterValue";

export const FILTERS_INIT = {
  analyst: [],
  region: [],
  rtMarket: [],
  subregion: []
};

type SelectItem = { label: string, value: number };

type MarketParams = {
  analyst?: SelectItem,
  region?: SelectItem,
  subregion?: SelectItem
};

export class MarketsStore {
  @observable
  filters = { ...FILTERS_INIT };

  @observable
  filtersEnabled = true;

  @observable
  status = Status.INIT;

  @observable
  rtMarkets = [];

  @observable
  sidebar = {
    isOpen: false,
    filterKey: "",
    filterQuery: ""
  };

  @observable
  searchParams = { ...FILTERS_INIT };

  @observable
  table = {
    status: Status.INIT,
    columns: ["rtMarket", "analystName", "regionName", "subregionName"],
    data: [],
    selectedRows: []
  };

  @action.bound
  setSidebarOpen(isOpen) {
    this.sidebar.isOpen = isOpen;
    if (!isOpen) {
      this.sidebar.filterQuery = "";
      this.sidebar.filterKey = "";
    }
  }

  @action.bound
  toggleFiltersEnabled() {
    this.filtersEnabled = !this.filtersEnabled;
    this.getMarkets();
  }

  @action.bound
  setSidebarFilterQuery(filterQuery, filterKey) {
    const { sidebar } = this;

    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action getMarketList() {
    const params = {
      sortBy: {
        direction: "asc",
        field: "market"
      }
    };

    api
      .getMarkets(params)
      .then(({ data }) => {
        this.rtMarkets = data.rows.map(row => row.rtMarket);
        this.status = Status.DONE;
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action getMarkets() {
    const { filters, table } = this;
    table.status = Status.LOADING;
    const filterParams = {
      ...(!isEmpty(filters.rtMarket) && {
        rtMarkets: filters.rtMarket
      }),
      ...(!isEmpty(filters.analyst) && {
        analystId: filters.analyst
      }),
      ...(!isEmpty(filters.region) && {
        regionId: filters.region
      }),
      ...(!isEmpty(filters.subregion) && {
        subregionId: filters.subregion
      })
    };

    const params = {
      sortBy: {
        field: "market",
        direction: "asc"
      },
      ...(this.filtersEnabled && { filters: filterParams })
    };
    api
      .getMarkets(params)
      .then(({ data }) => {
        table.data = data.rows;
        table.status = Status.DONE;
      })
      .catch(() => {
        table.status = Status.ERROR;
      });
  }

  @action
  changeFilter(name: string, value: any) {
    this.filters[name] = value;
  }

  @action.bound
  resetSearchParam(name: string) {
    this.filters[name] = FILTERS_INIT[name];
    this.submitSearchForm();
  }

  @action.bound
  removeFilterValue(filterKey: string, option: string) {
    const { filters } = this;
    if (filters[filterKey]) {
      filters[filterKey] = removeFilterValue(filters[filterKey], option, FILTERS_INIT[filterKey]);
      this.submitSearchForm();
    }
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { data, selectedRows } = this.table;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.map((row, i) => i);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.table.selectedRows = !isToggleOn ? union(selectedRows, actionRows) : difference(selectedRows, actionRows);
  }

  @action
  changeSelectedRows(selectedRows: Array<string>) {
    this.table.selectedRows.replace(selectedRows);
  }

  @action.bound
  submitSearchForm() {
    this.searchParams = cloneDeep(toJS(this.filters));
    this.getMarkets();
    this.sidebar.isOpen = false;
    this.sidebar.filterQuery = "";
    this.table.selectedRows.clear();
  }

  @action
  updateMarkets(params: MarketParams) {
    const { table } = this;
    const { analyst, region, subRegion } = params;
    const { value: analystId, label: analystName } = analyst;
    const { value: regionId, label: regionName } = region;
    const { value: subregionId, label: subRegionName } = subRegion;

    const selectedMarkets = table.selectedRows.map(selectedIndex => table.data[selectedIndex].rtMarket);
    const patchData = {
      ...(!isEmpty(table.selectedRows) && {
        rtMarkets: selectedMarkets
      }),
      ...(analystName && { analystId }),
      ...(regionName && { regionId }),
      ...(subRegionName && { subregionId })
    };
    api.updateMarkets(patchData).then(() => {
      const toastMsg =
        table.selectedRows.length === 1
          ? `1 Market Mapping has been updated`
          : `${table.selectedRows.length} Market Mappings have been updated`;
      AppToaster.show({
        message: toastMsg,
        intent: Intent.SUCCESS
      });
      this.table.selectedRows.clear();
      this.getMarkets();
    });
  }

  @action
  resetParams() {
    this.searchParams = { ...FILTERS_INIT };
  }

  @computed
  get isLoading() {
    const { table, status } = this;
    return table.status === Status.LOADING || status === Status.LOADING;
  }

  @computed
  get isError() {
    const { table } = this;
    return table.status === Status.ERROR;
  }

  @computed
  get marketCount() {
    const { table } = this;
    return pluralize("Market", table.selectedRows.length, true);
  }

  @computed
  get decoratedPage() {
    const { changeFilter } = this;
    return {
      ...this,
      changeFilter
    };
  }
}
