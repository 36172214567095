// @flow

import React from "react";
import { observer } from "mobx-react";
import { Suggest } from "@blueprintjs/select";
import { MenuItem, Button, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import isEmpty from "lodash.isempty";
import kebabCase from "lodash.kebabcase";
import clsx from "clsx";

const SingleSuggest = styled(Suggest)`
  input {
    padding-right: 30px;
  }
`;
const ClearButtonStyled = styled(Button)`
  position: absolute;
  right: 0;
  z-index: 10;
`;

type Props = {
  title: string,
  items: Array<Object | string>,
  selectedItem: Object,
  selectItem: Function
};

function SidebarSingleSelect(props: Props) {
  const { title = "", items = [], selectedItem = {}, selectItem } = props;

  const isCollection = typeof items[0] === "object";

  function filterItems(query, item, index, exactMatch) {
    const normalizedQuery = query.toLowerCase();
    const normalizedTitle = isCollection ? item.label.toLowerCase() : item.toLowerCase();

    return exactMatch ? normalizedTitle === normalizedQuery : normalizedTitle.includes(normalizedQuery);
  }

  function inputValueRenderer(value: Object | string) {
    if (isEmpty(value)) {
      return null;
    }

    if (value.label) {
      return value.label;
    }

    return isCollection ? (items.find(item => item.value === value) || {}).label : value;
  }

  const popoverClassName = clsx("sidebar-select", kebabCase(`${title}-select`));
  const testId = kebabCase(`${title}-select`);

  return (
    <div className="mb-3" data-testid={testId}>
      <h6 className="bp3-heading">{title}</h6>
      <div className="d-flex position-relative">
        <SingleSuggest
          fill
          itemPredicate={filterItems}
          itemRenderer={(item, itemProps) => (
            <MenuItem
              key={isCollection ? item.value : item}
              onClick={itemProps.handleClick}
              text={isCollection ? item.label : item}
            />
          )}
          inputProps={{
            placeholder: `Select ${title}`
          }}
          inputValueRenderer={inputValueRenderer}
          items={items}
          noResults={<MenuItem disabled text="No results." />}
          onItemSelect={item => selectItem(item)}
          popoverProps={{
            minimal: true,
            modifiers: {
              offset: 100
            },
            popoverClassName,
            position: Position.BOTTOM
          }}
          selectedItem={selectedItem}
        />
        {!isEmpty(selectedItem) ? (
          <ClearButtonStyled data-testid="clear-select" icon="cross" minimal onClick={() => selectItem({})} />
        ) : null}
      </div>
    </div>
  );
}

export default observer(SidebarSingleSelect);
