import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

export const SystemSettingsSectionHeader = styled("h4")`
  color: ${Colors.DARK_GRAY1};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 14px;
`;

export const SystemSettingsDescription = styled("p")`
  color: ${Colors.GRAY1};
  font-size: 12px;
  line-height: 1.25;
`;

export const SystemSettingsSelect = styled(Select)`
  button {
    display: flex;
    justify-content: space-between;
    min-width: 150px;
  }
`;

export const SystemSettingsControlLabel = styled("h5")`
  color: ${Colors.DARK_GRAY1};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
`;
