// @flow

import React from "react";
import { observer } from "mobx-react";
import { css, Global } from "@emotion/core";

import Module from "../Module";
import DataTable from "../DataTable";
import MarketMappingsButton from "./MarketMappingsButton";
import { Status } from "../../helpers/Status";

type Props = {
  columns: Array<Object>,
  data: Array<Object>,
  fetchData: ?Function,
  pagination: Object,
  selectedRows: Array<string>,
  status: string
};

function MarketsTable(props: Props) {
  const { status, selectedRows } = props;

  const columnLabels = {
    analystName: "Analyst",
    numberOfFlights: "Number of Flights",
    regionName: "Region",
    rtMarket: "Market",
    subregionName: "Subregion"
  };

  const tableProps = {
    ...props,
    columnLabels,
    showPagination: false,
    sortable: "frontend"
  };

  const isLoaded = status === Status.DONE;

  return (
    <Module
      childrenClassName="h-100"
      className="market-mappings-table h-100 p-0"
      title="Market Mappings"
      titleTools={isLoaded && <MarketMappingsButton selectedRows={selectedRows} />}
    >
      <Global
        styles={css`
          .market-mappings-table {
            width: 680px;
            thead tr:first-of-type {
              display: none !important;
            }
          }
        `}
      />
      <DataTable {...tableProps} />
    </Module>
  );
}

export default observer(MarketsTable);
