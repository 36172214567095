// @flow

import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";

import { FILTERS_INIT, Tab } from "../../models/Tab.model";
import getRowObjectIds from "../../helpers/getRowObjectIds";
import { influenceSteps, PRICE_LIMITS_INIT, PriceLimit } from "../../models/Influence.model";
import normalizeFilters from "../../helpers/normalizeFilters";
import { Status } from "../../helpers/Status";

import InfluenceControlNavigation from "./InfluenceControlNavigation";
import InfluenceControlSave from "./InfluenceControlSave";
import InfluenceControlSelection from "./InfluenceControlSelection";
import InfluenceControlSet from "./InfluenceControlSet";
import InfluenceControlSummary from "./InfluenceControlSummary";
import Module from "../Module";
import NumberOfFlights from "../NumberOfFlights";
import { HIGHEST_START_NDO } from "../../helpers/influenceConstants";
import { minMaxOverlaps } from "../../helpers/minMaxOverlaps";
import isNumber from "../../helpers/isNumber";

type Props = {
  data: {
    influenceId: string,
    base: Object,
    preview: Object,
    previewCounters: Object
  },
  finishInfluence: Function,
  previewInfluence: Function,
  influencesList: Array<{
    disabled: boolean,
    isPreview: boolean,
    rows: Array<{ key: string }>,
    type: string
  }>,
  parameters: {
    comment: string,
    inputValue: number | Array<PriceLimit>,
    value: number | Array<PriceLimit>
  },
  resetInfluence: Function,
  saveInfluence: Function,
  selectedInfluence: {
    disabled: boolean,
    isPreview: boolean,
    rows: Array<{ key: string }>,
    type: string
  },
  state: {
    influenceStep: number,
    influenceType: string
  },
  status: string,
  tab: Tab,
  updateParameters: Function,
  updateState: Function
};

function InfluenceModule(props: Props) {
  const {
    data,
    finishInfluence,
    influencesList,
    parameters,
    resetInfluence,
    selectedInfluence,
    state,
    status,
    tab,
    updateParameters,
    updateState
  } = props;

  const { influenceStep } = state;
  const { SELECTION, SET, SAVE, SUMMARY } = influenceSteps;

  const { applied = {}, flightsTable = {}, xDayBuild } = tab;
  const { aggregations = [], selectedRows = [] } = flightsTable;

  const influenceParams = {
    filters: normalizeFilters(applied.filters, FILTERS_INIT),
    conditionalFilters: applied.conditionalFilters,
    rowIds: getRowObjectIds(aggregations, selectedRows),
    xDayBuild
  };

  const isInputValue = () => {
    const { inputValue } = parameters;

    if (isNumber(inputValue)) {
      return inputValue !== 0;
    }

    if (Array.isArray(inputValue)) {
      const valuesCheck = minMaxOverlaps(inputValue).map(element => {
        const values = [element.min, element.max];
        const ndoValues = [element.startNdo, element.endNdo];
        const ndoOverlaps = [element.startNdoOverlaps, element.endNdoOverlaps];

        // both NDOs are mandatory - start should be bigger
        if (ndoValues.some(value => !isNumber(value)) || element.startNdo < element.endNdo) {
          return false;
        }

        if (element.startNdo > HIGHEST_START_NDO) {
          return false;
        }

        if (ndoOverlaps.some(value => !!value)) {
          return false;
        }

        // both positive values - max should be bigger or equal
        if (values.every(value => isNumber(value) && value >= 0)) {
          return element.max >= element.min;
        }

        // at least one should be number
        return values.some(isNumber);
      });

      return valuesCheck.every(Boolean);
    }
    return false;
  };

  const getInfluencePreview = (type: string, inputValue: number, options: Object) => {
    updateParameters({ value: cloneDeep(toJS(inputValue)) });

    const pagination = { size: flightsTable.pagination.totalRows, offset: 0 };
    const { sortBy } = flightsTable;
    const { isBaseValue } = options || {};

    // preview price limits
    if (type === "MM") {
      props.previewInfluence(
        "MM",
        {
          ...influenceParams,
          aggregations,
          adjustments: isBaseValue
            ? []
            : inputValue.map(element => ({
                min: isNumber(element.min) ? element.min : null,
                max: isNumber(element.max) ? element.max : null,
                ndo: { start: element.startNdo, end: element.endNdo }
              })),
          pagination,
          sortBy
        },
        options
      );
      return;
    }

    // preview other types
    props.previewInfluence(
      type,
      {
        ...influenceParams,
        aggregations,
        pagination,
        sortBy,
        value: inputValue
      },
      options
    );
  };

  const saveInfluence = () => {
    const { comment, value } = parameters;

    // save price limits
    if (state.influenceType === "MM") {
      props.saveInfluence("MM", {
        ...influenceParams,
        comment,
        adjustments: value.map(element => ({
          min: isNumber(element.min) ? element.min : null,
          max: isNumber(element.max) ? element.max : null,
          ndo: { start: element.startNdo, end: element.endNdo }
        }))
      });
      return;
    }

    // save other types
    props.saveInfluence(state.influenceType, {
      ...influenceParams,
      aggregations,
      comment,
      value
    });
  };

  const setInfluenceType = (type: string) => {
    updateState({ influenceType: type, influenceStep: 2 });
    // `props.selectedInfluence` getter is not updated at this point
    const options = influencesList.find(item => item.type === type) || {};

    if (options.isPreview) {
      getInfluencePreview(type, 0, { isBaseValue: true });
    }
    if (options.type === "MM") {
      updateParameters({
        inputValue: [cloneDeep(PRICE_LIMITS_INIT)],
        value: [cloneDeep(PRICE_LIMITS_INIT)]
      });
    }
  };

  const renderContent = () => {
    switch (influenceStep) {
      case SELECTION:
      default: {
        const isDone = flightsTable.status === Status.DONE;
        const isFlightTableReady = isDone && !isEmpty(flightsTable.data);

        const selectionProps = {
          influencesList,
          isFlightTableReady,
          setInfluenceType,
          shouldShowTooltip: isEmpty(selectedRows)
        };
        return <InfluenceControlSelection {...selectionProps} />;
      }
      case SET:
      case SAVE: {
        const setSaveProps = {
          data,
          parameters,
          selectedInfluence,
          state,
          status,
          updateParameters
        };
        return influenceStep === SET ? (
          <InfluenceControlSet {...setSaveProps} />
        ) : (
          <InfluenceControlSave {...setSaveProps} />
        );
      }
      case SUMMARY: {
        const summaryProps = {
          data,
          finishInfluence,
          status
        };
        return <InfluenceControlSummary {...summaryProps} />;
      }
    }
  };

  const navigationProps = {
    parameters,
    getInfluencePreview,
    isInputValue,
    resetInfluence,
    saveInfluence,
    selectedInfluence,
    state,
    status,
    updateParameters,
    updateState
  };

  const subtitle = [
    <NumberOfFlights
      flightsCount={tab.flightsCount}
      flightsTable={tab.flightsTable}
      options={{ showSelected: true }}
      selectedRowsNumberOfFlights={tab.selectedRowsNumberOfFlights}
    />
  ];

  return (
    <Module
      className="h-100"
      footer={<InfluenceControlNavigation {...navigationProps} />}
      subtitle={subtitle}
      title="Influence Controls"
      childrenClassName="flex-grow-1 overflow-auto"
    >
      {renderContent()}
    </Module>
  );
}

export default observer(InfluenceModule);
