export class LazyValue<T> {
  resolver: () => T;
  resolved = false;
  cachedValue: T;

  constructor(resolver: () => T) {
    this.resolver = resolver;
  }

  get value(): T {
    if (!this.resolved) {
      this.cachedValue = this.resolver();
      this.resolved = true;
    }

    return this.cachedValue;
  }

  reset() {
    this.cachedValue = undefined;
    this.resolved = false;
  }
}
