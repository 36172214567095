import styled from "@emotion/styled";

import { Colors } from "@blueprintjs/core";

const StyledChipCounter = styled.span`
  background: ${Colors.LIGHT_GRAY2};
  border-radius: 3px;
  color: ${Colors.DARK_GRAY5};
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 4px 0 6px;
  min-width: ${({ width }) => `${width || 18}px`};
  padding: 0.1rem 0.3rem;
  text-align: center;
  &.pill {
    background: ${Colors.BLUE5}66;
    color: ${Colors.BLUE3};
  }
  &.sidebar--applied {
    background: ${Colors.LIGHT_GRAY4};
    color: ${Colors.BLUE3};
  }
`;

export default StyledChipCounter;
