import React from "react";
import { observer } from "mobx-react";

import TagsList from "./TagsList";
import { daysOfWeek, extraMetrics } from "../helpers/constants";
import { FILTERS_INIT } from "../models/Cirrus.model";
import { cirrusMetrics } from "./CirrusSidebar";
import { useStores } from "../store/Store";

function CirrusTagList() {
  const { cirrusStore } = useStores();
  const { searchParams } = cirrusStore;

  const filterOrder = cirrusMetrics.map(({ filters }) => filters.map(({ key }) => key)).flat();

  const onTagClick = (filterId: string) => {
    cirrusStore.setSidebarOpen(true);
    cirrusStore.setSidebarFilterQuery(extraMetrics[filterId], filterId);
  };

  const tagsListProps = {
    disabled: !cirrusStore.filtersEnabled,
    filterOrder,
    initialFilters: FILTERS_INIT,
    onChangeParam: name => {
      cirrusStore.resetSearchParam(name);
    },
    onTagClick,
    onValueRemove: cirrusStore.removeFilterValue,
    params: searchParams,
    valueRenderers: {
      departureDayOfWeek: value => daysOfWeek[value].short,
      fusionrmStatus: value => (value ? "On" : "Off")
    }
  };

  return <TagsList {...tagsListProps} />;
}

export default observer(CirrusTagList);
