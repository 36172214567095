// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import isEmpty from "lodash.isempty";
import { Button, Classes, Colors, Dialog } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { useStores } from "../../store/Store";
import MarketMappingsAnalystSelect from "./MarketMappingsAnalystSelect";
import MarketMappingsRegionSelect from "./MarketMappingsRegionSelect";
import MarketMappingsSubregionSelect from "./MarketMappingsSubregionSelect";

const StyledMarketMappingsModalBody = styled("div")`
  .bp3-input-group {
    width: 220px;
  }
`;
const StyledDialogFooter = styled("div")`
  box-shadow: 0 -1px 0 ${Colors.BLACK}26;
`;

type Props = {
  modal: Object
};

function MarketMappingsModal({ modal }: Props) {
  const [selectedAnalyst, setSelectedAnalyst] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedSubregion, setSelectedSubregion] = useState(null);
  const { marketsStore } = useStores();

  const modalTitle = `Update Mappings (${marketsStore.marketCount})`;

  const isRegionSelected = selectedRegion && selectedRegion.value !== null;
  const isSubregionSelected = selectedSubregion && selectedSubregion.value !== null;

  const isSaveButtonDisabled =
    [selectedAnalyst, selectedRegion, selectedSubregion].every(isEmpty) || (isSubregionSelected && !isRegionSelected);

  return (
    <Dialog
      className="p-0"
      isOpen={modal.modalActive === "market-mappings"}
      onClose={() => modal.setModal("")}
      title={modalTitle}
    >
      <StyledMarketMappingsModalBody className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="market-mappings-modal">
        <MarketMappingsAnalystSelect onChange={setSelectedAnalyst} selectedAnalyst={selectedAnalyst} />
        <MarketMappingsRegionSelect
          onChange={setSelectedRegion}
          selectedRegion={selectedRegion}
          selectedSubregion={selectedSubregion}
        />
        <MarketMappingsSubregionSelect
          onChange={setSelectedSubregion}
          selectedSubregion={selectedSubregion}
          selectedRegion={selectedRegion}
        />
      </StyledMarketMappingsModalBody>

      <StyledDialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            className="ml-auto px-3"
            data-testid="market-mapping-modal-save-btn"
            disabled={isSaveButtonDisabled}
            intent="primary"
            onClick={() => {
              marketsStore.updateMarkets({
                analyst: selectedAnalyst || {},
                region: selectedRegion || {},
                subRegion: selectedSubregion || {}
              });
              modal.setModal("");
            }}
            text="Save"
          />
        </div>
      </StyledDialogFooter>
    </Dialog>
  );
}

export default observer(MarketMappingsModal);
