// @flow
import React from "react";
import { observer } from "mobx-react";

import SidebarConditionalFilter from "../SidebarConditionalFilter";
import SidebarFilter from "../SidebarFilter";
import SidebarSelect from "../SidebarSelect";
import { FiltersHelpTexts } from "../../helpers/filtersHelpTexts";
import { lacGroupLabels } from "../../helpers/metricLabels/metricLabels";
import type { AnalysisMappingsStore } from "../../models/AnalysisMappings.model";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  pageContext: Object,
  filters: Array<Object>,
  mappings: AnalysisMappingsStore,
  xDayBuild: number
};

function LacFilters(props: Props) {
  const { mappings, pageContext, filters: filterList, xDayBuild = 7 } = props;
  const {
    addCondition,
    changeConditionalFilter,
    changeFilter,
    conditionalFilters,
    deleteCondition,
    filters,
    sidebar
  } = pageContext;

  const bookingClasses = mappings.getBookingClasses();
  const bookingClassesByCabinClass = mappings.getBookingClassesByCabinClass(filters);

  const labels = lacGroupLabels({
    withUnits: true,
    xDayBuild
  });
  const filterLabels = lacGroupLabels({
    withParent: true,
    withUnits: true,
    xDayBuild
  });
  const columnHelpUrls = FiltersHelpTexts();

  const filtersByKey = {};
  filterList.forEach(filter => {
    filtersByKey[filter.key] = filter;
  });

  const renderConditionalFilter = type => (
    <SidebarFilter isVisible={isSidebarFilterVisible(filterLabels[type], type, sidebar)}>
      <SidebarConditionalFilter
        conditionalFilters={conditionalFilters}
        isNonNegative={filtersByKey[type].isNonNegative}
        name={type}
        onAddCondition={addCondition}
        onChangeCondition={changeConditionalFilter}
        onDeleteCondition={deleteCondition}
        title={labels[type]}
        type="absolute"
      />
    </SidebarFilter>
  );

  return (
    <>
      <a
        className="mb-3 d-inline-block"
        href={columnHelpUrls.lacGroup.url}
        target="_blank"
        rel="noopener noreferrer"
        title={columnHelpUrls.shortText}
      >
        {columnHelpUrls.lacGroup.text}
      </a>

      {filtersByKey.lacRank && renderConditionalFilter("lacRank")}

      <SidebarFilter isVisible={isSidebarFilterVisible(filterLabels.lacRbd, "lacRbd", sidebar)}>
        <SidebarSelect
          allItemsGroupLabel="All classes"
          fuzzySearch
          items={bookingClasses}
          matchingItems={bookingClassesByCabinClass}
          onChange={filterValue => changeFilter("lacRbd", filterValue)}
          selectedItems={filters.lacRbd}
          title={labels.lacRbd}
        />
      </SidebarFilter>

      {filtersByKey.xDayLacRank && renderConditionalFilter("xDayLacRank")}

      <SidebarFilter isVisible={isSidebarFilterVisible(filterLabels.xDayLacRbd, "xDayLacRbd", sidebar)}>
        <SidebarSelect
          allItemsGroupLabel="All classes"
          fuzzySearch
          items={bookingClasses}
          matchingItems={bookingClassesByCabinClass}
          onChange={filterValue => changeFilter("xDayLacRbd", filterValue)}
          selectedItems={filters.xDayLacRbd}
          title={labels.xDayLacRbd}
        />
      </SidebarFilter>

      {filtersByKey.lacRankFrm && renderConditionalFilter("lacRankFrm")}

      <SidebarFilter isVisible={isSidebarFilterVisible(filterLabels.lacRbdFrm, "lacRbdFrm", sidebar)}>
        <SidebarSelect
          allItemsGroupLabel="All classes"
          fuzzySearch
          items={bookingClasses}
          matchingItems={bookingClassesByCabinClass}
          onChange={filterValue => changeFilter("lacRbdFrm", filterValue)}
          selectedItems={filters.lacRbdFrm}
          title={labels.lacRbdFrm}
        />
      </SidebarFilter>

      {filtersByKey.xDayLacRankFrm && renderConditionalFilter("xDayLacRankFrm")}

      <SidebarFilter isVisible={isSidebarFilterVisible(filterLabels.xDayLacRbdFrm, "xDayLacRbdFrm", sidebar)}>
        <SidebarSelect
          allItemsGroupLabel="All classes"
          fuzzySearch
          items={bookingClasses}
          matchingItems={bookingClassesByCabinClass}
          onChange={filterValue => changeFilter("xDayLacRbdFrm", filterValue)}
          selectedItems={filters.xDayLacRbdFrm}
          title={labels.xDayLacRbdFrm}
        />
      </SidebarFilter>

      {filtersByKey.xDayLacBuildDiff && renderConditionalFilter("xDayLacBuildDiff")}
    </>
  );
}

export default observer(LacFilters);
