// @flow

import React from "react";
import { observer } from "mobx-react";

import { Tab } from "../models/Tab.model";
import { useStores } from "../store/Store";

import AnalysisTagsList from "./AnalysisTagsList";
import BuildPeriodSelect from "./BuildPeriodSelect";
import FilterInteractionsWrapper from "./FilterInteractionsWrapper";
import SwitchButtons from "./SwitchButtons";
import TopBar from "./TopBar";

type Props = {
  tab: Tab
};

function AnalysisTopBar({ tab }: Props) {
  const { analysisMappingsStore, influenceStore, regionsStore } = useStores();

  const { isPreviewActive } = influenceStore;
  const { changeBuildPeriod, filtersEnabled, setSidebarOpen, toggleFiltersEnabled, xDayBuild } = tab;

  const isSidebarInteractionsDisabled = isPreviewActive || analysisMappingsStore.isError;

  const isLoading = analysisMappingsStore.isLoading || regionsStore.isLoading;

  return (
    <TopBar>
      <SwitchButtons tabId={tab.id} />
      <BuildPeriodSelect changeBuildPeriod={changeBuildPeriod} disabled={isLoading} xDayBuild={xDayBuild} />
      {tab.applied && <AnalysisTagsList tab={tab} />}
      <FilterInteractionsWrapper
        buttonProps={{
          disabled: isSidebarInteractionsDisabled,
          isLoading,
          onClick: setSidebarOpen,
          testId: "sidebar-button"
        }}
        filterSwitchProps={{
          checked: filtersEnabled,
          disabled: isSidebarInteractionsDisabled,
          onChange: toggleFiltersEnabled
        }}
      />
    </TopBar>
  );
}

export default observer(AnalysisTopBar);
