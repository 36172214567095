import React, { useEffect } from "react";
import { observer } from "mobx-react";

import SidebarSingleSelect from "../SidebarSingleSelect";
import { useStores } from "../../store/Store";

type Props = {
  onChange: Function,
  selectedRegion: { label: string, key: number } | null,
  selectedSubregion: { label: string, key: number } | null
};

const unassignedItem = { value: null, label: "Unassigned" };

function MarketMappingsRegionSelect(props: Props) {
  const { onChange, selectedRegion, selectedSubregion } = props;
  const { regionsStore } = useStores();
  const regionsToSelect = regionsStore.getRegionsBySubregionId();

  const regionItems = [
    unassignedItem,
    ...regionsToSelect.map(({ regionId, regionName }) => ({
      label: regionName,
      value: regionId
    }))
  ];

  useEffect(() => {
    if (selectedSubregion?.value) {
      const regionToSelect = regionsStore.getRegionsBySubregionId(selectedSubregion.value)?.[0];
      onChange({ label: regionToSelect.regionName, value: regionToSelect.regionId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubregion]);

  return (
    <div className="d-flex">
      <SidebarSingleSelect
        items={regionItems}
        selectedItem={selectedRegion}
        selectItem={item => onChange(item)}
        title="Region"
      />
    </div>
  );
}

export default observer(MarketMappingsRegionSelect);
