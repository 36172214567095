// @flow

import React from "react";
import { observer } from "mobx-react";
import { ControlGroup, Icon } from "@blueprintjs/core";

import formatValueWithUnit from "../../helpers/FormatValueWithUnit";
import { InfluenceStatus } from "../../models/Influence.model";
import { influenceTypeNames } from "../../helpers/influenceConstants";
import { StyledButtonIcon, StyledCard, StyledHeaderTitle, StyledInfluenceInput } from "./InfluenceStyles";

import Row from "../Row";

type Props = {
  parameters: {
    comment: string,
    inputValue: number,
    value: ?number
  },
  readOnly: boolean,
  status: string,
  updateParameters: Function
};

function PriceAdjustmentForm(props: Props) {
  const { parameters, readOnly, status, updateParameters } = props;
  const { inputValue, value } = parameters;

  const onChange = (newValue: number) => (!Number.isNaN(newValue) ? updateParameters({ inputValue: newValue }) : null);

  const renderContent = () => {
    if (readOnly) {
      return <div className="ml-auto">{formatValueWithUnit(value, "percent")}</div>;
    }

    const isLoading = status.includes(InfluenceStatus.LOADING);
    const inputProps = {
      allowNumericCharactersOnly: false,
      clampValueOnBlur: true,
      disabled: isLoading,
      fill: true
    };

    return (
      <>
        <StyledButtonIcon disabled={isLoading}>
          <Icon icon="percentage" />
        </StyledButtonIcon>
        <StyledInfluenceInput
          {...inputProps}
          data-testid="influence-percent-input"
          max={100}
          min={-100}
          onValueChange={onChange}
          placeholder="0"
          value={inputValue}
        />
      </>
    );
  };

  return (
    <StyledCard className="p-3 mb-2">
      <Row className="align-items-center">
        <div className="col-5">
          <StyledHeaderTitle>{influenceTypeNames.PA}</StyledHeaderTitle>
        </div>
        <div className="col-7 pl-0">
          <ControlGroup className="align-items-center" data-testid="influence-percent-value">
            <strong className="mr-2">Adj. </strong>
            {renderContent()}
          </ControlGroup>
        </div>
      </Row>
    </StyledCard>
  );
}

export default observer(PriceAdjustmentForm);
