// @flow

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, InputGroup, Keys, RangeSlider } from "@blueprintjs/core";
import clsx from "clsx";

const MIN_TIME = "00:00";
const MAX_TIME = "23:59";
const MAX_MINUTES = 1440;
const TIME_REGEXP = /^\d\d:[0-5]\d$/;

export const numberToHour = (number: number): string => {
  const pad = (digit: number) => String(digit).padStart(2, "0");

  if (number >= MAX_MINUTES) {
    return MAX_TIME;
  }
  const hours = parseInt(number / 60, 10);
  const minutes = number - hours * 60;

  return `${pad(hours)}:${pad(minutes)}`;
};

export const hourToNumber = (hour: string): number => {
  if (hour === "24:00") {
    return 1439;
  }
  const [hours, minutes] = hour.split(":");
  return Number(hours) * 60 + Number(minutes);
};

type TimeInputProps = {
  defaultValue: string,
  max: number,
  min: number,
  onValueChange: string => void,
  value: string
};

function TimeInput({ defaultValue, max, min, onValueChange, value, ...rest }: TimeInputProps) {
  const [rawValue, setRawValue] = useState("");

  useEffect(() => {
    if (value !== rawValue) {
      setRawValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleCommit = () => {
    let safeValue = (rawValue || "").trim();
    if (safeValue.length > 2 && safeValue[2] !== ":") {
      safeValue = `${safeValue.slice(0, 2)}:${safeValue.slice(2)}`;
    }

    if (TIME_REGEXP.test(safeValue)) {
      const minutes = hourToNumber(safeValue);
      if (!(minutes < hourToNumber(min) || minutes > hourToNumber(max))) {
        setRawValue(safeValue);
        onValueChange(safeValue);

        return;
      }
    }

    setRawValue(value);
  };

  return (
    <InputGroup
      {...rest}
      onBlur={handleCommit}
      onKeyDown={event => {
        if (event.keyCode === Keys.ENTER) {
          handleCommit();
        }
      }}
      onChange={event => {
        setRawValue(event.target.value);
      }}
      rightElement={
        <Button
          className={clsx(value === defaultValue && "invisible")}
          icon="cross"
          minimal
          onClick={() => {
            setRawValue(defaultValue);
            onValueChange(defaultValue);
          }}
        />
      }
      value={rawValue}
    />
  );
}

type Props = {
  hours: Object,
  title: string,
  onChange: Function
};

function SidebarHourSelect(props: Props) {
  const { title = "", hours = { start: MIN_TIME, end: MAX_TIME }, onChange } = props;

  return (
    <div className="d-flex flex-column mb-3">
      <h6 className="bp3-heading">{title}</h6>
      <div className="d-flex mb-2">
        <TimeInput
          defaultValue={MIN_TIME}
          fill
          max={hours.end}
          min={MIN_TIME}
          onValueChange={value => {
            onChange({ start: value, end: hours.end });
          }}
          value={hours.start}
        />
        <TimeInput
          className="ml-2"
          defaultValue={MAX_TIME}
          fill
          max={MAX_TIME}
          min={hours.start}
          onValueChange={value => {
            onChange({ start: hours.start, end: value });
          }}
          value={hours.end}
        />
      </div>
      <div className="mx-3">
        <RangeSlider
          min={0}
          max={MAX_MINUTES}
          onChange={([start, end]) => {
            onChange({ start: numberToHour(start), end: numberToHour(end) });
          }}
          value={[hourToNumber(hours.start), hourToNumber(hours.end)]}
          labelStepSize={360}
          labelRenderer={numberToHour}
        />
      </div>
    </div>
  );
}

export default observer(SidebarHourSelect);
