// @flow

import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";

export default function getActiveFilters(filterEntries: Array<Object> = [], initialFilters: ?Object): Array<Object> {
  return filterEntries.filter(([name, value]) => {
    if (initialFilters && !isEmpty(initialFilters[name]) && isEqual(initialFilters[name], value)) {
      return false;
    }

    if (typeof value === "boolean") {
      return true;
    }

    if (typeof value === "object" && value !== null && "start" in value && "end" in value) {
      return (
        (!isEmpty(value.start) && !isEmpty(value.end)) || (Number.isInteger(value.start) && Number.isInteger(value.end))
      );
    }

    // conditional filters arrays
    const isConditionalFilter =
      Array.isArray(value) && !isEmpty(value) && value[0] && Object.keys(value[0]).includes("func");

    if (isConditionalFilter) {
      return value.map(x => x.value).join("");
    }

    return !isEmpty(value);
  });
}
