// @flow

import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { MenuItem } from "@blueprintjs/core";

import { Status } from "../../helpers/Status";
import { useStores } from "../../store/Store";
import { eventItemRenderer } from "./eventItemRenderer";

const StyledMenu = styled("div")`
  min-height: 70px;
`;

type Props = {
  onSelectItem: Function
};

const EventsDateAutocomplete = React.forwardRef((props: Props, ref) => {
  const {
    eventsManagementStore: {
      page: { suggestedEvents = {} }
    }
  } = useStores();
  const { onSelectItem } = props;

  const { status, data, extraEvents } = suggestedEvents;

  if (status !== Status.DONE) {
    return null;
  }
  const items = data.map(item => {
    return eventItemRenderer(
      { ...item },
      {
        handleClick: e => onSelectItem(item, e)
      }
    );
  });

  return (
    <StyledMenu ref={ref}>
      {items}
      {extraEvents > 0 && <MenuItem text={`${extraEvents} more events`} key="info-item" disabled />}
    </StyledMenu>
  );
});

export default observer(EventsDateAutocomplete);
