// @flow

import React from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";
import isEqual from "lodash.isequal";
import styled from "@emotion/styled";

import { InfluenceStatus, influenceSteps, PriceLimit } from "../../models/Influence.model";

const NavigationStyled = styled("div")`
  min-height: 48px;
`;

type Props = {
  getInfluencePreview: Function,
  isInputValue: Function,
  parameters: {
    comment: string,
    inputValue: number | Array<PriceLimit>,
    value: ?number | Array<PriceLimit>
  },
  resetInfluence: Function,
  saveInfluence: Function,
  selectedInfluence: {
    disabled: boolean,
    isPreview: boolean,
    rows: Array<{ key: string }>,
    type: string
  },
  state: {
    influenceStep: number,
    influenceType: string
  },
  status: string,
  updateParameters: Function,
  updateState: Function
};

function InfluenceControlNavigation(props: Props) {
  const {
    parameters,
    getInfluencePreview,
    isInputValue,
    resetInfluence,
    saveInfluence,
    selectedInfluence,
    state,
    status,
    updateParameters,
    updateState
  } = props;

  const { comment, inputValue, value } = parameters;
  const { influenceStep, influenceType } = state;
  const { SELECTION, SET, SAVE, SUMMARY } = influenceSteps;

  const changeStep = (step: number) => {
    updateState({ influenceStep: influenceStep + step });
  };

  const renderPrevButton = () => {
    if ([SELECTION, SUMMARY].includes(influenceStep)) {
      return null;
    }
    return (
      <Button intent="primary" minimal onClick={() => (influenceStep === SET ? resetInfluence() : changeStep(-1))}>
        Back
      </Button>
    );
  };

  const renderNextButton = () => {
    const buttonProps = {
      className: "flex-shrink-0",
      intent: "primary"
    };

    if ([SELECTION, SUMMARY].includes(influenceStep)) {
      return null;
    }

    if (influenceStep === SET) {
      const isPreview = selectedInfluence.isPreview;
      const isValueEqual = isEqual(value, inputValue);
      const isPreviewReady = status === InfluenceStatus.DONE_PREVIEW && isValueEqual;

      const setButtonProps = {
        ...buttonProps,
        disabled: !isInputValue() || status.includes(InfluenceStatus.LOADING)
      };

      if (!isPreview) {
        return (
          <Button
            {...setButtonProps}
            onClick={() => {
              updateParameters({ value: inputValue });
              changeStep(1);
            }}
          >
            Next
          </Button>
        );
      }

      if (isPreviewReady) {
        return (
          <Button {...setButtonProps} onClick={() => changeStep(1)}>
            Next
          </Button>
        );
      }

      return (
        <Button {...setButtonProps} onClick={() => getInfluencePreview(influenceType, inputValue)}>
          Preview
        </Button>
      );
    }

    // save
    if (influenceStep === SAVE) {
      return (
        <Button
          {...buttonProps}
          disabled={!comment}
          onClick={() => {
            saveInfluence();
            changeStep(1);
          }}
        >
          Save
        </Button>
      );
    }
  };

  return (
    <NavigationStyled className="d-flex justify-content-between align-items-center flex-shrink-0 p-2">
      <div className="flex-fill" data-testid="influence-step-back-button">
        {renderPrevButton()}
      </div>
      <div className="bp3-text-small text-center" data-testid="influence-step-number">
        Step {Math.min(3, influenceStep)} of 3
      </div>
      <div className="d-flex justify-content-end flex-fill" data-testid="influence-step-next-button">
        {renderNextButton()}
      </div>
    </NavigationStyled>
  );
}

export default observer(InfluenceControlNavigation);
