// @flow

import React, { useEffect } from "react";
import { observer } from "mobx-react";

import StyledScrollableContainer from "../components/StyledScrollableContainer";
import TopBar from "../components/TopBar";
import { useStores } from "../store/Store";
import RegionsTable from "../components/RegionsTable";

function Regions() {
  const { regionsStore, systemUnitsStore } = useStores();

  useEffect(() => {
    regionsStore.getRegionsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { table } = regionsStore;

  const tableProps = {
    ...table,
    computedDateFormat: systemUnitsStore.computedDateFormat,
    onShiftToggle: regionsStore.shiftToggleRows,
    onRowToggle: regionsStore.changeSelectedRows
  };

  return (
    <StyledScrollableContainer className="d-flex flex-column">
      <TopBar />

      <div className="p-2 d-flex flex-column flex-shrink-1 mh-0">
        <RegionsTable {...tableProps} />
      </div>
    </StyledScrollableContainer>
  );
}

export default observer(Regions);
