// @flow
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";
import { Range, getTrackBackground } from "react-range";
import { getTime, format, addDays, startOfToday, isFirstDayOfMonth } from "date-fns";
import isEmpty from "lodash.isempty";
import kebabCase from "lodash.kebabcase";

import { DAYS_BACK } from "../models/Tab.model";
import { Status } from "../helpers/Status";
import { useStores } from "../store/Store";

const StyledGraphTimeline = styled("div")`
  background: ${Colors.LIGHT_GRAY5};
  border-top: 1px solid ${Colors.LIGHT_GRAY1};
  border-radius: 0 0 4px 4px;
`;

const StyledTrackBackground = styled("div")`
  cursor: auto;
  overflow: hidden;

  .graph-timeline-track {
    cursor: all-scroll;
    height: 24px;
  }
`;

const StyledThumb = styled("div")`
  background: transparent;
  cursor: ew-resize !important;
  height: 24px;
  width: 40px;
  z-index: 3 !important;

  &:hover div {
    border-left-width: 3px;
  }
  div {
    border-left: 1px solid ${Colors.LIGHT_GRAY1};
  }
`;

const StyledMark = styled("div")`
  color: ${Colors.GRAY2};
  font-size: 10px;
  margin-top: 5px !important;
  white-space: nowrap;
  z-index: 2;
`;

const StyledMonthMark = styled("div")`
  border-left: 1px solid ${Colors.LIGHT_GRAY1};
  color: ${Colors.GRAY2};
  font-size: 10px;
  margin-top: 5px !important;
  padding-left: 3px;
  transform: translateX(50%);
  white-space: nowrap;
  z-index: 2;
`;

const StyledTodayMark = styled("div")`
  border-right: 1px dashed ${Colors.BLUE4}80;
  z-index: 2;
`;

type Props = {
  changeSelectedRange: Function,
  range: { start: number, end: number },
  selectedRange: { start: number, end: number },
  series: Array<string>,
  status: string,
  today: number
};

function GraphTimeline(props: Props) {
  const { changeSelectedRange, range, selectedRange, series, status, today = getTime(startOfToday()) } = props;

  // @FIXME: TEMPORARY HACK AROUND REACT-RANGE
  // https://github.com/tajo/react-range/issues/128
  // draggableTrack is not working properly when `min` is a negative value
  // so we move everything from - 30 to 0
  const end = (range.end || 0) + DAYS_BACK;
  const start = (range.start || 0) + DAYS_BACK;
  const selectedEnd = (selectedRange.end || 0) + DAYS_BACK;
  const selectedStart = (selectedRange.start || 0) + DAYS_BACK;

  const { systemUnitsStore } = useStores();
  const { computedDateFormat, dateDelimiter } = systemUnitsStore;

  const [values, setValues] = useState([selectedStart, selectedEnd]);

  useEffect(() => {
    if (values[0] !== selectedStart || values[1] !== selectedEnd) {
      setValues([selectedStart, selectedEnd]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStart, selectedEnd]);

  if ([Status.INIT, Status.LOADING, Status.ERROR].includes(status)) {
    return null;
  }

  const selectedSeries = series.map(group => group[1]).flat();
  if (isEmpty(selectedSeries)) {
    return null;
  }

  const renderTrack = ({ children, props }: Object) => {
    const background = getTrackBackground({
      colors: [Colors.LIGHT_GRAY5, `${Colors.GRAY4}33`, Colors.LIGHT_GRAY5],
      max: end,
      min: start,
      values
    });

    /* eslint-disable react/prop-types */
    return (
      <StyledTrackBackground
        className="d-flex w-100"
        onMouseDown={props.onMouseDown}
        onTouchStart={props.onTouchStart}
        style={props.style}
      >
        <div className="w-100 graph-timeline-track" ref={props.ref} style={{ background }}>
          {children}
        </div>
      </StyledTrackBackground>
    );
    /* eslint-enable react/prop-types */
  };

  const renderThumb = ({ props }: { props: Object }) => (
    <StyledThumb {...props} className="d-flex justify-content-center" data-testid="timeline-thumb">
      <div />
    </StyledThumb>
  );

  const renderDayLabel = (dateShift: number) => {
    const isDayFirst = computedDateFormat.toLowerCase().indexOf("d") < computedDateFormat.toLowerCase().indexOf("m");
    return format(addDays(today, dateShift), isDayFirst ? `d${dateDelimiter}M` : `M${dateDelimiter}d`);
  };

  const renderDayMark = ({ index, props }: { index: number, props: Object }) => {
    const indexStep = 4;

    // @FIXME: TEMPORARY HACK AROUND REACT-RANGE
    // https://github.com/tajo/react-range/issues/128
    // subtracting DAYS_BACK should be removed
    const dateShift = start + index - DAYS_BACK; // negative values are in the past, positive values are in the future
    const isVisible = (index - 1) % indexStep === 0; // render index 1, 5, 9...

    if (dateShift === 0) {
      return <StyledTodayMark className="h-100" {...props} data-testid="timeline-today-mark" />;
    }

    if (!isVisible) {
      return null;
    }

    const dayLabel = renderDayLabel(dateShift);

    return (
      <StyledMark {...props} data-testid={`timeline-day-mark-${kebabCase(dayLabel)}`}>
        {dayLabel}
      </StyledMark>
    );
  };

  const renderMonthMark = ({ index, props }: { index: number, props: Object }) => {
    // @FIXME: TEMPORARY HACK AROUND REACT-RANGE
    // https://github.com/tajo/react-range/issues/128
    // subtracting DAYS_BACK should be removed
    const dateShift = start + index - DAYS_BACK;
    const day = addDays(today, dateShift);
    const isVisible = isFirstDayOfMonth(day);

    if (dateShift === 0) {
      return <StyledTodayMark className="h-100" {...props} data-testid="timeline-today-mark" />;
    }

    if (!isVisible) {
      return null;
    }

    return (
      <StyledMonthMark {...props} data-testid={`timeline-month-mark-${format(day, "MM")}`}>
        {format(day, "MMM")}
      </StyledMonthMark>
    );
  };

  // @FIXME: TEMPORARY HACK AROUND REACT-RANGE
  // https://github.com/tajo/react-range/issues/128
  // subtracting DAYS_BACK should be removed
  const totalDays = end - start - DAYS_BACK;

  return (
    <StyledGraphTimeline className="d-flex justify-content-center flex-wrap" data-testid="graph-timeline">
      <Range
        draggableTrack
        max={end}
        min={start}
        onChange={setValues}
        onFinalChange={([newStart, newEnd]) => {
          // @FIXME: TEMPORARY HACK AROUND REACT-RANGE
          // https://github.com/tajo/react-range/issues/128
          // subtracting DAYS_BACK should be removed
          changeSelectedRange({ start: newStart - DAYS_BACK, end: newEnd - DAYS_BACK });
        }}
        renderMark={totalDays > 90 ? renderMonthMark : renderDayMark}
        renderThumb={renderThumb}
        renderTrack={renderTrack}
        step={1}
        values={values}
      />
    </StyledGraphTimeline>
  );
}

export default observer(GraphTimeline);
