// @flow

import React from "react";
import clsx from "clsx";
import isEmpty from "lodash.isempty";
import roundTo from "round-to";
import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";

import formatValueWithUnit from "../helpers/FormatValueWithUnit";
import isNumber from "../helpers/isNumber";
import minMaxDataTableCell from "../helpers/minMaxDataTableCell";
import { valueWithLimits } from "../helpers/valueWithLimits";

const withDecimalNumber = (withFixedNumber, percent) =>
  withFixedNumber && percent && !percent.includes(".") && percent !== "0%" && percent !== "-0%"
    ? `${percent.split("%")[0]}.0%`
    : percent;

const StyledCellBackground = styled("div")`
  background: ${({ color }) => color || "rgba(231, 238, 243, 0.75)"};
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: ${({ value }) => `${Math.min(value, 100)}%`};
`;

const StyledCellInfluenceBase = styled("span")(
  ({ isStriked }) =>
    isStriked && {
      fontWeight: 300,
      opacity: 0.5,
      textDecoration: "line-through"
    }
);

const StyledCellInfluencePreview = styled("strong")`
  color: ${({ isPercentage, value }) => {
    if (isPercentage && value > 0) return Colors.GREEN4;
    if (isPercentage && value < 0) return Colors.RED4;
    return Colors.VIOLET4;
  }};
`;

const StyledCellValue = styled("div")`
  text-align: right;
  position: relative;
  z-index: 1;
`;

const basePreview = (value, type) => {
  const influenceType = type.slice(10);

  const formattedBaseValue =
    value.base === null ? "–" : isNumber(value.base) && formatValueWithUnit(value.base, influenceType);
  const formattedPreviewValue =
    value.preview === null ? "–" : isNumber(value.preview) && formatValueWithUnit(value.preview, influenceType);

  return { base: formattedBaseValue, preview: formattedPreviewValue, influenceType };
};

const renderBasePreview = (value, type) => {
  const { base, preview, influenceType } = basePreview(value, type);

  return (
    <div className="d-flex flex-grow-1 justify-content-between" data-type={type}>
      <StyledCellInfluenceBase
        className="mr-1"
        data-testid="cell-influence-base-text"
        isStriked={isNumber(preview) || !isEmpty(preview)}
      >
        {base}
      </StyledCellInfluenceBase>
      <StyledCellInfluencePreview isPercentage={influenceType === "percent"} value={value.preview}>
        {preview}
      </StyledCellInfluencePreview>
    </div>
  );
};

const renderCurrency = (value: number, type: string, formatter: Function) => {
  if (!isNumber(value)) {
    return "";
  }

  return (
    <div className="d-flex flex-grow-1 justify-content-between" data-type={type}>
      <span>$</span>
      <span>{formatter.format(value)}</span>
    </div>
  );
};

const renderPercent = (value: number, type: string, formatter: Function, withFixedNumber: boolean) => {
  if (!isNumber(value)) {
    return "";
  }

  const roundedValue = roundTo(value, 0);
  const percentValue = withDecimalNumber(withFixedNumber, formatter.format(value * 0.01));
  const isPercentRelative = type === "percent-relative";

  return (
    <>
      <StyledCellBackground
        value={roundedValue}
        {...(isPercentRelative && {
          color: `${value > 0 ? Colors.GREEN5 : Colors.RED3}33`,
          value: Math.abs(roundedValue)
        })}
      />
      <StyledCellValue data-type={type}>{percentValue}</StyledCellValue>
    </>
  );
};

const renderMinMax = (value, type, options) => {
  let renderedValue = minMaxDataTableCell(value[0]);

  if (options.withLimits) {
    renderedValue = valueWithLimits({ type: "MM", value });
  }

  return (
    <div className="d-flex flex-grow-1 justify-content-between" data-type={type}>
      <span
        className={clsx({
          "bp3-text-overflow-ellipsis": options.withLimits
        })}
      >
        {renderedValue}
      </span>
    </div>
  );
};

export { renderBasePreview, renderCurrency, renderPercent, renderMinMax };
