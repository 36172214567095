import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Spinner } from "@blueprintjs/core";

import { useStores } from "../store/Store";
import globalStyles from "../helpers/globalStyles";

import Login from "./Login";
import Application, { StyledApp } from "./Application";
import AuthFooter from "../components/AuthFooter";

function AuthGate() {
  const { appStore, themeStore } = useStores();

  useEffect(() => {
    appStore.init();
  }, [appStore]);

  if (appStore.isUninitialized || !window.crypto) {
    return null;
  }

  if (appStore.isLoggedOut) {
    return (
      <StyledApp className={themeStore.themeClass}>
        {globalStyles}
        <Login app={appStore} />
        <AuthFooter />
      </StyledApp>
    );
  }

  if (appStore.isLoading) {
    return (
      <StyledApp className={`d-flex ${themeStore.themeClass}`}>
        {globalStyles}
        <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <h3 className="mb-3">Cirrus UI</h3>
          <Spinner />
          <div className="mt-2 bp3-text-small bp3-text-muted">Initializing application</div>
        </div>
        <AuthFooter />
      </StyledApp>
    );
  }

  if (appStore.isError) {
    return (
      <StyledApp className={`d-flex ${themeStore.themeClass}`}>
        {globalStyles}
        <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <h3 className="mb-3">Cirrus UI</h3>
          <h5>Error happened.</h5>
        </div>
        <AuthFooter />
      </StyledApp>
    );
  }

  return (
    <>
      {globalStyles}
      <Application />
    </>
  );
}

export default observer(AuthGate);
