import { startOfMonth, startOfWeek, startOfQuarter, setQuarter } from "date-fns";

export function compareDateFiltersToNow(depDate, depWeek, depMonth, quarter, currentDate = new Date()) {
  // It's enough for 1 arg to be in past to set return true

  // check months
  const currentMonthValue = new Date(startOfMonth(currentDate)).valueOf();
  const minMonthValue = Array.isArray(depMonth) ? Math.min(...depMonth.map(month => new Date(month).valueOf())) : 0;

  if (minMonthValue && new Date(startOfMonth(new Date(minMonthValue))).valueOf() < currentMonthValue) {
    return true;
  }

  // check weeks
  const currentWeekValue = new Date(startOfWeek(currentDate, { weekStartsOn: 1 })).valueOf();
  const minWeekValue =
    Array.isArray(depWeek) && depWeek.length ? Math.min(...depWeek.map(week => new Date(week).valueOf())) : 0;

  if (minWeekValue && new Date(startOfWeek(new Date(minWeekValue), { weekStartsOn: 1 })).valueOf() < currentWeekValue) {
    return true;
  }

  // check quarter
  const currentQuarterValue = new Date(startOfQuarter(currentDate)).valueOf();
  const minQuarterValue =
    Array.isArray(quarter) && quarter.length
      ? Math.min(
          ...quarter.map(q => {
            const [year, quarterNumber] = q.split("Q");
            return new Date(setQuarter(new Date(year, 0, 1), quarterNumber)).valueOf();
          })
        )
      : 0;

  if (minQuarterValue && minQuarterValue < currentQuarterValue) {
    return true;
  }

  const minDateValue = depDate && depDate.start ? new Date(depDate.start).valueOf() : 0;

  return !!(minDateValue && minDateValue < currentDate.valueOf());
}
