// @flow

import React from "react";
import { observer } from "mobx-react";
import { Button, Classes, Colors, Dialog } from "@blueprintjs/core";
import styled from "@emotion/styled";
import pluralize from "pluralize";

export const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 ${Colors.BLACK}26;
`;

type Props = {
  modal: {
    data: {
      deleteEvents: Function,
      selectedRows: Array<number>
    },
    modalActive: string,
    setModal: Function
  }
};

function DeleteEventsModal({ modal }: Props) {
  const { deleteEvents, selectedRows = [] } = modal.data;

  const deleteCountText = pluralize("event", selectedRows.length, true );
  const pluralConfirmationText = selectedRows.length === 1 ? "this event" : "these events";

  return (
    <Dialog
      className="p-0"
      isOpen={modal.modalActive === "deleteEvents"}
      onClose={() => modal.setModal("")}
      title={`Delete (${deleteCountText})`}
    >
      <div className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="delete-events-modal">
        <div className="mb-2 font-weight-bold bp3-fill">{`Are you sure you want to delete ${pluralConfirmationText}?`}</div>
        <div className="mb-4">You won’t be able to restore your work.</div>
      </div>

      <DialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 px-3 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button type="button" onClick={() => modal.setModal(null)}>
            Cancel
          </Button>
          <Button
            data-testid="delete-events-button"
            intent="primary"
            onClick={() => {
              deleteEvents({
                eventIds: selectedRows
              });
              modal.setModal(null);
            }}
          >
            Delete
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default observer(DeleteEventsModal);
