// @flow

import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { ErrorBoundary } from "@sentry/react";
import kebabCase from "lodash.kebabcase";
import { Button, Colors } from "@blueprintjs/core";
import clsx from "clsx";

const StyledModule = styled("div")`
  border: 1px solid ${Colors.LIGHT_GRAY2};
  background: white;
  border-radius: 4px;

  min-height: ${({ isCollapsed, minHeight }) => (!isCollapsed && minHeight ? `${minHeight}px` : 0)};
`;

const StyledHeader = styled("div")`
  flex: 0 0 48px;
  color: #30404d;
  border-bottom: ${({ isCollapsed }) => `1px solid ${isCollapsed ? "transparent" : Colors.LIGHT_GRAY2}`};

  & > h3 {
    margin: 0 0.5rem 0 0;
  }
`;

const StyledChildren = styled("div")`
  min-height: 0;

  &.placeholder-center {
    align-items: center;
    display: flex;
    height: 100%;
  }
`;

const StyledFooter = styled("div")`
  border-top: 1px solid ${Colors.LIGHT_GRAY2};
`;

type Props = {
  children: ?Element,
  childrenClassName: ?string,
  className: ?string,
  headerClassName: ?string,
  isCollapsed: ?string,
  setCollapsed: ?Function,
  footer: ?Element,
  footerClassName: ?string,
  minHeight: ?number,
  subtitle: Array<string | Element>,
  title: string,
  titleTools: ?Element
};

function Module(props: Props) {
  const {
    children = null,
    childrenClassName = "",
    className = "",
    footer = null,
    footerClassName = "",
    minHeight = 315,
    isCollapsed,
    headerClassName = "",
    setCollapsed,
    subtitle = [],
    title = "",
    titleTools = null
  } = props;
  const collapsible = Boolean(setCollapsed);

  const subTitles =
    subtitle &&
    subtitle.filter(Boolean).map((element: string | Element) => {
      const key =
        typeof element === "string" || !element.type ? element : element.type.displayName || element.type.name;
      return (
        <span className="bp3-text-muted bp3-text-small mt-1 text-truncate" key={`subtitle-${kebabCase(key)}`}>
          {element}
        </span>
      );
    });

  return (
    <ErrorBoundary>
      <StyledModule
        className={`d-flex flex-column ${className}`}
        data-testid={`module-${kebabCase(title)}`}
        isCollapsed={isCollapsed}
        minHeight={minHeight}
      >
        <StyledHeader
          className={clsx('px-2 d-flex align-items-center', headerClassName)} 
          isCollapsed={isCollapsed}
          data-testid={`module-title-${kebabCase(title)}`}
        >
          {collapsible ? (
            <Button
              className="mr-1"
              icon={isCollapsed ? "chevron-down" : "chevron-up"}
              minimal
              onClick={() => setCollapsed(!isCollapsed)}
            />
          ) : null}
          <h3 className="flex-shrink-0">{title}</h3>
          {subTitles}
          {!isCollapsed && <div className="ml-auto flex-shrink-0">{titleTools}</div>}
        </StyledHeader>
        <div
          className={
            collapsible && isCollapsed ? "d-none" : "d-flex flex-column justify-content-center flex-grow-1 h-100 mh-0"
          }
          data-testid={isCollapsed ? "module-collapsed" : "module-expanded"}
        >
          {children ? (
            <StyledChildren className={childrenClassName} data-testid="module-content">
              {children}
            </StyledChildren>
          ) : null}
          {footer ? (
            <StyledFooter className={footerClassName} data-testid="module-footer">
              {footer}
            </StyledFooter>
          ) : null}
        </div>
      </StyledModule>
    </ErrorBoundary>
  );
}

export default observer(Module);
