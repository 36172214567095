// @flow

import React from "react";
import styled from "@emotion/styled";

const StyledLogoTab = styled.div`
  border-radius: 2px 2px 0 0;
  padding: 0 5px;
  height: 30px;
  background-color: ${({ active, background }) => (active ? "white" : background || "#ffffff33")};
`;

function HeaderLogo(props: Props) {
  return (
    <StyledLogoTab
      className="d-flex justify-content-center align-items-center mr-2"
      active={props.active}
      background={props.background}
    >
      {props.children}
    </StyledLogoTab>
  );
}

export default HeaderLogo;
