import IHDepartureDateFilters from "../components/InfluenceHistorySidebarGroups/IHDepartureDateFilters";
import IHFlightFilters from "../components/InfluenceHistorySidebarGroups/IHFlightFilters";
import IHInfluenceFilters from "../components/InfluenceHistorySidebarGroups/IHInfluenceFilters";
import IHMarketFilters from "../components/InfluenceHistorySidebarGroups/IHMarketFilters";

export const influenceHistoryMetrics = [
  {
    key: "market",
    name: "Market",
    filters: [{ key: "owMarket" }],
    Component: IHMarketFilters
  },
  {
    key: "flight",
    name: "Flight",
    filters: [
      { key: "flightNumber" },
      { key: "depTime", isMetric: true },
      { key: "depTimeBucket" },
      { key: "cabinClass" }
    ],
    Component: IHFlightFilters
  },
  {
    key: "dep-date",
    name: "Departure Date",
    filters: [
      { key: "quarter" },
      { key: "ndo" },
      { key: "depMonth" },
      { key: "depWeek" },
      { key: "depDate" },
      { key: "depDow" }
    ],
    Component: IHDepartureDateFilters
  },
  {
    key: "influence",
    name: "Influence",
    filters: [{ key: "type" }, { key: "status" }, { key: "createdOn" }, { key: "updatedOn" }, { key: "userIds" }],
    Component: IHInfluenceFilters
  }
];

export const filterOrder = influenceHistoryMetrics.map(({ filters }) => filters.map(({ key }) => key)).flat();
