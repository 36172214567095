// @flow

import { NonIdealState } from "@blueprintjs/core";
import React from "react";
import { observer } from "mobx-react";
import Row from "../components/Row";
import Module from "../components/Module";
import TopBar from "../components/TopBar";

function Dashboard() {
  return (
    <>
      <TopBar />

      <div className="p-2 d-flex flex-column flex-grow-1 row-fullscreen">
        <Row className="flex-grow-1">
          <div className="col-9 d-flex flex-column">
            <Row className="mb-2 flex-grow-1">
              <div className="col">
                <Module childrenClassName="placeholder-center" className="h-100" title="Calendar">
                  <NonIdealState className="text-center d-flex align-items-center" icon="heat-grid" />
                </Module>
              </div>
              <div className="col">
                <Module childrenClassName="placeholder-center" className="h-100" title="Watchlist">
                  <NonIdealState className="text-center d-flex align-items-center" icon="list-detail-view" />
                </Module>
              </div>
            </Row>
            <div className="flex-grow-1">
              <Module childrenClassName="placeholder-center" className="h-100" title="Market-Months">
                <NonIdealState className="text-center d-flex align-items-center" icon="th" />
              </Module>
            </div>
          </div>
          <div className="col-3">
            <Module childrenClassName="placeholder-center" className="h-100" title="Markets">
              <NonIdealState className="text-center d-flex align-items-center" icon="th-filtered" />
            </Module>
          </div>
        </Row>
      </div>
    </>
  );
}

export default observer(Dashboard);
