// @flow

import React from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";
import cloneDeep from "lodash.clonedeep";

import { PRICE_LIMITS_INIT, PriceLimit } from "../../models/Influence.model";

import PriceLimitsForm from "./PriceLimitsForm";
import PriceLimitsSummary from "./PriceLimitsSummary";

type Props = {
  parameters: {
    comment: string,
    inputValue: Array<PriceLimit>,
    value: Array<PriceLimit>
  },
  readOnly: boolean,
  status: string
};

function PriceLimits(props: Props) {
  const { readOnly, parameters, status } = props;
  const { inputValue, value } = parameters;

  const renderPanels = () =>
    value.map((entryValue: Object, index: number) => {
      return (
        <PriceLimitsSummary
          key={`price-limit-${index}`} // eslint-disable-line react/no-array-index-key
          index={index}
          value={value}
        />
      );
    });

  const renderForms = () =>
    inputValue.map((entryInputValue: Object, index: number) => {
      return (
        <PriceLimitsForm
          index={index}
          inputValue={inputValue}
          key={`price-limit-${index}`} // eslint-disable-line react/no-array-index-key
          status={status}
          updateInputValue={val => {
            inputValue[index] = { ...val };
          }}
          value={value}
        />
      );
    });

  return (
    <>
      {readOnly ? renderPanels() : renderForms()}
      {!readOnly && (
        <Button
          className="mb-2"
          icon="plus"
          intent="primary"
          minimal
          onClick={() => {
            inputValue.push(cloneDeep(PRICE_LIMITS_INIT));
            value.push(cloneDeep(PRICE_LIMITS_INIT));
          }}
        >
          Add Price Limit
        </Button>
      )}
    </>
  );
}

export default observer(PriceLimits);
