// @flow

import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react";
import { NonIdealState } from "@blueprintjs/core";
import { runInAction } from "mobx";

import AnalysisSidebar from "../components/AnalysisSidebar";
import AnalysisSubpage from "../components/AnalysisSubpage";
import AnalysisTopBarWithSidebar from "../components/AnalysisTopBar";
import FlightsTable from "../components/FlightsTable";
import MileTransformationContext from "../services/contexts/MileTransformationContext";
import StyledScrollableContainer from "../components/StyledScrollableContainer";
import { TAB_INIT } from "../models/Tab.model";
import { useStores } from "../store/Store";

function Analysis() {
  const route = useRouteMatch();
  const { influenceStore, systemUnitsStore, tabsStore, templatesStore } = useStores();
  const history = useHistory();
  const { computedDateFormat } = systemUnitsStore;

  const subpage = route.url.split("/").pop();
  const tab = tabsStore.getTab(route.params.tabId);

  useEffect(() => {
    if (tab) {
      localStorage.setItem("activeTab", tab.id);
      runInAction(() => {
        tab.flightsTable.pagination.pageIndex = 0;
      });
    } else {
      localStorage.removeItem("activeTab");
    }
  }, [tab]);

  if (!tab) {
    return (
      <NonIdealState
        className="mb-4 pb-3 flex-grow-1"
        icon="disable"
        title="Tab not found"
        description="It looks this tab was removed. If you want to share tab please use Import/Export Tab option"
      />
    );
  }

  const columnSelectDisabled = influenceStore.state.influenceStep !== 1;

  const componentFromSavedView = templatesStore.getComponent(tab.parentId, tab.parentType, "flightsTable");
  const baseMetrics = componentFromSavedView ? componentFromSavedView.columns : TAB_INIT.flightsTable.columns;

  const flightsTableProps = {
    baseMetrics,
    columnSelectDisabled,
    computedDateFormat,
    isPreviewActive: influenceStore.isPreviewActive,
    subpage,
    tab
  };

  const sidebarProps = {
    submitSearchForm: () => {
      tab.submitSearchForm();
      history.push(`/analysis/${tab.id}/explore`);
    },
    tab
  };

  return (
    <MileTransformationContext.Provider value={tab.distanceUnit === "mi"}>
      <AnalysisTopBarWithSidebar tab={tab} />
      <AnalysisSidebar {...sidebarProps} />
      <StyledScrollableContainer key={tab.id} className="d-flex flex-shrink-1 flex-column px-2 pt-2 mb-2">
        {tab && <AnalysisSubpage subpage={subpage} tab={tab} />}
        <FlightsTable {...flightsTableProps} />
      </StyledScrollableContainer>
    </MileTransformationContext.Provider>
  );
}

export default observer(Analysis);
