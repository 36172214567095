// @flow

import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import FilterGroups from "../AnalysisSidebarFilterGroups/FilterGroups";
import SearchInput from "../SearchInput";
import Sidebar from "../Sidebar";
import MarketMappingsSidebarGroup from "./MarketMappingsSidebarGroup";
import { useStores } from "../../store/Store";
import focusOnFilter from "../../helpers/focusOnFilter";

export const marketMappingsMetrics = [
  {
    key: "marketMappings",
    name: "Market Mappings",
    filters: [{ key: "rtMarket" }, { key: "analyst" }, { key: "region" }, { key: "subregion" }],
    Component: MarketMappingsSidebarGroup
  }
];

type Props = {
  analystList: Array
};

function MarketMappingsSidebar(props: Props) {
  const { marketsStore } = useStores();
  const { setSidebarFilterQuery, setSidebarOpen, submitSearchForm, decoratedPage, sidebar } = marketsStore;
  const { filterQuery, isOpen, filterKey } = sidebar;

  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  return (
    <Sidebar
      footer={<Button fill intent="primary" onClick={submitSearchForm} text="Apply" />}
      isOpen={isOpen}
      setOpen={value => setSidebarOpen(value)}
      ref={sidebarRef}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />

      <div className="p-3">
        <FilterGroups
          analystList={props.analystList}
          filterGroups={marketMappingsMetrics}
          pageContext={decoratedPage}
          type="market"
          withoutCollapsing
        />
      </div>
    </Sidebar>
  );
}

export default observer(MarketMappingsSidebar);
