// @flow

import React from "react";
import { observer } from "mobx-react";
import { Button, Icon, Colors, Intent } from "@blueprintjs/core";
import clsx from "clsx";
import styled from "@emotion/styled";

import { InfluenceStatus, PriceLimit } from "../../models/Influence.model";
import { influenceTypeNames, HIGHEST_START_NDO } from "../../helpers/influenceConstants";
import { minMaxOverlaps } from "../../helpers/minMaxOverlaps";

import { StyledButtonIcon, StyledCard, StyledHeaderTitle, StyledInfluenceInput } from "./InfluenceStyles";
import Row from "../Row";
import isNumber from "../../helpers/isNumber";

const StyledMinMaxLabel = styled("strong")`
  text-align: right;
  text-transform: capitalize;
  width: 35px;
`;

const StyledMinMaxError = styled("div")`
  color: ${Colors.RED3};
  font-size: 12px;
  line-height: 1.25;
  margin-top: 20px;

  .bp3-icon svg {
    font-size: 12px;
  }
`;

type Props = {
  index: number,
  inputValue: Array<PriceLimit>,
  status: string,
  updateInputValue: Function,
  value: Array<PriceLimit>
};

const minMaxStartEnd = values => [
  ...values.map(value => {
    if (value.endNdo > value.startNdo) {
      return { ...value, endIsHigher: true };
    }
    if (value.startNdo > HIGHEST_START_NDO) {
      return { ...value, startNdoIsTooHigh: true };
    }
    return value;
  })
];

function PriceLimitsForm(props: Props) {
  const { index, inputValue, status, updateInputValue, value } = props;
  const inputValueWithValidation = minMaxStartEnd(minMaxOverlaps(inputValue));
  const isLoading = status.includes(InfluenceStatus.LOADING);

  const { endIsHigher, endNdoOverlaps, startNdoOverlaps, startNdoIsTooHigh } = inputValueWithValidation[index];
  const isOverlapping = endNdoOverlaps || startNdoOverlaps;
  const isStartTooHigh = !isOverlapping && startNdoIsTooHigh;

  const inputProps = {
    allowNumericCharactersOnly: false,
    clampValueOnBlur: true,
    disabled: isLoading,
    fill: true
  };

  const onChange = (fieldName: string, newValue: ?number) => {
    if (newValue === null || newValue === undefined) {
      return updateInputValue({ ...inputValue[index], [fieldName]: newValue });
    }
    return isNumber(newValue) ? updateInputValue({ ...inputValue[index], [fieldName]: Math.round(newValue) }) : null;
  };

  const renderControl = (value: ?number, onValueChange: Function) => {
    if (value === null) {
      return (
        <>
          <Button
            className="flex-grow-1 flex-shrink-0 mr-2"
            disabled={isLoading}
            icon="plus"
            onClick={() => onValueChange(undefined)}
          >
            Add
          </Button>
          <Button
            className="flex-grow-1 flex-shrink-0"
            disabled={isLoading}
            icon="reset"
            onClick={() => onValueChange(-1)}
          >
            Remove
          </Button>
        </>
      );
    }

    const deleteButton = (
      <Button className="ml-2" disabled={isLoading} icon="cross" minimal onClick={() => onValueChange(null)} />
    );

    if (value === -1) {
      return (
        <>
          <span className="ml-2 pl-2 flex-grow-1">Remove Limits</span>
          {deleteButton}
        </>
      );
    }

    return (
      <>
        <StyledButtonIcon disabled={isLoading}>
          <Icon icon="dollar" />
        </StyledButtonIcon>
        <StyledInfluenceInput
          {...inputProps}
          autoFocus
          className="flex-grow-1"
          data-testid="influence-price-limits-input"
          min={0}
          onValueChange={onValueChange}
          value={value}
        />
        {deleteButton}
      </>
    );
  };

  const minMaxInputs = ["min", "max"].map(param => (
    <div className="d-flex align-items-center w-100 mb-2" key={param}>
      <StyledMinMaxLabel className="mr-2 flex-shrink-0">{param}</StyledMinMaxLabel>
      {renderControl(inputValueWithValidation[index][param], value => onChange(param, value))}
    </div>
  ));

  const intentNdoInput = type =>
    endIsHigher ||
    (type === "start" && isStartTooHigh) ||
    (type === "start" && startNdoOverlaps) ||
    (type === "end" && endNdoOverlaps)
      ? Intent.DANGER
      : Intent.NONE;

  const renderErrorMessage = () => {
    if (!(isOverlapping || isStartTooHigh)) {
      return null;
    }

    let message = "NDO ranges must not overlap across all price limits";
    if (isStartTooHigh) {
      message = "NDO value must not exceed 500";
    }

    return (
      <StyledMinMaxError className="d-flex flex-row" data-testid="minmax-limits-info-error">
        <Icon className="mr-1" icon="error" />
        {message}
      </StyledMinMaxError>
    );
  };

  const ndoInputs = ["start", "end"].map(param => (
    <div className="d-flex align-items-center w-100 mb-2" key={param}>
      <StyledMinMaxLabel className="mr-2 flex-shrink-0">{param}</StyledMinMaxLabel>
      <StyledButtonIcon disabled={isLoading}>NDO</StyledButtonIcon>
      <StyledInfluenceInput
        {...inputProps}
        autoFocus
        className="flex-grow-1"
        data-testid={`influence-price-limits-${param}-ndo`}
        intent={intentNdoInput(param)}
        min={0}
        onValueChange={value => onChange(`${param}Ndo`, value)}
        value={inputValueWithValidation[index][`${param}Ndo`]}
      />
    </div>
  ));

  return (
    <StyledCard className="p-3 mb-2" data-testid="price-limits-form">
      <Row className="mb-2">
        <div className="col-8">
          <StyledHeaderTitle>
            {influenceTypeNames.MM} {inputValueWithValidation.length > 1 && `#${index + 1}`}
          </StyledHeaderTitle>
        </div>
        <div className="col-4">
          <div className="d-flex">
            <Button
              className={clsx(["ml-auto", inputValueWithValidation.length === 1 && "invisible"])}
              data-testid="influence-price-limits-delete"
              disabled={isLoading}
              icon="trash"
              minimal
              small
              onClick={() => {
                inputValue.splice(index, 1);
                value.splice(index, 1);
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-5">
          <div className="d-flex flex-column mr-2 bp3-text-muted bp3-text-small" data-testid="minmax-limits-info">
            <span>Adding or removing limits will be applied to all selected flights.</span>
            <a
              className="mt-1"
              href="http://help.cirrus.ai/en/articles/4550260-influence-min-max-price-limits"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more
            </a>
          </div>

          {renderErrorMessage()}
        </div>

        <div className="pl-0 col-7">
          <div className="align-items-center flex-column" data-testid="influence-price-limits-value">
            {minMaxInputs}
            {ndoInputs}
          </div>
        </div>
      </Row>
    </StyledCard>
  );
}

export default observer(PriceLimitsForm);
