// @flow

import { action, observable } from "mobx";
import { Intent } from "@blueprintjs/core";
import isEmpty from "lodash.isempty";

import api, { isCancel } from "../services/Api";
import { AppToaster } from "../services/Toaster";
import type { RootStore } from "./Root.model";
import { filterPersistentTab, Tab } from "./Tab.model";
import { deepEqual } from "../helpers/deepEqual";
import { Status } from "../helpers/Status";

export type SavedView = {
  id: number,
  userId: number,
  label: string,
  view: Tab
};
const forcedColumnsGroupKey = "influenceGroup";

export const tabToSavedView = (tab: Tab) => {
  const { id, label, parentId, parentType, ...view } = filterPersistentTab(tab);

  return { label, view };
};

const overrideForcedColumns = (columns: Array<string, Array<string>>, values: Array<string>) =>
  columns.map(([columnHeaderKey, columnKeys]) => {
    if (columnHeaderKey === forcedColumnsGroupKey) {
      return [columnHeaderKey, values];
    }
    return [columnHeaderKey, columnKeys];
  });

export const dataWithoutTemporaryParams = (data: Object, options: { overrideValue: Array<string> } = {}) => {
  const { overrideValue = [] } = options;
  const columns = !isEmpty(overrideValue)
    ? overrideForcedColumns(data.view.flightsTable.columns, overrideValue)
    : data.view.flightsTable.columns;
  return {
    ...data,
    view: {
      ...data.view,
      departureDateExtremes: {},
      flightsTable: {
        ...data.view.flightsTable,
        columns,
        groupStatuses: []
      },
      buildCurves: { ...data.view.buildCurves, range: {}, selectedRange: {} }
    }
  };
};

export class TemplatesStore {
  @observable isOpen: boolean = false;
  @observable savedAnalyses: SavedView[] = [];
  @observable systemAnalyses: SavedView[] = [];
  @observable status = Status.INIT;

  rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action
  getAllTemplates() {
    this.status = Status.LOADING;
    const savedAnalysesPromise = api.getSavedViews();
    const systemAnalysesPromise = api.getSystemTemplates();
    const promiseArray = [savedAnalysesPromise, systemAnalysesPromise];

    return Promise.all(promiseArray)
      .then(([savedAnalysesResponse, systemAnalysesResponse]) => {
        const { data: savedAnalysesData = [] } = savedAnalysesResponse;
        const { data: systemAnalysesData = [] } = systemAnalysesResponse;
        this.savedAnalyses = savedAnalysesData;
        // @TODO: for v2 simplify logic for templates when responses on BE are aligned
        this.systemAnalyses = systemAnalysesData.rows.map(({ template, ...args }) => {
          return {
            view: template,
            ...args
          };
        });
        this.status = Status.DONE;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.status = Status.LOADING;
          return;
        }
        AppToaster.show({
          message: `Could not load templates`,
          intent: Intent.DANGER
        });
        this.savedAnalyses.clear();
        this.systemAnalyses.clear();
        this.status = Status.ERROR;
      });
  }

  @action
  getSavedViews(): SavedView[] {
    this.status = Status.LOADING;
    return api
      .getSavedViews()
      .then(({ data }) => {
        this.status = Status.DONE;
        this.savedAnalyses = data;
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action
  addView(tab: Tab) {
    const view = tabToSavedView(tab);
    return api
      .addSavedView(view)
      .then(({ data }) => {
        return this.getSavedViews().then(() => {
          AppToaster.show({
            message: `${view.label} has been saved`,
            intent: Intent.PRIMARY
          });

          const savedViewId = data.id;
          if (savedViewId) {
            tab.patchSavedViewId(savedViewId);
          }
        });
      })
      .catch(() => {
        AppToaster.show({
          message: `${view.label} could not be saved`,
          intent: Intent.DANGER
        });
        this.status = Status.ERROR;
      });
  }

  @action
  patchView(viewId: number, tabData: Object) {
    const view = tabToSavedView(tabData);
    api
      .patchSavedView(viewId, view)
      .then(() => {
        this.getSavedViews();
        AppToaster.show({
          message: `${view.label} has been saved`,
          intent: Intent.PRIMARY
        });
      })
      .catch(() => {
        AppToaster.show({
          message: `${view.label} could not be saved`,
          intent: Intent.DANGER
        });
        this.status = Status.ERROR;
      });
  }

  @action
  removeView(viewId: number, label: string) {
    return api
      .removeSavedView(viewId)
      .then(() => {
        this.getSavedViews();
        AppToaster.show({
          message: `${label} has been deleted`,
          intent: Intent.PRIMARY
        });
        this.removeObsoleteSavedViewId(viewId);
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action.bound
  setSidebarOpen() {
    this.isOpen = !this.isOpen;
  }

  @action
  getTemplateById = (viewId: number, templateType = "saved", legacyId): SavedView => {
    if (!viewId) return undefined;
    if (legacyId) {
      return this.savedAnalyses.find(view => view.id === viewId);
    }

    let savedViews;
    switch (templateType) {
      case "saved":
      default:
        savedViews = this.savedAnalyses;
        break;
      case "system":
        savedViews = this.systemAnalyses;
        break;
    }
    return savedViews.find(view => view.id === viewId);
  };

  @action
  getComponent = (viewId: string, parentType: string, componentName: string): Object => {
    const savedView = this.getTemplateById(viewId, parentType);
    if (!savedView || !savedView.view[componentName]) {
      return undefined;
    }

    return savedView.view[componentName];
  };

  checkTabUnsaved = (tabId: string): boolean => {
    const { tabsStore } = this.rootStore;
    const tab = tabsStore.getTab(tabId);

    if (!tab) return false;

    const savedView = this.getTemplateById(tab.parentId, tab.savedViewId);

    // tab based not on saved view – always return as unsaved
    if (!savedView) return true;

    const forcedColumn = savedView.view.flightsTable.columns.find(([groupKey]) => groupKey === forcedColumnsGroupKey);

    const forcedColumnKeys = forcedColumn ? forcedColumn[1] : [];

    return !deepEqual(
      dataWithoutTemporaryParams({ label: savedView.label, view: savedView.view }),
      dataWithoutTemporaryParams(tabToSavedView(tab), {
        overrideValue: forcedColumnKeys
      })
    );
  };

  removeObsoleteSavedViewId(viewId: number) {
    this.rootStore.tabsStore.tabs
      .filter((tab: Tab) => tab.parentId === viewId)
      .forEach((tab: Tab) => tab.patchSavedViewId(null));
  }
}
