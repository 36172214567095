// @flow

import { action, computed, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import isEmpty from "lodash.isempty";
import uniqBy from "lodash.uniqby";
import keyBy from "lodash.keyby";

import api from "../services/Api";
import { Status } from "../helpers/Status";
import { LazyValue } from "../helpers/LazyValue";
import type { PageContextFilters } from "../types/Flights.types";

export const getUniqueAndSortedOWMarkets = (flights: Array<string>) => {
  return [...new Set(flights.map(flight => flight.owMarket))].sort();
};

const naturalSort = (a, b) => a.localeCompare(b, undefined, { numeric: true });

const flipMarket = (market: string): string => `${market.slice(3)}${market.slice(0, 3)}`;

const prepareFlightNumbers = (flights: Array = []): Array<string> => {
  const flightNumbers = flights.flatMap(flight => flight.flightNumbers);
  return [...new Set(flightNumbers)].sort(naturalSort);
};

const uniqAndSort = array => [...new Set(array)].sort();

const toOrigin = market => market.slice(0, 3);

const toDestination = market => market.slice(3);

export class AnalysisMappingsStore {
  @observable status = Status.INIT;
  @observable analystStatus = Status.INIT;
  @observable data = {};
  @observable analystData = {};

  allFlightNumbers = new LazyValue<Array<string>>(() => prepareFlightNumbers(this.data.flights));

  @action
  getMappings() {
    this.status = Status.LOADING;
    this.allFlightNumbers.reset();

    api
      .getExploreMappings()
      .then(response => {
        runInAction(() => {
          this.data = response.data;
          this.status = Status.DONE;
        });
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action.bound
  getMarketAnalyst() {
    this.analystStatus = Status.LOADING;
    return api
      .getMarkets()
      .then(response => {
        this.analystData = response.data;
        this.analystStatus = Status.DONE;
        return response.data;
      })
      .catch(() => {
        this.analystStatus = Status.ERROR;
      });
  }

  @action
  getFlightNumbers = computedFn((filters: PageContextFilters | null): Array<string> => {
    const { flights = [] } = this.data;
    const { owMarket = [], rtMarket = [], origin = [], destination = [] } = { ...filters };
    const filtersEmpty = [owMarket, rtMarket, origin, destination].every(isEmpty);

    if (filters === null) {
      return this.allFlightNumbers.value;
    }

    if (filtersEmpty) {
      return [];
    }

    const matchers = this.createFlightMatchers(filters);
    const filteredFlights = flights.filter(({ owMarket }) => matchers.some(matcher => matcher(owMarket)));

    return prepareFlightNumbers(filteredFlights);
  }, true);

  getRoundTripMarkets = computedFn((filters: PageContextFilters | null): Array<string> => {
    const allMarkets = this.analystGroupedRtMarkets;
    const { owMarket = [], origin = [], destination = [] } = { ...filters };
    const allFiltersEmpty = [owMarket, origin, destination].every(isEmpty);

    if (filters === null) {
      return allMarkets;
    }

    if (allFiltersEmpty) {
      return [];
    }

    const allowedMarkets = owMarket.flatMap(market => [market, flipMarket(market)]);

    const byOwMarkets = allMarkets.filter(market => allowedMarkets.includes(market));
    const byOrigins = allMarkets.filter(market => origin.some(origin => market.includes(origin)));
    const byDestinations = allMarkets.filter(market => destination.some(destination => market.includes(destination)));

    const rtMarkets = [...byOwMarkets, ...byOrigins, ...byDestinations];

    return uniqAndSort(rtMarkets);
  }, true);

  getOneWayMarkets = computedFn((filters: PageContextFilters | null): Array<string> => {
    const { flights = [] } = this.data;
    const { rtMarket = [], origin = [], destination = [] } = { ...filters };

    const allMarkets = getUniqueAndSortedOWMarkets(flights);
    const allFiltersEmpty = [rtMarket, origin, destination].every(isEmpty);

    if (filters === null) {
      return allMarkets;
    }

    if (allFiltersEmpty) {
      return [];
    }

    const allowedMarkets = rtMarket.flatMap(market => [market, flipMarket(market)]);

    const byRtMarkets = allMarkets.filter(market => allowedMarkets.includes(market));
    const byOrigins = allMarkets.filter(market => origin.some(origin => toOrigin(market).includes(origin)));
    const byDestinations = allMarkets.filter(market =>
      destination.some(destination => toDestination(market).includes(destination))
    );

    const owMarkets = [...byRtMarkets, ...byOrigins, ...byDestinations];

    return uniqAndSort(owMarkets);
  }, true);

  getOrigins = computedFn((filters: PageContextFilters | null): Array<string> => {
    const { flights = [] } = this.data;
    const { rtMarket = [], owMarket = [], destination = [] } = { ...filters };

    if (filters === null) {
      const allFlights = flights.map(flight => toDestination(flight.owMarket));
      return uniqAndSort(allFlights);
    }

    const byDestinations = flights.filter(flight => destination.includes(toDestination(flight.owMarket)));
    const byOwMarkets = flights.filter(flight => owMarket.includes(flight.owMarket));
    const byRtMarkets = this.getFlightsByMarkets(rtMarket, owMarket);

    const origins = [
      ...byDestinations.map(flight => toOrigin(flight.owMarket)),
      ...byOwMarkets.map(flight => toOrigin(flight.owMarket)),
      ...byRtMarkets.map(toOrigin)
    ];

    return uniqAndSort(origins);
  }, true);

  getDestinations = computedFn((filters: PageContextFilters | null): Array<string> => {
    const { flights = [] } = this.data;
    const { rtMarket = [], owMarket = [], origin = [] } = { ...filters };

    if (filters === null) {
      const allFlights = flights.map(flight => toOrigin(flight.owMarket));
      return uniqAndSort(allFlights);
    }

    const byOrigin = flights.filter(flight => origin.includes(toOrigin(flight.owMarket)));
    const byOwMarkets = flights.filter(flight => owMarket.includes(flight.owMarket));
    const byRtMarkets = this.getFlightsByMarkets(rtMarket, owMarket);

    const destinations = [
      ...byOrigin.map(flight => toDestination(flight.owMarket)),
      ...byOwMarkets.map(flight => toDestination(flight.owMarket)),
      ...byRtMarkets.map(toDestination)
    ];

    return uniqAndSort(destinations);
  }, true);

  getCabinClasses = computedFn(() => {
    const { cabinClassMappings = [] } = this.data;

    return cabinClassMappings.flatMap(({ cabinClass }) => cabinClass).filter(Boolean);
  });

  getBookingClassesByCabinClass = computedFn((filters: PageContextFilters | null) => {
    if (filters === null) {
      return this.getBookingClasses();
    }
    return this.getBookingClasses(filters.cabinClass);
  });

  getBookingClasses(withoutCabinClasses) {
    return this.data.cabinClassMappings
      .flatMap(cabinClass => {
        if (!withoutCabinClasses || withoutCabinClasses.includes(cabinClass.cabinClass)) {
          return cabinClass.bookingClasses;
        }
        return [];
      })
      .filter(Boolean);
  }

  @computed
  get isLoading() {
    return this.status === Status.LOADING;
  }

  @computed
  get isError() {
    return this.status === Status.ERROR;
  }

  @computed
  get analystUsers() {
    const analystData = this.analystData && this.analystData.rows;
    if (!analystData) {
      return [];
    }

    const uniqUsers = uniqBy(analystData, "analystId").map(({ analystId: value, analystName: label }) => ({
      label: label === null ? "Unassigned" : label,
      value
    }));
    const undefinedUsers = uniqUsers.find(user => user.value === null) || {
      label: "Unassigned",
      value: null
    };

    const sortedUsers = uniqUsers
      .filter(user => user.value !== null)
      .sort((user1, user2) => user1.label.localeCompare(user2.label));

    return [undefinedUsers, ...sortedUsers];
  }

  @computed
  get isAnalystLoading() {
    return this.analystStatus === Status.LOADING;
  }

  @computed
  get analystGroupedByUserId() {
    return keyBy(this.analystUsers, "value");
  }

  @computed
  get analystGroupedRtMarkets() {
    const analystData = this.analystData && this.analystData.rows;

    if (!analystData) {
      return [];
    }

    return uniqBy(analystData, "rtMarket")
      .map(user => user.rtMarket)
      .filter(user => user !== null)
      .sort();
  }

  getFlightsByMarkets(rtMarket, owMarket) {
    const { flights = [] } = this.data;
    const allMarkets = getUniqueAndSortedOWMarkets(flights);
    const allowedMarkets = rtMarket.flatMap(market => [market, flipMarket(market)]);

    return allMarkets
      .filter(market => allowedMarkets.includes(market))
      .filter(market => !owMarket.includes(flipMarket(market)));
  }

  createFlightMatchers(filters) {
    const { owMarket = [], rtMarket = [], origin = [], destination = [] } = { ...filters };

    const matchesOwMarkets = market => owMarket.includes(market);
    const matchesRtMarkets = market => rtMarket.includes(market) || rtMarket.includes(flipMarket(market));
    const matchesOrigin = market => origin.includes(toOrigin(market));
    const matchesDestination = market => destination.includes(toDestination(market));

    const matchesOriginAndDestination = market => matchesOrigin(market) && matchesDestination(market);
    const matchesOnlyOrigin = market => matchesOrigin(market) && isEmpty(destination);
    const matchesOnlyDestination = market => matchesDestination(market) && isEmpty(origin);

    return [matchesOwMarkets, matchesRtMarkets, matchesOnlyOrigin, matchesOnlyDestination, matchesOriginAndDestination];
  }
}
