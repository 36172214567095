export function skipCustomParams(params, filter) {
  if (!filter) {
    return params;
  }
  return Object.keys(params).reduce((acc, item) => {
    // filter arrays
    if (item && Array.isArray(filter[item])) {
      return {
        ...acc,
        [item]: params[item].filter(paramValue => !filter[item].includes(paramValue))
      };
    }
    return {
      ...acc,
      [item]: params[item]
    };
  }, {});
}
