// @flow

import { AnalysisMappingsStore } from "./AnalysisMappings.model";
import { AppStore } from "./App.model";
import { CirrusStore } from "./Cirrus.model";
import { EventsManagementStore } from "./EventsManagement.model";
import { InfluenceHistoryStore } from "./InfluenceHistory.model";
import { InfluenceStore } from "./Influence.model";
import { MappingsStore } from "./Mappings.model";
import { MarketsStore } from "./Markets.model";
import { ModalStore } from "./Modal.model";
import { RegionsStore } from "./Regions.model";
import { TemplatesStore } from "./Templates.model";
import { SystemUnitsStore } from "./SystemUnits.model";
import { TabsStore } from "./Tabs.model";
import { ThemeStore } from "./Theme.model";
import { TimeStore } from "./Time.model";
import { UsersStore } from "./Users.model";

export class RootStore {
  analysisMappingsStore = new AnalysisMappingsStore(this);
  appStore = new AppStore(this);
  cirrusStore = new CirrusStore(this);
  eventsManagementStore = new EventsManagementStore(this);
  influenceHistoryStore = new InfluenceHistoryStore(this);
  influenceStore = new InfluenceStore(this);
  mappingsStore = new MappingsStore(this);
  marketsStore = new MarketsStore(this);
  modalStore = new ModalStore(this);
  regionsStore = new RegionsStore(this);
  systemUnitsStore = new SystemUnitsStore(this);
  tabsStore = new TabsStore(this);
  templatesStore = new TemplatesStore(this);
  themeStore = new ThemeStore(this);
  timeStore = new TimeStore(this);
  usersStore = new UsersStore(this);
}
