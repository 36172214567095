import isEmpty from "lodash.isempty";

const isSidebarFilterVisible = (filterName: string, filterKey: string, sidebar: Object): boolean => {
  if (!isEmpty(sidebar.filterKey)) {
    return sidebar.filterKey === filterKey;
  }
  return filterName.toLowerCase().includes(sidebar.filterQuery.toLowerCase());
};

export default isSidebarFilterVisible;
