// @flow

import { replaceAllBy } from "./constants";

type ShortVersionProps = {
  group: Object
};

const overrides = {
  sellingFareDiffToCirrusCompFare: "Fare Δ to Fare Match",
  sellingFareDiffToCirrusCompFarePercentile: "Fare Δ to Fare Match",
  sellingFareDiffToDepDateCompFare: "Fare Δ to Fare Dep Date",
  sellingFareDiffToDepDateCompFarePercentile: "Fare Δ to Fare Dep Date",
  sellingFareDiffToTimePeriodCompFare: "Fare Δ to Fare Time",
  sellingFareDiffToTimePeriodCompFarePercentile: "Fare Δ to Fare Time"
};

export default function metricShortVersionByGroup(props: ShortVersionProps) {
  const { group } = props;
  const newGroup = group;

  Object.entries(group).forEach(([key: string, value: string]) => {
    const replaces = {
      Revenue: "Rev",
      "Load Factor": "LF",
      Influences: "Infl",
      "Price Percentile": "Price",
      "Selling Fare": "Selling",
      "Seat Allocations SA": "SA",
      "Competitor Fare": "Comp Fare",
      "Comp Fare Fare": "Comp Fare"
    };

    if (overrides[key]) {
      newGroup[key] = overrides[key];
    } else {
      newGroup[key] = replaceAllBy(value, replaces);
    }
  });

  return newGroup;
}
