import React, { useContext } from "react";
import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import isEmpty from "lodash.isempty";

import SidebarGroup from "../SidebarGroup";
import ActiveFilterCounter from "../ActiveFilterCounter";
import MileTransformationContext from "../../services/contexts/MileTransformationContext";
import metricLabels from "../../helpers/metricLabels/metricLabels";
import { extraMetrics } from "../../helpers/constants";
import { FiltersHelpTexts } from "../../helpers/filtersHelpTexts";
import ConditionalFiltersGroup from "./ConditionalFiltersGroup";

type Props = {
  computedDateFormat: string,
  computedDateMonthFormat: string,
  filterGroups: Object,
  pageContext: Object,
  type: string,
  withoutCollapsing: ?boolean
};

const forcedGroupsWithHelp = ["competitorFareGroup"];

function FilterGroups(props: Props) {
  const { filterGroups, pageContext, type, withoutCollapsing } = props;
  const { id, sidebar = {}, xDayBuild = 7 } = pageContext;
  const { filterQuery } = sidebar;

  const transformToMile = useContext(MileTransformationContext);
  const titleLabels = {
    ...extraMetrics,
    ...metricLabels({ transformToMile, withUnits: true, xDayBuild })
  };
  const filterLabels = {
    ...extraMetrics,
    ...metricLabels({ transformToMile, withParent: true, withUnits: true, xDayBuild })
  };

  const isFilterQuery = !isEmpty(filterQuery);
  const columnHelpUrls = FiltersHelpTexts(transformToMile);

  const renderHelpText = (type: string) => {
    if (!columnHelpUrls[type]) {
      return null;
    }

    return (
      <a
        className="mb-3 d-inline-block"
        href={columnHelpUrls[type].url}
        target="_blank"
        rel="noopener noreferrer"
        title={columnHelpUrls.shortText}
      >
        {columnHelpUrls[type].text}
      </a>
    );
  };

  return filterGroups
    .filter(({ key, name, filters }) => {
      const groupTitle = titleLabels[key] || name;
      const filterNames = filters
        .map(({ key }) => `${groupTitle} ${titleLabels[key]}`)
        .join(" ")
        .toLowerCase();

      if (sidebar && sidebar.filterKey) {
        return filters.map(({ key }) => key).includes(sidebar.filterKey);
      }

      return filterQuery ? filterNames.includes(filterQuery.toLowerCase()) : true;
    })
    .map(({ Component, name, filters, key }) => {
      const isConditionalFilterGroup = !Component;
      const shouldRenderHelp = forcedGroupsWithHelp.includes(key);
      const searchParamsApplied = Object.keys(pageContext).includes("searchParams");

      const pageFilters = {
        applied: {
          conditionalFilters: searchParamsApplied ? {} : pageContext.applied && pageContext.applied.conditionalFilters,
          filters: searchParamsApplied ? pageContext.searchParams : pageContext.applied.filters
        },
        conditionalFilters: pageContext.conditionalFilters || {},
        filters: pageContext.filters
      };

      const filtersComponentProps = {
        ...props,
        filters: filters.filter(filter => !filter.excludeInFilters),
        tabId: id,
        pageContext,
        xDayBuild,
        ...(isConditionalFilterGroup && { labels: { titleLabels, filterLabels } })
      };

      return (
        <SidebarGroup
          title={titleLabels[key] || name}
          titleRight={
            <>
              <Tooltip content="Applied filters" hoverOpenDelay={1000} modifiers={{ arrow: false }}>
                <ActiveFilterCounter
                  filterNames={filters.map(({ key }) => key)}
                  isApplied
                  pageFilters={pageFilters}
                  type={type}
                />
              </Tooltip>

              <Tooltip content="Updated filters" hoverOpenDelay={1000} modifiers={{ arrow: false }}>
                <ActiveFilterCounter
                  filterNames={filters.map(({ key }) => key)}
                  type={type}
                  pageFilters={pageFilters}
                />
              </Tooltip>
            </>
          }
          forceShown={isFilterQuery}
          key={key}
          withoutCollapsing={withoutCollapsing}
        >
          {(isConditionalFilterGroup || shouldRenderHelp) && renderHelpText(key)}
          {Component && <Component {...filtersComponentProps} />}
          {isConditionalFilterGroup && <ConditionalFiltersGroup {...filtersComponentProps} />}
        </SidebarGroup>
      );
    });
}

export default observer(FilterGroups);
