import React from "react";

import { RootStore } from "../models/Root.model";

const rootStore = new RootStore();
const StoresContext = React.createContext(rootStore);

export const useStores = (): RootStore => React.useContext(StoresContext);

if (window.Cypress) {
  window.rootStore = rootStore;
}

export default StoresContext;
