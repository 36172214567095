import React, { useLayoutEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { css, Global } from "@emotion/core";
import { observer } from "mobx-react";
import {
  addDays,
  differenceInDays,
  format,
  getTime,
  isAfter,
  isWithinInterval,
  startOfToday,
  endOfDay,
  toDate
} from "date-fns";
import { Button, Colors, NonIdealState, Spinner } from "@blueprintjs/core";
import { renderToString } from "react-dom/server";
import cloneDeep from "lodash.clonedeep";
import clsx from "clsx";
import isEmpty from "lodash.isempty";
import intersection from "lodash.intersection";
import styled from "@emotion/styled";

import { dashStylesGraph, metricGroupTypes, metricsTypes, metricUnitTypes } from "../helpers/constants";
import formatValueWithUnit from "../helpers/FormatValueWithUnit";
import { getMetricsColor } from "../helpers/metricsColors";
import { numberAdjustedByRest } from "../helpers/numberAdjustedByRest";
import { Status } from "../helpers/Status";
import { useStores } from "../store/Store";
import isNumber from "../helpers/isNumber";

import SeriesLegend from "./SeriesLegend";

const TOOLTIP_VERTICAL_SPACING = 18;
const TOOLTIP_HORIZONTAL_SPACING = 12;
const LABEL_TOOLTIP_CLASS = "highcharts-tooltip-label";

const steps = 11;

if (process.env.NODE_ENV === "test") {
  // https://api.highcharts.com/class-reference/Highcharts#.useSerialIds
  Highcharts.useSerialIds(true);
}

const Y_AXIS_BASE = {
  crosshair: { color: Colors.GRAY2, dashStyle: "Dash", snap: false, width: 1 },
  gridLineColor: Colors.LIGHT_GRAY3,
  labels: {
    align: "right",
    margin: 0,
    padding: 0,
    style: { color: Colors.GRAY2, fontSize: "11px", zIndex: 0 },
    x: -6 // margin on right side, between numbers and border/chart
  },
  lineColor: Colors.LIGHT_GRAY1,
  lineWidth: 1,
  margin: 0,
  min: 0,
  startOnTick: false,
  endOnTick: false,
  tickAmount: steps + 1,
  tickLength: 3,
  tickWidth: 1,
  title: {
    align: "high",
    margin: 8, // margin between title and numbers
    useHTML: true
  }
};

const StyledSeriesLabel = styled("div")`
  line-height: 1;
  margin: 8px 10px 8px 0;

  .highcharts-yaxis--first-alone & {
    margin-bottom: 20px !important;
  }
`;

export type chartAxesTypes = Array<{
  coll: string,
  getExtremes: Function,
  min: number,
  options: { groupName: Array<string> },
  setExtremes: Function
}>;

type Props = {
  allMetrics: Array<string | Array<string>>,
  changeSelectedRange: Function,
  columnLabels: Object,
  customYAxis: Object,
  data: Object,
  fetchData: Function,
  maxGrouped: ?Array<Object>,
  mergedGroups: Array<Array<string>>,
  percentGroup: Array<string>,
  selectedRange: { start: ?number, end: ?number },
  series: Array<string | Array<string>>,
  status: string,
  today: number,
  tooltipLabels: Object
};

const removeTooltip = () => {
  const tooltips = document.getElementsByClassName(LABEL_TOOLTIP_CLASS);
  if (tooltips.length) {
    Array.from(tooltips).forEach(tooltip => {
      tooltip.remove();
    });
  }
};

const createLabelTooltip = (chart, elementPosition, label, isOpposite = false) => {
  removeTooltip(); // bug with resizing charts

  const wrapper = document.createElement("div");
  wrapper.setAttribute("class", LABEL_TOOLTIP_CLASS);
  const y = elementPosition.y + TOOLTIP_VERTICAL_SPACING;
  wrapper.style.top = `${y}px`;

  if (isOpposite) {
    const x = window.innerWidth - elementPosition.right + TOOLTIP_HORIZONTAL_SPACING;
    wrapper.style.right = `${x}px`;
  } else {
    const x = elementPosition.x + TOOLTIP_HORIZONTAL_SPACING;
    wrapper.style.left = `${x}px`;
  }

  wrapper.insertAdjacentHTML("beforeend", `<div class='${LABEL_TOOLTIP_CLASS}__title' title='${label}'>${label}</div>`);
  document.body.appendChild(wrapper);
};

export const joinAxes = (yAxis: Array<Object>, mergedGroups: Array<Array<string>>) => {
  const flatMergedGroups = mergedGroups.flat();
  const newGroups = yAxis.filter(({ groupName }) => groupName && !flatMergedGroups.includes(groupName[0]));
  const inMergedGroup = yAxis.filter(({ groupName }) => groupName && flatMergedGroups.includes(groupName[0]));

  mergedGroups
    .map(group => inMergedGroup.filter(({ groupName }) => groupName && group.includes(groupName[0])))
    .filter(group => group.length)
    .forEach(group => {
      const groupName = group.flatMap(axis => axis.groupName);
      const title = {
        ...Y_AXIS_BASE.title,
        text: group.map(axis => axis.title.text).join(" ")
      };
      newGroups.unshift({ ...group[0], groupName, title });
    });
  return newGroups;
};

export const setMaxForGroups = (
  chart: { axes: chartAxesTypes },
  maxGrouped: Array<{ sources: Array<string>, target: string }>
) => {
  const includesGroupName = (axis, groupName) => axis.coll === "yAxis" && axis.options.groupName.includes(groupName);
  const chartAxes = chart.axes;

  maxGrouped.forEach(({ target, sources }) => {
    const allAxisInGroup = [target, ...sources];
    const maxPerGroup = [];

    allAxisInGroup.forEach(theAxis =>
      chartAxes.forEach(axis => {
        const groupIndex = chartAxes.findIndex(axis => includesGroupName(axis, theAxis));
        const axisBySeriesName = includesGroupName(axis, theAxis);

        if (axisBySeriesName && groupIndex !== -1) {
          maxPerGroup.push({ index: groupIndex, max: axis.getExtremes().max });
        }
      })
    );

    if (!isEmpty(maxPerGroup)) {
      const groupMaxes = maxPerGroup.map(group => group.max);
      const maxNumberSeries = Math.max(...groupMaxes, 0);

      maxPerGroup.forEach(groupAxis => {
        chartAxes[groupAxis.index].setExtremes(chartAxes[groupAxis.index].min, maxNumberSeries);
      });
    }
  });
};

const isToday = (timestamp, today) => isWithinInterval(timestamp, { start: toDate(today), end: endOfDay(today) });
const isForecasted = (timestamp, today) => isAfter(timestamp, today);

const generateClassName = (timestamp, today) => {
  if (isToday(timestamp, today)) return "";
  return isForecasted(timestamp, today) ? "forecasted" : "past";
};

function Graph(props: Props) {
  const {
    allMetrics = [],
    changeSelectedRange,
    columnLabels = {},
    customYAxis = {},
    data = {},
    fetchData,
    maxGrouped = [],
    mergedGroups = [],
    percentGroup = [],
    selectedRange,
    series = [],
    status,
    today = getTime(startOfToday()),
    tooltipLabels = columnLabels || {}
  } = props;

  const { systemUnitsStore } = useStores();
  const { computedDateFormat, dateDelimiter } = systemUnitsStore;
  const moduleHeight = 300;

  const isInitialized = useRef(false);
  const chartRef = useRef(null);
  const newTooltips = cloneDeep(tooltipLabels);

  // label are removed because it duplicates group name
  newTooltips.sellingPrice = "";
  newTooltips.pricePercentile = "";

  useLayoutEffect(() => {
    if (!isInitialized.current) {
      // fetch data on first mount if there is no data
      if (fetchData && status !== Status.LOADING && !Object.keys(data).length) {
        fetchData({}, { saveOptions: false });
      }
      isInitialized.current = true;
      return;
    }

    // fetch data on deps change - for now unused
    if (fetchData) {
      fetchData({}, { saveOptions: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedSeries = series.map(group => group[1]).flat();
  if (isEmpty(selectedSeries)) {
    return (
      <div className="m-5 w-100">
        <NonIdealState icon="geosearch" title="No series selected" />
      </div>
    );
  }

  if (status === Status.INIT) {
    return <div className="m-5" />;
  }

  if (status === Status.LOADING) {
    return (
      <div className="w-100">
        <Spinner />
      </div>
    );
  }

  const xAxis = data.scenarioDate || data.ndo;
  if (isEmpty(xAxis)) {
    return (
      <div className="w-100">
        <NonIdealState
          icon="geosearch"
          title="No data matching filters"
          description="Please change your search criteria."
        />
      </div>
    );
  }

  if (status === Status.ERROR) {
    return (
      <div className="my-5 w-100">
        <NonIdealState
          icon="issue"
          title="Error getting data"
          description="Please retry if possible. If this problem reoccurs, please contact FLYR."
          action={
            <Button icon="refresh" onClick={() => fetchData && fetchData({}, { saveOptions: false })}>
              Retry Now
            </Button>
          }
        />
      </div>
    );
  }

  const getSeriesIndex = (groupName: string, metricName: string) => {
    const group = allMetrics.find(group => group[0] === groupName);

    if (!isEmpty(group)) {
      const groupMetrics = group[1].map(metric => metric.key);
      return groupMetrics.indexOf(metricName);
    }
    return null;
  };

  function xAxisFormatter() {
    const { value } = this;

    // convert milliseconds time to 30/8
    if (isNumber(value)) {
      const isDayFirst = computedDateFormat.toLowerCase().indexOf("d") < computedDateFormat.toLowerCase().indexOf("m");
      return format(new Date(value), isDayFirst ? `d${dateDelimiter}M` : `M${dateDelimiter}d`);
    }

    return value;
  }

  // used when selecting range via chart
  function afterSetExtremes() {
    if (!selectedRange || !changeSelectedRange) {
      return;
    }

    setTimeout(() => {
      const { min, max } = this;
      const newStart = differenceInDays(new Date(min), today);
      const newEnd = differenceInDays(new Date(max), today);

      const { start, end } = selectedRange;
      if (newStart !== start || newEnd !== end) {
        changeSelectedRange({ start: newStart, end: newEnd });
      }
    });
  }

  const generateXAxis = () => {
    return {
      crosshair: { color: Colors.GRAY2, dashStyle: "dash", width: 1 },
      events: { afterSetExtremes },
      gridLineColor: Colors.LIGHT_GRAY3,
      gridLineWidth: 1,
      labels: {
        align: "center",
        autoRotation: false,
        formatter: xAxisFormatter,
        style: { color: Colors.GRAY2, fontSize: "11px", whiteSpace: "nowrap" },
        y: 22
      },
      lineWidth: 0,
      offset: 0,
      ordinal: true,
      plotLines: data.today
        ? [
            {
              color: `${Colors.BLUE4}80`, // 50% opacity
              dashStyle: "dash",
              value: data.today,
              width: 1,
              zIndex: 2,
              className: "today-plot-line"
            }
          ]
        : [],
      startOnTick: false,
      tickPixelInterval: 80,
      tickWidth: 0,
      title: {
        align: "low",
        offset: 0,
        rotation: 0,
        style: { color: Colors.GRAY2, fontSize: "12px", fontWeight: 500 },
        text: "Obs Date",
        x: -70,
        y: 10
      },
      type: "datetime"
    };
  };

  const formatYAxisLabel = (params: Object, groupName: string, reversed: boolean) => {
    if ((reversed && !params.isFirst) || (!reversed && !params.isLast)) {
      const { value } = params;

      if (value && typeof params.value === "number") {
        return numberAdjustedByRest(params);
      }
      return params.axis.defaultLabelFormatter.call(params);
    }

    return undefined;
  };

  const getActiveSeriesGroups = () => series.filter(group => group[1] && group[1].length);

  const getIsLacGridActive = () => {
    const activeGroups = getActiveSeriesGroups().map(group => group[0]);

    // LAC grid is active when LAC is active and percentage series are not
    // in other words - grid priority is: percentage, LAC, all the rest
    return activeGroups.includes("lacGroup") && !intersection(percentGroup, activeGroups).length;
  };

  const generateYAxisScale = (groupName: string) => {
    const isLacGridActive = getIsLacGridActive();

    if (groupName === "lacGroup") {
      return {
        gridLineWidth: isLacGridActive ? 1 : 0
      };
    }
    if (percentGroup.includes(groupName)) {
      return {
        gridLineWidth: isLacGridActive ? 0 : 1,
        max: 110,
        isPercentScale: true
      };
    }

    return { gridLineWidth: isLacGridActive ? 0 : 1 };
  };

  const generateYAxisTitle = (groupName: string, groupMetrics: string) => {
    const squares = groupMetrics.map(metricName => (
      <SeriesLegend
        className="highcharts-axis-series-legend"
        group={groupName}
        index={getSeriesIndex(groupName, metricName)}
        key={groupName}
        legendTitle={columnLabels[metricName]}
      />
    ));

    const groupUnit = metricUnitTypes[metricGroupTypes[groupName]];
    const seriesTitle = `${columnLabels[groupName]} ${groupUnit || ""}`;

    return renderToString(
      <StyledSeriesLabel>
        {seriesTitle}
        {squares}
      </StyledSeriesLabel>
    );
  };

  const generateYAxis = (groupName: string, groupMetrics: Array<string>) => {
    const text = generateYAxisTitle(groupName, groupMetrics);
    const customProps = customYAxis[groupName] || {};

    const { reversed = false, labels = {}, ...otherCustomProps } = customProps;
    const className = clsx({
      "y-axis-reversed": reversed,
      [Y_AXIS_BASE.className]: Y_AXIS_BASE.className
    });

    return {
      ...Y_AXIS_BASE,
      ...generateYAxisScale(groupName),
      labels: {
        ...Y_AXIS_BASE.labels,
        formatter: params => formatYAxisLabel(params, groupName, reversed),
        ...labels
      },
      className,
      groupName: [groupName], // same as above
      reversed,
      title: { ...Y_AXIS_BASE.title, text },
      ...otherCustomProps
    };
  };

  const addExtraClassName = (yAxis: Array<Object>) => {
    const newAxisWithSpace = [...yAxis];

    if (!isEmpty(newAxisWithSpace) && newAxisWithSpace.length <= 2) {
      const firstSeriesClassName = newAxisWithSpace[0].className;
      newAxisWithSpace[0].className = clsx("highcharts-yaxis--first-alone", {
        [firstSeriesClassName]: firstSeriesClassName
      });
    }

    return newAxisWithSpace;
  };

  const rotateEvenAxes = (yAxis: Array<Object>) => {
    const evenAxes = yAxis.filter((_, index) => index % 2);

    evenAxes.forEach(axis => {
      /* eslint-disable no-param-reassign */
      axis.opposite = true;
      axis.labels = {
        ...axis.labels,
        align: "left",
        x: 6 // margin between chart and number on right side
      };
      axis.title = {
        ...axis.title,
        text: `<div class="highcharts-axis-even">${axis.title.text}</div>`
      };
      /* eslint-enable no-param-reassign */
    });

    return yAxis;
  };

  const generateSeries = () => {
    const graphSeries = [];
    const activeSeriesGroups = getActiveSeriesGroups();

    // generate and optimize y-axes
    const allYAxis = activeSeriesGroups
      .map(group => {
        const [groupName, groupMetrics] = group;
        const groupMetricsWithData = groupMetrics.filter(metricName => data[metricName]);

        return !isEmpty(groupMetricsWithData) ? generateYAxis(groupName, groupMetricsWithData) : undefined;
      })
      .filter(Boolean);
    const yAxis = addExtraClassName(rotateEvenAxes(joinAxes(allYAxis, mergedGroups)));

    // generate series
    activeSeriesGroups.forEach(group => {
      const [groupName, groupMetrics] = group;
      const axisIndex = yAxis.indexOf(yAxis.find(axis => axis.groupName.includes(groupName)));

      groupMetrics.forEach(metricName => {
        if (!data[metricName]) {
          return;
        }

        const index = getSeriesIndex(groupName, metricName);
        const color = getMetricsColor(groupName, index);
        graphSeries.push({
          color,
          dashStyle: dashStylesGraph[index],
          data: data[metricName].map(([timestamp, y]) => ({
            x: timestamp,
            y,
            className: generateClassName(timestamp, today)
          })),
          key: metricName,
          name: columnLabels[metricName] || metricName,
          parent: groupName,
          yAxis: axisIndex
        });
      });
    });

    return {
      graphSeries,
      yAxis
    };
  };

  const { graphSeries, yAxis } = generateSeries();

  if (isEmpty(yAxis) || graphSeries.every(axis => isEmpty(axis.data))) {
    return (
      <div className="w-100">
        <NonIdealState
          description="Please change your search or series criteria."
          icon="geosearch"
          title="No data matching"
        />
      </div>
    );
  }

  function tooltipFormatter() {
    const { points } = this;
    if (!points.length) return "";

    const xValue = points[0].x;

    const yValues = points.map(point => {
      const series = graphSeries[point.series.index];
      const typeFilter = metricsTypes[series.key];

      const parentLabel = newTooltips[series.parent];
      const label = newTooltips[series.key];

      return (
        <div className="d-flex align-items-center mb-1" key={series.key}>
          <SeriesLegend
            className="mr-1"
            group={series.parent}
            index={getSeriesIndex(series.parent, series.key)}
            size={12}
          />
          <span className="mr-2">
            {parentLabel} {label ? `(${label})` : ""}
          </span>
          <span className="font-weight-bold">{formatValueWithUnit(point.y, typeFilter)}</span>
        </div>
      );
    });

    return renderToString(
      <div className="mx-2">
        <div className="mb-2">
          {format(xValue, computedDateFormat)} {xValue === data.today && "(today)"}
        </div>
        <div>{yValues}</div>
      </div>
    );
  }

  const generateTooltip = () => {
    return {
      animation: false,
      enabled: true,
      formatter: tooltipFormatter,
      outside: true,
      shared: true,
      split: false,
      style: { color: "white", border: 0 },
      useHTML: true
    };
  };

  const chartTooltips = chart => {
    const { axisTitle } = chart.xAxis && chart.xAxis[0];
    const axisTitlePosition = axisTitle && axisTitle.element.getBoundingClientRect();

    axisTitle
      .on("mouseover", () => {
        createLabelTooltip(chart, axisTitlePosition, "Observation Date");
      })
      .on("mouseout", removeTooltip);

    chart.yAxis.forEach(yAxis => {
      const title = yAxis.axisTitle;
      const elements =
        (title && title.element && title.element.getElementsByClassName("highcharts-axis-series-legend")) || [];
      const isOpposite = yAxis.opposite;

      Array.from(elements).forEach((element, index) => {
        const elementPosition = element.getBoundingClientRect();
        element.addEventListener("mouseover", () => {
          const {
            options: { key, parent }
          } = yAxis.series[index];
          const labelTitle = `${tooltipLabels[parent]} (${tooltipLabels[key]})`;

          createLabelTooltip(chart, elementPosition, labelTitle, isOpposite);
        });
        element.addEventListener("mouseout", removeTooltip);
      });
    });
  };

  Highcharts.setOptions({ global: { useUTC: false } });

  const adjustTime = (numberOfDays: number) => getTime(addDays(today, numberOfDays));

  const adjustExtremes = chart => {
    if (chart && selectedRange) {
      const { xAxis } = chart;
      const newMin = adjustTime(selectedRange.start);
      const newMax = adjustTime(selectedRange.end);

      xAxis[0].setExtremes(newMin, newMax);
    }
  };

  adjustExtremes(chartRef.current?.chart);

  const chartOptions = {
    plotOptions: {
      line: {
        animation: false,
        connectNulls: true,
        marker: { enabled: false, radius: 2.5, symbol: "circle" },
        states: { hover: { lineWidth: 2 } }
      },
      series: {
        states: {
          hover: { enabled: true },
          inactive: { opacity: 1 }
        }
      }
    },
    chart: {
      alignTicks: true,
      animation: {
        duration: 100,
        enabled: true
      },
      backgroundColor: "transparent",
      events: {
        redraw() {
          chartTooltips(this);
        },
        resize() {
          chartTooltips(this);
        },
        load() {
          chartTooltips(this);
        }
      },
      height: moduleHeight,
      ignoreHiddenSeries: true,
      panning: true,
      panKey: "shift",
      spacing: [0, 0, 7, 0],
      style: { fontFamily: "inherit" },
      zoomType: "x"
    },
    credits: false,
    legend: false,
    rangeSelector: { selected: 1 },
    series: graphSeries,
    title: "",
    tooltip: generateTooltip(),
    xAxis: generateXAxis(),
    yAxis
  };

  const callbackHighcharts = chart => {
    setMaxForGroups(chart, maxGrouped);
    adjustExtremes(chart);
  };

  return (
    <div className="w-100 position-relative">
      <Global
        styles={css`
          .highcharts-spinner-wrapper {
            background-color: ${Colors.WHITE};
            left: 0;
            position: absolute;
            top: 0;
          }

          // legend metric - yaxis
          .highcharts-item-figure {
            display: inline-block;
            height: 8px;
            margin-left: 7px;
            width: 8px;
          }
          .highcharts-axis-title,
          .highcharts-axis-even {
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
            z-index: 0;
          }
          .highcharts-axis-even {
            transform: rotate(180deg);
          }
          .highcharts-yaxis-grid.y-axis-reversed path:last-of-type,
          .highcharts-yaxis-grid:not(.y-axis-reversed) path:first-of-type {
            stroke: ${Colors.LIGHT_GRAY1} !important;
          }

          .highcharts-axis-series-legend {
            margin-left: 7px;
          }

          // toolip
          .highcharts-tooltip-label,
          .highcharts-tooltip .highcharts-tooltip-box:first-of-type {
            display: block;
            border-radius: 3px;
            box-shadow: 0 0 0 ${Colors.BLACK}1A, 0 2px 4px ${Colors.BLACK}33, 0 8px 24px ${Colors.BLACK}33;
          }

          .highcharts-tooltip-label {
            background-color: ${Colors.DARK_GRAY5}E6;
            padding: 4px 8px;
            position: absolute;
            z-index: 4;
            &__title {
              color: ${Colors.WHITE};
              font-size: 10px;
              font-weight: 400;
            }
          }

          .highcharts-tooltip {
            cursor: default;
            pointer-events: none;
            transition: stroke 150ms;
            white-space: nowrap;

            .highcharts-tooltip-box {
              display: none;
            }
            .highcharts-tooltip-box:first-of-type {
              fill: ${Colors.DARK_GRAY5};
              fill-opacity: 0.9;
              stroke-width: 1px;
            }
          }

          .highcharts-reset-zoom {
            display: none;
          }
        `}
      />
      <HighchartsReact
        allowChartUpdate={false}
        callback={callbackHighcharts}
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
    </div>
  );
}

export default observer(Graph);
