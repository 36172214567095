import React from "react";
import { Button, Icon, MenuItem, Spinner } from "@blueprintjs/core";
import clsx from "clsx";

import { SystemSettingsControlLabel, SystemSettingsSelect } from "./SystemSettingsUI";
import { delimiterOptions } from "../../models/SystemUnits.model";

type Props = {
  dateDelimiter: string,
  isLoading: boolean,
  onChange: Function
};

const renderMenuItem = (item, itemProps, selected) => {
  const isSelected = selected === item.value;

  return (
    <MenuItem
      data-testid="date-delimiter-menu-item"
      key={item.label}
      onClick={itemProps.handleClick}
      text={item.label}
      className={clsx({ "bp3-active": isSelected })}
      labelElement={isSelected ? <Icon icon="tick" /> : null}
    />
  );
};

function DateDelimiterControl({ dateDelimiter, onChange, isLoading }: Props) {
  const delimiterLabel = delimiterOptions.find(option => option.value === dateDelimiter)?.label;
  const buttonText = isLoading ? "Loading..." : delimiterLabel;
  const rightIcon = isLoading ? <Spinner size={12} /> : "caret-down";

  return (
    <div className="d-flex justify-content-end align-items-center">
      <SystemSettingsControlLabel className="m-0 mr-2">Delimiter</SystemSettingsControlLabel>
      <SystemSettingsSelect
        disabled={isLoading}
        filterable={false}
        items={delimiterOptions}
        itemRenderer={(item, itemProps) => renderMenuItem(item, itemProps, dateDelimiter)}
        onItemSelect={option => onChange({ dateDelimiter: option.value })}
        popoverProps={{ minimal: true }}
      >
        <Button
          data-testid="system-units-date-delimiter-select"
          disabled={isLoading}
          text={buttonText}
          rightIcon={rightIcon}
        />
      </SystemSettingsSelect>
    </div>
  );
}
export default DateDelimiterControl;
