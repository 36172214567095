import "core-js/stable"; // polyfills
import "mobx-react/batchingForReactDom";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import * as Integrations from "@sentry/integrations";
import * as FullStory from "@fullstory/browser";
import { IntercomProvider } from "react-use-intercom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { FocusStyleManager } from "@blueprintjs/core";

import { FULLSTORY_ID, INTERCOM_APP_ID, LOGROCKET_ID } from "./config/config";
import isEnv from "./helpers/isEnv";
import { isLocalhost, isNotCypress } from "./helpers/constants";
import AuthGate from "./containers/AuthGate";

import "bootstrap-4-grid/css/grid.css";
import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

const { REACT_APP_PARTNER } = process.env;

function setupSentry() {
  Sentry.init({
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.input" || breadcrumb.category === "ui.click") {
        const { timestamp, category, message } = breadcrumb;
        const { target = {} } = hint.event || {};

        const data = { selector: message };
        if (target.value) {
          data.value = target.value;
        }
        if (target.placeholder) {
          data.placeholder = target.placeholder;
        }
        if (target.innerText && !target.innerText.includes("\n")) {
          data.text = target.innerText;
        }
        if (hint.event.key) {
          data.pressedKey = hint.event.key;
        }
        if (hint.event.path && hint.event.path.length) {
          const testPath = hint.event.path
            .map(el => el.dataset && el.dataset.testid)
            .filter(Boolean)
            .reverse()
            .join(" > ");
          if (testPath) {
            data.testPath = testPath;
          }
        }
        return { timestamp, category, data };
      }

      return breadcrumb;
    },
    dsn: "https://079ce3792751476186a1c7aa2c37627e@o308020.ingest.sentry.io/5618106",
    environment: REACT_APP_PARTNER.toUpperCase(),
    ignoreUrls: [/rs\.fullstory\.com/],
    integrations: [new Integrations.CaptureConsole({ levels: ["error"] })],
    maxBreadcrumbs: 25,
    release: "demo:fbb31e5eb4684cb5780aca0e55a5980f9624837e"
  });
}

function setupFullstory() {
  FullStory.init({ orgId: FULLSTORY_ID });
}

function setupLogRocket() {
  try {
    // https://docs.logrocket.com/docs/react-plugin
    LogRocket.init(LOGROCKET_ID);
    setupLogRocketReact(LogRocket);

    // https://docs.logrocket.com/docs/frontend-error-reproduction
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
  } catch {} // eslint-disable-line no-empty
}

ReactDOM.render(
  isNotCypress && !isLocalhost && isEnv(["staging", "integration", "production"]) ? (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <AuthGate />
    </IntercomProvider>
  ) : (
    <AuthGate />
  ),
  document.getElementById("root")
);

FocusStyleManager.onlyShowFocusOnTabs();

if (!isLocalhost) {
  if (isEnv(["staging", "integration", "production"])) {
    setupSentry();
  }

  if (isNotCypress) {
    if (isEnv(["integration", "production"])) {
      setupFullstory();
    }
    setupLogRocket();
  }
}
