import cloneDeep from "lodash.clonedeep";
import { action, computed, observable } from "mobx";
import { Intent } from "@blueprintjs/core";

import api from "../services/Api";
import { AppToaster } from "../services/Toaster";
import { capitalize } from "../helpers/textUtils";
import { INFLUENCES_LIST } from "../helpers/influenceConstants";

export const InfluenceStatus = {
  INIT: "INIT",
  LOADING: "LOADING",
  LOADING_BASE: "LOADING_BASE",
  LOADING_PREVIEW: "LOADING_PREVIEW",
  DONE: "DONE",
  DONE_BASE: "DONE_BASE",
  DONE_PREVIEW: "DONE_PREVIEW",
  ERROR: "ERROR",
  ERROR_BASE: "ERROR_BASE",
  ERROR_PREVIEW: "ERROR_PREVIEW"
};

export const influenceSteps = { SELECTION: 1, SET: 2, SAVE: 3, SUMMARY: 4 };

export const DATA_INIT = {
  influenceId: "",
  base: {},
  preview: {},
  previewCounters: {}
};

export const PARAMETERS_INIT = {
  comment: "",
  inputValue: 0,
  value: 0
};

export const STATE_INIT = {
  influenceStep: 1,
  influenceType: ""
};

export const PRICE_LIMITS_INIT = { min: null, max: null, startNdo: undefined, endNdo: undefined };
export type PriceLimit = typeof PRICE_LIMITS_INIT;

export class InfluenceStore {
  influencesList = INFLUENCES_LIST;

  @observable data = cloneDeep(DATA_INIT); // data from endpoint
  @observable parameters = cloneDeep(PARAMETERS_INIT); // params sent to endpoint
  @observable state = cloneDeep(STATE_INIT); // progress of influence
  @observable status = InfluenceStatus.INIT;

  @action.bound
  resetSteps() {
    this.data = cloneDeep(DATA_INIT);
    this.parameters = cloneDeep(PARAMETERS_INIT);
    this.state = cloneDeep(STATE_INIT);
    this.status = InfluenceStatus.INIT;
  }

  @action.bound
  updateState(object: Object) {
    this.state = {
      ...this.state,
      ...object
    };
  }

  @action.bound
  updateParameters(object: Object) {
    this.parameters = {
      ...this.parameters,
      ...object
    };
  }

  @action.bound
  saveInfluence(type: string, influence: Object) {
    this.status = InfluenceStatus.LOADING;

    const apiCall = {
      PA: () => api.savePercentInfluence(influence),
      MM: () => api.savePriceLimitsInfluence(influence)
    };

    apiCall[type]()
      .then(({ data }) => {
        this.data = data;
        this.status = InfluenceStatus.DONE;
      })
      .catch(() => {
        this.status = InfluenceStatus.ERROR;
      });
  }

  @action.bound
  previewInfluence(type: string, influence: Object, params: Object) {
    const { isBaseValue } = params || {};
    this.status = isBaseValue ? InfluenceStatus.LOADING_BASE : InfluenceStatus.LOADING_PREVIEW;

    const apiCall = {
      PA: () => api.previewPercentInfluence(influence),
      MM: () => api.previewPriceLimitsInfluence(influence)
    };

    apiCall[type]()
      .then(({ data }) => {
        const { aggregated, previewCounters, rows = [] } = data;

        if (isBaseValue) {
          this.data.base = { aggregated, rows };
          this.data.preview = {};
          this.data.previewCounters = previewCounters;
        } else {
          this.data.preview = { aggregated, rows };
          this.data.previewCounters = previewCounters;
        }

        if (previewCounters && previewCounters.unaffectedValueErrors > 0) {
          AppToaster.show({
            message:
              "Some flights cannot be influenced. The FLYR dev team has been informed and will be investigating.",
            intent: Intent.PRIMARY
          });
        }

        this.status = isBaseValue ? InfluenceStatus.DONE_BASE : InfluenceStatus.DONE_PREVIEW;
      })
      .catch(() => {
        this.status = isBaseValue ? InfluenceStatus.ERROR_BASE : InfluenceStatus.ERROR_PREVIEW;
      });
  }

  @computed
  get selectedInfluence() {
    return this.influencesList.find(el => el.type === this.state.influenceType) || {};
  }

  @computed
  get columnsList() {
    return this.selectedInfluence.rows ? this.selectedInfluence.rows.map(row => row.key) : [];
  }

  @computed
  get columnsListForFlightsTable() {
    return this.columnsList.map(key => `influence${capitalize(key)}`);
  }

  @computed
  get isPreviewActive() {
    return Boolean(this.state.influenceType);
  }

  @computed
  get isLoading() {
    return this.status === InfluenceStatus.LOADING;
  }

  @computed
  get isError() {
    return this.status === InfluenceStatus.ERROR;
  }
}
