import { getTime, parseISO } from "date-fns";
import isEmpty from "lodash.isempty";
import pickBy from "lodash.pickby";
import isNumber from "./isNumber";

export const mapToTime = (value: number) => getTime(parseISO(value));

export default function parseGraphSeries(data: Object) {
  const { maxLacRank, scenarioDate, today } = data;
  const series = pickBy(data, value => Array.isArray(value) && value.length === scenarioDate.length);

  if (scenarioDate && !isEmpty(scenarioDate)) {
    const scenarioDateTime = scenarioDate.map(mapToTime);

    const seriesNames = Object.keys(series);
    const adjustedSeries = {};
    const mapSeriesValue = (value: number, index: number) => [scenarioDateTime[index], value];

    seriesNames.forEach((seriesName: string) => {
      const seriesValues = series[seriesName];
      if (seriesValues.some(isNumber)) {
        adjustedSeries[seriesName] = seriesValues.map(mapSeriesValue);
      }
    });

    return {
      ...adjustedSeries,
      maxLacRank,
      scenarioDate: scenarioDateTime,
      today: mapToTime(today || scenarioDate[scenarioDate.length - 1])
    };
  }

  // @TODO: adapt to NDO
  return {
    ...series,
    maxLacRank,
    scenarioDate,
    today
  };
}
