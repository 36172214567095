// @flow

import React, { useContext } from "react";
import { observer } from "mobx-react";
import { NonIdealState } from "@blueprintjs/core";
import isEmpty from "lodash.isempty";
import { useStores } from "../store/Store";
import MileTransformationContext from "../services/contexts/MileTransformationContext";

import BuildCurves from "../components/BuildCurves";
import Module from "../components/Module";
import NumberOfFlights from "../components/NumberOfFlights";
import Row from "../components/Row";
import { Tab, TAB_INIT } from "../models/Tab.model";

type Props = {
  tab: Tab
};

function Explore(props: Props) {
  const { analysisMappingsStore, templatesStore } = useStores();
  const { tab } = props;
  const transformToMile = useContext(MileTransformationContext);

  const componentFromSavedView = templatesStore.getComponent(tab.parentId, tab.parentType, "buildCurves");
  const baseMetrics = componentFromSavedView ? componentFromSavedView.series : TAB_INIT.buildCurves.series;

  const buildCurvesProps = {
    ...tab.buildCurves,
    baseMetrics,
    cabinClasses: !isEmpty(tab.applied.filters.cabinClass)
      ? tab.applied.filters.cabinClass
      : analysisMappingsStore.getCabinClasses(),
    changeCabinClass: (cabinClass: string) => {
      tab.changeBuildCurvesClass(cabinClass);
    },
    changeSeries: (series: Array<string>) => tab.changeSeries(series),
    changeSelectedRange: range => tab.changeSelectedRange(range),
    changeGroupsStatus: items => {
      tab.changeBuildCurvesParams("groupStatuses", items, {
        refetch: false,
        skipSave: true
      });
    },
    fetchData: () => {
      tab.getDepartureDateExtremes().then(() => tab.getBuildCurvesData());
    },
    flightsTableSelectedRows: tab.flightsTable.selectedRows,
    isBuildCurvesZoomed: tab.isBuildCurvesZoomed,
    setCollapsed: (isCollapsed: boolean) => {
      tab.setModuleCollapsed("buildCurves", isCollapsed);
    },
    subtitle: [
      <NumberOfFlights
        selectedRowsNumberOfFlights={tab.selectedRowsNumberOfFlights}
        flightsCount={tab.flightsCount}
        flightsTable={tab.flightsTable}
      />
    ],
    today: tab.getToday(),
    transformToMile
  };

  return (
    <Row className="mb-2">
      <div className="col-6">
        <Module
          className="h-100"
          childrenClassName="placeholder-center"
          title="Pivot Table"
          isCollapsed={tab.pivotTable.isCollapsed}
          setCollapsed={(isCollapsed: boolean) => tab.setModuleCollapsed("pivotTable", isCollapsed)}
        >
          <NonIdealState className="text-center" icon="pivot-table" />
        </Module>
      </div>
      <div className="col-6">
        <BuildCurves {...buildCurvesProps} />
      </div>
    </Row>
  );
}

export default observer(Explore);
