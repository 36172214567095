import React from "react";
import { Button, Icon, MenuItem, Spinner } from "@blueprintjs/core";
import clsx from "clsx";

import { SystemSettingsSelect } from "./SystemSettingsUI";

const unitOptions = [
  { value: "km", label: "Kilometers (km)" },
  { value: "mi", label: "Miles (mi)" }
];

type Props = {
  distanceUnit: string,
  isLoading: boolean,
  onChange: Function
};

const renderMenuItem = (item, itemProps, selected) => {
  const isSelected = selected === item.value;

  return (
    <MenuItem
      className={clsx({ "bp3-active": isSelected })}
      data-testid="distance-menu-item"
      key={item.label}
      labelElement={isSelected ? <Icon icon="tick" /> : null}
      onClick={itemProps.handleClick}
      text={item.label}
    />
  );
};

function DistanceControl({ distanceUnit, onChange, isLoading }: Props) {
  const distanceLabel = unitOptions.find(option => option.value === distanceUnit)?.label;
  const buttonText = isLoading ? "Loading..." : distanceLabel;
  const rightIcon = isLoading ? <Spinner size={12} /> : "caret-down";

  return (
    <SystemSettingsSelect
      disabled={isLoading}
      filterable={false}
      items={unitOptions}
      itemRenderer={(item, itemProps) => renderMenuItem(item, itemProps, distanceUnit)}
      onItemSelect={option => onChange({ distanceUnit: option.value })}
      popoverProps={{ minimal: true }}
    >
      <Button data-testid="system-unit-select" disabled={isLoading} text={buttonText} rightIcon={rightIcon} />
    </SystemSettingsSelect>
  );
}

export default DistanceControl;
