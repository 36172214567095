// @flow
import React from "react";
import { observer } from "mobx-react";

import { useStores } from "../../store/Store";
import { renderSelect } from "../../helpers/EventsManagementHelpers";

function EventsManagementLocationSelects() {
  const { eventsManagementStore } = useStores();
  const {
    airports,
    changeField,
    countries,
    isSuggestModalLoading,
    metros,
    page: {
      modalEvent: { locations }
    },
    conflictedLocationsMetros,
    conflictedLocationsAirport
  } = eventsManagementStore;

  return (
    <div>
      {renderSelect({
        isDisabledGlobally: isSuggestModalLoading,
        items: countries,
        onChange: value => changeField("countries", value, "locations"),
        parent: "locations",
        selectedItems: locations?.countries,
        type: "country"
      })}
      {renderSelect({
        conflictList: conflictedLocationsMetros,
        isDisabledGlobally: isSuggestModalLoading,
        items: metros,
        onChange: value => changeField("metros", value, "locations"),
        parent: "locations",
        selectedItems: locations?.metros,
        type: "metro"
      })}
      {renderSelect({
        conflictList: conflictedLocationsAirport,
        isDisabledGlobally: isSuggestModalLoading,
        items: airports,
        onChange: value => changeField("airports", value, "locations"),
        parent: "locations",
        selectedItems: locations?.airports,
        type: "airport"
      })}
    </div>
  );
}

export default observer(EventsManagementLocationSelects);
