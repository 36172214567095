// @flow

import React, { useCallback, useContext } from "react";
import { Icon, OverflowList, Boundary } from "@blueprintjs/core";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import MileTransformationContext from "../services/contexts/MileTransformationContext";
import filterNamesMap, { filterNamesOverrides } from "../helpers/filterNames";
import getActiveFilters from "../helpers/getActiveFilters";
import Tag from "./Tag";
import { ChipValue } from "./ChipValue";
import { OverflownTagList } from "./OverflownTagList";
import ChipContent from "./ChipContent";
import filterTitleValue from "../helpers/filterTitleValue";
import { useStores } from "../store/Store";

const StyledTagContent = styled("span")`
  display: grid;
  grid-template-columns: auto 20px auto;
  width: fit-content;
`;

type Props = {
  additionalTagLabels: Object,
  disabled: boolean,
  filterOrder: Array,
  initialFilters: Object,
  isPreviewActive: boolean,
  minItems: number,
  onChangeParam: ?Function,
  onTagClick: ?Function,
  onValueRemove: Function,
  params: Object,
  valueRenderers: Object,
  xDayBuild: number
};

function TagsList(props: Props) {
  const {
    additionalTagLabels = {},
    disabled,
    filterOrder = [],
    initialFilters,
    isPreviewActive,
    minItems = 0,
    onChangeParam,
    onTagClick,
    onValueRemove,
    params,
    valueRenderers = {},
    xDayBuild = 7
  } = props;

  const { systemUnitsStore } = useStores();
  const { computedDateFormat } = systemUnitsStore;

  const transformToMile = useContext(MileTransformationContext);
  const filterNames = filterNamesMap(transformToMile, additionalTagLabels, xDayBuild);

  const entries = params ? Object.entries(params) : [];

  const activeFilters = getActiveFilters(entries, initialFilters).sort((filterA, filterB) => {
    const filterAIndex = filterOrder.indexOf(filterA[0]);
    const filterBIndex = filterOrder.indexOf(filterB[0]);
    return filterAIndex > filterBIndex ? 1 : -1;
  });

  const handleRemove = useCallback(name => () => onChangeParam && onChangeParam(name), [onChangeParam]);

  const tagRenderer = ([name, value]) => {
    const filterName =
      filterNamesOverrides[value.name] ||
      filterNamesOverrides[name] ||
      filterNames[value.name] ||
      filterNames[name] ||
      name;

    if (!value && value !== false) {
      return null;
    }

    const popoverContent = (
      <ChipContent
        additionalTagLabels={additionalTagLabels}
        clearable={!isPreviewActive}
        customValueRenderer={valueRenderers[name]}
        name={name}
        onValueClick={onTagClick ? () => onTagClick(name) : undefined}
        onValueRemove={onValueRemove}
        title={filterName}
        value={value}
      />
    );

    const tagProps = {
      disabled,
      key: name,
      popoverContent,
      ...(!isPreviewActive && { onRemove: handleRemove(name) })
    };

    return (
      <Tag {...tagProps} inline>
        <StyledTagContent
          title={`${filterName}: ${filterTitleValue(
            additionalTagLabels && additionalTagLabels[value] ? additionalTagLabels[value] : value,
            name,
            additionalTagLabels,
            computedDateFormat
          )}`}
        >
          <span className="text-truncate">{filterName}</span>
          <span className="font-weight-normal mx-1 text-center"> &middot; </span>
          <ChipValue
            additionalTagLabels={additionalTagLabels}
            customValueRenderer={valueRenderers[name]}
            name={name}
            value={value}
          />
        </StyledTagContent>
      </Tag>
    );
  };

  const overflowRenderer = overflowedEntries => {
    return (
      <Tag
        disabled={disabled}
        fluid
        inline
        key="overflown-tag"
        popoverContent={
          <OverflownTagList
            additionalTagLabels={additionalTagLabels}
            disabled={disabled}
            handleRemove={handleRemove}
            isPreviewActive={isPreviewActive}
            onValueClick={onTagClick}
            onValueRemove={onValueRemove}
            tags={overflowedEntries}
            transformToMile={transformToMile}
            valueRenderers={valueRenderers}
          />
        }
        testId="popover-tag"
      >
        {`${overflowedEntries.length} more`}
        <Icon icon="caret-down" className="ml-2" iconSize={15} />
      </Tag>
    );
  };

  return (
    <OverflowList
      items={activeFilters}
      visibleItemRenderer={tagRenderer}
      overflowRenderer={overflowRenderer}
      collapseFrom={Boundary.END}
      observeParents
      minVisibleItems={minItems}
    />
  );
}

export default observer(TagsList);
