import React from "react";
import { Colors, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";

import HeaderContextMenu from "./HeaderContextMenu";
import getActiveFilters from "../helpers/getActiveFilters";
import groupConditionalFilters from "../helpers/groupConditionalFilters";

const StyledTableHeaderCell = styled("th")`
  position: sticky;
  z-index: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ depth, isSorted }) => {
    if (isSorted) return 600;
    return depth ? 400 : 500;
  }};
  background: ${({ depth }) => (depth ? Colors.LIGHT_GRAY5 : Colors.LIGHT_GRAY4)};

  .context-menu {
    opacity: 0;
    width: 0;
    transition: opacity 0.1s linear;
  }

  &:hover {
    .filter--used {
      display: none;
    }
    .context-menu {
      margin-right: -0.5rem;
      opacity: 1;
      width: 30px;
    }
  }

  button {
    height: 24px;
    min-height: 24px;
    svg {
      width: 14px;
      height: 14px;
    }
    span.bp3-button-text {
      height: 24px;
      align-self: center;
      line-height: 24px;
    }
  }
`;
const StyledHeaderLabel = styled.div`
  min-width: 0;
`;

type Props = {
  columnDescriptions: Object,
  columnSortType: Object,
  filters: Object,
  gotoPage: Function,
  headerGroups: Array,
  headers: Object,
  hideColumn: Function,
  initialFilters: Object,
  isAdjustable: boolean,
  nonFilterableColumns: Array<string>,
  onColumnFilter: ?Function,
  sortable: boolean,
  toggleFixedColumn: Function
};

function DataTableHeader(props: Props) {
  const {
    columnDescriptions,
    columnSortType = {},
    filters,
    gotoPage,
    headerGroups,
    headers,
    hideColumn,
    initialFilters,
    isAdjustable,
    nonFilterableColumns,
    onColumnFilter,
    sortable,
    toggleFixedColumn
  } = props;

  const { conditionalFilters = [], filters: searchFilters = {} } = filters;

  const groupedFilters = Object.entries({
    ...searchFilters,
    ...groupConditionalFilters(conditionalFilters)
  });

  const appliedFilters = getActiveFilters(groupedFilters, initialFilters).map(([name]) => name);

  const isColumnSortable = (column: { columns: Array<Object>, id: string }) => {
    const isGroupHeader = column.columns;
    const nonSortableColumns = ["checkbox", "expander"];
    return !isGroupHeader && !nonSortableColumns.includes(column.id) && columnSortType[column.id] !== null;
  };
  const isColumnUsed = (columnId: string) => appliedFilters.includes(columnId);

  return headerGroups.map((headerGroup: Object) => {
    const headerCells = headerGroup.headers.map(column => {
      const isSortable = sortable && isColumnSortable(column);
      const icon = column.isSortedDesc ? "arrow-up" : "arrow-down";
      const parentId = column.parent ? column.parent.id : "";
      const columnsWithoutContextMenu = ["selection", "aggregations"];
      const isParentFrozenToRightSide = column.parent ? column.parent.sticky === "right" : false;
      const headerCellProps = {
        depth: column.depth,
        isSorted: column.isSorted,
        width: column.width,
        ...column.getHeaderProps(),
        title: undefined
      };

      const sortProps = isSortable ? column.getSortByToggleProps() : undefined;
      const columnId = column.originalId || column.id;
      const isFrozen = column.sticky;
      const isGroupColumn = !column.depth;
      const isHideable =
        isAdjustable &&
        !isParentFrozenToRightSide &&
        !columnsWithoutContextMenu.includes(parentId) &&
        !columnsWithoutContextMenu.includes(columnId);
      const isFreezable =
        isAdjustable && column.sticky !== "right" && !columnsWithoutContextMenu.includes(column.originalId);
      const isFilterable = !nonFilterableColumns.includes(columnId);

      const isFilterUsed = isColumnUsed(columnId);

      const contextMenuProps = {
        columnId,
        isFilterUsed,
        isFrozenColumn: isFrozen,
        isGroupColumn,
        ...(isFilterable && { onColumnFilter }),
        ...(isFreezable && {
          onColumnFreeze: () => toggleFixedColumn(columnId)
        }),
        ...(isHideable && {
          onColumnHide: () => hideColumn(columnId)
        }),
        ...(isSortable && {
          onColumnSort: isDesc => column.toggleSortBy(isDesc)
        })
      };

      return (
        <StyledTableHeaderCell {...headerCellProps}>
          <StyledHeaderLabel
            {...sortProps}
            title={columnDescriptions[column.id]}
            data-testid={`table-header-${columnId}`}
            className="flex-grow-1 d-flex align-items-center"
            onClick={e => {
              if (sortProps && sortProps.onClick) {
                gotoPage(0);
                sortProps.onClick(e);
              }
            }}
          >
            {column.isSorted && <Icon className="mr-1" icon={icon} iconSize={12} />}
            {column.render("Header")}
            {isFilterUsed && <Icon className="ml-auto filter--used" color={Colors.GRAY2} icon="filter" iconSize={12} />}
            {headers[column.id] ? headers[column.id] : null}
          </StyledHeaderLabel>
          <HeaderContextMenu {...contextMenuProps} />
        </StyledTableHeaderCell>
      );
    });

    return <tr {...headerGroup.getHeaderGroupProps()}>{headerCells}</tr>;
  });
}

export default DataTableHeader;
