// @flow

import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { MenuDivider } from "@blueprintjs/core";

import FilterGroups from "./AnalysisSidebarFilterGroups/FilterGroups";
import SearchInput from "./SearchInput";
import Sidebar from "./Sidebar";
import { filterGroups } from "../helpers/metrics";
import focusOnFilter from "../helpers/focusOnFilter";
import SidebarApplyButton from "./SidebarApplyButton";
import { useStores } from "../store/Store";
import FiltersActionButtons from "./FiltersActionButtons";

type Props = {
  submitSearchForm: Function,
  tab: Object
};

function AnalysisSidebar(props: Props) {
  const { submitSearchForm, tab } = props;
  const { analysisMappingsStore, regionsStore, systemUnitsStore } = useStores();
  const { computedDateFormat, computedDateMonthFormat } = systemUnitsStore;

  const { setSidebarFilterQuery, setSidebarOpen, sidebar } = tab;
  const { filterQuery, isOpen, filterKey } = sidebar;

  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  const onSubmit = () => {
    setSidebarOpen(false);
    setSidebarFilterQuery("");
    submitSearchForm();
  };

  return (
    <Sidebar
      footer={<SidebarApplyButton onSubmit={onSubmit} pageContext={tab} type="analysis" />}
      isOpen={isOpen}
      ref={sidebarRef}
      setOpen={setSidebarOpen}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />
      <MenuDivider className="m-0" />
      <FiltersActionButtons pageContext={tab} />
      <MenuDivider className="m-0" />
      <FilterGroups
        computedDateFormat={computedDateFormat}
        computedDateMonthFormat={computedDateMonthFormat}
        filterGroups={filterGroups}
        mappings={analysisMappingsStore}
        pageContext={tab}
        regions={regionsStore}
        type="analysis"
      />
    </Sidebar>
  );
}

export default observer(AnalysisSidebar);
