// @flow

import React from "react";
import { format, parseISO } from "date-fns";
import { css, Global } from "@emotion/core";
import { Position, Tooltip } from "@blueprintjs/core";

import ChipCounter from "../components/ChipCounter";
import DataTableCell from "../components/DataTableCell";
import minMaxDataTableCell from "./minMaxDataTableCell";
import StatusIndicator from "../components/StatusIndicator";

const renderValue = (type: string, value: ?number | string, options: ?Object) => {
  switch (type) {
    case "PA": {
      return <DataTableCell type="percent-relative" value={value} options={options} />;
    }
    case "MM": {
      return <DataTableCell type="min-max" value={value} options={options} />;
    }
    default:
      return value;
  }
};

const getValue = (row: Row, name: string, options: ?Object) => {
  let value = row.original[name];

  if (row.depth > 0) {
    value = row.values[name];
  }

  return name === "value" ? renderValue(row.original.typeKey, value, options) : value;
};

const renderValueWithTooltip = (row: Row) => {
  const {
    id,
    original: { value }
  } = row;
  const renderValueText = getValue(row, "value", { withLimits: true });

  if (Array.isArray(value)) {
    const sortedByHighestNDO = value.sort((ndo1, ndo2) => (ndo1.end > ndo2.end ? 1 : -1));

    const tooltipContent = (
      <div>
        {sortedByHighestNDO.map(
          (range, index) =>
            range.ndo && (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`tooltip-${id}-${index}`} data-testid="tooltip-min-max-row">
                <strong>{`NDO ${range.ndo.start}-${range.ndo.end}`}: </strong>
                {minMaxDataTableCell(range)}
              </div>
            )
        )}
      </div>
    );

    return (
      <>
        <Global
          styles={css`
            .bp3-tooltip .bp3-popover-content {
              padding: 5px 10px;
            }
            .bp3-popover-target {
              max-width: 100%;
            }
          `}
        />

        <Tooltip className="w-100 text-truncate" content={tooltipContent} position={Position.BOTTOM}>
          {renderValueText}
        </Tooltip>
      </>
    );
  }

  return renderValueText;
};

const renderOWMarkets = (markets: Array<string>) => {
  const list = markets.slice().sort().join(", ");
  return (
    <span title={list}>
      <ChipCounter width="22">{markets.length}</ChipCounter> {list}
    </span>
  );
};

export const cellRenderers = (computedDateFormat: string) => ({
  analystName: ({ row }) => getValue(row, "analystName"),
  comment: ({ value }: Cell) => <span title={value}>{value}</span>,
  influenceId: ({ row, value }) => !row.depth && value,
  minDepDate: ({ value }) => value && format(parseISO(value), computedDateFormat),
  maxDepDate: ({ value }) => value && format(parseISO(value), computedDateFormat),
  numberOfFlights: ({ row }) => getValue(row, "numberOfFlights"),
  owMarkets: ({ value }) => value && renderOWMarkets(value),
  status: ({ value, row }: Cell) =>
    !row.depth ? <StatusIndicator isActive={value === "active"} text={value} /> : null,
  updatedOn: ({ row }) => format(parseISO(`${getValue(row, "updatedOn")}Z`), `${computedDateFormat} HH:mm:ss`),
  value: ({ row }) => renderValueWithTooltip(row)
});
