// @flow

import React from "react";
import { observer } from "mobx-react";
import pick from "lodash.pick";

import { useStores } from "../../store/Store";
import SidebarSelect from "../SidebarSelect";
import SidebarFilter from "../SidebarFilter";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";
import getGroupItemsForFilter from "../../helpers/getGroupItemsForFilter";

type Props = {
  pageContext: Object
};

function EventsMarketFilters(props: Props) {
  const { pageContext } = props;
  const { filters, changeFilter, sidebar } = pageContext;
  const { analysisMappingsStore } = useStores();
  const { getRoundTripMarkets, getOneWayMarkets, getOrigins, getDestinations } = analysisMappingsStore;

  const roundTripFilters = pick(filters, ["owMarket", "rtMarket", "origin", "destination"]);
  const oneWayFilters = pick(filters, ["rtMarket", "origin", "destination"]);
  const originFilters = pick(filters, ["rtMarket", "owMarket", "destination"]);
  const destinationFilters = pick(filters, ["rtMarket", "owMarket", "origin"]);

  const matchingGroupLabel = "Matching pre-selected";

  const [rtMarkets, matchingRtMarkets] = getGroupItemsForFilter(roundTripFilters, getRoundTripMarkets);
  const [owMarkets, matchingOwMarkets] = getGroupItemsForFilter(oneWayFilters, getOneWayMarkets);
  const [origins, matchingOrigins] = getGroupItemsForFilter(originFilters, getOrigins);
  const [destinations, matchingDestinations] = getGroupItemsForFilter(destinationFilters, getDestinations);

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("market rt market", "rtMarket", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingRtMarkets },
            { label: "All markets", groupItems: rtMarkets }
          ]}
          onChange={filterValue => changeFilter("rtMarket", filterValue)}
          selectedItems={filters.rtMarket}
          title="RT Market"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("market ow market", "owMarket", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingOwMarkets },
            { label: "All markets", groupItems: owMarkets }
          ]}
          onChange={filterValue => changeFilter("owMarket", filterValue)}
          selectedItems={filters.owMarket}
          title="OW Market"
        />
      </SidebarFilter>
      <SidebarFilter name="market origin" isVisible={isSidebarFilterVisible("market origin", "origin", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingOrigins },
            { label: "All airports", groupItems: origins }
          ]}
          onChange={filterValue => changeFilter("origin", filterValue)}
          selectedItems={filters.origin}
          title="Origin"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("market destination", "destination", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingDestinations },
            { label: "All airports", groupItems: destinations }
          ]}
          onChange={filterValue => changeFilter("destination", filterValue)}
          selectedItems={filters.destination}
          title="Destination"
        />
      </SidebarFilter>
    </>
  );
}
export default observer(EventsMarketFilters);
