// @flow

import React from "react";
import { observer } from "mobx-react";

import SidebarConditionalFilter from "./SidebarConditionalFilter";
import SidebarFilter from "./SidebarFilter";
import SidebarSelect from "./SidebarSelect";
import { competitorFareGroupLabels } from "../helpers/metricLabels/metricLabels";
import isSidebarFilterVisible from "../helpers/isSidebarFilterVisible";

type Props = {
  filters: Array<Object>,
  mappings: Object,
  pageContext: Object
};

function CompetitorFareFilters(props: Props) {
  const { mappings, filters: filterList, pageContext } = props;
  const {
    addCondition,
    changeConditionalFilter,
    changeFilter,
    conditionalFilters,
    deleteCondition,
    filters,
    sidebar
  } = pageContext;

  const labels = competitorFareGroupLabels({
    withUnits: true
  });
  const shortLabels = competitorFareGroupLabels({
    shortVersion: true,
    withParent: true
  });

  const filtersByKey = {};
  filterList.forEach(filter => {
    filtersByKey[filter.key] = filter;
  });

  const renderSelect = type => (
    <SidebarFilter isVisible={isSidebarFilterVisible(shortLabels[type], type, sidebar)}>
      <SidebarSelect
        fuzzySearch
        items={mappings.data.compFareAirline}
        onChange={filterValue => changeFilter(type, filterValue)}
        selectedItems={filters[type]}
        title={labels[type]}
      />
    </SidebarFilter>
  );

  const renderConditionalFilter = type => (
    <SidebarFilter isVisible={isSidebarFilterVisible(shortLabels[type], type, sidebar)}>
      <SidebarConditionalFilter
        conditionalFilters={conditionalFilters}
        isNonNegative={filtersByKey[type].isNonNegative}
        name={type}
        onAddCondition={addCondition}
        onChangeCondition={changeConditionalFilter}
        onDeleteCondition={deleteCondition}
        title={labels[type]}
        type="absolute"
      />
    </SidebarFilter>
  );

  return (
    <>
      {renderSelect("compFareCirrusMatchAirline")}
      {renderConditionalFilter("compFareCirrusMatchFare")}
      {renderConditionalFilter("sellingFareDiffToCirrusCompFarePercentile")}
      {renderConditionalFilter("sellingFareDiffToCirrusCompFare")}

      {renderSelect("compFareTimeMatchAirline")}
      {renderConditionalFilter("compFareTimeMatchFare")}
      {renderConditionalFilter("sellingFareDiffToTimePeriodCompFarePercentile")}
      {renderConditionalFilter("sellingFareDiffToTimePeriodCompFare")}

      {renderSelect("compFareDateMatchAirline")}
      {renderConditionalFilter("compFareDateMatchFare")}
      {renderConditionalFilter("sellingFareDiffToDepDateCompFarePercentile")}
      {renderConditionalFilter("sellingFareDiffToDepDateCompFare")}
    </>
  );
}

export default observer(CompetitorFareFilters);
