// @flow

import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Colors, Icon, Intent, Menu, Popover, Position } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash.isempty";
import pluralize from "pluralize";

import filterNamesMap from "../../helpers/filterNames";
import MileTransformationContext from "../../services/contexts/MileTransformationContext";
import { useStores } from "../../store/Store";
import { MAX_TAB_COUNT } from "../../helpers/constants";
import { AppToaster } from "../../services/Toaster";
import groupConditionalFilters from "../../helpers/groupConditionalFilters";
import getActiveFilters from "../../helpers/getActiveFilters";
import { FILTERS_INIT } from "../../models/Tab.model";
import type { SavedView } from "../../models/Templates.model";
import isEnv from "../../helpers/isEnv";
import { tabCountReachedMessage } from "../../helpers/messages";

const StyledPopoverContent = styled(Menu)`
  &.bp3-menu {
    width: 300px;
  }
  .list-unstyled li {
    list-style: none;
  }
`;

const StyledTemplateFilters = styled("div")`
  color: ${Colors.GRAY1};
  &:hover {
    border-bottom: 1px solid ${Colors.GRAY1};
  }
`;

const StyledTemplateItem = styled("div")`
  height: 54px;
  .more-options {
    visibility: hidden;
  }

  &:hover {
    background-color: ${Colors.LIGHT_GRAY3};
    border-radius: 2px;
    cursor: pointer;

    .more-options {
      transform: rotate(90deg);
      visibility: visible;
    }
  }
`;

const StyledTemplateLabel = styled("div")`
  font-weight: 600;
`;

const StyledFilterLabel = styled("div")`
  flex: 1 1 50px;
  white-space: nowrap;
`;

const StyledTemplateMenuContainer = styled("div")`
  ${({ isActive }) => isActive && `transform: rotate(90deg); visibility: visible !important`};
`;

const typeDictionary = {
  "My Saved Analyses": "saved",
  "Team Analyses": "team",
  "System Analyses": "system"
};

const actionsDictionary = {
  "My Saved Analyses": {
    actions: ["delete"],
    isAdminOnly: false
  },

  "Team Analyses": {
    actions: isEnv(["development"]) ? ["edit", "hide", "default", "delete"] : [],
    isAdminOnly: true
  },

  "System Analyses": {
    actions: isEnv(["development"]) ? ["hide", "default"] : [],
    isAdminOnly: true
  }
};

type Props = {
  template: SavedView,
  type: string
};

function TemplateItem(props: Props) {
  const transformToMile = useContext(MileTransformationContext);
  const history = useHistory();
  const [actionsMenuStatus, setActionsMenuStatus] = useState(false);
  const { appStore, tabsStore, templatesStore } = useStores();
  const { isAdmin = false } = appStore.auth;
  const { template, type } = props;
  const { id, label, view } = template;
  const { conditionalFilters, filters, flightsTable } = view;

  const groupedFilters = Object.entries({
    ...filters,
    ...groupConditionalFilters(conditionalFilters)
  });
  const appliedFilters = getActiveFilters(groupedFilters, FILTERS_INIT);
  const appliedFiltersCount = appliedFilters.length;
  const filterNames = filterNamesMap(transformToMile);
  const filterText = `⋅ ${pluralize("filter", appliedFiltersCount, true)}`;

  const isActionsMenu = !isEmpty(actionsDictionary[type].actions);

  const createTab = () => {
    if (tabsStore.tabs.length < MAX_TAB_COUNT) {
      tabsStore.createTabFromView(template, typeDictionary[type]);
      templatesStore.setSidebarOpen();
      history.push(`/analysis/${tabsStore.lastTabId}/explore`);
    } else {
      AppToaster.show({ message: tabCountReachedMessage, intent: Intent.WARNING });
    }
  };

  const aggregationsLabel = (
    <div className="flex-grow-1 text-truncate">
      {isEmpty(flightsTable.aggregations)
        ? "Network"
        : flightsTable.aggregations.map(aggregation => filterNames[aggregation]).join("/")}
    </div>
  );

  const filtersLabel = <StyledFilterLabel className="ml-auto p-0 text-right"> {filterText} </StyledFilterLabel>;

  const detailsPopover = (
    <StyledPopoverContent className="pt-2 pb-1 px-3">
      {!isEmpty(flightsTable.aggregations) ? (
        <ul className="my-0 p-0 list-unstyled">
          <li className="pb-1">
            <strong>Aggregations</strong>
          </li>
          {flightsTable.aggregations.map(aggregation => (
            <li className="pb-1" key={aggregation}>
              {filterNames[aggregation]}
            </li>
          ))}
        </ul>
      ) : null}
      {!isEmpty(appliedFilters) ? (
        <>
          {!isEmpty(flightsTable.aggregations) ? <Menu.Divider className="mx-0 my-1" /> : null}
          <ul className="my-0 p-0 list-unstyled">
            <li className="pb-1">
              <strong> Filters </strong>
            </li>
            {appliedFilters.map(([filterKey]) => (
              <li className="pb-1" key={filterKey}>
                {filterNames[filterKey]}
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </StyledPopoverContent>
  );

  const actionsMenu = (
    <Menu>
      {actionsDictionary[type].actions.map((action: string, isAdminOnly: boolean) => {
        const isDisplayed = !isAdminOnly ? true : isAdmin === isAdminOnly;
        if (!isDisplayed) {
          return null;
        }
        switch (action) {
          case "delete":
            return isDisplayed ? (
              <Menu.Item
                icon="trash"
                key={`${type - action}`}
                onClick={() => {
                  if (type === "My Saved Analyses") {
                    templatesStore.removeView(id, label);
                  }
                }}
                text="Delete"
              />
            ) : null;
          case "edit":
            return isDisplayed ? (
              <Menu.Item icon="edit" key={`${type - action}`} onClick={() => {}} text="Edit" />
            ) : null;
          case "hide":
            return isDisplayed ? (
              <Menu.Item icon="eye-off" key={`${type - action}`} onClick={() => {}} text="Hide Template" />
            ) : null;
          default:
            return isDisplayed ? (
              <Menu.Item icon="star-empty" key={`${type - action}`} onClick={() => {}} text="Make Default" />
            ) : null;
        }
      })}
    </Menu>
  );

  return (
    <StyledTemplateItem className="p-2" data-testid="template-item" onClick={createTab}>
      <div className="d-flex">
        <StyledTemplateLabel className="text-truncate">{label}</StyledTemplateLabel>
        {isActionsMenu && (
          <StyledTemplateMenuContainer
            className="ml-auto more-options"
            onClick={e => e.stopPropagation()}
            isActive={actionsMenuStatus}
          >
            <Popover
              content={actionsMenu}
              isOpen={actionsMenuStatus}
              onInteraction={state => setActionsMenuStatus(state)}
            >
              <Icon icon="more" iconSize={14} color={Colors.GRAY1} onClick={() => {}} />
            </Popover>
          </StyledTemplateMenuContainer>
        )}
      </div>
      <div data-testid="template-details" className="mt-1" onClick={e => e.stopPropagation()}>
        <Popover
          boundary="viewport"
          captureDismiss
          content={detailsPopover}
          data-testid="template-item-details"
          fill
          position={Position.BOTTOM}
        >
          <StyledTemplateFilters className="d-flex">
            {aggregationsLabel}
            {filtersLabel}
          </StyledTemplateFilters>
        </Popover>
      </div>
    </StyledTemplateItem>
  );
}

export default observer(TemplateItem);
