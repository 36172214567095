import React, { useEffect } from "react";
import isEmpty from "lodash.isempty";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { Spinner } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { useStores } from "store/Store";

const AppStyled = styled("div")`
  height: 100%;
`;

function LandingPage() {
  const { appStore, tabsStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    const { user, firstTabId } = appStore.auth;
    const { tabs } = user;

    if (!isEmpty(tabs)) {
      const activeTabFromLS = localStorage.getItem("activeTab");
      if (tabs.map(tab => tab.id).includes(activeTabFromLS)) {
        history.push(`/analysis/${activeTabFromLS || firstTabId}/explore`);
      } else {
        localStorage.removeItem("activeTab");
        history.push(`/analysis/${firstTabId}/explore`);
      }
    }

    if (isEmpty(tabs)) {
      tabsStore.addTab();

      const { lastTabId } = tabsStore;
      if (lastTabId) {
        history.push(`/analysis/${lastTabId}/explore`);
      }
    }
  }, [tabsStore, appStore.auth, history]);

  return (
    <AppStyled className="d-flex">
      <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
        <h3 className="mb-3">Cirrus UI</h3>
        <Spinner />
        <div className="mt-2 bp3-text-small bp3-text-muted">Initializing application</div>
      </div>
    </AppStyled>
  );
}

export default observer(LandingPage);
