// @flow

import { AxiosPromise } from "axios";
import cloneDeep from "lodash.clonedeep";
import omit from "lodash.omit";
import { action, observable } from "mobx";

import api, { isCancel } from "../../services/Api";
import getRowObjectIds from "../../helpers/getRowObjectIds";
import { Status } from "../../helpers/Status";
import type { FixedColumns, GetFlightsCountData, GetFlightsData, GroupStatus, Pagination } from "./FlightsTable.types";
import type { FlightCountParams, FlightEntry, FlightsParams, FlightsResponse } from "../../types/Flights.types";
import type { TableSortType } from "../../types/Table.types";
import { columnsToRequest, getName, groupStatuses, initColumns } from "./FlightsTable.utils";
import { canEnableMetric } from "../../helpers/flightsTable";

export class FlightsTable {
  @observable aggregations: string[] = ["rtMarket", "depMonth"];
  @observable columns: any[] = initColumns;
  @observable data: FlightEntry[] = [];
  @observable fixedColumns: FixedColumns = { aggregations: "left" };
  @observable groupStatuses: GroupStatus[] = groupStatuses;
  @observable influenceImpactGroupColumns: string[];
  @observable lastUpdated: string;
  @observable pagination: Pagination = { pageIndex: 0, pageSize: 25, pageCount: 1, totalRows: 0 };
  @observable selectedRows: string[] = [];
  @observable showOnlySelected: boolean = false;
  @observable sortBy: TableSortType = { field: "xDayRevenuePotential", direction: "asc" };
  @observable status: typeof Status = Status.INIT;

  rootStore: any;

  constructor(rootStore?: any, flightsTable?: FlightsTable) {
    if (rootStore) {
      this.rootStore = rootStore;
    }

    if (flightsTable) {
      const flightsTableToMerge = flightsTable.toJSON ? flightsTable.toJSON() : flightsTable;

      Object.assign(this, flightsTableToMerge);
    }
  }

  @action
  fetchFlightsData(params: GetFlightsData): AxiosPromise<FlightsResponse> {
    const { filters, conditionalFilters, tabId, xDayBuild } = params;
    const usersById = this.rootStore.analysisMappingsStore.analystGroupedByUserId;
    const { regionsById, subregionsById } = this.rootStore.regionsStore;
    const { pageIndex = 0, pageSize = this.pagination.pageSize, sortBy = this.sortBy } = params || {};

    this.status = Status.LOADING;
    this.pagination.pageSize = pageSize;
    this.pagination.pageIndex = pageIndex;
    this.sortBy = sortBy;

    const fetchParams: FlightsParams = {
      aggregations: this.aggregations,
      columns: columnsToRequest(this),
      conditionalFilters,
      filters,
      pagination: {
        size: pageSize,
        offset: pageIndex * pageSize
      },
      sortBy,
      xDayBuild,
      ...(this.showOnlySelected && {
        rowIds: getRowObjectIds(this.aggregations, this.selectedRows)
      })
    };

    return api
      .getFlights(fetchParams, tabId)
      .then(response => {
        const { rows, lastUpdated } = response.data;
        this.lastUpdated = lastUpdated;

        this.data = rows.map((row: FlightEntry) => {
          const analystIdInRow = row.analystId;
          const regionIdInRow = row.regionId;
          const subregionIdInRow = row.subregionId;

          return {
            ...row,
            analyst: getName(analystIdInRow, usersById, "label"),
            region: getName(regionIdInRow, regionsById),
            subregion: getName(subregionIdInRow, subregionsById)
          };
        });
        this.status = Status.DONE;

        return response;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.status = Status.LOADING;
          return;
        }
        this.status = Status.ERROR;
      });
  }

  @action
  fetchFlightsCountData(params: GetFlightsCountData) {
    const {
      conditionalFilters,
      filters,
      pageIndex = 0,
      pageSize = this.pagination.pageSize,
      sortBy = this.sortBy,
      tabId,
      xDayBuild
    } = params;

    const fetchParams: FlightCountParams = {
      aggregations: this.aggregations,
      conditionalFilters,
      filters,
      sortBy,
      pagination: {
        size: pageSize,
        offset: pageIndex * pageSize
      },
      xDayBuild,
      ...(this.showOnlySelected && {
        rowIds: getRowObjectIds(this.aggregations, this.selectedRows)
      })
    };

    return api.getFlightsCount(fetchParams, tabId).then(response => {
      const { numberOfRows } = response.data;

      const pageCount = Math.ceil(numberOfRows / pageSize);

      this.pagination.totalRows = numberOfRows;
      this.pagination.pageCount = pageCount;

      return response.data;
    });
  }

  @action
  toggleFixedColumn(column: string) {
    if (this.fixedColumns[column] === "left") {
      delete this.fixedColumns[column];
    } else {
      this.fixedColumns[column] = "left";
    }
  }

  @action
  resetFixedColumns(initialFixedColumns: FixedColumns) {
    this.fixedColumns = cloneDeep(initialFixedColumns);
  }

  @action
  flushData() {
    this.status = Status.INIT;
    this.data = [];
    this.showOnlySelected = false;
  }

  @action
  toggleShowOnlySelected() {
    this.showOnlySelected = !this.showOnlySelected;
  }

  @action
  hideColumn(columnId: string) {
    const allColumns = this.columns
      .map(([groupId, metrics]) => [groupId, ...metrics.map(metric => metric).flat()])
      .flat();
    this.columns = allColumns
      .filter(metric => metric !== columnId)
      .reduce((accumulator, currentValue) => {
        const isGroup = this.columns.find(([group]) => group === currentValue);

        if (isGroup) {
          return [...accumulator, [currentValue, []]];
        }

        const parentNode = this.columns.find(([, metrics]) => {
          return metrics.includes(currentValue);
        });

        if (!parentNode) {
          return accumulator;
        }
        return accumulator.map(([groupTitle, metrics]) =>
          groupTitle === parentNode[0] ? [groupTitle, [...metrics, currentValue]] : [groupTitle, metrics]
        );
      }, []);
  }

  @action
  changeFlightsTableParams(key, items, options = { refetch: true, skipSave: false }) {
    Object.assign(this, {
      ...(options.refetch && { data: [], status: Status.LOADING }),
      [key]: items
    });

    if (key === "aggregations") {
      this.columns = this.columns.map(([groupName, groupColumns]) => {
        const enabledColumns = groupColumns.filter(column => canEnableMetric(this.aggregations, column));
        return [groupName, enabledColumns];
      });
      this.showOnlySelected = false;
    }
  }

  toJSON(): FlightsTable {
    return JSON.parse(JSON.stringify(omit(this, ["rootStore"])));
  }
}
