import React, { useState } from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { Menu, Spinner } from "@blueprintjs/core";

import { useStores } from "../store/Store";

const StyledSpinner = styled(Spinner)`
  margin-top: 2px;
`;

type Props = {
  closePopover: Function;
  tabId: string;
};

function ShareMenuItem({ tabId, closePopover }: Props) {
  const { tabsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const loadingIcon = isLoading ? <StyledSpinner size={16} /> : "link";

  const onShare = () => {
    const timeout = setTimeout(() => {
      setIsLoading(true);
    }, 500);

    tabsStore.share(tabId).finally(() => {
      clearTimeout(timeout);
      setIsLoading(false);
      closePopover();
    });
  };
  return (
    <Menu.Item icon={loadingIcon} text="Copy shared link" onClick={onShare} shouldDismissPopover={false} />
  );
}

export default observer(ShareMenuItem);
