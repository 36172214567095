// @flow

import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import FilterGroups from "./AnalysisSidebarFilterGroups/FilterGroups";
import SearchInput from "./SearchInput";
import Sidebar from "./Sidebar";
import CirrusMarketFilters from "./CirrusSidebarFilterGroups/CirrusMarketFilters";
import CirrusFilters from "./CirrusSidebarFilterGroups/CirrusFilters";
import { useStores } from "../store/Store";
import focusOnFilter from "../helpers/focusOnFilter";

export const cirrusMetrics = [
  {
    key: "market",
    name: "Market",
    filters: [{ key: "odPairs" }],
    Component: CirrusMarketFilters
  },
  {
    key: "cirrus",
    name: "Cirrus",
    filters: [
      { key: "flightNumber_1" },
      { key: "depTime" },
      { key: "cabinClass" },
      { key: "departureDayOfWeek" },
      { key: "depDate" },
      { key: "fusionrmStatus" }
    ],
    Component: CirrusFilters
  }
];

type Props = {
  analystList: Array<string>,
  flightNumbers: Array<string>
};

function CirrusSidebar(props: Props) {
  const { analystList, flightNumbers } = props;
  const { cirrusStore } = useStores();
  const { sidebar, setSidebarFilterQuery, setSidebarOpen, submitSearchForm, decoratedPage } = cirrusStore;

  const { filterQuery, isOpen, filterKey } = sidebar;
  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  const filterGroupsProps = {
    analystList,
    filterGroups: cirrusMetrics,
    flightNumbers,
    pageContext: decoratedPage,
    type: "cirrus",
    withoutCollapsing: true
  };

  return (
    <Sidebar
      footer={<Button fill intent="primary" onClick={submitSearchForm} text="Apply" />}
      isOpen={isOpen}
      setOpen={value => setSidebarOpen(value)}
      ref={sidebarRef}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />
      <div className="p-3">
        <FilterGroups {...filterGroupsProps} />
      </div>
    </Sidebar>
  );
}

export default observer(CirrusSidebar);
