// @flow

import React from "react";
import { Tag } from "@blueprintjs/core";

import { categories, OTHER_CATEGORY_ID } from "./categories";

export const renderTagSelect = (selectedCategory: { value: number, label: string }) => {
  const backgroundColor = categories[selectedCategory.value || OTHER_CATEGORY_ID].color;
  return <Tag style={{ backgroundColor }}>{selectedCategory.label}</Tag>;
};
