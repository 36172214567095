// @flow

import { action, computed, observable } from "mobx";

import Auth from "./Auth.model";

import partnerConfig from "../config/config";
import api from "../services/Api";

const AuthStatus = {
  INIT: "INIT",
  LOADING: "LOADING",
  AUTHORIZED: "AUTHORIZED",
  UNAUTHORIZED: "UNAUTHORIZED",
  ERROR: "ERROR"
};

export class AppStore {
  @observable status: string = AuthStatus.INIT;
  @observable environment: ?string;
  @observable partner: ?string;

  @observable config: ?Object;
  @observable auth: ?Auth;

  constructor() {
    this.environment = process.env.REACT_APP_ENV || process.env.NODE_ENV;
    this.partner = process.env.REACT_APP_PARTNER || "ak";
  }

  @action
  init() {
    this.status = AuthStatus.LOADING;

    if (!window.crypto) {
      this.status = AuthStatus.ERROR;
      return;
    }

    if (partnerConfig) {
      this.config = partnerConfig;
      this.auth = new Auth(this.config);

      this.auth.initLock().then(() => {
        this.auth
          .fetchAccessToken()
          .then(() => {
            api.init(this.config, this.auth.accessToken, () => this.auth.logOut());
            this.status = AuthStatus.AUTHORIZED;
          })
          .catch(() => {
            this.status = AuthStatus.UNAUTHORIZED;
          });
      });
    } else {
      this.status = AuthStatus.ERROR;
    }
  }

  @action
  logIn() {
    this.status = AuthStatus.LOADING;
    this.auth
      .authorizeWithPopup()
      .then(() => {
        api.init(this.config, this.auth.accessToken);
        this.status = AuthStatus.AUTHORIZED;
      })
      .catch(() => {
        this.status = AuthStatus.UNAUTHORIZED;
      });
  }

  @action
  logInWithToken(accessToken: string) {
    this.status = AuthStatus.LOADING;
    this.auth
      .authorizeWithToken(accessToken)
      .then(() => {
        api.init(this.config, this.auth.accessToken);
        this.status = AuthStatus.AUTHORIZED;
      })
      .catch(() => {
        this.status = AuthStatus.UNAUTHORIZED;
      });
  }

  @action.bound
  logOut() {
    this.status = AuthStatus.LOADING;
    this.auth
      .logOut()
      .then(() => {
        this.status = AuthStatus.UNAUTHORIZED;
      })
      .catch(() => {
        this.status = AuthStatus.ERROR;
      });
  }

  @action
  resetPassword(email: string) {
    api.init(this.config);
    api.resetPassword(email);
  }

  @computed
  get isUninitialized(): boolean {
    return this.status === AuthStatus.INIT;
  }

  @computed
  get isLoading(): boolean {
    return this.status === AuthStatus.LOADING;
  }

  @computed
  get isError(): boolean {
    return this.status === AuthStatus.ERROR;
  }

  @computed
  get isLoggedIn(): boolean {
    return this.status === AuthStatus.AUTHORIZED;
  }

  @computed
  get isLoggedOut(): boolean {
    return this.status === AuthStatus.UNAUTHORIZED;
  }
}
