// @flow

import React, { useEffect, useRef } from "react";

import InfluenceControlTable from "./InfluenceControlTable";
import InfluenceControlForm from "./InfluenceControlForm";
import InfluenceControlDescription from "./InfluenceControlDescription";

type Props = {
  data: {
    influenceId: string,
    base: Object,
    preview: Object,
    previewCounters: Object
  },
  parameters: {
    comment: string,
    inputValue: number | Array<Object>,
    value: number | Array<Object>
  },
  selectedInfluence: {
    disabled: boolean,
    isPreview: boolean,
    rows: Array<{ key: string }>,
    type: string
  },
  state: {
    influenceStep: number,
    influenceType: string
  },
  status: string,
  updateParameters: Function
};

function InfluenceControlSave(props: Props) {
  const { data, parameters, selectedInfluence, state, status, updateParameters } = props;
  const { isPreview, rows } = selectedInfluence;

  const containerRef = useRef();

  useEffect(() => {
    if (containerRef.current && state.influenceStep === 3) {
      containerRef.current.querySelector("textarea").focus();
    }
  }, [state.influenceStep]);

  return (
    <div className="p-2" data-testid="influence-control-save" ref={containerRef}>
      <InfluenceControlDescription parameters={parameters} updateParameters={updateParameters} />
      <InfluenceControlForm data={data} parameters={parameters} readOnly state={state} status={status} />
      {isPreview ? <InfluenceControlTable data={data} status={status} rows={rows} /> : null}
    </div>
  );
}

export default InfluenceControlSave;
