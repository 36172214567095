import { observable, action, computed } from "mobx";

export class ThemeStore {
  @observable
  style = "light";

  @action
  setTheme(newTheme: string) {
    this.style = newTheme;
  }

  @computed
  get themeClass() {
    return `theme-${this.style}`;
  }
}
