import cloneDeep from "lodash.clonedeep";
import { action, computed, observable, toJS } from "mobx";
import { Intent } from "@blueprintjs/core";
import keyBy from "lodash.keyby";

import type { RootStore } from "./Root.model";
import { filterPersistentTab, Tab, TAB_INIT, TabStruct } from "./Tab.model";
import { MAX_TAB_COUNT } from "../helpers/constants";
import api from "../services/Api";
import { AppToaster } from "../services/Toaster";
import copyToClipboard from "../helpers/copyToClipboard";
import type { SharedAnalysis } from "../types/Tab.types";

const generateRandomId = () => Math.random().toString(36).substr(2, 9);

// eslint-disable-next-line no-restricted-globals
const generateShareLink = shareId => `${location.protocol}//${location.host}/share/${shareId}`;

const DEFAULT_CABIN_CLASS = ["Y"];

export class TabsStore {
  @observable
  tabs: Tab[] = [];

  rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  getTab(tabId: string): Tab {
    return this.tabs.find(tab => tab.id === tabId);
  }

  @action
  initTabs(tabs: Array<TabStruct>) {
    this.tabs = tabs.map(tab => new Tab(this, tab));
  }

  @action
  addTab() {
    const filtersState = this.createFiltersState();

    const newTab = {
      ...cloneDeep(TAB_INIT),
      ...filtersState,
      id: generateRandomId(),
      label: `Analysis ${this.tabs.length + 1}`
    };

    this.tabs.push(new Tab(this, newTab));
    this.saveTabs();
  }

  @action
  createTabFromView(savedView: Object, type: string) {
    const { id, label, view } = toJS(savedView, { recurseEverything: true });

    const newTab = filterPersistentTab({
      ...view,
      label,
      id: generateRandomId(),
      parentId: id,
      parentType: type
    });

    if (this.canCreateTab) {
      this.tabs.push(new Tab(this, newTab));
      this.saveTabs();
    }
  }

  @action
  duplicateTab(tabId: string) {
    const tab = this.getTab(tabId);
    if (!tab) return;

    const newTab = {
      ...tab.toJSON(),
      id: generateRandomId(),
      label: `Copy of ${tab.label}`,
      parentId: null,
      duplicatedFromSavedId: tab.parentId
    };

    const insertIndex = this.tabs.indexOf(tab) + 1;
    this.tabs.splice(insertIndex, 0, new Tab(this, newTab));
    this.saveTabs();
  }

  @action
  createTabFromSharedAnalysis(analysis: SharedAnalysis) {
    const filtersState = this.createFiltersState();
    const newTab = {
      ...cloneDeep(TAB_INIT),
      ...filtersState,
      ...analysis,
      id: generateRandomId(),
      label: `Analysis ${this.tabs.length + 1}`
    };

    this.tabs.push(new Tab(this, newTab));
    this.saveTabs();
  }

  @action
  removeTab(tabId: string) {
    this.tabs = this.tabs.filter(tab => tab.id !== tabId);
    this.saveTabs();
  }

  @action
  saveTabs() {
    this.rootStore.appStore.auth.editCurrentUser({
      tabs: this.tabs.map(filterPersistentTab)
    });
  }

  @action
  flushTabs() {
    this.tabs.forEach(tab => tab.flushTab());
  }

  @computed
  get lastTabId() {
    return this.tabs[this.tabs.length - 1].id;
  }

  @computed
  get tabsById() {
    return keyBy(this.tabs, "id");
  }

  createFiltersState() {
    const userId = this.rootStore.appStore.auth.user.id;
    const isAnalystWithMarkets = Boolean(this.rootStore.analysisMappingsStore.analystGroupedByUserId[userId]);

    const filters = {
      ...TAB_INIT.filters,
      cabinClass: DEFAULT_CABIN_CLASS
    };

    if (isAnalystWithMarkets) {
      filters.analystId = [userId];
    }

    return {
      applied: { filters },
      conditionalFilters: [],
      filters
    };
  }

  createSharedAnalysis(tab: Tab): SharedAnalysis {
    return {
      applied: tab.applied,
      buildCurves: {
        series: tab.buildCurves.series
      },
      filtersEnabled: tab.filtersEnabled,
      flightsTable: {
        aggregations: tab.flightsTable.aggregations,
        columns: tab.flightsTable.columns,
        fixedColumns: tab.flightsTable.fixedColumns,
        groupStatuses: tab.flightsTable.groupStatuses,
        pagination: {
          pageSize: tab.flightsTable.pagination.pageSize
        },
        sortBy: tab.flightsTable.sortBy
      },
      pivotTable: tab.pivotTable,
      sidebar: tab.sidebar
    };
  }

  @action
  share(tabId: string) {
    const tab = this.getTab(tabId);
    const analysis = this.createSharedAnalysis(tab);

    return api.shareAnalysis(analysis).then(response => {
      const shareLink = generateShareLink(response.data.shareId);

      copyToClipboard(shareLink);

      AppToaster.show({
        message: `The link has been copied to the clipboard`,
        intent: Intent.SUCCESS
      });
    });
  }

  @action
  getSharedAnalysis(shareId: string) {
    return api.getSharedAnalysis(shareId);
  }

  get canCreateTab() {
    return this.tabs.length < MAX_TAB_COUNT;
  }
}
