// @flow

import React from "react";
import { observer } from "mobx-react";

import SidebarSelect from "../SidebarSelect";
import SidebarFilter from "../SidebarFilter";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  mappings: {
    data: Array,
    getOneWayMarkets: Function
  },
  pageContext: Object
};

function IHMarketFilters(props: Props) {
  const { mappings, pageContext } = props;
  const { filters, changeFilter, sidebar } = pageContext;

  const owMarkets = mappings.getOneWayMarkets(null);

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("ow market one-way market", "owMarket", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={owMarkets}
          onChange={filterValue => changeFilter("owMarket", filterValue)}
          selectedItems={filters.owMarket}
          title="OW Market"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(IHMarketFilters);
