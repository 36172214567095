import React from "react";
import { Classes, Tag } from "@blueprintjs/core";
import { format, isValid, parseISO } from "date-fns";

import { useStores } from "../store/Store";
import { filterTitleFromArray } from "../helpers/filterTitleValue";

type Props = {
  additionalTagLabels: ?Object,
  clearable: boolean,
  customValueRenderer: ?Function,
  name: ?string,
  onValueClick: ?Function,
  onValueRemove: Function,
  title: string,
  value: string | number | boolean | Array<string>
};

function ChipContent(props: Props) {
  const {
    value,
    title,
    name,
    additionalTagLabels,
    customValueRenderer,
    onValueClick,
    onValueRemove,
    clearable = true
  } = props;

  const { systemUnitsStore } = useStores();
  const { computedDateFormat } = systemUnitsStore;

  const onRemove = (e: Event, option: ?string) => {
    e && e.stopPropagation();
    onValueRemove(name, option);
  };

  const renderValue = value => {
    const type = typeof value;
    const isValueArray = Array.isArray(value);
    let result;

    const baseTagProps = {
      className: `d-flex mr-1 mb-1 ${Classes.POPOVER_DISMISS}`,
      "data-testid": "filter-value-tag",
      ...(onValueClick && { interactive: true, onClick: onValueClick }),
      ...(clearable && { onRemove: e => onRemove(e) })
    };

    if (customValueRenderer) {
      if (isValueArray) {
        return value.map(option => {
          const customTagArrayProps = {
            ...baseTagProps,
            key: option,
            ...(clearable && { onRemove: e => onRemove(e, option) })
          };
          return <Tag {...customTagArrayProps}>{customValueRenderer(option)}</Tag>;
        });
      }
      return <Tag {...baseTagProps}>{customValueRenderer(value)}</Tag>;
    }

    if (type === "string" || type === "number") {
      result = value;
    }

    if (type === "boolean") {
      result = value ? "Yes" : "No";
    }

    if (value && value.label) {
      result = value.label;
    }

    if (isValueArray) {
      return value.map(option => {
        let tagText = filterTitleFromArray(option, name, option);
        if (additionalTagLabels && additionalTagLabels[tagText]) {
          tagText = additionalTagLabels[tagText];
        }
        const tagArrayProps = {
          ...baseTagProps,
          key: tagText,
          ...(clearable && { onRemove: e => onRemove(e, option) })
        };

        return <Tag {...tagArrayProps}>{tagText}</Tag>;
      });
    }

    if (value && (value.start != null || value.end != null)) {
      const parsedStartDate = parseISO(value.start);
      const parsedEndDate = parseISO(value.end);
      const isValidDate = isValid(parsedStartDate) || isValid(parsedEndDate);
      if (isValidDate) {
        result = `${format(parsedStartDate, computedDateFormat)} – ${format(parsedEndDate, computedDateFormat)}`;
      } else {
        result = `${value.start} – ${value.end}`;
      }
    }

    return <Tag {...baseTagProps}>{result}</Tag>;
  };

  return (
    <>
      <h5 className="bp3-heading">{`Filter by ${title}`}</h5>
      <div className="d-flex flex-wrap">{renderValue(value)}</div>
    </>
  );
}

export default ChipContent;
