// @flow

import React from "react";
import styled from "@emotion/styled";
import { Spinner } from "@blueprintjs/core";
import kebabCase from "lodash.kebabcase";

import { previewInfluenceGroup } from "../../helpers/metricLabels/constants";
import { metricsTypes } from "../../helpers/constants";
import formatValueWithUnit from "../../helpers/FormatValueWithUnit";
import isNumber from "../../helpers/isNumber";

const BaseTd = styled("td")`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5c7080 !important;
  ${({ isStriked }) => isStriked && "text-decoration: line-through"};
`;

const PreviewTd = styled(BaseTd)`
  text-decoration: none;
  font-weight: bold;
  color: #a854a8 !important;
`;

type Props = {
  data: {
    influenceId: string,
    base: Object,
    preview: Object,
    previewCounters: Object
  },
  rows: Array<{ key: string }>,
  status: string
};

function InfluenceControlTable(props: Props) {
  const { data, status, rows = [] } = props;

  const spinner = <Spinner className="d-flex justify-content-end influence-control-table-spinner" size={18} />;

  function renderValue(aggregated: Object, key: string) {
    if (!aggregated || !isNumber(aggregated[key])) {
      return "-";
    }
    return formatValueWithUnit(aggregated[key], metricsTypes[key]);
  }

  const tableRows = rows
    .filter(row => row.key !== "numberOfImpactedFlights")
    .map(row => {
      const { key } = row;
      const label = previewInfluenceGroup[key];
      const testId = `influence-control-table-${kebabCase(label)}`;
      const { base = {}, preview = {} } = data;

      return (
        <tr key={key} data-testid={testId}>
          <td>{label}</td>
          <BaseTd className="text-right" isStriked={status === "DONE_PREVIEW"} width="110">
            {status === "LOADING_BASE" ? spinner : renderValue(base.aggregated, key)}
          </BaseTd>
          <PreviewTd className="text-right" width="110">
            {status === "LOADING_PREVIEW" ? spinner : renderValue(preview.aggregated, key)}
          </PreviewTd>
        </tr>
      );
    });

  return (
    <table className="bp3-html-table bp3-html-table-condensed bp3-html-table-striped w-100">
      <tbody>{tableRows}</tbody>
    </table>
  );
}

export default InfluenceControlTable;
