// @flow

import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useStores } from "../store/Store";

import FilterGroups from "./AnalysisSidebarFilterGroups/FilterGroups";
import SearchInput from "./SearchInput";
import Sidebar from "./Sidebar";
import { influenceHistoryMetrics } from "../helpers/influenceHistoryMetrics";
import type { AnalysisMappingsStore } from "../models/AnalysisMappings.model";
import focusOnFilter from "../helpers/focusOnFilter";
import SidebarApplyButton from "./SidebarApplyButton";

type Props = {
  computedDateFormat: string,
  computedDateMonthFormat: string,
  creators: Object,
  mappings: AnalysisMappingsStore
};

function InfluenceHistorySidebar(props: Props) {
  const { computedDateFormat, computedDateMonthFormat, creators, mappings } = props;
  const { influenceHistoryStore } = useStores();
  const { decoratedPage, page, setSidebarFilterQuery, setSidebarOpen, submitSearchForm } = influenceHistoryStore;
  const { isOpen, filterQuery, filterKey } = page.sidebar;

  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  const onSubmit = () => {
    setSidebarOpen(false);
    setSidebarFilterQuery("");
    submitSearchForm();
  };

  return (
    <Sidebar
      footer={<SidebarApplyButton onSubmit={onSubmit} pageContext={decoratedPage} type="history" />}
      isOpen={isOpen}
      setOpen={value => setSidebarOpen(value)}
      ref={sidebarRef}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />

      <FilterGroups
        creators={creators}
        filterGroups={influenceHistoryMetrics}
        filterQuery={filterQuery}
        mappings={mappings}
        pageContext={decoratedPage}
        computedDateFormat={computedDateFormat}
        computedDateMonthFormat={computedDateMonthFormat}
        type="history"
      />
    </Sidebar>
  );
}

export default observer(InfluenceHistorySidebar);
