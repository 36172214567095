import React from "react";
import styled from "@emotion/styled";
import { Button, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Tab } from "../models/Tab.model";

const StyledTooltip = styled(Tooltip)`
  &,
  .bp3-popover-target {
    width: 100%;
  }
`;

const StyledActionButton = styled(Button)`
  height: 30px;
  display: flex;
  width: 100%;
`;

type Props = {
  pageContext: Tab
};

export function FilterActionButtons(props: Props) {
  const { clearAllFilters, resetFiltersToDefaults } = props.pageContext;

  const clearAll = () => clearAllFilters();
  const reset = () => resetFiltersToDefaults();

  return (
    <div className="d-flex justify-content-between m-2">
      <StyledTooltip content="Reset all filters to a default state" hoverOpenDelay={2000}>
        <StyledActionButton onClick={reset}>Reset</StyledActionButton>
      </StyledTooltip>
      <StyledTooltip className="ml-2" content="Remove all filters" hoverOpenDelay={2000}>
        <StyledActionButton onClick={clearAll}>Clear All</StyledActionButton>
      </StyledTooltip>
    </div>
  );
}

export default observer(FilterActionButtons);
