// @flow

import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";

import columnTextWidths from "../helpers/ColumnTextWidths";
import Module from "./Module";
import DataTable from "./DataTable";
import StatusIndicator from "./StatusIndicator";
import CirrusTableActions from "./CirrusTableActions";
import { Status } from "../helpers/Status";

type Props = {
  actionsDisabled: Boolean,
  columns: Array<Object>,
  computedDateFormat: string,
  data: Array<Object>,
  dataStats: Object,
  fetchData: ?Function,
  pagination: Object,
  selectedRows: Array<string>,
  status: string
};

type CellProps = {
  value: string
};

function CirrusTable(props: Props) {
  const { actionsDisabled, computedDateFormat, dataStats = {}, pagination, selectedRows, status } = props;

  const columnLabels = {
    origin: "Origin",
    destination: "Destination",
    flightNumber_1: "Flight Number",
    departureDate: "Departure Date",
    fusionrmStatus: "On/Off",
    cabinClass: "Cabin Class",
    departureDayOfWeek: "Dep DOW",
    flights: "Flights",
    status: "Status",
    owMarket: "OW Market",
    depTime: "Departure Time"
  };

  const cellRenderers = {
    fusionrmStatus: ({ value }: CellProps) => <StatusIndicator isActive={value} />,
    departureDate: ({ value }): CellProps => format(parseISO(value), computedDateFormat),
    departureDayOfWeek: "day-week-long",
    owMarket: ({ row }) => {
      const { original } = row;
      return `${original.origin}${original.destination}`;
    }
  };

  const columnWidths = {};
  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), [columnLabels]);

  const getRowIdCallback = row => {
    const status = row.fusionrmStatus ? "on" : "off";
    return `${row.flightId}_${status}`;
  };

  const tableProps = {
    ...props,
    cellRenderers,
    columnLabels,
    columnTextWidth,
    columnWidths,
    sortable: false,
    getRowIdCallback
  };

  const flightsCount = pagination.totalRows;
  const isLoaded = status === Status.DONE;
  const subtitle = `${dataStats.enabled} of ${flightsCount} flights turned on`;

  return (
    <Module
      title="Cirrus On/Off"
      className="h-100"
      titleTools={
        isLoaded && (
          <CirrusTableActions selectedRows={selectedRows} flightsCount={dataStats} disabled={actionsDisabled} />
        )
      }
      subtitle={isLoaded ? [subtitle && `· ${subtitle}`] : null}
    >
      <DataTable {...tableProps} />
    </Module>
  );
}

export default observer(CirrusTable);
