// @flow

import type { AxiosPromise } from "axios";
import { parseISO, startOfToday } from "date-fns";
import { action, observable } from "mobx";

import api from "../services/Api";

export class TimeStore {
  @observable carrierTimezone: string = "";
  @observable todayDate: ?Date = undefined;

  @action getKPIsTimestamp(): AxiosPromise<> {
    return api
      .getKPIsTimestamp()
      .then(({ data }) => {
        this.carrierTimezone = data.carrierTimezone;
        this.todayDate = parseISO(data.kpisTimestamp.slice(0, 10));
      })
      .catch(() => {
        this.todayDate = startOfToday();
      });
  }
}
