// @flow

import { action, computed, observable } from "mobx";
import isEmpty from "lodash.isempty";
import api from "../services/Api";

// "x" is set to be able to replace it with its delimiter at the later stage
export const dateOptions = [
  { label: "YYYYMMDD", value: `YYYYxMMxDD` },
  { label: "YYMMDD", value: `YYxMMxDD` },
  { label: "DDMMYYYY", value: `DDxMMxYYYY` },
  { label: "DDMMYY", value: `DDxMMxYY` },
  { label: "MMDDYYYY", value: `MMxDDxYYYY` },
  { label: "MMDDYY", value: `MMxDDxYY` }
];

export const delimiterOptions = [
  { label: "-", value: "-" },
  { label: "/", value: "/" },
  { label: ".", value: "." },
  { label: "none", value: "" }
];

export class SystemUnitsStore {
  @observable distanceUnit = "";
  @observable dateFormat = "";
  @observable dateDelimiter = "";

  @action getDistanceUnit() {
    return api.getDistanceUnit().then(response => {
      this.distanceUnit = response.data.value;
      return response.data.value;
    });
  }

  @action setDistanceUnit(unit: string) {
    api.setDistanceUnit(unit).then(() => {
      this.distanceUnit = unit;
    });
  }

  @action getDateFormat() {
    return api.getDateFormat().then(response => {
      const date = response.data.value;
      const withoutDelimiters = date.replace(/[^DMY]/g, "");
      const [dateDelimiter = ""] = date.replace(/[DMY]/g, "");
      const dateFormat = dateOptions.find(option => option.label === withoutDelimiters).value;

      this.dateFormat = dateFormat;
      this.dateDelimiter = dateDelimiter;

      return { dateFormat, dateDelimiter };
    });
  }

  @action setDateFormat({ dateDelimiter, dateFormat }) {
    const delimiter = dateDelimiter || "";
    const date = dateFormat.replace(/x/g, delimiter);

    api.setDateFormat(date).then(() => {
      this.dateFormat = dateFormat;
      this.dateDelimiter = dateDelimiter;
    });
  }

  hasInitialStateChanged(unitsState) {
    const changes = this.compareInitialStateWith(unitsState);

    return !isEmpty(Object.values(changes).filter(Boolean));
  }

  @action saveAll(unitsState) {
    const { distanceUnit, dateFormat, dateDelimiter } = unitsState;
    const changes = this.compareInitialStateWith(unitsState);

    return Promise.all([
      changes.distance && this.setDistanceUnit(distanceUnit),
      changes.date && this.setDateFormat({ dateDelimiter, dateFormat })
    ]);
  }

  compareInitialStateWith(unitsState) {
    const {
      distanceUnit = this.distanceUnit,
      dateFormat = this.dateFormat,
      dateDelimiter = this.dateDelimiter
    } = unitsState;

    const hasDistanceChanged = distanceUnit !== this.distanceUnit;
    const hasDateChanged = dateFormat !== this.dateFormat || dateDelimiter !== this.dateDelimiter;

    return {
      date: hasDateChanged,
      distance: hasDistanceChanged
    };
  }

  @computed
  get computedDateFormat() {
    if (this.dateFormat) {
      const parsedFormat = this.dateFormat;
      return parsedFormat.toLowerCase().replace(/m/g, "M").replace(/x/g, this.dateDelimiter);
    }

    return "yyyy-MM-dd";
  }

  @computed
  get computedDateMonthFormat() {
    if (this.dateFormat) {
      const parsedFormat = this.dateFormat;
      return parsedFormat
        .toLowerCase()
        .replace(/xdd/g, "")
        .replace(/ddx/g, "")
        .replace(/m/g, "M")
        .replace(/x/g, this.dateDelimiter);
    }

    return "yyyy-MM";
  }
}
