// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { FormGroup, InputGroup, Button, ControlGroup, HTMLSelect } from "@blueprintjs/core";

import Module from "./Module";

const UserDetailsStyled = styled("div")`
  padding: 0.75rem;

  img {
    width: 80px;
    max-height: 80px;
    border-radius: 50%;
  }
  .bp3-form-group {
    .bp3-label {
      min-width: 150px;
    }
    .bp3-form-content {
      flex-grow: 1;
    }
  }
`;

type Props = {
  title: string,
  user: Object,
  userGroups: ?Array<Object>,
  editUser: Function,
  resetPassword: ?Function,
  closePanel: ?Function
};

function UserDetails(props: Props) {
  const { title = "", user, userGroups = [], editUser = null, resetPassword = null, closePanel = null } = props;

  const rolesOptions = userGroups.map(({ name }) => ({
    value: name,
    label: name
  }));

  const { nickname, name: userFullName, group = {}, userId } = user;

  const [name, setName] = useState(userFullName || nickname);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState(group);

  function saveChanges() {
    editUser(userId, { name, group: role });
    closePanel && closePanel();
  }

  const closeButton = closePanel ? (
    <Button icon="cross" data-testid="close-button" minimal onClick={() => closePanel()} />
  ) : null;

  const footer = (
    <UserDetailsStyled className="d-flex justify-content-end align-items-center py-2">
      {resetPassword ? (
        <Button
          className="mr-2"
          minimal
          disabled
          icon="reset"
          onClick={() => resetPassword(user)}
          text="Reset Password"
        />
      ) : null}
      {editUser ? <Button intent="primary" className="ml-auto px-3" onClick={() => saveChanges()} text="Save" /> : null}
    </UserDetailsStyled>
  );

  const content = (
    <UserDetailsStyled className="d-flex flex-column">
      <div className="d-flex flex-column w-100">
        <FormGroup className="font-weight-bold" label="Email" labelFor="email-input-details">
          <InputGroup
            disabled
            id="email-input-details"
            onChange={e => setEmail(e.target.value)}
            type="email"
            value={email}
          />
        </FormGroup>
        <FormGroup className="font-weight-bold" label="Full Name" labelFor="full-name-input-details">
          <InputGroup id="full-name-input-details" value={name} onChange={e => setName(e.target.value)} />
        </FormGroup>
        <FormGroup className="font-weight-bold mb-1" label="Role" labelFor="role-input">
          <ControlGroup>
            <HTMLSelect
              data-testid="role-select"
              className="w-50 p-0"
              id="role-input"
              options={rolesOptions}
              onChange={e => setRole(e.currentTarget.value)}
              value={role}
              disabled
            />
          </ControlGroup>
        </FormGroup>
      </div>
    </UserDetailsStyled>
  );

  return (
    <Module title={title} titleTools={closeButton} footer={footer}>
      {content}
    </Module>
  );
}

export default observer(UserDetails);
