// @flow

import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";

import FilterGroups from "../AnalysisSidebarFilterGroups/FilterGroups";
import focusOnFilter from "../../helpers/focusOnFilter";
import SearchInput from "../SearchInput";
import Sidebar from "../Sidebar";
import SidebarApplyButton from "../SidebarApplyButton";
import { eventsManagementMetrics } from "../../helpers/eventsManagementMetrics";
import { useStores } from "../../store/Store";

type Props = {
  computedDateFormat: string,
  computedDateMonthFormat: string
};

function EventsManagementSidebar(props: Props) {
  const { computedDateFormat, computedDateMonthFormat } = props;
  const { analysisMappingsStore, eventsManagementStore } = useStores();
  const { decoratedPage, page, setSidebarFilterQuery, setSidebarOpen, submitSearchForm } = eventsManagementStore;
  const { filterKey, filterQuery, isOpen } = page.sidebar;

  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    focusOnFilter(filterKey, filterQuery, sidebarRef);
  }, [filterKey, filterQuery]);

  const onSubmit = () => {
    setSidebarOpen(false);
    setSidebarFilterQuery("");
    submitSearchForm();
  };

  return (
    <Sidebar
      footer={<SidebarApplyButton onSubmit={onSubmit} pageContext={decoratedPage} type="events" />}
      isOpen={isOpen}
      setOpen={value => setSidebarOpen(value)}
      ref={sidebarRef}
    >
      <SearchInput onChange={setSidebarFilterQuery} value={filterQuery} />

      <FilterGroups
        computedDateFormat={computedDateFormat}
        computedDateMonthFormat={computedDateMonthFormat}
        filterGroups={eventsManagementMetrics}
        filterQuery={filterQuery}
        mappings={analysisMappingsStore}
        pageContext={decoratedPage}
        type="events"
      />
    </Sidebar>
  );
}

export default observer(EventsManagementSidebar);
