// @flow

import React, { useEffect, useRef } from "react";
import { NonIdealState } from "@blueprintjs/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import { useStores } from "../store/Store";
import { deepEqual } from "../helpers/deepEqual";
import { Status } from "../helpers/Status";
import { transformInfluenceData } from "../helpers/transformInfluenceData";

import InfluenceModule from "../components/InfluenceControls/InfluenceModule";
import Module from "../components/Module";
import Row from "../components/Row";
import { Tab } from "../models/Tab.model";

// TODO remove hardcode!
const forcedInfluenceGroup = [
  "influenceGroup",
  ["numberOfImpactedFlights", "priceAdjustment", "minmaxAdjustmentMin", "minmaxAdjustmentMax"]
];

type Props = {
  tab: Tab
};

const InfluenceRow = styled(Row)`
  flex: 0 1 350px;
  min-height: 350px;
`;

function Influence(props: Props) {
  const { influenceStore } = useStores();
  const { data, parameters, state, status } = influenceStore;

  const { tab } = props;
  const { flightsTable = {} } = tab;
  const originalInfluenceGroupRef = useRef();

  // mount
  useEffect(() => {
    originalInfluenceGroupRef.current =
      flightsTable.columns && flightsTable.columns.find(col => col[0] === "influenceGroup");

    const forcedColumns =
      flightsTable.columns &&
      flightsTable.columns.map(col => {
        if (col[0] === "influenceGroup") {
          return forcedInfluenceGroup;
        }
        return col;
      });

    tab.changeFlightsTableParams("columns", forcedColumns, {
      refetch: true,
      savePageIndex: true,
      skipSave: true
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // un-mount
  useEffect(() => {
    return () => {
      runInAction(() => {
        tab.resetInfluence();

        const restoredColumns =
          flightsTable.columns &&
          flightsTable.columns.map(col => {
            if (col[0] === "influenceGroup") {
              return originalInfluenceGroupRef.current;
            }
            return col;
          });

        tab.changeFlightsTableParams("columns", restoredColumns, {
          refetch: true,
          savePageIndex: true,
          skipSave: true
        });
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // merge data on data load
  if (flightsTable.status === Status.DONE && state.influenceType && ["DONE_BASE", "DONE_PREVIEW"].includes(status)) {
    if (deepEqual(parameters.value, parameters.inputValue)) {
      const mergedData = transformInfluenceData({
        columns: influenceStore.columnsList,
        influenceData: data,
        flightsTable: tab.flightsTable
      });

      // check for infinite loop in Influence
      if (
        JSON.stringify(flightsTable.influenceImpactGroupColumns) !==
          JSON.stringify(influenceStore.columnsListForFlightsTable) ||
        JSON.stringify(flightsTable.data) !== JSON.stringify(mergedData)
      ) {
        tab.updatePreviewData(influenceStore.columnsListForFlightsTable.slice(0), mergedData);
      }
    }
  }

  const influenceModuleProps = {
    data,
    finishInfluence: tab.finishInfluence,
    influencesList: influenceStore.influencesList,
    parameters,
    previewInfluence: influenceStore.previewInfluence,
    resetInfluence: tab.resetInfluence,
    saveInfluence: influenceStore.saveInfluence,
    selectedInfluence: influenceStore.selectedInfluence,
    state,
    status,
    tab,
    updateParameters: influenceStore.updateParameters,
    updateState: influenceStore.updateState
  };

  return (
    <InfluenceRow className="mb-2">
      <div className="col-4 h-100">
        <Module className="h-100" childrenClassName="placeholder-center" title="Seat Allocations">
          <NonIdealState icon="chart" />
        </Module>
      </div>
      <div className="col-4 h-100">
        <Module className="h-100" childrenClassName="placeholder-center" title="Price Distribution">
          <NonIdealState icon="chart" />
        </Module>
      </div>
      <div className="col-4 h-100">
        <InfluenceModule {...influenceModuleProps} />
      </div>
    </InfluenceRow>
  );
}

export default observer(Influence);
