import EventsMarketFilters from "../components/EventsManagement/EventsMarketFilters";
import EventFilters from "../components/EventsManagement/EventFilters";

export const eventsManagementMetrics = [
  {
    key: "market",
    name: "Market",
    filters: [{ key: "owMarket" }, { key: "rtMarket" }, { key: "origin" }, { key: "destination" }],
    Component: EventsMarketFilters
  },
  {
    key: "event",
    name: "Event",
    filters: [{ key: "categoryIds" }, { key: "dateRange" }, { key: "userIds" }],
    Component: EventFilters
  }
];

export const filterOrder = eventsManagementMetrics.map(({ filters }) => filters.map(({ key }) => key)).flat();
