// @flow

import cloneDeep from "lodash.clonedeep";
import pluralize from "pluralize";

import { isCurrency, isPercent, replaceRegex } from "./constants";
import { metricsTypes } from "../constants";

type LongVersionProps = {
  group: Object,
  parent: string,
  xDayBuild: number
};

const overrides = {
  netBookings: "Net Bookings Actual",
  netBookingsBaseline: "Net Bookings Baseline",
  netBookingsDiffToBaseline: "Net Bookings Diff to Baseline",
  xDayNetBookings: "Net Bookings Actual X Days Ago",
  xDayNetBookingsBaseline: "Net Bookings Baseline X Days Ago",
  xDayNetBookingsDiffToBaseline: "Net Bookings Diff to Baseline X Days Ago",
  xDayNetBookingsBuild: "Net Bookings Actual X Days Build",
  xDayNetBookingsBuildBaseline: "Net Bookings Baseline X Days Build",
  xDayNetBookingsBuildDiffToBaseline: "Net Bookings Diff to Baseline X Days Build",
  aircraftCapacity: "Physical Capacity",
  sellableCapacity: "Sellable Capacity",
  xDayAircraftCapacity: "Physical Capacity X Days Ago",
  xDaySellableCapacity: "Sellable Capacity X Days Ago",
  xDayAircraftCapacityBuild: "Physical Capacity X Days Build",
  xDaySellableCapacityBuild: "Sellable Capacity X Days Build",
  xDayLacRbd: "Booking Class X Days Ago",
  xDayLacRbdFrm: "Booking Class Cirrus X Days Ago",
  lacSeatsAvailable: "Seats Available at LAC",
  depTime: "Departure Time",
  distance: "Distance",
  xDayLacRank: "LAC Value X Days Ago",
  compFareCirrusMatchAirline: "Airline operating Cirrus - matched Fare",
  compFareCirrusMatchFare: "Cirrus - matched Fare",
  compFareTimeMatchFare: "Time Period - matched Fare",
  compFareTimeMatchAirline: "Airline operating Time Period - matched Fare",
  compFareDateMatchFare: "Departure Date - matched Fare",
  compFareDateMatchAirline: "Airline operating Departure Date - matched Fare",
  sellingFareDiffToCirrusCompFarePercentile: "Selling Fare Diff to Cirrus-matched Competitor Fare",
  sellingFareDiffToCirrusCompFare: "Selling Fare Diff to Cirrus-matched Competitor Fare",
  sellingFareDiffToTimePeriodCompFarePercentile: "Selling Fare Diff to Time Period-matched Competitor Fare",
  sellingFareDiffToTimePeriodCompFare: "Selling Fare Diff to Time Period - matched Competitor Fare",
  sellingFareDiffToDepDateCompFarePercentile: "Selling Fare Diff to Departure Date - matched Competitor Fare",
  sellingFareDiffToDepDateCompFare: "Selling Fare Diff to Departure Date - matched Competitor Fare",
  xDayRevenuePerBooking: "Revenue per Booking Actual X Days Ago",
  xDayRevenuePerBookingBaseline: "Revenue per Booking Baseline X Days ago",
  xDayRevenuePerBookingBuild: "X Day Revenue per Booking Actual Build",
  xDayRevenuePerBookingBuildBaseline: "X Day Revenue per Booking Baseline Build",
  xDayRevenuePerBookingBuildDiffToBaseline: "X Day Revenue per Booking Diff to Baseline Build"
};

export default function metricLongVersion(props: LongVersionProps) {
  const { group, parent, xDayBuild } = props;
  const newGroup = cloneDeep(group);

  Object.entries(group).forEach((props: Object) => {
    const [key: string, value: string] = props;

    let newValue = value;
    const buildKey = `${xDayBuild}d`;
    const replaces = {
      [buildKey]: `${pluralize("Day", xDayBuild, true)} Ago`,
      bld: "Build",
      act: "Actual",
      base: "Baseline",
      exp: "Expected",
      Δ: "Diff",
      comp: "Competitor",
      sens: "Sensitivity",
      " %ile": "",
      adj: "Adjustment",
      "flt impacted": "Number of Impacted Flights",
      RBD: "Booking Class"
    };

    const labelUnit = metricsTypes[key];

    newValue = newValue.replace(".", "");

    if (overrides[key]) {
      newValue = overrides[key]
        .replace("X Days", pluralize("Day", xDayBuild, true))
        .replace("X Day", `${xDayBuild} Day`);
    }

    Object.entries(replaces).forEach((props: Object) => {
      const [replaceKey: string, replaceValue: string] = props;

      if (replaceKey === buildKey && (newValue.includes("Bld") || newValue.includes("Build"))) {
        newValue = newValue.replace(replaceRegex(replaceKey), pluralize("Day", xDayBuild, true));
      } else if (replaceKey === buildKey && key === "xDayLacRankFrm") {
        newValue = `${newValue.replace(replaceRegex(` ${replaceKey}`), "")} ${replaceValue}`;
      } else if (newValue.toLowerCase().includes(replaceKey.toLowerCase())) {
        newValue = newValue.replace(replaceRegex(replaceKey), replaceValue);
      }
    });

    if (newValue.includes(replaces.exp)) {
      const textWithoutParent = newValue.split(`${newGroup[parent]} `)[1];
      const splittedText = textWithoutParent.split(" ");
      const indexOfExpected = splittedText.indexOf(replaces.exp);
      const newState = [
        replaces.exp,
        ...splittedText.slice(0, indexOfExpected),
        ...splittedText.slice(indexOfExpected + 1)
      ];
      newValue = `${newGroup[parent]} ${newState.join(" ")}`;
    }

    const unit = !newValue.includes("%ile") && (isPercent(labelUnit, true) || isCurrency(labelUnit, true));

    newGroup[key] = `${newValue}${unit ? ` ${unit}` : ""}`;
  });

  return newGroup;
}
