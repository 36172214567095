import { action, computed, observable } from "mobx";
import { Intent } from "@blueprintjs/core";

import api from "services/Api";
import { AppToaster } from "services/Toaster";
import { Status } from "../helpers/Status";

export class UsersStore {
  @observable
  usersList = {
    currentUserStatus: Status.INIT,
    status: Status.INIT,
    columns: ["name", "email", "emailVerified", "group"],
    data: [],
    pagination: {
      pageIndex: 0,
      pageSize: 50,
      pageCount: 1,
      totalRows: 0
    }
  };

  @observable
  userGroups = {
    status: Status.INIT,
    data: []
  };

  @action.bound
  fetchUsers() {
    this.usersList.status = Status.LOADING;
    api
      .getUsers()
      .then(response => {
        const pageCount = Math.ceil(response.data.length / this.usersList.pagination.pageSize);

        this.usersList.data = response.data;
        this.usersList.pagination.pageCount = pageCount;
        this.usersList.status = Status.DONE;
      })
      .catch(() => {
        this.usersList.status = Status.ERROR;
      });
  }

  @action.bound
  fetchUserGroups() {
    this.userGroups.status = Status.LOADING;
    api
      .getUserGroups()
      .then(response => {
        this.userGroups.data = response.data;
        this.userGroups.status = Status.DONE;
      })
      .catch(() => {
        this.userGroups.status = Status.ERROR;
      });
  }

  @action.bound
  addUser(user: Object) {
    this.usersList.status = Status.LOADING;
    api
      .addUser(user)
      .then(() => {
        this.fetchUsers();
      })
      .catch(() => {
        AppToaster.show({
          message: "Error adding user",
          intent: Intent.DANGER
        });
        this.fetchUsers();
      });
  }

  @action.bound
  deleteUser(userId: string) {
    this.usersList.status = Status.LOADING;
    api
      .deleteUser(userId)
      .then(() => {
        this.fetchUsers();
      })
      .catch(() => {
        AppToaster.show({
          message: "Error deleting user",
          intent: Intent.DANGER
        });
        this.fetchUsers();
      });
  }

  @action.bound
  editUser(userId: string, metadata: Object) {
    this.usersList.status = Status.LOADING;
    api
      .editUser(userId, metadata)
      .then(() => {
        this.fetchUsers();
      })
      .catch(() => {
        AppToaster.show({
          message: "Error editing user",
          intent: Intent.DANGER
        });
        this.fetchUsers();
      });
  }

  @action
  editCurrentUser(name: string, action) {
    this.usersList.currentUserStatus = Status.LOADING;
    new Promise((resolve, reject) => action(name, resolve, reject))
      .then(() => {
        this.usersList.currentUserStatus = Status.DONE;
        this.fetchUsers();
      })
      .catch(() => {
        this.usersList.currentUserStatus = Status.ERROR;
      });
  }

  @computed
  get isLoadingUsers() {
    const { usersList } = this;
    return usersList.status === Status.LOADING;
  }

  @computed
  get analystList() {
    const { usersList } = this;
    const unassignedUser = { label: "Unassigned", value: null };

    if (usersList.status === Status.DONE) {
      return [
        unassignedUser,
        ...usersList.data
          .map(({ name, id }) => ({
            label: name,
            value: id
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      ];
    }
    return [];
  }
}
