import React, { useState } from "react";
import { Button, Menu, MenuDivider, MenuItem, Popover, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";

type Props = {
  columnId: string,
  isFrozenColumn: boolean,
  isGroupColumn: boolean,
  onColumnHide: Function,
  onColumnFreeze: Function,
  onColumnFilter: Function,
  onColumnSort: Function
};

const StyledContextMenuButton = styled(Button)`
  transform: rotate(90deg);
`;

const StyledPopoverWrapper = styled("div")`
  ${({ isOpen }) =>
    isOpen &&
    `opacity: 1!important;
     margin-right: -0.5rem;
     width: 30px!important`};

  button {
    border-radius: 0;
    padding: 0;
    outline: none;

    &:hover,
    &:focus {
      background: none !important;
    }
  }
`;

function HeaderContextMenu(props: Props) {
  const { columnId, isFrozenColumn, isGroupColumn, onColumnFreeze, onColumnHide, onColumnFilter, onColumnSort } = props;
  const [isOpen, setIsOpen] = useState(false);

  const hideColumn = () => {
    setIsOpen(false);
    onColumnHide && onColumnHide();
  };

  const renderGroupMenuContent = () => {
    if ([onColumnFreeze, onColumnHide].every(fn => !fn)) {
      return null;
    }

    const freezeText = isFrozenColumn ? "Unfreeze Section" : "Freeze Section";
    return (
      <Menu>
        {onColumnFreeze && <MenuItem icon="snowflake" text={freezeText} onClick={onColumnFreeze} />}
        {onColumnHide && <MenuItem icon="eye-off" text="Hide Section" onClick={hideColumn} />}
      </Menu>
    );
  };

  const renderMenuContent = () => {
    if ([onColumnSort, onColumnHide].every(fn => !fn)) {
      return null;
    }

    const showDivider = onColumnSort && (onColumnHide || onColumnFilter);
    return (
      <Menu>
        {onColumnSort && <MenuItem icon="sort-asc" text="Sort Ascending" onClick={() => onColumnSort(false)} />}
        {onColumnSort && <MenuItem icon="sort-desc" text="Sort Descending" onClick={() => onColumnSort(true)} />}
        {showDivider && <MenuDivider />}
        {onColumnHide && <MenuItem icon="eye-off" text="Hide column" onClick={hideColumn} />}
        {onColumnFilter && <MenuItem icon="filter" text="Filter by column" onClick={() => onColumnFilter(columnId)} />}
      </Menu>
    );
  };

  const handleInteraction = nextOpenState => {
    setIsOpen(nextOpenState);
  };
  const popoverContent = isGroupColumn ? renderGroupMenuContent() : renderMenuContent();

  if (!popoverContent) {
    return null;
  }

  return (
    <StyledPopoverWrapper className="context-menu" isOpen={isOpen}>
      <Popover
        content={popoverContent}
        position={Position.BOTTOM_RIGHT}
        isOpen={isOpen}
        onInteraction={state => handleInteraction(state)}
      >
        <StyledContextMenuButton
          data-testid="context-menu"
          className="ml-auto"
          icon="more"
          minimal
          onClick={() => setIsOpen(true)}
        />
      </Popover>
    </StyledPopoverWrapper>
  );
}

export default HeaderContextMenu;
