// @flow

import React from "react";
import { observer } from "mobx-react";

type Props = {
  children: ?Element,
  isVisible: boolean
};

function SidebarFilter(props: Props) {
  const { isVisible = true, children = null } = props;
  const className = isVisible ? "visible-filter" : "d-none";
  return (
    <div data-testid="sidebar-filter" className={className}>
      {children}
    </div>
  );
}

export default observer(SidebarFilter);
