// @flow

import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Button, Colors, Menu, Popover, Position, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";
import kebabCase from "lodash.kebabcase";
import pluralize from 'pluralize';

import { useStores } from "../store/Store";
import filterNamesMap from "../helpers/filterNames";
import MileTransformationContext from "../services/contexts/MileTransformationContext";
import HeaderTab from "../components/HeaderTab";
import { Status } from "../helpers/Status";
import { MAX_TAB_COUNT } from "../helpers/constants";

const StyledTab = styled("div")`
  min-width: 0;
`;

const StyledMenuTopSummary = styled("div")`
  color: ${Colors.GRAY1};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  margin-left: 10px;
`;

const StyledTabLimitMenu = styled(Menu)`
  padding: 18px 20px;
  width: 300px !important;
`;

const StyledNewSavedViewRow = styled("li")`
  b {
    color: #30404d;
  }
`;

const StyledNewSavedViewRmBtn = styled(Button)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const SavedViewsList = styled("ul")`
  max-height: 252px;
  overflow-y: auto;
`;

const StyledTabLimitHeader = styled("p")`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.3px;
`;

const StyledNavMenu = styled(Menu)`
  &.bp3-menu {
    max-width: 405px;
    overflow: hidden;
    padding: 0;
    width: 405px;
  }
`;

const StyledMenuTop = styled("div")`
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
`;

const StyledNavPopoverButton = styled(Button)`
  &.bp3-add-tab {
    border-radius: 2px 2px 0 0;
    border: 1px solid transparent;
    border-bottom: none;
    padding: 0 3px;
    height: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background: #ffffff33;

    .bp3-icon-cross svg {
      fill: #ffffff99;
    }

    &:hover {
      background: #ffffff33;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 2px 2px ${Colors.BLUE3}99 !important;
    }
  }
`;

const StyledAddTemplateButton = styled(Button)`
  width: 140px;
`;

const StyledAddTabButton = styled(Button)`
  width: 95px;
`;

function HeaderTabs() {
  const transformToMile = useContext(MileTransformationContext);
  const history = useHistory();
  const [navPopoverStatus, setNavPopoverStatus] = useState(false);
  const { appStore, modalStore, templatesStore, tabsStore } = useStores();
  const filterNames = filterNamesMap(transformToMile);

  const { auth = {} } = appStore;
  const { user } = auth;

  const { savedAnalyses = [], status } = templatesStore;

  const { tabs } = tabsStore;
  const tabsCount = tabs.length;

  useEffect(() => {
    const { tabs: metaDataTabs = [] } = user;
    tabsStore.initTabs(metaDataTabs);
  }, [user, tabsStore]);

  const tabsList = tabs.map((tab, index) => (
    <HeaderTab
      getPreviousTabId={() => {
        if (tabs.length > 1) {
          if (!tabs[index - 1]) return tabs[index + 1].id;
          return tabs[index - 1].id;
        }
      }}
      key={tab.id}
      onDuplicate={tabId => {
        tabsStore.duplicateTab(tabId);
      }}
      onRemove={tabId => {
        tabsStore.removeTab(tabId);
      }}
      onSave={tab => {
        if (tab.parentId && templatesStore.savedAnalyses.find(view => view.id === tab.parentId)) {
          templatesStore.patchView(tab.parentId, tab);
        } else {
          templatesStore.addView(tab);
        }
      }}
      savedViews={savedAnalyses}
      tab={tab}
      tabsCount={tabsCount}
    />
  ));

  const closePopoverAction = () => {
    setNavPopoverStatus(false);
    history.push(`/analysis/${tabsStore.lastTabId}/explore`);
  };

  const showDeleteConfirmation = (id: string, label: string) => {
    const config = {
      title: "Delete View",
      header: "Are you sure you want to delete this view?",
      text: "You won’t be able to restore your work.",
      buttonText: "Delete",
      action: () => templatesStore.removeView(id, label)
    };
    modalStore.setModal("confirmation", config);
  };

  const sortedViews = savedAnalyses.slice().sort((viewA, viewB) => viewA.label - viewB.label);

  const navMenu =
    tabsCount < MAX_TAB_COUNT ? (
      <StyledNavMenu>
        <StyledMenuTop className="row m-0 py-1">
          <StyledMenuTopSummary className="pl-2 align-self-center">
            {pluralize("SAVED ANALYSIS", savedAnalyses.length, true)}
          </StyledMenuTopSummary>
          <StyledAddTemplateButton
            className="ml-auto"
            icon="add"
            intent="primary"
            minimal
            onClick={() => {
              setNavPopoverStatus(false);
              templatesStore.setSidebarOpen();
            }}
          >
            From Template
          </StyledAddTemplateButton>
          <StyledAddTabButton
            className="ml-auto mr-2"
            data-testid="add-tab-button"
            icon="add"
            intent="primary"
            minimal
            onClick={() => {
              tabsStore.addTab();
              closePopoverAction();
            }}
          >
            Analysis
          </StyledAddTabButton>
        </StyledMenuTop>
        {status === Status.LOADING ? <Spinner className="p-2" /> : null}
        {savedAnalyses.length && status !== Status.LOADING ? (
          <SavedViewsList className="bp3-list-unstyled py-2">
            {sortedViews.map(savedView => {
              const { id, label, view } = savedView;
              const { flightsTable } = view;
              return (
                <StyledNewSavedViewRow className="d-flex position-relative row m-0 px-2" key={id}>
                  <Button
                    className="col justify-content-start pr-5"
                    minimal
                    data-testid={`saved-view-${kebabCase(label)}`}
                    onClick={() => {
                      tabsStore.createTabFromView(savedView, "saved");
                      closePopoverAction();
                    }}
                  >
                    <div>
                      <strong className="text-break">{label}</strong>
                    </div>
                    <div className="mt-1">
                      {flightsTable.aggregations && flightsTable.aggregations.length ? (
                        <span>
                          {flightsTable.aggregations.map(aggregation => filterNames[aggregation]).join(" / ")}
                        </span>
                      ) : null}
                    </div>
                  </Button>
                  <StyledNewSavedViewRmBtn
                    data-testid="remove-saved-view"
                    className="col-auto ml-auto"
                    icon="trash"
                    minimal
                    onClick={() => showDeleteConfirmation(id, label)}
                  />
                </StyledNewSavedViewRow>
              );
            })}
          </SavedViewsList>
        ) : null}
      </StyledNavMenu>
    ) : (
      <StyledTabLimitMenu>
        <div>
          <StyledTabLimitHeader>Maximum tab count reached</StyledTabLimitHeader>
          <p className="mb-0">To create a new tab or load a saved view, close one of your existing tabs.</p>
        </div>
      </StyledTabLimitMenu>
    );

  const navButton = (
    <StyledNavPopoverButton
      data-testid="add-tab-popover-button"
      icon="plus"
      outlined
      className="justify-content-center align-items-center bp3-add-tab"
      onClick={e => {
        e.stopPropagation();
        setNavPopoverStatus(!navPopoverStatus);
      }}
    />
  );

  const navPopover = (
    <Popover
      boundary="viewport"
      content={navMenu}
      isOpen={navPopoverStatus}
      position={Position.BOTTOM}
      onInteraction={state => setNavPopoverStatus(state)}
      target={navButton}
    />
  );

  return (
    <React.Fragment>
      <StyledTab className="d-flex flex-grow-1 mr-2 overflow-auto">
        {tabsList}
        {navPopover}
      </StyledTab>
    </React.Fragment>
  );
}

export default observer(HeaderTabs);
