// @flow

import { observer } from "mobx-react";
import { format, getTime, parseISO } from "date-fns";

import numberFormat from "../helpers/NumberFormat";
import { daysOfWeek } from "../helpers/constants";
import { renderBasePreview, renderCurrency, renderPercent, renderMinMax } from "./DataTableCellRenders";
import { useStores } from "../store/Store";
import isNumber from "../helpers/isNumber";

const numberFormatter = numberFormat(0, 0);
const numberPreciseFormatter = numberFormat(3, 3);
const percentFormatter = numberFormat(undefined, 1, "percent");
const percentRelativeFormatter = numberFormat(undefined, 1, "percent", "exceptZero");

type Props = {
  options: ?Object,
  type: string,
  value: any
};

function DataTableCell(props: Props) {
  const { type, value = {}, options = {}, withFixedNumber = false } = props;
  const { systemUnitsStore } = useStores();
  const { computedDateFormat, computedDateMonthFormat } = systemUnitsStore;

  if (type && type.includes("influence")) {
    // influence, influence-currency, influence-percent
    return renderBasePreview(value, type);
  }

  if (type === "string") {
    return value;
  }

  if (type === "date") {
    return value && format(getTime(parseISO(value)), computedDateFormat);
  }

  if (type === "date-month") {
    return value && format(getTime(parseISO(value)), computedDateMonthFormat);
  }

  if (type === "integer") {
    return Math.round(value);
  }

  if (type === "day-week-long") {
    if (!isNumber(value)) {
      return value;
    }

    return daysOfWeek[value] && daysOfWeek[value].long;
  }

  if (type === "quarter") {
    return `${value.substring(0, 4)} ${value.substring(4)}`;
  }

  if (type === "currency") {
    return renderCurrency(value, type, numberFormatter);
  }

  if (type === "currency-precise") {
    return renderCurrency(value, type, numberPreciseFormatter);
  }

  if (type === "percent") {
    return renderPercent(value, type, percentFormatter, withFixedNumber);
  }

  if (type === "percent-relative") {
    return renderPercent(value, type, percentRelativeFormatter, withFixedNumber);
  }

  if (type === "min-max") {
    return renderMinMax(value, type, options);
  }

  return value;
}

export default observer(DataTableCell);
