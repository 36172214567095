// @flow

import { action, computed, observable, toJS } from "mobx";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import union from "lodash.union";
import difference from "lodash.difference";

import api from "services/Api";
import prepareStructure from "../helpers/prepareStructure";
import { influenceTypeNames } from "../helpers/influenceConstants";
import type { RootStore } from "./Root.model";
import normalizeFilters from "../helpers/normalizeFilters";
import { Status } from "../helpers/Status";
import removeFilterValue from "../helpers/removeFilterValue";
import { valueWithLimits } from "../helpers/valueWithLimits";

export const FILTERS_INIT = {
  owMarket: [],
  rtMarket: [],
  flightNumber: [],
  cabinClass: [],
  quarter: [],
  depDate: {
    start: null,
    end: null
  },
  depTime: {
    start: "00:00",
    end: "23:59"
  },
  ndo: {
    start: 0,
    end: 360
  },
  depDow: [],
  depMonth: [],
  depWeek: [],
  depTimeBucket: [],
  type: [],
  status: [],
  createdOn: {
    start: null,
    end: null
  },
  updatedOn: {
    start: null,
    end: null
  },
  userIds: []
};

export const PAGE_INIT = {
  applied: {
    filters: { status: ["active"] }
  },
  filters: { ...FILTERS_INIT, status: ["active"] },
  filtersEnabled: true,
  influenceTimeline: {
    isCollapsed: false
  },
  sidebar: {
    isOpen: false,
    filterKey: "",
    filterQuery: ""
  },
  status: Status.INIT,
  historyTable: {
    status: Status.INIT,
    columns: [
      [
        "influence",
        [
          "influenceId",
          "status",
          "type",
          "value",
          "updatedOn",
          "analystName",
          "comment",
          "numberOfFlights",
          "minDepDate",
          "maxDepDate",
          "owMarkets"
        ]
      ]
    ],
    data: [],
    selectedRows: [],
    pagination: {
      pageIndex: 0,
      pageSize: 25,
      pageCount: 1,
      totalRows: 0
    },
    sortBy: {
      field: "updatedOn",
      direction: "desc"
    }
  }
};

export const CREATORS_INIT = {
  analystCreators: [],
  status: Status.INIT
};

const typeAllowingToSortByValue = ["PA"];

export class InfluenceHistoryStore {
  @observable page = cloneDeep(PAGE_INIT);
  @observable creators = cloneDeep(CREATORS_INIT);

  rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setDefaultSorting() {
    this.page.historyTable.sortBy = PAGE_INIT.historyTable.sortBy;
  }

  @action
  initPage(page: Object) {
    const newPageStructure = cloneDeep(prepareStructure({ template: PAGE_INIT, object: page }));

    this.page = {
      ...newPageStructure,
      status: Status.DONE,
      applied: {
        filters: {
          ...newPageStructure.filters,
          ...newPageStructure.applied.filters
        }
      }
    };
  }

  @action.bound
  toggleFiltersEnabled() {
    this.page.filtersEnabled = !this.page.filtersEnabled;
    this.getInfluenceHistoryData();
  }

  @action
  setTimelineCollapsed(isCollapsed: boolean) {
    this.page.influenceTimeline.isCollapsed = isCollapsed;
    this.saveView();
  }

  @action
  getInfluenceHistoryData(params: Object) {
    const { applied, historyTable, filtersEnabled } = this.page;
    const { pageIndex = 0, pageSize = historyTable.pagination.pageSize, sortBy = historyTable.sortBy } = params || {};
    const filters = filtersEnabled ? applied.filters : FILTERS_INIT;

    historyTable.status = Status.LOADING;
    historyTable.pagination.pageSize = pageSize;
    historyTable.pagination.pageIndex = pageIndex;
    historyTable.sortBy = sortBy;

    api
      .getInfluenceHistory({
        filters: normalizeFilters(filters, FILTERS_INIT),
        pagination: {
          size: pageSize,
          offset: pageIndex * pageSize
        },
        sortBy
      })
      .then(({ data }) => {
        const { rows, pagination } = data;
        const pageCount = Math.ceil(pagination.totalRows / pageSize);

        historyTable.data = rows.map(row => {
          const { history, ...rest } = row;
          const type = influenceTypeNames[row.type] || row.type;
          const typeKey = row.type;
          const subRows = history
            .sort((a, b) => a.updatedOn.localeCompare(b.updatedOn)) // sort, oldest first
            .map((item, index) => ({
              ...item,
              influenceId: `${row.influenceId}.${index}`, // generate id for subrow
              type, // copy type to subrow
              typeKey,
              valueText: valueWithLimits(item)
            }))
            .reverse() // sort, newest first
            .slice(1); // slice newest subrow

          return { ...rest, type, typeKey, subRows, valueText: valueWithLimits(row) };
        });
        historyTable.pagination.totalRows = pagination.totalRows;
        historyTable.pagination.pageCount = pageCount;
        historyTable.status = Status.DONE;
      })
      .catch(() => {
        historyTable.status = Status.ERROR;
      });
  }

  @action
  getInfluenceHistoryCreators() {
    this.creators.status = Status.LOADING;

    api
      .getInfluenceHistoryCreators()
      .then(({ data }) => {
        this.creators.analystCreators = data.map(({ userId, name, email }) => ({
          value: userId,
          label: name,
          email
        }));
        this.creators.status = Status.DONE;
      })
      .catch(() => {
        this.creators.status = Status.ERROR;
      });
  }

  @action
  deleteInfluences(params: { influenceIds: Array<string>, comment: string }) {
    const { influenceIds, comment } = params;
    this.page.historyTable.status = Status.LOADING;
    api.deleteInfluences({ influenceIds, comment }).then(() => this.getInfluenceHistoryData());
  }

  @action.bound
  submitSearchForm() {
    const { page } = this;
    if (!isEqual(page.filters.type, typeAllowingToSortByValue)) {
      this.setDefaultSorting();
    }
    page.applied.filters = cloneDeep(toJS(page.filters));
    this.saveView();
    this.getInfluenceHistoryData();

    this.page.historyTable.selectedRows.clear();
  }

  @action.bound
  clearSearchParam(name: string) {
    const { page } = this;

    if (name in page.filters) {
      page.filters[name] = FILTERS_INIT[name];
    }
    this.submitSearchForm();
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { data, selectedRows } = this.page.historyTable;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.filter(row => row.status === "active").map(row => row.influenceId);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.page.historyTable.selectedRows = !isToggleOn
      ? union(selectedRows, actionRows)
      : difference(selectedRows, actionRows);
  }

  @action
  updateSelectedRows(selectedRows: Array<string>) {
    this.page.historyTable.selectedRows.replace(selectedRows);
  }

  @action
  saveView() {
    this.rootStore.appStore.auth.editCurrentUser({
      influenceHistory: this.pageToSave
    });
  }

  @action.bound
  changeFilter(filterKey, filterValue) {
    const { filters } = this.page;
    filters[filterKey] = filterValue;
  }

  @action.bound
  removeFilterValue(filterKey: string, valueToRemove: string) {
    const { filters } = this.page;
    if (filters[filterKey]) {
      filters[filterKey] = removeFilterValue(filters[filterKey], valueToRemove, FILTERS_INIT[filterKey]);
      this.submitSearchForm();
    }
  }

  @action.bound
  setSidebarOpen(isOpen) {
    const { sidebar } = this.page;
    sidebar.isOpen = isOpen;
    if (!isOpen) {
      sidebar.filterQuery = "";
      sidebar.filterKey = null;
    }
  }

  @action.bound
  setSidebarFilterQuery(filterQuery: string, filterKey?: string) {
    const { sidebar } = this.page;
    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @computed
  get decoratedPage() {
    const { changeFilter } = this;
    return {
      ...this.page,
      changeFilter
    };
  }

  @computed
  get pageToSave() {
    return {
      ...this.page,
      historyTable: {
        ...PAGE_INIT.historyTable,
        sortBy: this.page.historyTable.sortBy
      }
    };
  }
}
