// @flow

import React from "react";
import { observer } from "mobx-react";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import { toJS } from "mobx";
import styled from "@emotion/styled";

import AddRegionsModal from "../components/AddRegionsModal";
import AddUserModal from "../components/AddUserModal";
import DeleteEventsModal from "../components/DeleteEventsModal";
import DeleteInfluenceModal from "../components/DeleteInfluenceModal";
import EditRegionsModal from "../components/EditRegionsModal";
import EditUserModal from "../components/EditUserModal";
import EventsManagementModal from "../components/EventsManagement/EventsManagementModal";
import MarketMappingsModal from "../components/MarketMappings/MarketMappingsModal";
import { useStores } from "../store/Store";

const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
`;

const Modal = () => {
  const { appStore, cirrusStore, modalStore, usersStore, eventsManagementStore } = useStores();

  const user = appStore.auth && appStore.auth.user;

  switch (modalStore.modalActive) {
    case "addUser":
      return <AddUserModal addUser={usersStore.addUser} modal={modalStore} userGroups={usersStore.userGroups.data} />;
    case "confirmation":
      return (
        <Dialog
          className="p-0"
          isCloseButtonShown={false}
          isOpen={modalStore.modalActive === "confirmation"}
          onClose={() => modalStore.setModal(null)}
          title={modalStore.data.title}
        >
          <div data-testid="confirmation-modal">
            <div className={`${Classes.DIALOG_BODY} mx-0 my-1 px-3 py-2`}>
              {modalStore.data.header ? <p className="font-weight-bold">{modalStore.data.header}</p> : null}
              <p>{modalStore.data.text}</p>
            </div>
            <DialogFooter className={`${Classes.DIALOG_FOOTER} m-0 px-3 py-2`}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  type="button"
                  onClick={() => {
                    modalStore.data.cancelAction ? modalStore.data.cancelAction() : modalStore.setModal(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="confirmation-modal-delete-btn"
                  intent="primary"
                  onClick={() => {
                    modalStore.data.action && modalStore.data.action();
                    modalStore.setModal(null);
                  }}
                >
                  {modalStore.data.buttonText}
                </Button>
              </div>
            </DialogFooter>
          </div>
        </Dialog>
      );
    case "deleteInfluence":
      return <DeleteInfluenceModal modal={modalStore} />;
    case "editUser":
      return (
        <EditUserModal
          currentUserId={user.userId}
          deleteUser={usersStore.deleteUser}
          editUser={(userId, data) =>
            userId === user.userId
              ? usersStore.editCurrentUser(data.name, appStore.auth.editCurrentUserName)
              : usersStore.editUser(userId, data)
          }
          modal={modalStore}
          userGroups={usersStore.userGroups.data}
        />
      );
    case "fusionrm":
      return (
        <Dialog
          className="p-0"
          icon="info-sign"
          isOpen={modalStore.modalActive === "fusionrm"}
          onClose={() => modalStore.setModal(null)}
          title="Cirrus Status change"
        >
          <div className="p-3">
            <p>
              <strong>
                You are going to change the Cirrus Status to {modalStore.data.newStatus} for {modalStore.data.totalRows}{" "}
                flights records.
              </strong>
            </p>
            <p>Please confirm this action.</p>
            <Button
              intent="primary"
              onClick={() => {
                cirrusStore.modifyStatus(toJS(modalStore.data.searchParams), modalStore.data.newStatus === "ON");
                return modalStore.setModal("");
              }}
            >
              Confirm
            </Button>
          </div>
        </Dialog>
      );
    case "market-mappings":
      return <MarketMappingsModal modal={modalStore} />;
    case "add-regions":
      return <AddRegionsModal />;
    case "edit-regions":
      return <EditRegionsModal />;
    case "addEvent":
      return <EventsManagementModal modal={modalStore} />;
    case "editEvent":
      return <EventsManagementModal modal={modalStore} event={eventsManagementStore.selectedEvent} />;
    case "deleteEvents":
      return <DeleteEventsModal modal={modalStore} />;
    default:
      if (modalStore.modalActive) {
        // eslint-disable-next-line
        console.warn(`Unknown modal "${modalStore.modalActive}"`);
      }
      return null;
  }
};

export default observer(Modal);
