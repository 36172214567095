import cloneDeep from "lodash.clonedeep";
import difference from "lodash.difference";
import intersection from "lodash.intersection";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import union from "lodash.union";
import omit from "lodash.omit";
import { action, computed, observable, toJS } from "mobx";
import { addDays, differenceInDays, format, parseISO, subDays } from "date-fns";

import type { RootStore } from "./Root.model";
import type { TabsStore } from "./Tabs.model";

import api, { isCancel } from "../services/Api";
import getRowObjectIds from "../helpers/getRowObjectIds";
import normalizeFilters from "../helpers/normalizeFilters";
import parseGraphSeries from "../helpers/parseGraphSeries";
import prepareStructure from "../helpers/prepareStructure";
import { buildCurvesMetrics } from "../helpers/metrics";
import { Status } from "../helpers/Status";
import { clearDataFromPriceAdjExtraKeys, initGroupStatus } from "../helpers/tab";
import getRowId from "../helpers/getRowId";
import isNumber from "../helpers/isNumber";
import mergePastAndFutureTimelines, { sharedSeries } from "../helpers/mergePastAndFutureTimelines";
import removeFilterValue from "../helpers/removeFilterValue";
import { FlightsTable } from "./FlightsTable/FlightsTable.model";
import type { PageContextFilters } from "../types/Flights.types";

export const FILTERS_INIT: PageContextFilters = {
  airline: [],
  analystId: [],
  cabinClass: [],
  compFareCirrusMatchAirline: [],
  compFareDateMatchAirline: [],
  compFareTimeMatchAirline: [],
  depDate: {
    start: null,
    end: null
  },
  depDow: [],
  depMonth: [],
  depTime: {
    start: "00:00",
    end: "23:59"
  },
  depTimeBucket: [],
  depWeek: [],
  destination: [],
  flightNumber: [],
  fusionrmStatus: undefined,
  lacRbd: [],
  lacRbdFrm: [],
  ndo: {
    start: 0,
    end: 360
  },
  origin: [],
  owMarket: [],
  quarter: [],
  regionId: [],
  rtMarket: [],
  subregionId: [],
  xDayLacRbd: [],
  xDayLacRbdFrm: []
};

const initSeries = [
  ["raskGroup", ["rask", "raskBaseline"]],
  ["loadFactorGroup", ["loadFactor", "loadFactorBaseline"]],
  ["pricePercentileGroup", ["pricePercentile"]]
];

const forecastedSeries = ["raskExpected", "revenueExpected", "loadFactorExpected"];

export const TAB_INIT = {
  applied: {
    conditionalFilters: [],
    filters: { ...FILTERS_INIT }
  },
  buildCurves: {
    cabinClass: ["Y"],
    data: {},
    groupStatuses: buildCurvesMetrics.map(([label]) => ({
      label,
      isOpen: !isEmpty(initSeries.filter(([groupTitle, metrics]) => label === groupTitle && !isEmpty(metrics)))
    })),
    isCollapsed: false,
    range: { start: undefined, end: undefined },
    selectedRange: { start: undefined, end: undefined },
    series: initSeries,
    status: Status.INIT
  },
  conditionalFilters: [],
  departureDateExtremes: {
    max: undefined,
    min: undefined,
    status: Status.INIT
  },
  distanceUnit: undefined,
  filters: { ...FILTERS_INIT },
  flightsCount: {
    numberOfRows: undefined,
    selectedRowsNumberOfFlights: {},
    status: Status.INIT,
    totalNumberOfFlights: undefined
  },
  filtersEnabled: true,
  flightsTable: new FlightsTable().toJSON(),
  id: "",
  label: "Analysis 1",
  pivotTable: {
    isCollapsed: false
  },
  parentId: null,
  parentType: null,
  duplicatedFromSavedId: null,
  savedViewId: null,
  sidebar: {
    isOpen: false,
    filterKey: null,
    filterQuery: ""
  },
  xDayBuild: 7
};

export type TabStruct = typeof TAB_INIT;

export const filterPersistentTab = (tab: TabStruct): TabStruct => ({
  ...(tab.toJSON ? tab.toJSON() : tab),
  buildCurves: {
    ...tab.buildCurves,
    range: { start: undefined, end: undefined },
    selectedRange: { start: undefined, end: undefined },
    status: Status.INIT,
    data: {}
  },
  conditionalFilters: tab.applied.conditionalFilters,
  departureDateExtremes: TAB_INIT.departureDateExtremes,
  distanceUnit: TAB_INIT.distanceUnit,
  filters: tab.applied.filters,
  filtersEnabled: true,
  flightsCount: TAB_INIT.flightsCount,
  flightsTable: {
    ...(tab.flightsTable?.toJSON ? tab.flightsTable.toJSON() : tab.flightsTable),
    showOnlySelected: TAB_INIT.flightsTable.showOnlySelected,
    data: [],
    influenceImpactGroupColumns: undefined,
    lastUpdated: undefined,
    pagination: {
      ...(tab.flightsTable && tab.flightsTable.pagination),
      pageCount: 1,
      totalRows: 0
    },
    selectedRows: [],
    status: Status.INIT
  }
});

export const DAYS_BACK = 30;
export const DAYS_FORWARD = 30;

const synchronizedCollapses = [["buildCurves", "pivotTable"]];

export class Tab {
  @observable applied: $PropertyType<TabStruct, "applied">;
  @observable buildCurves: $PropertyType<TabStruct, "buildCurves">;
  @observable conditionalFilters: $PropertyType<TabStruct, "conditionalFilters">;
  @observable distanceUnit: ?string;
  @observable filters: $PropertyType<TabStruct, "filters">;
  @observable filtersEnabled: $PropertyType<TabStruct, "filtersEnabled">;
  @observable flightsCount: $PropertyType<TabStruct, "flightsCount">;
  @observable id: string;
  @observable label: string;
  @observable pivotTable: $PropertyType<TabStruct, "pivotTable">;
  @observable parentId: number | null;
  @observable savedViewId: number | null;
  @observable sidebar: $PropertyType<TabStruct, "sidebar">;
  @observable xDayBuild: number;

  duplicatedFromSavedId: number | null;
  flightsTable: FlightsTable;
  rootStore: RootStore;
  tabsStore: TabsStore;

  constructor(tabsStore: TabsStore, tab?: TabStruct) {
    const newTab = prepareStructure({
      template: TAB_INIT,
      object: tab || {},
      keysToMerge: ["fixedColumns"],
      arraysToMerge: [{ key: "groupStatuses", identifier: "label" }]
    });

    const seed = {
      ...newTab,
      flightsTable: new FlightsTable(tabsStore.rootStore, newTab.flightsTable)
    };

    Object.assign(this, initGroupStatus(seed, TAB_INIT));

    // tab can have serialized tabsStore etc. so these need to come last
    this.rootStore = tabsStore.rootStore;
    this.tabsStore = tabsStore;
    this.flightsTable = seed.flightsTable;
  }

  @action.bound
  setSidebarOpen(isOpen: boolean) {
    this.sidebar.isOpen = isOpen;
    if (!isOpen) {
      this.sidebar.filterQuery = "";
      this.sidebar.filterKey = null;
    }
  }

  @action.bound
  toggleFiltersEnabled() {
    this.filtersEnabled = !this.filtersEnabled;
    this.refetchFlightsTableData();
    this.getDepartureDateExtremes().then(() => this.getBuildCurvesData());
  }

  @action.bound
  setSidebarFilterQuery(filterQuery: string, filterKey?: string) {
    const { sidebar } = this;

    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action
  renameTab(name: string) {
    this.label = name;
    this.saveTabs();
  }

  @action
  flushTab() {
    this.flightsTable.flushData();
  }

  @action.bound
  toggleShowOnlySelected() {
    this.flightsTable.toggleShowOnlySelected();
    this.refetchFlightsTableData();
  }

  @action
  setModuleCollapsed(moduleId: string, isCollapsed: boolean) {
    const affectedModuleIds = synchronizedCollapses.find(ids => ids.includes(moduleId)) || [moduleId];

    affectedModuleIds.forEach(id => {
      this[id].isCollapsed = isCollapsed;
    });

    this.saveTabs();
  }

  @action.bound
  refetchFlightsTableData(params: Object, options: Object) {
    this.getFlightsData(params);
    this.getFlightsCountData(params);

    if (options && options.saveOptions) {
      this.saveTabs();
    }
  }

  @action
  getNormalizedFilters(): {
    conditionalFilters: $PropertyType<TabStruct, "conditionalFilters">,
    filters: $PropertyType<TabStruct, "filters">
  } {
    const filters = this.filtersEnabled ? this.applied.filters : FILTERS_INIT;
    const conditionalFilters = this.filtersEnabled ? this.applied.conditionalFilters : [];

    return {
      conditionalFilters,
      filters: normalizeFilters(filters, FILTERS_INIT)
    };
  }

  @action
  getDepartureDateExtremes() {
    const { conditionalFilters, filters } = this.getNormalizedFilters();

    const params = {
      aggregations: ["depDate", "flightNumber"],
      columns: ["depDate"],
      conditionalFilters,
      filters,
      pagination: { size: 1, offset: 0 },
      xDayBuild: this.xDayBuild
    };

    const extractDate = (response: Object) => response.data?.rows?.[0]?.depDate;

    const minDatePromise = api.getFlights(
      { ...params, sortBy: { field: "depDate", direction: "asc" } },
      this.id,
      "minDepDate"
    );
    const maxDatePromise = api.getFlights(
      { ...params, sortBy: { field: "depDate", direction: "desc" } },
      this.id,
      "maxDepDate"
    );

    this.buildCurves.status = Status.LOADING;
    this.departureDateExtremes.status = Status.LOADING;

    return Promise.all([minDatePromise, maxDatePromise])
      .then(responses => {
        const [min, max] = responses.map(extractDate);
        this.departureDateExtremes = { max, min, status: Status.DONE };
      })
      .catch(() => {
        this.departureDateExtremes = { ...TAB_INIT.departureDateExtremes, status: Status.ERROR };
      });
  }

  @action
  getFlightsData(params: Object) {
    const { conditionalFilters, filters } = this.getNormalizedFilters();

    const flightsDataParams = {
      ...params,
      filters,
      conditionalFilters,
      tabId: this.id,
      xDayBuild: this.xDayBuild
    };

    this.flightsTable.fetchFlightsData(flightsDataParams).then(response => {
      if (response) {
        this.distanceUnit = response.data.distanceUnit;
      }

      this.flightsTable.data.forEach(row => {
        const rowId = getRowId(this.flightsTable.aggregations, row);

        if (!isEmpty(this.flightsTable.selectedRows) && this.flightsTable.selectedRows.includes(rowId)) {
          this.updateSelectedRows(this.flightsTable.selectedRows, { [rowId]: row.numberOfFlights });
        }
      });
    });
  }

  @action
  getFlightsCountData(params: Object) {
    const { applied, filtersEnabled, flightsCount, id, xDayBuild } = this;

    flightsCount.status = Status.LOADING;
    flightsCount.numberOfRows = undefined;
    flightsCount.totalNumberOfFlights = undefined;

    const filters = filtersEnabled ? applied.filters : FILTERS_INIT;

    const flightsCountParams = {
      ...params,
      filters: normalizeFilters(filters, FILTERS_INIT),
      conditionalFilters: filtersEnabled ? applied.conditionalFilters : [],
      tabId: id,
      xDayBuild
    };

    this.flightsTable
      .fetchFlightsCountData(flightsCountParams)
      .then(data => {
        const { numberOfRows, totalNumberOfFlights } = data;
        flightsCount.numberOfRows = numberOfRows;
        flightsCount.totalNumberOfFlights = totalNumberOfFlights;
        flightsCount.status = Status.DONE;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          flightsCount.status = Status.LOADING;
          return;
        }
        flightsCount.status = Status.ERROR;
      });
  }

  @action
  getToday() {
    return this.buildCurves.data?.today || this.rootStore.timeStore?.todayDate;
  }

  @action
  getBuildCurvesData(params: ?{ daysForward: number }) {
    const { aggregations = [], selectedRows = [] } = this.flightsTable;

    const filters = this.filtersEnabled ? this.applied.filters : FILTERS_INIT;
    const conditionalFilters = this.filtersEnabled ? this.applied.conditionalFilters : [];

    if (isEmpty(this.buildCurvesFlatSeries)) {
      return;
    }

    if (
      this.departureDateExtremes.status === Status.DONE &&
      (!this.departureDateExtremes.min || !this.departureDateExtremes.max)
    ) {
      this.buildCurves.data = cloneDeep(TAB_INIT.buildCurves.data);
      this.buildCurves.status = Status.DONE;
      return;
    }

    this.buildCurves.status = Status.LOADING;

    const historicalSeries = this.enabledHistoricalSeries;
    const expectedSeries = this.enabledForecastedSeries;

    const daysForward = () => {
      if (params?.daysForward) {
        return params?.daysForward;
      }
      if (this.buildCurves.selectedRange?.end > 0) {
        return this.buildCurves.selectedRange?.end;
      }
      return DAYS_FORWARD;
    };
    const baseParams = {
      conditionalFilters,
      filters: {
        ...normalizeFilters(filters, FILTERS_INIT),
        cabinClass: this.buildCurves.cabinClass
      },
      rowIds: getRowObjectIds(aggregations, selectedRows),
      xDayBuild: this.xDayBuild
    };

    const historicalSeriesPromise =
      (this.isHistoricalMode || this.isMixedMode) &&
      api.getBuildCurves(
        {
          ...baseParams,
          scenarioDateAggregation: { daysBack: DAYS_BACK },
          series: historicalSeries
        },
        this.id
      );

    const expectedSeriesPromise =
      (this.isForecastMode || this.isMixedMode) &&
      api.getForecastedBuildCurves(
        {
          ...baseParams,
          scenarioDateAggregation: {
            start: format(subDays(this.getToday(), 2), "yyyy-MM-dd"), // subtract two days to make sure series matches
            end: format(addDays(this.getToday(), daysForward()), "yyyy-MM-dd")
          },
          series: expectedSeries
        },
        this.id
      );

    this.buildCurves.status = Status.LOADING;

    const promiseArray = [historicalSeriesPromise, expectedSeriesPromise].filter(Boolean);

    return Promise.all(promiseArray)
      .then(responses => {
        if (responses.length === 1) {
          this.buildCurves.data = responses[0].data && parseGraphSeries(responses[0].data);
        } else {
          const mergedData = mergePastAndFutureTimelines(responses);
          this.buildCurves.data = parseGraphSeries(mergedData);
        }

        const historicalData = responses[0]?.data;

        const today = this.getToday();
        const earliestDate = parseISO(historicalData?.scenarioDate[0]);
        const start = differenceInDays(earliestDate, today);

        // set timeline from -30 to 0 or 360
        this.buildCurves.range = {
          start: isNumber(start) ? Math.min(start, DAYS_BACK * -1) : DAYS_BACK * -1,
          end:
            this.isForecastMode || this.isMixedMode
              ? differenceInDays(parseISO(this.departureDateExtremes.max), today)
              : 0
        };

        // init selected range if there is none
        if (
          !this.buildCurves.selectedRange.start &&
          !this.buildCurves.selectedRange.end &&
          !isEmpty(historicalData?.scenarioDate)
        ) {
          this.buildCurves.selectedRange = {
            start: this.isHistoricalMode || this.isMixedMode ? Math.max(start, this.buildCurves.range.start) : 0,
            end: this.isForecastMode || this.isMixedMode ? Math.min(daysForward(), this.buildCurves.range.end) : 0
          };
        }

        // case when selected range is bigger than maximum
        if (this.buildCurves.selectedRange.end > this.buildCurves.range.end) {
          this.changeSelectedRange();
        }

        // case when graph is scaled to historic data and forecasted series are added
        if (!this.isHistoricalMode && this.buildCurves.selectedRange.end <= 0) {
          this.changeSelectedRange();
        }

        // case when there is no historic data
        if (this.isForecastMode && !this.isMixedMode && this.buildCurves.selectedRange.start <= 0) {
          this.changeSelectedRange({ end: this.buildCurves.selectedRange.end });
        }

        this.buildCurves.status = Status.DONE;

        return responses;
      })
      .catch(thrown => {
        if (isCancel(thrown)) {
          this.buildCurves.status = Status.LOADING;
          return;
        }
        this.buildCurves.status = Status.ERROR;
      });
  }

  @action
  patchSavedViewId(savedViewId: number | null) {
    this.parentId = savedViewId;
    this.parentType = "saved";
    this.saveTabs();
  }

  @action
  submitSearchForm() {
    // cast values for conditional filters to numbers and wipe wrong values
    this.conditionalFilters = this.conditionalFilters
      .map(filter => {
        const value = filter.value === "" ? "" : Number(filter.value);
        return { ...filter, value };
      })
      .filter(filter => isNumber(filter.value));

    this.applied.filters = cloneDeep(toJS(this.filters));
    this.applied.conditionalFilters = this.conditionalFilters
      .slice()
      .sort((a, b) => a.value - b.value)
      .map(conditionalFilter => toJS(conditionalFilter));

    this.saveTabs();
    this.getFlightsData();
    this.getFlightsCountData();
    this.getDepartureDateExtremes().then(() => this.getBuildCurvesData());

    this.updateSelectedRows([], []);
  }

  @action.bound
  clearSearchParam(name: string) {
    if (name in this.filters) {
      this.filters[name] = FILTERS_INIT[name];
    } else {
      const otherFilters = this.conditionalFilters.filter(filter => filter.name !== name);

      this.conditionalFilters = [...otherFilters];
    }

    this.submitSearchForm();
  }

  @action.bound
  toggleFixedColumn(column: string) {
    this.flightsTable.toggleFixedColumn(column);

    this.saveTabs();
  }

  @action.bound
  resetFixedColumns() {
    this.flightsTable.resetFixedColumns(TAB_INIT.flightsTable.fixedColumns);
  }

  @action.bound
  hideColumn(columnId: string) {
    this.flightsTable.hideColumn(columnId);

    this.saveTabs();
  }

  @action.bound
  changeBuildPeriod(buildPeriod: number) {
    this.xDayBuild = buildPeriod;
    this.submitSearchForm();
  }

  @action
  changeBuildCurvesParams(key, items, options = { refetch: true, skipSave: false }) {
    if (!key) return;

    const { refetch, skipSave } = options;

    Object.assign(this.buildCurves, {
      ...(refetch && { data: [], status: Status.LOADING }),
      [key]: items
    });

    if (refetch) {
      this.getDepartureDateExtremes().then(() => this.getBuildCurvesData());
    }

    if (!skipSave) {
      this.saveTabs();
    }
  }

  @action
  changeFlightsTableParams(key, items, options = { refetch: true, skipSave: false, savePageIndex: false }) {
    if (!key) return;

    const { refetch, skipSave, savePageIndex } = options;

    this.flightsTable.changeFlightsTableParams(key, items, options);

    if (key === "aggregations") {
      this.updateSelectedRows([], []);
    }

    if (refetch) {
      const params = savePageIndex ? { pageIndex: this.flightsTable.pagination.pageIndex } : {};

      this.getFlightsData(params);
      this.getFlightsCountData(params);
    }

    if (!skipSave) {
      this.saveTabs();
    }
  }

  getEndValue(endValue: number): number {
    if (isNumber(endValue)) {
      return endValue;
    }
    if (this.isForecastMode || this.isMixedMode) {
      return Math.min(DAYS_FORWARD, this.buildCurves.range.end);
    }
    return 0;
  }

  getStartValue(startValue: number): number {
    const { start } = this.buildCurves.range;
    const min = isNumber(start) ? Math.max(start, DAYS_BACK * -1) : DAYS_BACK * -1;
    if (isNumber(startValue)) {
      return Math.max(startValue, min);
    }
    if (this.isHistoricalMode || this.isMixedMode) {
      return min;
    }
    return 0;
  }

  @action
  changeSelectedRange(range: { start: number, end: number } = {}) {
    const newEnd = this.getEndValue(range.end);
    const newStart = this.getStartValue(range.start);

    const previousSelectedRangeEnd = this.buildCurves.selectedRange.end;
    this.buildCurves.selectedRange = { start: newStart, end: newEnd };

    // if end date is smaller than previous one, then never refetch data
    const isSmallerRange = previousSelectedRangeEnd >= newEnd;
    if (isSmallerRange) {
      return;
    }

    const { scenarioDate } = this.buildCurves.data;
    const lastScenarioDate = new Date(scenarioDate[scenarioDate.length - 1]);
    const maxDataRange = differenceInDays(lastScenarioDate, this.getToday());

    // if user selected range where we have no data, get them
    if (newEnd > maxDataRange && (this.isForecastMode || this.isMixedMode)) {
      this.getBuildCurvesData({ daysForward: newEnd });
    }
  }

  @action
  updatePreviewData(columns: Array, data: Object) {
    if (columns == null && this.flightsTable.influenceImpactGroupColumns == null) {
      return;
    }

    const flightsData = columns ? this.flightsTable.data : clearDataFromPriceAdjExtraKeys(this.flightsTable.data);

    Object.assign(this.flightsTable, {
      data: data || flightsData,
      influenceImpactGroupColumns: columns
    });
  }

  @action.bound
  resetInfluence() {
    this.rootStore.influenceStore.resetSteps();
    this.updatePreviewData(undefined);
  }

  @action.bound
  finishInfluence() {
    this.resetInfluence();
    this.getFlightsData();
    this.getFlightsCountData();
    this.updateSelectedRows([], []);
  }

  @action
  changeSeries(series: Array<string | Array<string>>) {
    this.buildCurves.series = series;

    this.saveTabs();
    this.getBuildCurvesData();
  }

  @action
  changeBuildCurvesClass(cabinClass: string) {
    this.buildCurves.cabinClass = [cabinClass];

    this.saveTabs();
    this.getDepartureDateExtremes().then(() => this.getBuildCurvesData());
  }

  @action.bound
  changeFilter(filterKey: string, value) {
    this.filters[filterKey] = value;
  }

  @action.bound
  clearAllFilters() {
    this.filters = { ...FILTERS_INIT };
    this.conditionalFilters = [];
  }

  @action.bound
  resetFiltersToDefaults() {
    const { duplicatedFromSavedId, parentId: savedId, savedViewId, parentType } = this;

    const filtersState = this.tabsStore.createFiltersState();

    if (!savedId && !duplicatedFromSavedId) {
      this.filters = filtersState.filters;
      this.conditionalFilters = filtersState.conditionalFilters;

      return;
    }

    const savedView = this.rootStore.templatesStore.getTemplateById(
      savedId || duplicatedFromSavedId,
      parentType,
      savedViewId
    );

    if (savedView) {
      this.filters = { ...savedView.view.filters };
      this.conditionalFilters = [...savedView.view.conditionalFilters];
    }
  }

  @action.bound
  removeFilterValue(filterKey: string, option) {
    const { filters } = this;
    if (filters[filterKey]) {
      filters[filterKey] = removeFilterValue(filters[filterKey], option, FILTERS_INIT[filterKey]);
    } else {
      const optionToRemove = this.conditionalFilters.find(
        ({ name, func, value }) => name === filterKey && func === option.func && option.value === value
      );
      this.conditionalFilters.remove(optionToRemove);
    }

    this.submitSearchForm();
  }

  @action.bound
  changeConditionalFilter(conditionIndex: number, newCondition: Object) {
    this.conditionalFilters[conditionIndex] = { ...this.conditionalFilters[conditionIndex], ...newCondition };
  }

  @action.bound
  addCondition(newCondition) {
    this.conditionalFilters.push(newCondition);
  }

  @action.bound
  deleteCondition(conditionIndex) {
    this.conditionalFilters.replace(this.conditionalFilters.filter((condition, index) => index !== conditionIndex));
  }

  @action.bound
  updateSelectedRows(selectedRows: Array<string>, selectedRowsNumberOfFlights: Object) {
    const { flightsTable, flightsCount, refetchFlightsTableData } = this;
    let shouldRefetch = false;
    if (flightsTable.showOnlySelected) {
      shouldRefetch = selectedRows.length < flightsTable.selectedRows.length;
      if (isEmpty(selectedRows)) {
        this.toggleShowOnlySelected();
      }
    }
    flightsTable.selectedRows.replace(selectedRows);

    flightsCount.selectedRowsNumberOfFlights = {
      ...flightsCount.selectedRowsNumberOfFlights,
      ...selectedRowsNumberOfFlights
    };

    if (shouldRefetch) {
      refetchFlightsTableData();
    }
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { flightsTable, flightsCount, refetchFlightsTableData } = this;
    const { aggregations, data, selectedRows } = flightsTable;

    const isToggleOn = selectedRows.includes(clickedRow);
    const rowIds = data.map(row => getRowId(aggregations, row));
    const rowNumberOfFlights = data.map(row => row.numberOfFlights);

    const sliceIndexes = [rowIds.indexOf(selectedRow), rowIds.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRowIds = rowIds.slice(sliceIndexes[0], sliceIndexes[1] + 1);
    const actionRowNumberOfFlights = rowNumberOfFlights.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    let shouldRefetch = false;
    if (this.flightsTable.showOnlySelected) {
      shouldRefetch = actionRowIds.length <= selectedRows.length;
      if (isEqual(actionRowIds, selectedRows)) {
        this.toggleShowOnlySelected();
      }
    }

    this.flightsTable.selectedRows = !isToggleOn
      ? union(selectedRows, actionRowIds)
      : difference(selectedRows, actionRowIds);

    const selectedRowsNumberOfFlights = {};
    actionRowIds.forEach((rowId, index) => {
      const value = !isToggleOn ? actionRowNumberOfFlights[index] : undefined;
      selectedRowsNumberOfFlights[rowId] = value;
    });

    flightsCount.selectedRowsNumberOfFlights = {
      ...flightsCount.selectedRowsNumberOfFlights,
      ...selectedRowsNumberOfFlights
    };

    if (shouldRefetch) {
      refetchFlightsTableData();
    }
  }

  @action
  saveTabs() {
    this.tabsStore.saveTabs();
  }

  @computed
  get selectedRowsNumberOfFlights(): number {
    const { flightsTable, flightsCount } = this;
    return Object.entries(flightsCount.selectedRowsNumberOfFlights)
      .map(([flightId, value]) => (flightsTable.selectedRows.includes(flightId) ? value : 0))
      .reduce((a, b) => a + b, 0);
  }

  @computed
  get buildCurvesFlatSeries(): Array<string> {
    return this.buildCurves.series.flatMap(group => group[1]);
  }

  @computed
  get enabledHistoricalSeries(): Array<string> {
    return difference(this.buildCurvesFlatSeries, forecastedSeries);
  }

  @computed
  get enabledForecastedSeries(): Array<string> {
    return intersection(this.buildCurvesFlatSeries, [...forecastedSeries, ...sharedSeries]);
  }

  @computed
  get isHistoricalMode(): boolean {
    return !isEmpty(difference(this.enabledHistoricalSeries, sharedSeries));
  }

  @computed
  get isForecastMode(): boolean {
    return !isEmpty(difference(this.enabledForecastedSeries, sharedSeries));
  }

  @computed
  get isMixedMode(): boolean {
    return (
      (this.isHistoricalMode && this.isForecastMode) || // both historical and forecast series are enabled
      (!this.isHistoricalMode && !this.isForecastMode) // only shared series are enabled
    );
  }

  @computed
  get isBuildCurvesZoomed(): boolean {
    const { buildCurves } = this;
    if (!buildCurves.selectedRange) {
      return false;
    }

    const isStartChanged = buildCurves.selectedRange.start !== this.getStartValue();
    const isEndChanged = buildCurves.selectedRange.end !== this.getEndValue();

    return isStartChanged || isEndChanged;
  }

  toJSON(): TabStruct {
    const tab = {
      ...this,
      flightsTable: this.flightsTable.toJSON()
    };

    return omit(toJS(tab), ["rootStore", "tabsStore", "sidebar"]);
  }
}
