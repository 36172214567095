import AdministrativeFilters from "../components/AnalysisSidebarFilterGroups/AdministrativeFilters";
import CompetitorFareFilters from "../components/CompetitorFareFilters";
import DepartureDateFilters from "../components/AnalysisSidebarFilterGroups/DepartureDateFilters";
import FlightFilters from "../components/AnalysisSidebarFilterGroups/FlightFilters";
import LacFilters from "../components/AnalysisSidebarFilterGroups/LacFilters";
import MarketFilters from "../components/AnalysisSidebarFilterGroups/MarketFilters";
import * as metricGroups from "./metricLabels/constants";

export const filterGroups = [
  {
    key: "market",
    name: "Market",
    filters: [
      { key: "regionId" },
      { key: "subregionId" },
      { key: "rtMarket" },
      { key: "owMarket" },
      { key: "origin" },
      { key: "destination" }
    ],
    Component: MarketFilters
  },
  {
    key: "flight",
    name: "Flight",
    filters: [
      { key: "flightNumber" },
      { key: "depTime", isMetric: true },
      { key: "depTimeBucket" },
      { key: "cabinClass" },
      { key: "distance", isMetric: true, isNonNegative: true }
    ],
    Component: FlightFilters
  },
  {
    key: "dep-date",
    name: "Departure Date",
    filters: [
      { key: "quarter" },
      { key: "ndo" },
      { key: "depMonth" },
      { key: "depWeek" },
      { key: "depDate" },
      { key: "depDow" }
    ],
    Component: DepartureDateFilters
  },
  {
    key: "administrative",
    name: "Administrative",
    filters: [{ key: "fusionrmStatus" }, { key: "airline" }, { key: "analystId" }],
    Component: AdministrativeFilters
  },
  {
    key: "finalRevenueGroup",
    name: "Final Revenue",
    filters: [
      { key: "xDayRevenuePotential", isMetric: true },
      { key: "finalRevenueExpected", isMetric: true, isNonNegative: true },
      { key: "finalRevenueBaseline", isMetric: true, isNonNegative: true },
      { key: "xDayFinalRevenueExpected", isMetric: true, isNonNegative: true }
    ]
  },
  {
    key: "finalRaskGroup",
    name: "Final Rask",
    filters: [
      { key: "finalRaskExpected", isMetric: true, isNonNegative: true },
      { key: "xDayFinalRaskExpected", isMetric: true, isNonNegative: true },
      { key: "xDayFinalRaskBuildExpected", isMetric: true },
      { key: "finalRaskBaseline", isMetric: true, isNonNegative: true }
    ]
  },
  {
    key: "raskGroup",
    name: "RASK",
    filters: [
      { key: "rask", isMetric: true, isNonNegative: true },
      { key: "raskBaseline", isMetric: true, isNonNegative: true },
      { key: "raskDiffToBaseline", isMetric: true },
      { key: "xDayRask", isMetric: true, isNonNegative: true },
      { key: "xDayRaskBaseline", isMetric: true, isNonNegative: true },
      { key: "xDayRaskDiffToBaseline", isMetric: true },
      { key: "xDayRaskBuild", isMetric: true },
      { key: "xDayRaskBuildBaseline", isMetric: true },
      { key: "xDayRaskBuildDiffToBaseline", isMetric: true }
    ]
  },
  {
    key: "revenueGroup",
    name: "Revenue",
    filters: [
      { key: "revenue", isMetric: true, isNonNegative: true },
      { key: "revenueBaseline", isMetric: true, isNonNegative: true },
      { key: "diffToErb", isMetric: true },
      { key: "xDayRevenue", isMetric: true, isNonNegative: true },
      { key: "xDayRevenueBaseline", isMetric: true, isNonNegative: true },
      { key: "xDayRevenueDiffToBaseline", isMetric: true },
      { key: "xDayRevenueBuildDiff", isMetric: true },
      { key: "xDayRevenueBuildBaseline", isMetric: true },
      { key: "xDayRevenueBuildDiffToBaseline", isMetric: true }
    ]
  },
  {
    key: "finalLoadFactorGroup",
    name: "Final Load Factor",
    filters: [
      { key: "finalLoadFactorExpected", isMetric: true, isNonNegative: true },
      { key: "xDayFinalLoadFactorExpected", isMetric: true, isNonNegative: true },
      { key: "xDayFinalLoadFactorBuildExpected", isMetric: true },
      { key: "finalLoadFactorBaseline", isMetric: true, isNonNegative: true }
    ]
  },
  {
    key: "loadFactorGroup",
    name: "Load Factor",
    filters: [
      { key: "loadFactor", isMetric: true, isNonNegative: true },
      { key: "loadFactorBaseline", isMetric: true, isNonNegative: true },
      { key: "diffToElb", isMetric: true },
      { key: "xDayLoadFactor", isMetric: true, isNonNegative: true },
      { key: "xDayLoadFactorBaseline", isMetric: true, isNonNegative: true },
      { key: "xDayLoadFactorDiffToBaseline", isMetric: true },
      { key: "xDayLoadFactorBuild", isMetric: true },
      { key: "xDayLoadFactorBuildBaseline", isMetric: true },
      { key: "xDayLoadFactorBuildDiffToBaseline", isMetric: true }
    ]
  },
  {
    key: "bookingsGroup",
    name: "Bookings",
    filters: [
      { key: "netBookings", isMetric: true, isNonNegative: true },
      { key: "netBookingsBaseline", isMetric: true, isNonNegative: true },
      { key: "netBookingsDiffToBaseline", isMetric: true },
      { key: "xDayNetBookings", isMetric: true, isNonNegative: true },
      { key: "xDayNetBookingsBaseline", isMetric: true, isNonNegative: true },
      { key: "xDayNetBookingsDiffToBaseline", isMetric: true },
      { key: "xDayNetBookingsBuild", isMetric: true },
      { key: "xDayNetBookingsBuildBaseline", isMetric: true },
      { key: "xDayNetBookingsBuildDiffToBaseline", isMetric: true }
    ]
  },
  {
    key: "capacityGroup",
    name: "Capacity",
    filters: [
      { key: "aircraftCapacity", isMetric: true, isNonNegative: true },
      { key: "sellableCapacity", isMetric: true, isNonNegative: true },
      { key: "xDayAircraftCapacity", isMetric: true, isNonNegative: true },
      { key: "xDaySellableCapacity", isMetric: true, isNonNegative: true },
      { key: "xDayAircraftCapacityBuild", isMetric: true },
      { key: "xDaySellableCapacityBuild", isMetric: true }
    ]
  },
  {
    key: "finalYieldGroup",
    name: "Final Yield",
    filters: [
      { key: "finalYieldExpected", isMetric: true, isNonNegative: true },
      { key: "xDayFinalYieldExpected", isMetric: true, isNonNegative: true },
      { key: "xDayFinalYieldBuildExpected", isMetric: true },
      { key: "finalYieldBaseline", isMetric: true, isNonNegative: true }
    ]
  },
  {
    key: "yieldGroup",
    name: "Yield",
    filters: [
      { key: "yield", isMetric: true, isNonNegative: true },
      { key: "yieldBaseline", isMetric: true, isNonNegative: true },
      { key: "yieldDiffToBaseline", isMetric: true },
      { key: "xDayYield", isMetric: true, isNonNegative: true },
      { key: "xDayYieldBaseline", isMetric: true, isNonNegative: true },
      { key: "xDayYieldDiffToBaseline", isMetric: true },
      { key: "xDayYieldBuild", isMetric: true },
      { key: "xDayYieldBuildBaseline", isMetric: true },
      { key: "xDayYieldBuildDiffToBaseline", isMetric: true }
    ]
  },
  {
    key: "finalRevenuePerBookingGroup",
    name: "Final Revenue per Booking",
    filters: [
      { key: "finalRevenuePerBookingExpected", isMetric: true, isNonNegative: true },
      { key: "xDayFinalRevenuePerBookingExpected", isMetric: true, isNonNegative: true },
      { key: "xDayFinalRevenuePerBookingBuildExpected", isMetric: true },
      { key: "finalRevenuePerBookingBaseline", isMetric: true, isNonNegative: true }
    ]
  },
  {
    key: "revenuePerBookingGroup",
    name: "Revenue per Booking",
    filters: [
      { key: "revenuePerBooking", isMetric: true, isNonNegative: true },
      { key: "revenuePerBookingBaseline", isMetric: true, isNonNegative: true },
      { key: "revenuePerBookingDiffToBaseline", isMetric: true },
      { key: "xDayRevenuePerBooking", isMetric: true, isNonNegative: true },
      { key: "xDayRevenuePerBookingBaseline", isMetric: true, isNonNegative: true },
      { key: "xDayRevenuePerBookingDiffToBaseline", isMetric: true },
      { key: "xDayRevenuePerBookingBuild", isMetric: true },
      { key: "xDayRevenuePerBookingBuildBaseline", isMetric: true },
      { key: "xDayRevenuePerBookingBuildDiffToBaseline", isMetric: true }
    ]
  },
  {
    key: "sellingFareGroup",
    name: "Selling Fare",
    filters: [
      { key: "sellingPrice", isMetric: true, isNonNegative: true },
      { key: "xDaySellingPrice", isMetric: true, isNonNegative: true },
      { key: "sellingPriceFrm", isMetric: true, isNonNegative: true },
      { key: "xDaySellingPriceFrm", isMetric: true, isNonNegative: true },
      { key: "xDayPriceBuildDiff", isMetric: true }
    ]
  },
  {
    key: "pricePercentileGroup",
    name: "Price percentile",
    filters: [
      { key: "pricePercentile", isMetric: true, isNonNegative: true },
      { key: "xDayPricePercentile", isMetric: true, isNonNegative: true },
      { key: "pricePercentileFrm", isMetric: true, isNonNegative: true },
      { key: "xDayPricePercentileFrm", isMetric: true, isNonNegative: true },
      { key: "xDayPricePercentileBuild", isMetric: true }
    ]
  },
  {
    key: "lacGroup",
    name: "LAC",
    filters: [
      { key: "lacRank", isMetric: true, isNonNegative: true },
      { key: "lacRbd", isMetric: true },
      { key: "xDayLacRank", isMetric: true, isNonNegative: true },
      { key: "xDayLacRbd", isMetric: true },
      { key: "lacRankFrm", isMetric: true, isNonNegative: true },
      { key: "lacRbdFrm", isMetric: true },
      { key: "xDayLacRankFrm", isMetric: true, isNonNegative: true },
      { key: "xDayLacRbdFrm", isMetric: true },
      { key: "xDayLacBuildDiff", isMetric: true }
    ],
    Component: LacFilters
  },
  {
    key: "lacSeatsAvailableGroup",
    name: "Seat Allocations",
    filters: [{ key: "lacSeatsAvailable", isMetric: true, isNonNegative: true }]
  },
  {
    key: "competitorFareGroup",
    name: metricGroups.baseCompetitorFareGroup().competitorFareGroup,
    filters: [
      { key: "compFareCirrusMatchAirline", isMetric: true },
      { key: "compFareCirrusMatchFare", isMetric: true, isNonNegative: true },
      { key: "sellingFareDiffToCirrusCompFarePercentile", isMetric: true },
      { key: "sellingFareDiffToCirrusCompFare", isMetric: true },
      { key: "compFareTimeMatchAirline", isMetric: true },
      { key: "compFareTimeMatchFare", isMetric: true, isNonNegative: true },
      { key: "sellingFareDiffToTimePeriodCompFarePercentile", isMetric: true },
      { key: "sellingFareDiffToTimePeriodCompFare", isMetric: true },
      { key: "compFareDateMatchAirline", isMetric: true },
      { key: "compFareDateMatchFare", isMetric: true, isNonNegative: true },
      { key: "sellingFareDiffToDepDateCompFarePercentile", isMetric: true },
      { key: "sellingFareDiffToDepDateCompFare", isMetric: true }
    ],
    Component: CompetitorFareFilters
  },
  {
    key: "influenceGroup",
    name: "Influence",
    filters: [
      {
        key: "numberOfImpactedFlights",
        isMetric: true,
        excludeInFilters: true
      },
      {
        key: "priceAdjustment",
        isMetric: true
      },
      { key: "minmaxAdjustmentMin", isMetric: true, isNonNegative: true },
      { key: "minmaxAdjustmentMax", isMetric: true, isNonNegative: true }
    ]
  }
];

const filterIsMetric = filter => filter.isMetric;

export const exploreMetrics = filterGroups
  .filter(group => group.filters.some(filterIsMetric))
  .map(({ key, filters }) => {
    const metrics = filters.filter(filterIsMetric);
    return [key, metrics];
  });

export const buildCurvesMetrics = [
  ["raskGroup", [{ key: "rask" }, { key: "raskBaseline" }, { key: "finalRaskExpected" }, { key: "raskExpected" }]],
  [
    "revenueGroup",
    [{ key: "revenue" }, { key: "revenueBaseline" }, { key: "finalRevenueExpected" }, { key: "revenueExpected" }]
  ],
  [
    "revenuePerBookingGroup",
    [{ key: "revenuePerBooking" }, { key: "revenuePerBookingBaseline" }, { key: "finalRevenuePerBookingExpected" }]
  ],
  [
    "loadFactorGroup",
    [
      { key: "loadFactor" },
      { key: "loadFactorBaseline" },
      { key: "finalLoadFactorExpected" },
      { key: "loadFactorExpected" }
    ]
  ],
  ["yieldGroup", [{ key: "yield" }, { key: "yieldBaseline" }, { key: "finalYieldExpected" }]],
  ["sellingFareGroup", [{ key: "sellingPrice" }]],
  ["competitorFareGroup", [{ key: "compFareCirrusMatchFare" }]],
  ["pricePercentileGroup", [{ key: "pricePercentile" }]],
  ["lacGroup", [{ key: "lacRank" }]]
];

export const filterOrder = filterGroups.map(({ filters }) => filters.map(({ key }) => key)).flat();
