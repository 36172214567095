// @flow

import React from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";

import AdminToolsNavigation from "../components/AdminToolsNavigation";
import StyledScrollableContainer from "../components/StyledScrollableContainer";
import Cirrus from "./Cirrus";
import SystemUnits from "./SystemUnits";
import MarketMappings from "./MarketMappings";
import RegionsSubregions from "./Regions";

const StyledAdminContent = styled("div")`
  min-width: 0;
`;

const ADMIN_TOOLS_ROUTING = {
  "/cirrus-status": Cirrus,
  "/system-units": SystemUnits,
  "/market-mappings": MarketMappings,
  "/regions": RegionsSubregions
};

export const ADMIN_TOOLS_ROUTES = Object.keys(ADMIN_TOOLS_ROUTING);

function AdminTools() {
  const { pathname } = useLocation();

  const Content = ADMIN_TOOLS_ROUTING[pathname];

  return (
    <StyledScrollableContainer className="d-flex flex-grow-1 h-100">
      <AdminToolsNavigation currentPage={pathname} />
      <StyledAdminContent className="flex-grow-1 d-flex flex-column">
        <Content />
      </StyledAdminContent>
    </StyledScrollableContainer>
  );
}

export default observer(AdminTools);
