// @flow

import React from "react";
import styled from "@emotion/styled";
import { Button, Card, Colors, Intent, Spinner } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const CardStyled = styled(Card)`
  border: 1px solid ${Colors.LIGHT_GRAY2};
  box-shadow: none;
`;

type Props = {
  data: {
    influenceId: string,
    base: Object,
    preview: Object,
    previewCounters: Object
  },
  finishInfluence: Function,
  status: string
};

function InfluenceControlSummary(props: Props) {
  const { data, finishInfluence, status } = props;

  function renderContent() {
    if (status === "LOADING") {
      return <Spinner />;
    }
    if (status === "ERROR") {
      return <h3>Saving influence failed</h3>;
    }
    return (
      <h3>
        Influence saved as <Link to="/influence-history">{data.influenceId || "?"}</Link>
      </h3>
    );
  }

  return (
    <div className="w-100 h-100 p-2" data-testid="influence-control-summary">
      <CardStyled className="h-100 p-2 text-center">
        {renderContent()}
        <div className="m-4">
          <Button data-testid="influence-control-summary-return-btn" intent={Intent.PRIMARY} onClick={finishInfluence}>
            Return to controls
          </Button>
        </div>
      </CardStyled>
    </div>
  );
}

export default InfluenceControlSummary;
