// @flow

import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useDebounce } from "use-debounce";
import styled from "@emotion/styled";
import { Button } from "@blueprintjs/core";
import isEmpty from "lodash.isempty";

import isNumber from "../helpers/isNumber";
import metricLabels from "../helpers/metricLabels/metricLabels";
import { buildCurvesMetrics as allMetrics } from "../helpers/metrics";
import { Status } from "../helpers/Status";

import CabinClassSelect from "./CabinClassSelect";
import ColumnSelect from "./ColumnSelect";
import Graph from "./Graph";
import GraphTimeline from "./GraphTimeline";
import Module from "./Module";

const OpacityStyled = styled("div")`
  ${({ isTranslucent }) => isTranslucent && `opacity: 0.5`};
`;

type Props = {
  baseMetrics: Array<string | Array<string>>,
  cabinClass: string,
  cabinClasses: Array<string>,
  changeCabinClass: Function,
  changeGroupsStatus: Function,
  changeSelectedRange: Function,
  changeSeries: Function,
  data: Object,
  fetchData: Function,
  flightsTableSelectedRows: number,
  groupStatuses: Array<mixed>,
  isBuildCurvesZoomed: boolean,
  isCollapsed: boolean,
  range: { start: ?number, end: ?number },
  selectedRange: { start: ?number, end: ?number },
  series: Array<string | Array<string>>,
  setCollapsed: Function,
  status: string,
  subtitle: Array<string | Element>,
  today: number,
  transformToMile: boolean
};

function BuildCurves(props: Props) {
  const {
    baseMetrics = [],
    cabinClass,
    cabinClasses = [],
    changeCabinClass,
    changeGroupsStatus,
    changeSelectedRange,
    changeSeries,
    data,
    fetchData,
    flightsTableSelectedRows,
    groupStatuses = [],
    isBuildCurvesZoomed,
    isCollapsed,
    range,
    selectedRange,
    series = [],
    setCollapsed,
    status,
    subtitle = [],
    today,
    transformToMile
  } = props;

  const isInitialized = useRef({ translucency: false, fetching: false });
  const [isTranslucent, setTranslucent] = useState(false);

  const trigger = JSON.stringify(flightsTableSelectedRows);
  const [triggerDebounced] = useDebounce(trigger, 1000);

  // effect for opacity
  useEffect(() => {
    // on first change do nothing
    if (!isInitialized.current.translucency) {
      isInitialized.current.translucency = true;
      return;
    }

    setTranslucent(true);
  }, [trigger]);

  // effect for fetching data
  useEffect(() => {
    // on first change do nothing
    if (!isInitialized.current.fetching) {
      isInitialized.current.fetching = true;
      return;
    }

    setTranslucent(false);
    fetchData();
  }, [triggerDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

  // if selected cabin class is not on the list, select first one
  useEffect(() => {
    if (cabinClasses.length && !cabinClasses.includes(cabinClass[0])) {
      changeCabinClass(cabinClasses[0]);
    }
  }, [JSON.stringify(cabinClasses)]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = status === Status.LOADING;
  const columnLabels = metricLabels({ transformToMile, withUnits: true });
  const seriesSelectorLabels = { ...columnLabels };
  const tooltipLabels = metricLabels({ transformToMile, withUnits: false });

  // metrics from final groups are added to non-final groups so name needs prefix
  ["finalRaskExpected", "finalRevenueExpected"].forEach(finalKey => {
    columnLabels[finalKey] = `Final ${columnLabels[finalKey]}`;
    seriesSelectorLabels[finalKey] = `Final ${seriesSelectorLabels[finalKey]}`;
    tooltipLabels[finalKey] = `Final ${tooltipLabels[finalKey]}`;
  });

  // custom shorten labels for groups
  tooltipLabels.loadFactorGroup = "LF";
  tooltipLabels.revenueGroup = "Rev";

  ["finalLoadFactorExpected", "finalYieldExpected", "finalRevenuePerBookingExpected"].forEach(key => {
    columnLabels[key] = columnLabels[key].replace("Exp", "Final Exp");
    seriesSelectorLabels[key] = seriesSelectorLabels[key].replace("Exp", "Final Exp");
    tooltipLabels[key] = tooltipLabels[key].replace("Exp", "Final Exp");
  });

  columnLabels.revenuePerBookingGroup = "Rev/Bkg";
  tooltipLabels.revenuePerBookingGroup = "Rev/Bkg";

  columnLabels.competitorFareGroup = "Comp Fare";
  tooltipLabels.competitorFareGroup = "Comp Fare";

  const isSelectedRange = isNumber(selectedRange?.start) && isNumber(selectedRange?.end);
  const isData = !isEmpty(data.scenarioDate || data.ndo);
  const isGraphTimelineVisible = isSelectedRange && isData;

  const columnSelect = (
    <ColumnSelect
      allMetrics={allMetrics}
      baseMetrics={baseMetrics}
      buttonIcon="series-add"
      buttonLabel="Series"
      changeColumns={changeSeries}
      changeGroupsStatus={changeGroupsStatus}
      columnLabels={seriesSelectorLabels}
      columns={series}
      disabled={isLoading}
      groupStatuses={groupStatuses}
      showLegend
    />
  );

  const cabinSelect = (
    <CabinClassSelect
      cabinClasses={cabinClasses}
      changeCabinClass={changeCabinClass}
      className="mx-2"
      disabled={isLoading}
      selectedCabinClass={cabinClass}
    />
  );

  const isResetZoomButtonVisible = !isLoading && isBuildCurvesZoomed && isGraphTimelineVisible;
  const resetZoomButton = isResetZoomButtonVisible && (
    <Button data-testid="reset-zoom-button" text="Reset Zoom" onClick={changeSelectedRange} />
  );

  const graphProps = {
    ...props,
    allMetrics,
    changeSelectedRange,
    columnLabels,
    customYAxis: {
      lacGroup: {
        allowDecimals: false,
        labels: {
          formatter: ({ isFirst, value }) => {
            // display every second label, even or odd based on max lac rank
            return !isFirst && value % 2 === data.maxLacRank % 2 ? value : null;
          }
        },
        reversed: true,
        softMax: data.maxLacRank,
        tickAmount: data.maxLacRank ? data.maxLacRank + 1 : undefined,
        tickInterval: data.maxLacRank ? 1 : undefined
      }
    },
    maxGrouped: [{ target: "revenuePerBookingGroup", sources: ["competitorFareGroup", "sellingFareGroup"] }],
    percentGroup: ["pricePercentileGroup", "loadFactorGroup"],
    mergedGroups: [
      ["pricePercentileGroup", "loadFactorGroup"],
      ["competitorFareGroup", "sellingFareGroup"],
      ["yieldGroup", "raskGroup"]
    ],
    today,
    tooltipLabels
  };

  const graphTimelineProps = {
    changeSelectedRange,
    range,
    selectedRange,
    series,
    status,
    today
  };

  return (
    <Module
      className="h-100"
      isCollapsed={isCollapsed}
      minHeight={375} // graph (350) + timeline (25)
      setCollapsed={setCollapsed}
      subtitle={subtitle}
      title="Build Curves"
      titleTools={
        <div className="d-flex">
          {resetZoomButton}
          {cabinSelect}
          {columnSelect}
        </div>
      }
    >
      <OpacityStyled isTranslucent={isTranslucent}>
        <Graph {...graphProps} />
        {isGraphTimelineVisible && <GraphTimeline {...graphTimelineProps} />}
      </OpacityStyled>
    </Module>
  );
}

export default observer(BuildCurves);
