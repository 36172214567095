// @flow

import React from "react";
import { observer } from "mobx-react";

import FilterInteractionsWrapper from "../FilterInteractionsWrapper";
import EventsManagementTagList from "./EventsManagementTagList";
import TopBar from "../TopBar";
import { useStores } from "../../store/Store";

function EventsManagementTopBar() {
  const { analysisMappingsStore, eventsManagementStore } = useStores();
  const { isLoading } = analysisMappingsStore;
  const { page, setSidebarOpen } = eventsManagementStore;

  return (
    <TopBar>
      {page.applied && <EventsManagementTagList />}
      <FilterInteractionsWrapper
        buttonProps={{
          className: "ml-auto",
          testId: "sidebar-button",
          isLoading,
          onClick: setSidebarOpen
        }}
        withSwitchButton={false}
      />
    </TopBar>
  );
}

export default observer(EventsManagementTopBar);
