// @flow

import formatValueWithUnit from "./FormatValueWithUnit";
import isNumber from "./isNumber";

const minMaxValue = value => {
  return value === -1 ? "DEL" : `${formatValueWithUnit(value, "currency")}`;
};

export default function minMaxDataTableCell(singleValue: Object) {
  const min = isNumber(singleValue.min) ? minMaxValue(singleValue.min) : "--";
  const max = isNumber(singleValue.max) ? minMaxValue(singleValue.max) : "--";

  return `(${min}/${max})`;
}
