import React from "react";
import isEmpty from "lodash.isempty";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Switch } from "@blueprintjs/core";

import AggregationSelect from "./AggregationSelect";
import ColumnSelect from "./ColumnSelect";
import { exploreMetrics } from "../helpers/metrics";
import { canEnableMetric } from "../helpers/flightsTable";

const StyledSwitch = styled(Switch)`
  margin-bottom: 0;
`;

type Props = {
  aggregationsDisabled: boolean,
  baseMetrics: Array<string, Array<string>>,
  columnLabels: Array<string>,
  columnSelectDisabled: boolean,
  tab: Object
};

function FlightsTableTools(props: Props) {
  const { aggregationsDisabled, baseMetrics, columnLabels, columnSelectDisabled, tab } = props;
  const { aggregations, columns, groupStatuses, selectedRows, showOnlySelected } = tab.flightsTable;

  const selectableMetrics = exploreMetrics.map(([group, metrics]) => {
    const metricsToSelect = metrics.map(metric => {
      return {
        ...metric,
        isDisabled: !canEnableMetric(aggregations, metric.key)
      };
    });
    return [group, metricsToSelect];
  });

  return (
    <div className="d-flex align-items-center">
      <StyledSwitch
        data-testid="show-selected-switch"
        onChange={tab.toggleShowOnlySelected}
        disabled={isEmpty(selectedRows)}
        checked={showOnlySelected}
      >
        Show selected rows only
      </StyledSwitch>
      <div className="mr-2" />
      <AggregationSelect
        aggregations={aggregations}
        disabled={aggregationsDisabled}
        changeAggregations={agg => tab.changeFlightsTableParams("aggregations", agg)}
      />
      <div className="mr-2" />
      <ColumnSelect
        allMetrics={selectableMetrics}
        baseMetrics={baseMetrics}
        buttonIcon="column-layout"
        buttonLabel="Metrics columns"
        changeColumns={(columns, options) => {
          tab.changeFlightsTableParams("columns", columns, options);
        }}
        changeGroupsStatus={items => {
          tab.changeFlightsTableParams("groupStatuses", items, { refetch: false, skipSave: true });
        }}
        columnLabels={columnLabels}
        columns={columns}
        disabled={columnSelectDisabled}
        groupStatuses={groupStatuses}
        onReset={tab.resetFixedColumns}
      />
    </div>
  );
}

export default observer(FlightsTableTools);
