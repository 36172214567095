// @flow

import React from "react";
import { observer } from "mobx-react";
import pick from "lodash.pick";
import difference from "lodash.difference";

import SidebarSelect from "../SidebarSelect";
import SidebarFilter from "../SidebarFilter";
import SidebarHourSelect from "../SidebarHourSelect";
import ButtonSelector from "../ButtonSelector";
import SidebarConditionalFilter from "../SidebarConditionalFilter";
import type { AnalysisMappingsStore } from "../../models/AnalysisMappings.model";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  filters: Array<Object>,
  mappings: AnalysisMappingsStore,
  pageContext: Object
};

function FlightFilters(props: Props) {
  const { mappings, pageContext, filters: filterList } = props;
  const {
    addCondition,
    changeConditionalFilter,
    changeFilter,
    conditionalFilters,
    deleteCondition,
    filters,
    sidebar
  } = pageContext;

  const distanceFilter = filterList.find(filter => filter.key === "distance");
  const flightFilters = pick(filters, ["owMarket", "rtMarket", "origin", "destination"]);

  const matchedFlightNumbers = mappings.getFlightNumbers(flightFilters);
  const flightNumbers = difference(mappings.getFlightNumbers(null), matchedFlightNumbers);

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("flight number", "flightNumber", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: "Matching pre-selected", groupItems: matchedFlightNumbers },
            { label: "All flights", groupItems: flightNumbers }
          ]}
          onChange={filterValue => changeFilter("flightNumber", filterValue)}
          selectedItems={filters.flightNumber}
          title="Flight Number"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure time", "depTime", sidebar)}>
        <SidebarHourSelect
          hours={filters.depTime}
          onChange={filterValue => changeFilter("depTime", filterValue)}
          title="Departure Time"
        />
      </SidebarFilter>
      <SidebarFilter
        name="departure time bucket"
        isVisible={isSidebarFilterVisible("departure time bucket", "depTimeBucket", sidebar)}
      >
        <SidebarSelect
          fuzzySearch
          items={mappings.data.depTimeBucket}
          onChange={filterValue => changeFilter("depTimeBucket", filterValue)}
          selectedItems={filters.depTimeBucket}
          title="Departure Time Bucket"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("cabin class", "cabinClass", sidebar)}>
        <ButtonSelector
          items={mappings.getCabinClasses()}
          onSelect={filterValue => changeFilter("cabinClass", filterValue.sort())}
          selectedItems={filters.cabinClass}
          title="Cabin Class"
        />
      </SidebarFilter>

      {distanceFilter && (
        <SidebarFilter name="distance" isVisible={isSidebarFilterVisible("distance", "distance", sidebar)}>
          <SidebarConditionalFilter
            conditionalFilters={conditionalFilters}
            isNonNegative={distanceFilter.isNonNegative}
            name="distance"
            onAddCondition={addCondition}
            onChangeCondition={changeConditionalFilter}
            onDeleteCondition={deleteCondition}
            title="Distance"
            type="absolute"
          />
        </SidebarFilter>
      )}
    </>
  );
}

export default observer(FlightFilters);
