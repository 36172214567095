// @flow

import React from "react";
import { observer } from "mobx-react";

import FilterInteractionsWrapper from "./FilterInteractionsWrapper";
import InfluenceHistoryTagList from "./InfluenceHistoryTagList";
import TopBar from "./TopBar";
import { useStores } from "../store/Store";

function InfluenceHistoryTopBar() {
  const { analysisMappingsStore, influenceHistoryStore } = useStores();
  const { isLoading } = analysisMappingsStore;
  const { page, setSidebarOpen, toggleFiltersEnabled } = influenceHistoryStore;

  return (
    <TopBar>
      {page.applied && <InfluenceHistoryTagList />}
      <FilterInteractionsWrapper
        buttonProps={{
          className: "ml-auto",
          testId: "sidebar-button",
          isLoading,
          onClick: setSidebarOpen
        }}
        filterSwitchProps={{ checked: page.filtersEnabled, onChange: toggleFiltersEnabled }}
      />
    </TopBar>
  );
}

export default observer(InfluenceHistoryTopBar);
