import { withRouter } from "react-router-dom";

import { usePrevious } from "./usePrevious";

let previousPathName = null;
let currentPathName = null;

const SpyPaths = props => {
  previousPathName = usePrevious(props.location.pathname);
  currentPathName = props.location.pathname;
  return null;
};

export const SpyPathNames = withRouter(SpyPaths);

export const previousPageWasDifferent = () => previousPathName !== currentPathName && previousPathName;

export const getPathName = () => ({
  currentPathName,
  previousPathName
});
