// @flow

const currency = "currency";
const currencyPrecise = "currency-precise";
const percent = "percent";
const percentRelative = "percent-relative";

export const isPercent = (label: string, withBracket: ?boolean): string =>
  label === percentRelative || label === percent ? `${withBracket ? "(%)" : "%"}` : "";

export const isCurrency = (label: string, withBracket: ?boolean) =>
  label === currencyPrecise || label === currency ? `${withBracket ? "($)" : "$"}` : "";

export const replaceRegex = (replaceKey: string) => {
  if (replaceKey !== "Δ") {
    return new RegExp(`\\b${replaceKey}\\b`, "gi");
  }
  return "Δ";
};

export const replaceAllBy = (text: string, params: Object) => {
  let newText = text;

  Object.entries(params).forEach((props: Object) => {
    const [replaceKey: string, replaceValue: string] = props;
    newText = newText.replace(replaceRegex(replaceKey), replaceValue);
  });

  return newText;
};

export const baseFlightGroup = () => ({
  flight: "Flight",
  depTime: "Dep Time",
  distance: "Distance"
});

export const baseFinalRevenueGroup = () => ({
  finalRevenueGroup: "Final Revenue",
  xDayRevenuePotential: "XD Exp Bld",
  finalRevenueExpected: "Exp",
  finalRevenueBaseline: "Base",
  xDayFinalRevenueExpected: "XD Exp"
});

export const baseFinalRevenuePerBookingGroup = () => ({
  finalRevenuePerBookingGroup: "Final Revenue per Booking",
  finalRevenuePerBookingExpected: "Exp",
  xDayFinalRevenuePerBookingExpected: "XD Exp",
  xDayFinalRevenuePerBookingBuildExpected: "XD Exp Bld",
  finalRevenuePerBookingBaseline: "Base"
});

export const baseRevenuePerBookingGroup = () => ({
  revenuePerBookingGroup: "Revenue per Booking",
  revenuePerBooking: "Act",
  revenuePerBookingBaseline: "Base",
  revenuePerBookingDiffToBaseline: "Δ to Base",
  xDayRevenuePerBooking: "XD Act",
  xDayRevenuePerBookingBaseline: "XD Base",
  xDayRevenuePerBookingDiffToBaseline: "XD Δ to Base",
  xDayRevenuePerBookingBuild: "XD Bld Act",
  xDayRevenuePerBookingBuildBaseline: "XD Bld Base",
  xDayRevenuePerBookingBuildDiffToBaseline: "XD Bld Δ to Base"
});

export const baseFinalRaskGroup = (transformToMile: boolean) => ({
  finalRaskGroup: `Final ${transformToMile ? "RASM" : "RASK"}`,
  finalRaskExpected: "Exp",
  finalRaskBaseline: "Base",
  xDayFinalRaskExpected: "XD Exp",
  xDayFinalRaskBuildExpected: "XD Exp Bld"
});

export const baseRaskGroup = (transformToMile: boolean) => ({
  raskGroup: `${transformToMile ? "RASM" : "RASK"}`,
  rask: "Act",
  raskBaseline: "Base",
  raskExpected: "Expected",
  raskDiffToBaseline: "Δ to Base",
  xDayRask: "XD Act",
  xDayRaskBaseline: "XD Base",
  xDayRaskDiffToBaseline: "XD Δ to Base",
  xDayRaskBuild: "XD Bld Act",
  xDayRaskBuildBaseline: "XD Bld Base",
  xDayRaskBuildDiffToBaseline: "XD Bld Δ to Base"
});

export const baseRevenueGroup = () => ({
  revenueGroup: "Revenue",
  revenue: "Act",
  revenueBaseline: "Base",
  revenueExpected: "Expected",
  diffToErb: "Δ to Base",
  xDayRevenue: "XD Act",
  xDayRevenueBaseline: "XD Base",
  xDayRevenueDiffToBaseline: "XD Δ to Base",
  xDayRevenueBuildDiff: "XD Bld Act",
  xDayRevenueBuildBaseline: "XD Bld Base",
  xDayRevenueBuildDiffToBaseline: "XD Bld Δ to Base"
});

export const baseLoadFactorGroup = () => ({
  loadFactorGroup: "Load Factor",
  loadFactor: "Act",
  loadFactorBaseline: "Base",
  loadFactorExpected: "Expected",
  diffToElb: "Δ to Base",
  xDayLoadFactor: "XD Act",
  xDayLoadFactorBaseline: "XD Base",
  xDayLoadFactorDiffToBaseline: "XD Δ to Base",
  xDayLoadFactorBuild: "XD Bld Act",
  xDayLoadFactorBuildBaseline: "XD Bld Base",
  xDayLoadFactorBuildDiffToBaseline: "XD Bld Δ to Base"
});

export const baseFinalLoadFactorGroup = () => ({
  finalLoadFactorGroup: "Final Load Factor",
  finalLoadFactorBaseline: "Base",
  finalLoadFactorExpected: "Exp",
  xDayFinalLoadFactorExpected: "XD Exp",
  xDayFinalLoadFactorBuildExpected: "XD Exp Bld"
});

export const baseCapacityGroup = () => ({
  capacityGroup: "Capacity",
  aircraftCapacity: "Cap",
  sellableCapacity: "Sell Cap",
  xDayAircraftCapacity: "XD Cap",
  xDaySellableCapacity: "XD Sell Cap",
  xDayAircraftCapacityBuild: "XD Cap Bld",
  xDaySellableCapacityBuild: "XD Sell Cap Bld"
});

export const baseBookingsGroup = () => ({
  bookingsGroup: "Bookings",
  netBookings: "Net Act",
  netBookingsBaseline: "Net Base",
  xDayNetBookings: "XD Net Act",
  xDayNetBookingsBaseline: "XD Net Base",
  xDayNetBookingsDiffToBaseline: "XD Net Δ to Base",
  netBookingsDiffToBaseline: "Net Δ to Base",
  xDayNetBookingsBuild: "XD Bld Net Act",
  xDayNetBookingsBuildBaseline: "XD Bld Net Base",
  xDayNetBookingsBuildDiffToBaseline: "XD Bld Δ to Base"
});

export const baseYieldGroup = () => ({
  yieldGroup: "Yield",
  yield: "Act",
  yieldBaseline: "Base",
  yieldDiffToBaseline: "Δ to Base",
  xDayYield: "XD Act",
  xDayYieldBaseline: "XD Base",
  xDayYieldDiffToBaseline: "XD Δ to Base",
  xDayYieldBuild: "XD Bld Act",
  xDayYieldBuildBaseline: "XD Bld Base",
  xDayYieldBuildDiffToBaseline: "XD Bld Δ to Base"
});

export const baseFinalYieldGroup = () => ({
  finalYieldGroup: "Final Yield",
  finalYieldExpected: "Exp",
  xDayFinalYieldExpected: "XD Exp",
  xDayFinalYieldBuildExpected: "XD Exp Bld",
  finalYieldBaseline: "Base"
});

export const baseSellingFareGroup = () => ({
  sellingFareGroup: "Selling Fare",
  sellingPrice: "Fare",
  xDaySellingPrice: "XD Fare",
  sellingPriceFrm: "Cirrus Fare",
  xDaySellingPriceFrm: "XD Cirrus Fare",
  xDayPriceBuildDiff: "XD Fare Bld"
});

export const basePricePercentileGroup = () => ({
  pricePercentileGroup: "Price Percentile",
  pricePercentile: "%ile",
  xDayPricePercentile: "XD %ile",
  pricePercentileFrm: "Cirrus Fare %ile",
  xDayPricePercentileFrm: "XD Cirrus Fare %ile",
  xDayPricePercentileBuild: "XD %ile Bld"
});

export const baseLacGroup = () => ({
  lacGroup: "LAC",
  lacRank: "Value",
  lacRbd: "RBD",
  xDayLacRank: "XD Value",
  xDayLacRbd: "XD RBD",
  lacRankFrm: "Value Cirrus",
  lacRbdFrm: "RBD Cirrus",
  xDayLacRankFrm: "XD Value Cirrus",
  xDayLacRbdFrm: "XD RBD Cirrus",
  xDayLacBuildDiff: "XD Bld"
});

export const baseSeatsAllocationGroup = () => ({
  lacSeatsAvailableGroup: "Seat Allocations",
  lacSeatsAvailable: "SA LAC"
});

export const baseCompetitorFareGroup = () => ({
  competitorFareGroup: "Competitor Fare",
  compFareCirrusMatchAirline: "Airline Match",
  compFareCirrusMatchFare: "Fare Match",
  compFareTimeMatchAirline: "Airline Time",
  compFareTimeMatchFare: "Fare Time",
  compFareDateMatchAirline: "Airline Dep Date",
  compFareDateMatchFare: "Fare Dep Date",
  sellingFareDiffToCirrusCompFarePercentile: "Fare Δ to Fare Match",
  sellingFareDiffToCirrusCompFare: "Fare Δ to Fare Match",
  sellingFareDiffToTimePeriodCompFarePercentile: "Fare Δ to Fare Time",
  sellingFareDiffToTimePeriodCompFare: "Fare Δ to Fare Time",
  sellingFareDiffToDepDateCompFarePercentile: "Fare Δ to Fare Dep Date",
  sellingFareDiffToDepDateCompFare: "Fare Δ to Fare Dep Date"
});

export const baseInfluenceGroup = () => ({
  influenceGroup: "Influences",
  numberOfImpactedFlights: "Flt Impacted",
  compSensitivity: "Comp Sens",

  priceAdjustment: "Price Adj.",
  minmaxAdjustmentMin: "Min Price Limit",
  minmaxAdjustmentMax: "Max Price Limit"
});

export const baseInfluenceImpactGroup = () => ({
  influenceImpactGroup: "Influences",

  // adjustments - generic columns
  influenceNumberOfImpactedFlights: "Flt Impacted",
  influenceLacRank: "LAC Value",
  influencePricePercentile: "Price %ile",
  influenceSellingPrice: "Selling Fare",

  // adjustments - columns per type
  influencePriceAdjustment: "Price Adj.",
  influenceMinmaxAdjustmentMin: "Min Price Limit",
  influenceMinmaxAdjustmentMax: "Max Price Limit"
});

/**
 special case - not metrics
*/
export const previewInfluenceGroup = {
  numberOfImpactedFlights: "Flt Impacted",
  compSensitivity: "Comp Sens",

  lacRank: "LAC Value",
  pricePercentile: "Price %ile",
  sellingPrice: "Selling Fare",

  priceAdjustment: "Price Adj.",
  minmaxAdjustmentMin: "Min Price Limit",
  minmaxAdjustmentMax: "Max Price Limit"
};
