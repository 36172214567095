import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";

import { daysOfWeek, extraMetrics } from "../helpers/constants";
import { filterOrder } from "../helpers/metrics";
import { FILTERS_INIT, Tab } from "../models/Tab.model";
import groupConditionalFilters from "../helpers/groupConditionalFilters";
import metricLabels from "../helpers/metricLabels/metricLabels";
import MileTransformationContext from "../services/contexts/MileTransformationContext";
import { useStores } from "../store/Store";

import TagsList from "./TagsList";

type Props = {
  tab: Tab
};
const UNASSIGNED = "Unassigned";
const UNDEFINED = "Undefined";

const AnalysisTagsList = ({ tab }: Props) => {
  const { analysisMappingsStore, influenceStore, regionsStore, systemUnitsStore } = useStores();
  const { analystUsers = [] } = analysisMappingsStore;
  const { computedDateFormat, computedDateMonthFormat } = systemUnitsStore;

  const transformToMile = useContext(MileTransformationContext);
  const filterLabels = {
    ...metricLabels({ transformToMile, withParent: true, withUnits: true, xDayBuild: tab.xDayBuild }),
    ...extraMetrics,
    sellingFareDiffToCirrusCompFare: "Fare Δ to Fare Match",
    sellingFareDiffToCirrusCompFarePercentile: "Fare Δ to Fare Match",
    sellingFareDiffToDepDateCompFare: "Fare Δ to Fare Dep Date",
    sellingFareDiffToDepDateCompFarePercentile: "Fare Δ to Fare Dep Date",
    sellingFareDiffToTimePeriodCompFare: "Fare Δ to Fare Time",
    sellingFareDiffToTimePeriodCompFarePercentile: "Fare Δ to Fare Time"
  };

  const additionalAnalystUsers = useMemo(() => {
    const newAnalystUsers = {
      null: UNASSIGNED
    };
    analystUsers.filter(Boolean).forEach(({ value, label }) => {
      newAnalystUsers[value] = label;
    });
    return newAnalystUsers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(analystUsers)]);

  const onTagClick = (filterId: string) => {
    tab.setSidebarOpen(true);
    tab.setSidebarFilterQuery(filterLabels[filterId], filterId);
  };

  const tagsListProps = {
    additionalTagLabels: additionalAnalystUsers,
    disabled: !tab.filtersEnabled,
    filterOrder,
    initialFilters: FILTERS_INIT,
    isPreviewActive: influenceStore.isPreviewActive,
    onChangeParam: tab.clearSearchParam,
    onTagClick,
    onValueRemove: tab.removeFilterValue,
    params: {
      ...tab.applied.filters,
      ...groupConditionalFilters(tab.applied.conditionalFilters)
    },
    valueRenderers: {
      depDow: value => daysOfWeek[value].short,
      depMonth: value => value && format(parseISO(value), computedDateMonthFormat),
      depWeek: value => value && format(parseISO(value), computedDateFormat),
      fusionrmStatus: value => (value ? "On" : "Off"),
      quarter: value => `${value.substring(0, 4)} ${value.substring(4)}`,
      regionId: value => regionsStore.regionsById[value] || UNDEFINED,
      subregionId: value => regionsStore.subregionsById[value] || UNDEFINED
    },
    xDayBuild: tab.xDayBuild
  };

  return <TagsList {...tagsListProps} />;
};

export default observer(AnalysisTagsList);
