// @flow
/** @jsx jsx */

import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { jsx } from "@emotion/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import clsx from "clsx";
import {
  Button,
  Classes,
  Colors,
  Intent,
  Menu,
  Popover,
  PopoverInteractionKind,
  Position,
  Tooltip
} from "@blueprintjs/core";

import { AppToaster } from "../services/Toaster";
import { compareDateFiltersToNow } from "../helpers/compareDateFiltersToNow";
import { isLocalhost } from "../helpers/constants";
import { useStores } from "../store/Store";
import type { Tab } from "../models/Tab.model";
import { Status } from "../helpers/Status";
import ShareMenuItem from "./ShareMenuItem";

const defaultTabNameRegex = /(Analysis)(.?)([0-9]{0,})/g;

const ExtraColors = {
  WHITE_33: `${Colors.WHITE}33`,
  WHITE_99: `${Colors.WHITE}99`
};

const StyledTab = styled("div")`
  background: ${({ active }) => (active ? Colors.WHITE : ExtraColors.WHITE_33)};
  border-radius: 2px 2px 0 0;
  color: ${({ active }) => (active ? Colors.DARK_GRAY4 : ExtraColors.WHITE_99)};
  flex: 1 1 100%;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  max-width: 160px;
  min-width: 80px;

  a {
    font-weight: ${({ active }) => (active ? "700" : "500")};
    color: currentColor;
    height: 30px;
  }

  .bp3-icon-cross svg {
    fill: currentColor;
  }
  .tab-options {
    svg {
      fill: ${Colors.DARK_GRAY4};
    }
  }
`;

const StyledTabLink = styled(Link)`
  &:hover {
    text-decoration: none;

    .text {
      text-decoration: underline;
    }
  }
`;

const StyledDot = styled("span")`
  display: inline-block;
  text-decoration: none;
`;

const StyledInput = styled("input")`
  box-shadow: none;
  max-height: 28px;
  position: relative;
  width: 100%;
`;

type Props = {
  getPreviousTabId: ?Function,
  onDuplicate: Function,
  onRemove: Function,
  onSave: Function,
  savedViews: Array<{ id: number, label: string }>,
  tab: Tab,
  tabsCount: number
};

function HeaderTab(props: Props) {
  const { getPreviousTabId, onDuplicate, onRemove, onSave, savedViews = [], tab, tabsCount = 1 } = props;

  const { templatesStore } = useStores();

  const [isFocusedInput, setFocusedInput] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [isDuplicateName, setDuplicateName] = useState({
    show: false,
    match: false
  });
  const [activeName, setActiveName] = useState(tab.label);
  const location = useLocation();
  const history = useHistory();
  const inputRef = useRef();

  const isActiveTab = location.pathname.includes(tab.id);

  useEffect(() => {
    const isCompletedStatus = [Status.DONE, Status.ERROR].includes(tab.flightsTable.status);
    if (isActiveTab && isCompletedStatus) {
      const { depDate, depWeek, depMonth, quarter } = tab.applied.filters;
      const isFilterInPast = compareDateFiltersToNow(depDate, depWeek, depMonth, quarter);
      if (isFilterInPast) {
        AppToaster.show({
          message: "Some pre-defined departure dates are in the past",
          intent: "warning"
        });
      }
    }
  }, [isActiveTab, tab.flightsTable.status]); // eslint-disable-line react-hooks/exhaustive-deps

  function checkSaveViewExistence(label) {
    return savedViews.find(sv => sv.label === label) || label.match(defaultTabNameRegex);
  }

  function changeActiveName(val: string) {
    if (isDuplicateName.match) {
      setDuplicateName({
        show: checkSaveViewExistence(val),
        match: true
      });
    }
    setActiveName(val);
  }

  function onKeyDown(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    if (e && e.key === "Escape") {
      setFocusedInput(false);
      setDuplicateName({ show: false, match: false });
      setActiveName(tab.label);
    }

    if (e && e.key === "Enter") {
      const trimmedName = activeName && activeName.trim();
      const newName = trimmedName || tab.label;
      if (isDuplicateName.match && checkSaveViewExistence(newName)) {
        setFocusedInput(true);
      }

      setFocusedInput(false);
      setActiveName(newName);

      tab.renameTab(newName);
      if (isDuplicateName.match && !checkSaveViewExistence(newName)) {
        onSave(tab);
        setDuplicateName({ show: false, match: false });
      }
    }
  }

  const onBlur = () => {
    const trimmedName = activeName && activeName.trim();
    const newName = trimmedName || tab.label;

    setActiveName(newName);
    setFocusedInput(false);
    setDuplicateName({ show: false, match: false });

    tab.renameTab(newName);
    if (isDuplicateName.match && !checkSaveViewExistence(newName)) {
      onSave(tab);
    }
  };

  const isDefaultLabel = tab.label && tab.label.match(defaultTabNameRegex);

  const isUnsaved = templatesStore.checkTabUnsaved(tab.id);

  const isAlreadyUsedLabel = savedViews
    .filter(view => view.id !== tab.parentId)
    .map(view => view.label)
    .includes(tab.label);
  const isMaxLabels = savedViews.length === 50;
  const isMatchingSavedView = checkSaveViewExistence(tab.label);
  const maxLabelInputLength = 50;
  const isMaxLabelLength = activeName.length === maxLabelInputLength;
  const isSavedView = tab.parentId && tab.parentType === "saved";
  const menu = (
    <Menu>
      <Menu.Item icon="duplicate" disabled={tabsCount === 10} text="Duplicate" onClick={() => onDuplicate(tab.id)} />
      {isSavedView ? (
        <Menu.Item
          data-testid="patch-save-view"
          disabled={!isUnsaved || isAlreadyUsedLabel}
          icon={isUnsaved ? "floppy-disk" : "tick"}
          text={isUnsaved ? "Save" : "Saved"}
          onClick={() => {
            if (isDefaultLabel) {
              setFocusedInput(true);
              setDuplicateName({ show: true, match: true });
              return inputRef.current && inputRef.current.focus();
            }

            setDuplicateName({ show: false, match: false });
            return onSave(tab);
          }}
        />
      ) : (
        <Menu.Item
          data-testid="create-save-view"
          disabled={isAlreadyUsedLabel}
          icon="floppy-disk"
          text="Save"
          onClick={() => {
            if (isMaxLabels) {
              return AppToaster.show({
                message: "Limit of 50 Saved views is reached",
                intent: Intent.DANGER
              });
            }
            if (isMatchingSavedView || isDefaultLabel) {
              setFocusedInput(true);
              setDuplicateName({ show: true, match: true });
              return inputRef.current && inputRef.current.focus();
            }

            setDuplicateName({ show: false, match: false });
            return onSave(tab);
          }}
        />
      )}
      <ShareMenuItem tabId={tab.id} closePopover={() => setIsPopoverOpen(false)} />
      {isLocalhost && (
        <Menu.Item
          icon="code"
          text="Log to console"
          // eslint-disable-next-line no-console
          onClick={() => console.log(toJS(tab, { recurseEverything: true }))}
        />
      )}
    </Menu>
  );

  const unsavedIndicator = isUnsaved && (
    <StyledDot className="ml-2" data-testid="tab-unsaved-indicator">
      •
    </StyledDot>
  );

  const defaultTooltipContent = tab.label ? (
    <span>{`${tab.label}${isUnsaved ? " (unsaved changes)" : ""}`}</span>
  ) : null;

  const inactiveContent = !isActiveTab ? (
    <Tooltip
      boundary="viewport"
      className="text-truncate"
      content={defaultTooltipContent}
      hoverOpenDelay={1000}
      modifiers={{ arrow: false }}
      position={Position.BOTTOM_LEFT}
      fill
    >
      <StyledTabLink className="d-flex align-items-center" to={`/analysis/${tab.id}/explore`}>
        {unsavedIndicator}
        <span className="text ml-2">{tab.label}</span>
      </StyledTabLink>
    </Tooltip>
  ) : null;

  const activeUnfocusedContent =
    isActiveTab && !isFocusedInput ? (
      <React.Fragment>
        {unsavedIndicator}
        <Tooltip
          boundary="viewport"
          className="w-100 text-truncate"
          content={defaultTooltipContent}
          hoverOpenDelay={1000}
          modifiers={{ arrow: false }}
          position={Position.BOTTOM_LEFT}
        >
          <span className="ml-2" data-testid="tab-label-trigger" onClick={() => setFocusedInput(true)}>
            {tab.label}
          </span>
        </Tooltip>
        <Popover
          boundary="viewport"
          content={menu}
          interactionKind={PopoverInteractionKind.CLICK}
          isOpen={isPopoverOpen}
          onInteraction={state => setIsPopoverOpen(state)}
          position={Position.BOTTOM}
        >
          <Button className="tab-options" data-testid="tab-options" icon="chevron-down" minimal />
        </Popover>
      </React.Fragment>
    ) : null;

  const activeFocusedContent =
    isActiveTab && isFocusedInput ? (
      <React.Fragment>
        {unsavedIndicator}
        <Tooltip
          boundary="viewport"
          className={Classes.TOOLTIP_INDICATOR}
          popoverClassName="sv__tooltip"
          content={
            !isMaxLabelLength && !isDuplicateName.show ? null : (
              <span>
                {isMaxLabelLength ? `Limit of ${maxLabelInputLength} characters has been reached` : null}
                {isDuplicateName.show && !isMaxLabelLength ? `Specify a unique name to save this view` : null}
              </span>
            )
          }
          isOpen={isMaxLabelLength || isDuplicateName.show}
        >
          <StyledInput
            type="text"
            className={clsx(Classes.INPUT, "px-2")}
            autoFocus
            data-testid="tab-label"
            ref={inputRef}
            id="tab-label"
            maxLength={maxLabelInputLength}
            onBlur={onBlur}
            onChange={e => changeActiveName(e.target.value)}
            onKeyDown={onKeyDown}
            value={activeName}
          />
        </Tooltip>
      </React.Fragment>
    ) : null;

  const previousTabId = getPreviousTabId && getPreviousTabId();

  return (
    <StyledTab className="d-flex align-items-center mr-2" active={isActiveTab} data-testid="navigation-tab">
      {inactiveContent}
      {activeUnfocusedContent}
      {activeFocusedContent}
      <Button
        icon="cross"
        data-testid="remove-tab"
        minimal
        onClick={() => {
          onRemove(tab.id);
          if (tabsCount === 1) {
            localStorage.removeItem("activeTab");
            history.push("/dashboard");
          } else if (isActiveTab && previousTabId) {
            history.push(`/analysis/${previousTabId}/explore`);
          }
        }}
      />
    </StyledTab>
  );
}

export default observer(HeaderTab);
