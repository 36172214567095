// @flow

import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { Colors, Popover, Position, Tag as TagBlueprint } from "@blueprintjs/core";

const TagStyled = styled("div")`
  flex-shrink: 0;
  max-width: ${props => (props.inline ? "290px" : "unset")};

  &:not(:last-child) {
    ${({ inline }) => (inline ? "margin-right: 0.25rem" : "margin-bottom: 0.25rem")}
  }

  .bp3-tag {
    background: ${({ disabled }) => (disabled ? Colors.LIGHT_GRAY4 : `${Colors.BLUE5}44`)}!important;
    border-radius: 3px;
    color: ${({ disabled }) => (disabled ? Colors.GRAY2 : Colors.BLUE1)}!important;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    padding: 6px 10px;
    transition: background, color 0.2s linear;

    .bp3-icon {
      svg {
        fill: ${({ disabled }) => (disabled ? Colors.GRAY2 : Colors.BLUE5)};
      }
    }

    &:hover {
      background: ${({ disabled }) => (disabled ? Colors.LIGHT_GRAY2 : `${Colors.BLUE5}66`)} !important;
    }

    .pill {
      background: ${({ disabled }) => (disabled ? Colors.LIGHT_GRAY1 : `${Colors.BLUE5}44`)}!important;
      color: ${({ disabled }) => (disabled ? Colors.GRAY2 : Colors.BLUE1)}!important;
    }
  }
`;

type Props = {
  children: ?Element,
  disabled: boolean,
  inline: boolean,
  onRemove: ?Function,
  popoverContent: Function,
  testId: string
};

function Tag(props: Props) {
  const { children = null, disabled, inline, onRemove = null, popoverContent, testId = "tag" } = props;

  return (
    <TagStyled data-testid={testId} inline={inline} disabled={disabled}>
      <Popover
        content={popoverContent}
        popoverClassName="bp3-popover-content-sizing"
        position={Position.BOTTOM}
        targetClassName="d-flex"
      >
        <TagBlueprint className="d-flex align-items-center bp3-large" interactive onRemove={onRemove}>
          {children}
        </TagBlueprint>
      </Popover>
    </TagStyled>
  );
}

export default observer(Tag);
