// @flow

import React from "react";
import { css, Global } from "@emotion/core";
import { Colors } from "@blueprintjs/core";

export default (
  <Global
    styles={css`
      body,
      html,
      #root {
        height: 100%;
      }

      body {
        background: ${Colors.LIGHT_GRAY4};
        overscroll-behavior: none;
      }

      textarea {
        resize: vertical;
      }

      .mh-0 {
        min-height: 0;
      }

      .overflow-auto {
        overflow: auto;
      }

      // blueprint
      ul.bp3-menu {
        max-height: 300px;
        min-width: 100px;
        max-width: 220px;
        overflow: auto;
      }

      .page-size-popover .bp3-menu {
        width: 150px;
      }

      .bp3-popover {
        &.sidebar-select {
          width: 220px;
        }

        &.-small .bp3-menu {
          max-height: 260px;
        }

        &.-event-modal {
          width: 276px; // 300 - 24 padding

          .bp3-menu {
            max-width: 100%;
          }
        }
      }

      .bp3-multiselect-popover {
        margin-top: 2px !important;
      }

      .bp3-heading {
        font-weight: 500 !important;
      }

      .bp3-html-select select,
      .bp3-dialog {
        background-color: ${Colors.WHITE};
      }

      .bp3-dialog-header {
        padding-left: 1rem;
        padding-right: 1rem;
        min-height: 48px;
      }

      h4.bp3-heading {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 1px;
      }

      .sv__tooltip span {
        color: rgba(${Colors.WHITE}, 0.8);
      }

      /* sidebar - start */
      .bp3-dialog-body {
        margin: 10px;
      }

      .bp3-control-group {
        & > * {
          flex-shrink: 1;
        }

        .bp3-html-select {
          flex-shrink: 0;
        }
      }

      .bp3-dialog-body .bp3-button + div {
        overflow: hidden;
      }
      /* sidebar - end */

      .tag-select,
      .-event-modal {
        .bp3-menu-item.bp3-intent-primary:hover,
        .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item,
        .bp3-menu-item.bp3-active {
          background-color: ${Colors.LIGHT_GRAY4};
          color: inherit;
        }
      }
      .opacity-0 {
        opacity: 0;
      }
      .opacity-50 {
        opacity: 0.5;
      }
      .opacity-100 {
        opacity: 1;
      }
    `}
  />
);
