// @flow

import React from "react";
import isEmpty from "lodash.isempty";
import { observer } from "mobx-react";
import { AnchorButton } from "@blueprintjs/core";
import { useStores } from "../../store/Store";

type Props = {
  selectedRows: Array<string>
};

function MarketMappingsButton(props: Props) {
  const { selectedRows = [] } = props;
  const { marketsStore, modalStore } = useStores();

  return (
    <AnchorButton
      data-testid="market-mappings-button"
      disabled={isEmpty(selectedRows)}
      key="market-mappings-button"
      className="ml-5"
      onClick={() => modalStore.setModal("market-mappings")}
    >
      Update Mappings ({marketsStore.marketCount})
    </AnchorButton>
  );
}

export default observer(MarketMappingsButton);
