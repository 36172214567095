// @flow

import React from "react";
import { observer } from "mobx-react";

import FilterInteractionsWrapper from "../FilterInteractionsWrapper";
import TagsList from "../TagsList";
import TopBar from "../TopBar";
import { useStores } from "../../store/Store";

type Props = {
  tagsListProps: {
    disabled: boolean,
    filterOrder: Array<string>,
    initialFilters: Object,
    onChangeParam: Function,
    onTagClick: Function,
    onValueRemove: Function,
    params: Object,
    valueRenderers: Object
  }
};

function MarketMappingsTopBar({ tagsListProps }: Props) {
  const { mappingsStore, marketsStore, regionsStore, usersStore } = useStores();
  const { setSidebarOpen } = marketsStore;

  const isLoading =
    mappingsStore.isLoading || marketsStore.isLoading || usersStore.isLoadingUsers || regionsStore.isLoading;

  return (
    <TopBar>
      <TagsList {...tagsListProps} />
      <FilterInteractionsWrapper
        buttonProps={{
          className: "ml-auto",
          testId: "filters-button",
          loading: isLoading,
          onClick: setSidebarOpen
        }}
        filterSwitchProps={{ checked: marketsStore.filtersEnabled, onChange: marketsStore.toggleFiltersEnabled }}
      />
    </TopBar>
  );
}

export default observer(MarketMappingsTopBar);
