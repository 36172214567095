// @flow

import React from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";

import { useStores } from "../store/Store";
import { FILTERS_INIT } from "../models/Cirrus.model";

import CirrusSidebar from "../components/CirrusSidebar";
import CirrusTable from "../components/CirrusTable";
import CirrusTopBar from "../components/CirrusTopBar";
import StyledScrollableContainer from "../components/StyledScrollableContainer";

function Cirrus() {
  const { cirrusStore, systemUnitsStore } = useStores();

  const { table, searchParams } = cirrusStore;

  const tableProps = {
    ...table,
    actionsDisabled: isEqual(searchParams, FILTERS_INIT),
    computedDateFormat: systemUnitsStore.computedDateFormat,
    fetchData: params => cirrusStore.fetchData(params),
    onShiftToggle: cirrusStore.shiftToggleRows,
    onRowToggle: selectedRows => cirrusStore.changeSelectedRows(selectedRows)
  };

  return (
    <>
      <CirrusTopBar />
      <CirrusSidebar />
      <StyledScrollableContainer className="d-flex flex-column flex-shrink-1 p-2" data-testid="pricing-status-table">
        <CirrusTable {...tableProps} />
      </StyledScrollableContainer>
    </>
  );
}

export default observer(Cirrus);
