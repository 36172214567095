// @flow

import React from "react";
import { observer } from "mobx-react";
import difference from "lodash.difference";
import isEmpty from "lodash.isempty";

import ButtonSelector from "../ButtonSelector";
import SidebarDateRangeSelect from "../SidebarDateRangeSelect";
import SidebarDaySelect from "../SidebarDaySelect";
import SidebarFilter from "../SidebarFilter";
import SidebarHourSelect from "../SidebarHourSelect";
import SidebarSelect from "../SidebarSelect";
import { useStores } from "../../store/Store";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  pageContext: Object
};

function CirrusFilters(props: Props) {
  const { pageContext } = props;
  const { systemUnitsStore, mappingsStore } = useStores();
  const { computedDateFormat } = systemUnitsStore;
  const { data, getFlightNumbers } = mappingsStore;
  const { changeFilter, filters, sidebar } = pageContext;

  const matchingFlightNumbers = !isEmpty(filters.odPairs) ? getFlightNumbers(filters.odPairs) : [];
  const flightNumbers = difference(getFlightNumbers([]), matchingFlightNumbers);

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("flight number", "flightNumber_1", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: "Matching pre-selected", groupItems: matchingFlightNumbers },
            { label: "All flights", groupItems: flightNumbers }
          ]}
          onChange={filterValue => changeFilter("flightNumber_1", filterValue)}
          selectedItems={filters.flightNumber_1}
          title="Flight Number"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure time", "depTime", sidebar)}>
        <SidebarHourSelect
          hours={filters.depTime}
          onChange={filterValue => changeFilter("depTime", filterValue)}
          title="Departure Time"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("cabin class", "cabinClass", sidebar)}>
        <ButtonSelector
          items={data.cabinClasses}
          onSelect={filterValue => changeFilter("cabinClass", filterValue)}
          selectedItems={filters.cabinClass}
          title="Cabin Class"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure day of week", "departureDayOfWeek", sidebar)}>
        <SidebarDaySelect
          onChange={filterValue => changeFilter("departureDayOfWeek", filterValue)}
          selectedItems={filters.departureDayOfWeek}
          title="Departure Day of Week"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure date", "depDate", sidebar)}>
        <SidebarDateRangeSelect
          dates={filters.depDate}
          onChange={filterValue => changeFilter("depDate", filterValue)}
          computedDateFormat={computedDateFormat}
          title="Departure Date"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("cirrus on/off", "fusionrmStatus", sidebar)}>
        <ButtonSelector
          isMultiple={false}
          items={[
            { label: "On", value: true },
            { label: "Off", value: false }
          ]}
          onSelect={filterValue => changeFilter("fusionrmStatus", filterValue)}
          selectedItems={[filters.fusionrmStatus]}
          title="Cirrus On/Off"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(CirrusFilters);
