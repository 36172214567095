import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";

import { filterOrder } from "../helpers/influenceHistoryMetrics";
import { FILTERS_INIT } from "../models/InfluenceHistory.model";
import { daysOfWeek, extraMetrics } from "../helpers/constants";
import { influenceStatusNames } from "../helpers/influenceConstants";
import TagsList from "./TagsList";
import MileTransformationContext from "../services/contexts/MileTransformationContext";
import metricLabels from "../helpers/metricLabels/metricLabels";
import { useStores } from "../store/Store";

function InfluenceHistoryTagList() {
  const { influenceHistoryStore, systemUnitsStore } = useStores();
  const { creators, page } = influenceHistoryStore;
  const { computedDateFormat, computedDateMonthFormat } = systemUnitsStore;
  const { analystCreators = [] } = creators;

  const additionalTags = useMemo(() => {
    const tags = {};
    analystCreators.forEach(user => {
      tags[user.value] = user.label;
    });
    return tags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(analystCreators)]);

  const transformToMile = useContext(MileTransformationContext);
  const filterLabels = {
    ...metricLabels({ transformToMile, withParent: true, withUnits: true }),
    ...extraMetrics,
    createdOn: "Influences Created on",
    status: "Influence Status",
    type: "Type of Influence",
    updatedOn: "Influences Modified On",
    userIds: "Analyst"
  };

  const onTagClick = (filterKey: string) => {
    influenceHistoryStore.setSidebarOpen(true);
    influenceHistoryStore.setSidebarFilterQuery(filterLabels[filterKey], filterKey);
  };

  const tagsListProps = {
    additionalTagLabels: additionalTags,
    disabled: !page.filtersEnabled,
    filterOrder,
    initialFilters: FILTERS_INIT,
    onChangeParam: influenceHistoryStore.clearSearchParam,
    onTagClick,
    onValueRemove: influenceHistoryStore.removeFilterValue,
    params: page.applied.filters,
    valueRenderers: {
      depDow: value => daysOfWeek[value].short,
      depMonth: value => value && format(parseISO(value), computedDateMonthFormat),
      depWeek: value => value && format(parseISO(value), computedDateFormat),
      status: value => influenceStatusNames[value],
      quarter: value => `${value.substring(0, 4)} ${value.substring(4)}`
    }
  };

  return <TagsList {...tagsListProps} />;
}

export default observer(InfluenceHistoryTagList);
