// @flow

import React from "react";
import { observer } from "mobx-react";

import SidebarFilter from "../SidebarFilter";
import ButtonSelector from "../ButtonSelector";
import SidebarSelect from "../SidebarSelect";
import type { AnalysisMappingsStore } from "../../models/AnalysisMappings.model";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  mappings: AnalysisMappingsStore,
  pageContext: Object
};

function AdministrativeFilters(props: Props) {
  const { mappings, pageContext } = props;
  const { filters, changeFilter, sidebar } = pageContext;

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("cirrus on/off", "fusionrmStatus", sidebar)}>
        <ButtonSelector
          isMultiple={false}
          items={[
            { label: "On", value: true },
            { label: "Off", value: false }
          ]}
          onSelect={filterValue => changeFilter("fusionrmStatus", filterValue)}
          selectedItems={[filters.fusionrmStatus]}
          title="Cirrus On/Off"
        />
      </SidebarFilter>

      <SidebarFilter isVisible={isSidebarFilterVisible("airline", "airline", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={mappings.data.airline}
          onChange={filterValue => changeFilter("airline", filterValue)}
          selectedItems={filters.airline}
          title="Airline"
        />
      </SidebarFilter>

      <SidebarFilter isVisible={isSidebarFilterVisible("analyst", "analystId", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={mappings.analystUsers}
          onChange={filterValue => changeFilter("analystId", filterValue)}
          selectedItems={filters.analystId}
          title="Analyst"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(AdministrativeFilters);
