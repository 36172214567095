// @flow
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Classes,
  Colors,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Menu,
  Popover,
  Position
} from "@blueprintjs/core";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import clsx from "clsx";
import { isPast, isToday, parseISO, startOfToday, subYears } from "date-fns";
import isEmpty from "lodash.isempty";

import EventsAutocomplete from "./EventsAutocomplete";
import SidebarDateRangeSelect from "../SidebarDateRangeSelect";
import TagSelect from "../TagSelect";
import { categories } from "../../helpers/categories";
import { ColumnWrapper, displayConflictErrors } from "../../helpers/EventsManagementHelpers";
import { renderTagSelect } from "../../helpers/renderTagSelect";
import { useStores } from "../../store/Store";
import EventsDateAutocomplete from "./EventsDateAutocomplete";
import { Status } from "../../helpers/Status";

const StyledPopoverContent = styled(Menu)`
  &.bp3-menu {
    max-height: 310px;
    max-width: 276px;
    min-height: 80px;
    width: 276px;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  .bp3-label {
    margin-bottom: 0.5rem !important;
  }
`;

const StyledWarnings = styled("div")`
  color: ${Colors.ORANGE3};
  display: flex;
  font-size: 12px;
  line-height: 1.33;

  .bp3-icon {
    position: relative;
    top: 2px;
  }
`;

const StyledErrors = styled(StyledWarnings)`
  color: ${Colors.RED3};
`;

const INFORMATION_TYPE = {
  error: StyledErrors,
  warning: StyledWarnings
};

function EventsManagementGeneralColumn({ isAddingEvent }: { isAddingEvent: boolean }) {
  const {
    eventsManagementStore: {
      activeSuggestionType,
      changeField,
      clearExistingEvents,
      eventCategories,
      eventCategoriesById,
      getEventById,
      getExistingEvents,
      isConflicted,
      isSuggestModalLoading,
      page: { clonedModalEvent = {}, modalEvent = {}, suggestedEvents = {} }
    },
    systemUnitsStore: { computedDateFormat }
  } = useStores();
  const dateAutocompleteRef = useRef(null);
  const [isDateSelectActive, setIsDateSelectActive] = useState(false);
  const [isDateAutocomplete, setIsDateAutocomplete] = useState(false);
  const { categoryId, dateRange = {}, name, nonWorkingDay } = modalEvent;
  const { data, status } = suggestedEvents;

  useEffect(() => {
    if (isAddingEvent && dateRange.start && dateRange.end && isDateSelectActive) {
      setIsDateAutocomplete(true);
      getExistingEvents({ dateRange }, "dateRange");
    }
  }, [dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleClickOutside = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (dateAutocompleteRef?.current && !dateAutocompleteRef.current.contains(event.target)) {
        clearExistingEvents();
        setIsDateAutocomplete(false);
        setIsDateSelectActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateAutocompleteRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const styledTagSelectClassName = clsx("category-select", Classes.POPOVER_DISMISS, {
    [`-color-${categories[categoryId]?.key}`]: !!categoryId
  });

  const parsedStartDate = dateRange?.start && parseISO(dateRange?.start);
  const isPastDate = parsedStartDate && isPast(parsedStartDate) && !isToday(parsedStartDate);
  const isNameAndDateConflicting = displayConflictErrors(modalEvent, clonedModalEvent);
  const intent = isNameAndDateConflicting ? Intent.DANGER : Intent.NONE;

  const setFieldsFromAutocomplete = ({ value: id }) => {
    getEventById(id);
    clearExistingEvents();
    setIsDateAutocomplete(false);
  };

  const informationWarning = (type: "error" | "warning", text: string, link?: string) => {
    const Warning = INFORMATION_TYPE[type];
    return (
      <Warning className="mt-3">
        <Icon icon="error" iconSize={12} className="mr-2" />
        <div>
          <div>{text}</div>

          {link && (
            <a className="d-inline-block mt-1" href={link} rel="noopener noreferrer" target="_blank">
              Read more
            </a>
          )}
        </div>
      </Warning>
    );
  };

  const renderNameInput = () => {
    return isAddingEvent ? (
      <EventsAutocomplete
        dataTestid="event-general-name"
        inputProps={{
          intent,
          maxLength: 50
        }}
        onSelectItem={setFieldsFromAutocomplete}
        title="Name"
      />
    ) : (
      <StyledFormGroup className="font-weight-bold mb-3" label="Name" labelFor="event-general-name">
        <InputGroup
          autoComplete="off"
          data-testid="event-general-name"
          disabled={isSuggestModalLoading}
          id="event-general-name"
          intent={intent}
          maxLength={50}
          onChange={({ target }) => changeField("name", target.value)}
          placeholder="Enter event name"
          value={name}
        />
      </StyledFormGroup>
    );
  };

  const detailsAutocomplete = (
    <StyledPopoverContent>
      <EventsDateAutocomplete ref={dateAutocompleteRef} onSelectItem={item => setFieldsFromAutocomplete(item)} />
    </StyledPopoverContent>
  );

  const isDateRangeLoading = activeSuggestionType === "dateRange" && status === Status.LOADING;

  const renderDateInput = () => {
    const dateRangeInput = (
      <SidebarDateRangeSelect
        className="date-range-select"
        computedDateFormat={computedDateFormat}
        dates={dateRange}
        extraProps={{
          endInputProps: { intent, disabled: isSuggestModalLoading, onClick: () => setIsDateSelectActive(true) },
          startInputProps: { intent, disabled: isSuggestModalLoading, onClick: () => setIsDateSelectActive(true) }
        }}
        isLoading={isDateRangeLoading}
        minDate={subYears(startOfToday(), 20)}
        onChange={filterValue => changeField("dateRange", filterValue)}
        popoverProps={{
          boundary: "viewport"
        }}
        title="Date range"
        withResetDates={false}
      />
    );

    return isAddingEvent ? (
      <Popover
        boundary="viewport"
        content={detailsAutocomplete}
        disabled={isSuggestModalLoading || isEmpty(data)}
        fill
        isOpen={isDateSelectActive && isDateAutocomplete}
        modifiers={{ arrow: false }}
        onClose={() => setIsDateSelectActive(false)}
        popoverClassName="-event-modal"
        position={Position.BOTTOM}
      >
        {dateRangeInput}
      </Popover>
    ) : (
      dateRangeInput
    );
  };

  return (
    <ColumnWrapper type="general">
      {renderNameInput()}
      <TagSelect
        className={styledTagSelectClassName}
        disabled={isSuggestModalLoading}
        items={eventCategories}
        onChange={item => changeField("categoryId", item.value)}
        popoverProps={{
          boundary: "viewport",
          popoverClassName: clsx(Classes.POPOVER_DISMISS, "sidebar-select", "tag-select", "-event-modal")
        }}
        renderSelect={itemId => renderTagSelect(eventCategoriesById[itemId])}
        selectedItem={categoryId}
        title="Category"
      >
        <Button text="Select event category" rightIcon="double-caret-vertical" />
      </TagSelect>

      {renderDateInput()}
      <Checkbox
        checked={nonWorkingDay}
        data-testid="events-non-working-day-checkbox"
        disabled={isSuggestModalLoading}
        label="Public Holiday"
        onChange={e => changeField("nonWorkingDay", e.target.checked)}
      />
      {isNameAndDateConflicting && informationWarning("error", "Event with the same name and date(s) already exist.")}
      {!isNameAndDateConflicting &&
        isPastDate &&
        informationWarning("warning", "One or more selected dates are in the past")}
      {!isNameAndDateConflicting &&
        isConflicted &&
        informationWarning(
          "warning",
          "One or more selected locations are mutually exclusive. Locations of lower granularity will be removed to avoid duplicates once an event is saved.",
          "http://help.cirrus.ai/en/articles/4911231-events"
        )}
    </ColumnWrapper>
  );
}

export default observer(EventsManagementGeneralColumn);
