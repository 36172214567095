// @flow

import React from "react";
import { observer } from "mobx-react";
import pick from "lodash.pick";
import difference from "lodash.difference";

import SidebarSelect from "../SidebarSelect";
import SidebarFilter from "../SidebarFilter";
import type { AnalysisMappingsStore } from "../../models/AnalysisMappings.model";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";
import { RegionsStore } from "../../models/Regions.model";
import getGroupItemsForFilter from "../../helpers/getGroupItemsForFilter";

type Props = {
  mappings: AnalysisMappingsStore,
  pageContext: Object,
  regions: RegionsStore
};

function MarketFilters(props: Props) {
  const { mappings, pageContext, regions } = props;
  const { filters, changeFilter, sidebar } = pageContext;
  const { getRoundTripMarkets, getOneWayMarkets, getOrigins, getDestinations } = mappings;

  const roundTripFilters = pick(filters, ["owMarket", "rtMarket", "origin", "destination"]);
  const oneWayFilters = pick(filters, ["rtMarket", "origin", "destination"]);
  const originFilters = pick(filters, ["rtMarket", "owMarket", "destination"]);
  const destinationFilters = pick(filters, ["rtMarket", "owMarket", "origin"]);
  const regionFilters = pick(filters, ["subregionId"]);
  const subregionFilters = pick(filters, ["regionId"]);

  const matchingGroupLabel = "Matching pre-selected";

  const [rtMarkets, matchingRtMarkets] = getGroupItemsForFilter(roundTripFilters, getRoundTripMarkets);
  const [owMarkets, matchingOwMarkets] = getGroupItemsForFilter(oneWayFilters, getOneWayMarkets);
  const [origins, matchingOrigins] = getGroupItemsForFilter(originFilters, getOrigins);
  const [destinations, matchingDestinations] = getGroupItemsForFilter(destinationFilters, getDestinations);

  const matchingRegions = regions.getRegionsFromSelectedSubregions(regionFilters, "subregionId");
  const allRegions = difference(regions.regionsForFilter, matchingRegions);

  const matchingSubregions = regions.getSubregionsFromSelectedRegions(subregionFilters, "regionId");
  const allSubregions = difference(regions.subregionsForFilter, matchingSubregions);

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("market region", "regionId", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingRegions },
            { label: "All regions", groupItems: allRegions }
          ]}
          onChange={filterValue => changeFilter("regionId", filterValue)}
          selectedItems={filters.regionId}
          title="Region"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("market subregion", "subregionId", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingSubregions },
            { label: "All subregions", groupItems: allSubregions }
          ]}
          onChange={filterValue => changeFilter("subregionId", filterValue)}
          selectedItems={filters.subregionId}
          title="Subregion"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("market rt market", "rtMarket", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingRtMarkets },
            { label: "All markets", groupItems: rtMarkets }
          ]}
          onChange={filterValue => changeFilter("rtMarket", filterValue)}
          selectedItems={filters.rtMarket}
          title="RT Market"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("market ow market", "owMarket", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingOwMarkets },
            { label: "All markets", groupItems: owMarkets }
          ]}
          onChange={filterValue => changeFilter("owMarket", filterValue)}
          selectedItems={filters.owMarket}
          title="OW Market"
        />
      </SidebarFilter>
      <SidebarFilter name="market origin" isVisible={isSidebarFilterVisible("market origin", "origin", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingOrigins },
            { label: "All airports", groupItems: origins }
          ]}
          onChange={filterValue => changeFilter("origin", filterValue)}
          selectedItems={filters.origin}
          title="Origin"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("market destination", "destination", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: matchingGroupLabel, groupItems: matchingDestinations },
            { label: "All airports", groupItems: destinations }
          ]}
          onChange={filterValue => changeFilter("destination", filterValue)}
          selectedItems={filters.destination}
          title="Destination"
        />
      </SidebarFilter>
    </>
  );
}
export default observer(MarketFilters);
