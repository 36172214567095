import React from "react";
import { format, isValid, parseISO } from "date-fns";

import { metricsTypes } from "helpers/constants";
import formatValueWithUnit from "helpers/FormatValueWithUnit";
import { functionNames } from "helpers/ConditionalFilterFuctionNames";
import ChipCounter from "./ChipCounter";
import { useStores } from "../store/Store";

type Props = {
  additionalTagLabels: ?Object,
  customValueRenderer: ?Function,
  name: ?string,
  value: string | number | boolean | Array<string> | Object
};

export function ChipValue(props: Props) {
  const { value, name, additionalTagLabels, customValueRenderer } = props;
  const { systemUnitsStore } = useStores();
  const { computedDateFormat, computedDateMonthFormat } = systemUnitsStore;
  let result = null;
  const type = typeof value;

  const dateFormats = {
    date: computedDateFormat,
    "date-month": computedDateMonthFormat
  };

  if (customValueRenderer) {
    let customResult;

    if (Array.isArray(value)) {
      customResult =
        value.length > 1 ? <ChipCounter className="pill">{value.length}</ChipCounter> : customValueRenderer(value[0]);
    } else {
      customResult = customValueRenderer(value);
    }

    return (
      <span data-testid="tag-value" className="font-weight-normal flex-grow-1 flex-shrink-1 text-truncate">
        {customResult}
      </span>
    );
  }

  if (type === "string" || type === "number") {
    result = value;
  }

  if (type === "boolean") {
    result = value ? "Yes" : "No";
  }

  if (Array.isArray(value)) {
    const isConditionalFilter = typeof value[0] === "object" && value[0] !== null;
    const valueFunction = isConditionalFilter ? `${functionNames[value[0].func]} ` : "";
    const filterType = name && metricsTypes[name];

    let tagValue = isConditionalFilter ? value[0].value : value[0];
    if (additionalTagLabels && additionalTagLabels[tagValue]) {
      tagValue = additionalTagLabels[tagValue];
    }

    result = `${valueFunction}${formatValueWithUnit(tagValue, filterType, dateFormats)}`;

    if (value.length > 1) {
      result = <ChipCounter className="pill">{value.length}</ChipCounter>;
    }
  }

  if (value && (value.start != null || value.end != null)) {
    const isValidDate = isValid(parseISO(value.start)) || isValid(parseISO(value.end));
    if (isValidDate) {
      result = `${format(parseISO(value.start), computedDateFormat)} – ${format(
        parseISO(value.end),
        computedDateFormat
      )}`;
    } else {
      result = `${value.start} – ${value.end}`;
    }
  }

  if (value && value.label) {
    result = value.label;
  }

  if (result) {
    return (
      <span data-testid="tag-value" className="font-weight-normal flex-grow-1 flex-shrink-1 text-truncate">
        {result}
      </span>
    );
  }

  return null;
}
