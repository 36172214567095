export const daysOfWeek = [
  null,
  { long: "Monday", short: "Mo" },
  { long: "Tuesday", short: "Tu" },
  { long: "Wednesday", short: "We" },
  { long: "Thursday", short: "Th" },
  { long: "Friday", short: "Fr" },
  { long: "Saturday", short: "Sa" },
  { long: "Sunday", short: "Su" }
];

export const extraMetrics = {
  aggregations: "Aggregation",
  airline: "Airline",
  analyst: "Analyst",
  analystId: "Analyst",
  cabinClass: "Cabin",
  category: "Category",
  categoryIds: "Category",
  dateRange: "Date range",
  departureDayOfWeek: "Departure Day Of Week",
  depDate: "Departure Date",
  depDow: "Departure Day Of Week",
  depMonth: "Departure Month",
  depTime: "Departure Time",
  depTimeBucket: "Departure Time Bucket",
  depWeek: "Departure Week",
  destination: "Destination",
  distance: "Distance",
  flight: "Flight",
  flightNumber: "Flight Number",
  flightNumber_1: "Flight Number",
  fusionrmStatus: "Cirrus On/Off",
  minmaxAdjustmentMax: "Max Limit",
  minmaxAdjustmentMin: "Min Limit",
  ndo: "Number of Days to Departure",
  numberOfFlights: "Flight Count",
  odPairs: "OW Market",
  origin: "Origin",
  owMarket: "OW Market",
  quarter: "Quarter",
  region: "Region",
  regionId: "Region",
  rtMarket: "RT Market",
  subregion: "Subregion",
  subregionId: "Subregion"
};

export const metricsTypes = {
  depDate: "date",
  depWeek: "date",
  depMonth: "date-month",
  depDow: "day-week-long",
  quarter: "quarter",
  distance: "integer",

  xDayRevenuePotential: "currency",
  finalRevenueExpected: "currency",
  finalRevenueBaseline: "currency",
  xDayFinalRevenueExpected: "currency",

  finalRaskExpected: "currency-precise",
  finalRaskBaseline: "currency-precise",
  xDayFinalRaskExpected: "currency-precise",
  xDayFinalRaskBuildExpected: "percent",

  rask: "currency-precise",
  raskBaseline: "currency-precise",
  raskExpected: "currency-precise",
  raskDiffToBaseline: "percent-relative",
  xDayRask: "currency-precise",
  xDayRaskBaseline: "currency-precise",
  xDayRaskDiffToBaseline: "percent-relative",
  xDayRaskBuild: "currency-precise",
  xDayRaskBuildBaseline: "currency-precise",
  xDayRaskBuildDiffToBaseline: "percent-relative",

  revenue: "currency",
  revenueBaseline: "currency",
  revenueExpected: "currency",
  diffToErb: "currency",
  xDayRevenue: "currency",
  xDayRevenueBaseline: "currency",
  xDayRevenueDiffToBaseline: "currency",
  xDayRevenueBuildDiff: "currency",
  xDayRevenueBuildBaseline: "currency",
  xDayRevenueBuildDiffToBaseline: "currency",

  finalRevenuePerBookingExpected: "currency",
  xDayFinalRevenuePerBookingExpected: "currency",
  xDayFinalRevenuePerBookingBuildExpected: "currency",
  finalRevenuePerBookingBaseline: "currency",

  revenuePerBooking: "currency",
  revenuePerBookingBaseline: "currency",
  revenuePerBookingDiffToBaseline: "percent-relative",
  xDayRevenuePerBooking: "currency",
  xDayRevenuePerBookingBaseline: "currency",
  xDayRevenuePerBookingDiffToBaseline: "percent-relative",
  xDayRevenuePerBookingBuild: "currency",
  xDayRevenuePerBookingBuildBaseline: "currency",
  xDayRevenuePerBookingBuildDiffToBaseline: "percent-relative",

  finalLoadFactorBaseline: "percent",
  finalLoadFactorExpected: "percent",
  xDayFinalLoadFactorExpected: "percent",
  xDayFinalLoadFactorBuildExpected: "percent",

  loadFactor: "percent",
  loadFactorBaseline: "percent",
  loadFactorExpected: "percent",
  diffToElb: "percent-relative",
  xDayLoadFactor: "percent",
  xDayLoadFactorBaseline: "percent",
  xDayLoadFactorDiffToBaseline: "percent-relative",
  xDayLoadFactorBuild: "percent-relative",
  xDayLoadFactorBuildBaseline: "percent-relative",
  xDayLoadFactorBuildDiffToBaseline: "percent-relative",

  yield: "currency-precise",
  yieldBaseline: "currency-precise",
  yieldDiffToBaseline: "percent-relative",
  xDayYield: "currency-precise",
  xDayYieldBaseline: "currency-precise",
  xDayYieldDiffToBaseline: "percent-relative",
  xDayYieldBuild: "currency-precise",
  xDayYieldBuildBaseline: "currency-precise",
  xDayYieldBuildDiffToBaseline: "percent-relative",

  finalYieldExpected: "currency-precise",
  xDayFinalYieldExpected: "currency-precise",
  xDayFinalYieldBuildExpected: "percent-relative",
  finalYieldBaseline: "currency-precise",

  sellingPrice: "currency",
  xDaySellingPrice: "currency",
  sellingPriceFrm: "currency",
  xDaySellingPriceFrm: "currency",
  xDayPriceBuildDiff: "currency",

  pricePercentile: "percent",
  xDayPricePercentile: "percent",
  pricePercentileFrm: "percent",
  xDayPricePercentileFrm: "percent",
  xDayPricePercentileBuild: "percent",

  compFareCirrusMatchFare: "currency",
  compFareTimeMatchFare: "currency",
  compFareDateMatchFare: "currency",
  sellingFareDiffToCirrusCompFarePercentile: "percent-relative",
  sellingFareDiffToCirrusCompFare: "currency",
  sellingFareDiffToTimePeriodCompFarePercentile: "percent-relative",
  sellingFareDiffToTimePeriodCompFare: "currency",
  sellingFareDiffToDepDateCompFarePercentile: "percent-relative",
  sellingFareDiffToDepDateCompFare: "currency",

  priceAdjustment: "percent-relative",
  minmaxAdjustmentMax: "currency",
  minmaxAdjustmentMin: "currency",
  influenceNumberOfImpactedFlights: "influence",
  influencePriceAdjustment: "influence-percent",
  influenceSellingPrice: "influence-currency",
  influencePricePercentile: "influence",
  influenceLacRank: "influence",
  influenceMinmaxAdjustmentMin: "influence-currency",
  influenceMinmaxAdjustmentMax: "influence-currency"
};

export const metricGroupTypes = {
  competitorFareGroup: "currency",
  lacGroup: "influence",
  loadFactorGroup: "percent",
  pricePercentileGroup: "percent",
  raskGroup: "currency-precise",
  revenueGroup: "currency",
  revenuePerBookingGroup: "currency-precise",
  sellingFareGroup: "currency",
  yieldGroup: "currency-precise"
};

export const metricUnitTypes = {
  currency: "$",
  "currency-precise": "$",
  percent: "%",
  "percent-relative": "%"
};

export const dashStylesGraph = ["Solid", "ShortDot", "Dash", "DashDot"];
export const dashStylesCSS = ["solid", "dotted", "dashed", "dashdotted"];

export const isNotCypress = !window.Cypress;
export const isLocalhost = window.location.hostname === "localhost";

export const mockApiEnv = {
  clientId: "foobar",
  domain: "cirrus-xx-staging.us.auth0.com",
  apiUrl: "https://fusionrm-xx-staging.flyrlabs.com/acp-remake/"
};

export const MAX_TAB_COUNT = 10;
