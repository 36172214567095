import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import Row from "../Row";
import { useStores } from "../../store/Store";
import DateFormatControl from "./DateFormatControl";
import DateDelimiterControl from "./DateDelimiterControl";
import { SystemSettingsDescription, SystemSettingsSectionHeader } from "./SystemSettingsUI";

type Props = {
  dateDelimiter: string,
  dateFormat: string,
  onChange(): void
};

function SystemSettingsDate(props: Props) {
  const { onChange, dateFormat, dateDelimiter } = props;
  const { systemUnitsStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    systemUnitsStore
      .getDateFormat()
      .then(response => {
        props.onChange({ dateFormat: response.dateFormat, dateDelimiter: response.dateDelimiter });
      })
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SystemSettingsSectionHeader className="mt-4">Date Format</SystemSettingsSectionHeader>
      <Row>
        <div className="col-6">
          <SystemSettingsDescription className="m-0">
            Date format will be applied to all dates shown on all views including filters and data export.
          </SystemSettingsDescription>
        </div>
        <div className="col-6 d-flex-column justify-content-end align-items-start">
          <DateFormatControl onChange={onChange} dateFormat={dateFormat} isLoading={isLoading} />
          <DateDelimiterControl onChange={onChange} dateDelimiter={dateDelimiter} isLoading={isLoading} />
        </div>
      </Row>
    </>
  );
}

export default observer(SystemSettingsDate);
