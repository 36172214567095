// @flow

import { observable, action, toJS, computed } from "mobx";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import union from "lodash.union";
import difference from "lodash.difference";
import api from "../services/Api";
import { Status } from "../helpers/Status";
import removeFilterValue from "../helpers/removeFilterValue";

export const FILTERS_INIT = {
  odPairs: [],
  flightNumber_1: [],
  depDate: { start: null, end: null },
  depTime: { start: "00:00", end: "23:59" },
  startDate: null,
  endDate: null,
  departureDayOfWeek: [],
  cabinClass: [],
  fusionrmStatus: undefined
};

export function normalizeFilters(filters: Object) {
  if (filters.flightIds && filters.flightIds.length) {
    const flightIds = filters.flightIds.map(id => {
      const status = id.match(/_on|_off$/);
      const statusIndex = id.indexOf(status);
      return id.slice(0, statusIndex);
    });
    return { flightIds };
  }

  const normalizedFilters = { ...filters };

  Object.entries(normalizedFilters).forEach(([filterKey, filterValue]) => {
    if (Array.isArray(filterValue)) {
      if (isEmpty(filterValue) || isEqual(filterValue, FILTERS_INIT[filterKey])) {
        delete normalizedFilters[filterKey];
      }
    }
    if (normalizedFilters[filterKey] === null && isEqual(filterValue, FILTERS_INIT[filterKey])) {
      delete normalizedFilters[filterKey];
    }
  });

  if (normalizedFilters.depDate) {
    if (normalizedFilters.depDate.start && normalizedFilters.depDate.end) {
      normalizedFilters.startDate = normalizedFilters.depDate.start;
      normalizedFilters.endDate = normalizedFilters.depDate.end;
    }
    delete normalizedFilters.depDate;
  }

  ["departureDayOfWeek", "flightNumber_1", "odPairs"].forEach(filter => {
    if (normalizedFilters[filter] && !normalizedFilters[filter].length) {
      delete normalizedFilters[filter];
    }
  });

  return normalizedFilters;
}

export class CirrusStore {
  // form on the sidebar
  @observable
  filters = { ...FILTERS_INIT };

  @observable
  filtersEnabled = true;

  @observable
  sidebar = {
    isOpen: false,
    filterQuery: "",
    filterKey: ""
  };

  // params on the top of the screen
  // all modules are searched by these params
  @observable
  searchParams = { ...FILTERS_INIT };

  @observable
  table = {
    status: Status.INIT,
    columns: [
      ["status", ["fusionrmStatus"]],
      ["flights", ["owMarket", "flightNumber_1", "departureDayOfWeek", "departureDate", "depTime", "cabinClass"]]
    ],
    data: [],
    selectedRows: [],
    pagination: {
      pageIndex: 0,
      pageSize: 25,
      pageCount: 1,
      totalRows: 0
    },
    dataStats: {
      enabled: 0,
      disabled: 0
    }
  };

  @action.bound
  toggleFiltersEnabled() {
    this.filtersEnabled = !this.filtersEnabled;
    this.fetchData();
  }

  @action.bound
  setSidebarOpen(isOpen) {
    this.sidebar.isOpen = isOpen;
    if (!isOpen) {
      this.sidebar.filterQuery = "";
      this.sidebar.filterKey = "";
    }
  }

  @action.bound
  setSidebarFilterQuery(filterQuery, filterKey) {
    const { sidebar } = this;

    sidebar.filterQuery = filterQuery;
    sidebar.filterKey = filterKey;
  }

  @action.bound
  fetchData(params: Object) {
    const { table, searchParams } = this;
    const { pageIndex = 0, pageSize = table.pagination.pageSize } = params || {};

    table.status = Status.LOADING;
    table.pagination.pageIndex = pageIndex;
    table.pagination.pageSize = pageSize;

    api
      .getCirrusStatus({
        ...(this.filtersEnabled && normalizeFilters(toJS(searchParams))),
        page: pageIndex + 1,
        pageSize
      })
      .then((response: Object) => {
        const pageCount = Math.ceil(response.data.totalRows / pageSize);
        table.data = response.data.rows;
        table.dataStats = response.data.fusionrmStatus;
        table.pagination.pageCount = pageCount;
        table.pagination.totalRows = response.data.totalRows;
        table.status = Status.DONE;
      })
      .catch(() => {
        table.status = Status.ERROR;
      });
  }

  @action
  modifyStatus(form: Object, status: boolean) {
    this.table.status = Status.LOADING;
    this.table.selectedRows.clear();
    api.switchCirrusStatus(normalizeFilters(toJS(form)), status).then(() => {
      this.fetchData();
    });
  }

  @action
  resetSearchParam(name: string) {
    this.filters[name] = FILTERS_INIT[name];
    this.submitSearchForm();
  }

  @action.bound
  shiftToggleRows(selectedRow: string, clickedRow: string) {
    const { data, selectedRows } = this.table;
    const isToggleOn = selectedRows.includes(clickedRow);
    const tableIdRows = data.map(row => `${row.flightId}_${row.fusionrmStatus ? "on" : "off"}`);
    const sliceIndexes = [tableIdRows.indexOf(selectedRow), tableIdRows.indexOf(clickedRow)].sort((a, b) => a - b);
    const actionRows = tableIdRows.slice(sliceIndexes[0], sliceIndexes[1] + 1);

    this.table.selectedRows = !isToggleOn ? union(selectedRows, actionRows) : difference(selectedRows, actionRows);
  }

  @action.bound
  changeSelectedRows(selectedRows: Array<string>) {
    this.table.selectedRows.replace(selectedRows);
  }

  @action.bound
  submitSearchForm() {
    this.searchParams = cloneDeep(toJS(this.filters));
    this.fetchData();
    this.sidebar.isOpen = false;
    this.sidebar.filterQuery = "";
    this.table.selectedRows.clear();
  }

  @action.bound
  changeFilter(filterKey, value) {
    this.filters[filterKey] = value;
  }

  @action.bound
  removeFilterValue(filterKey: string, option: string) {
    const { filters } = this;
    if (filters[filterKey]) {
      filters[filterKey] = removeFilterValue(filters[filterKey], option, FILTERS_INIT[filterKey]);
      this.submitSearchForm();
    }
  }

  @computed
  get decoratedPage() {
    // for consistency with other pages, Cirrus view does not have "page"
    return this;
  }
}
