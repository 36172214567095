import isEmpty from "lodash.isempty";
import unionBy from "lodash.unionby";

export default function prepareStructure(args) {
  const { template = {}, object = {}, keysToMerge = [], arraysToMerge = [] } = args;
  const newObject = {};
  if (template === null) {
    return null;
  }
  if (isEmpty(template)) {
    return { ...object };
  }

  Object.entries(template).forEach(([key, templateValue]) => {
    const arrayObjectToMerge = arraysToMerge.find(array => array.key === key);

    if (
      // is object
      typeof templateValue === "object" &&
      templateValue !== null &&
      !Array.isArray(templateValue)
    ) {
      if (keysToMerge.includes(key)) {
        newObject[key] = { ...templateValue, ...(object ? object[key] : {}) };
      } else {
        newObject[key] = prepareStructure({
          template: templateValue,
          object: object && object[key],
          keysToMerge,
          arraysToMerge
        });
      }
    } else if (object && typeof object[key] === "boolean") {
      // is boolean
      newObject[key] = object[key];
    } else if (arrayObjectToMerge) {
      // is array that should be merged
      const value = object && object[key] ? object[key] : [];
      newObject[key] = unionBy(value, templateValue, arrayObjectToMerge.identifier);
    } else {
      const valueExists = object && object[key];
      if (!valueExists) {
        newObject[key] = templateValue;
      } else if (templateValue == null) {
        newObject[key] = object[key];
      } else {
        const isSameType = typeof object[key] === typeof templateValue;
        newObject[key] = isSameType ? object[key] : templateValue;
      }
    }
  });
  return newObject;
}
