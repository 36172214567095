// @flow

import React from "react";
import { observer } from "mobx-react";

import { PriceLimit } from "../../models/Influence.model";

import InfluenceControlTable from "./InfluenceControlTable";
import InfluenceControlForm from "./InfluenceControlForm";

type Props = {
  data: {
    influenceId: string,
    base: Object,
    preview: Object,
    previewCounters: Object
  },
  parameters: {
    comment: string,
    inputValue: number | Array<PriceLimit>,
    value: number | Array<PriceLimit>
  },
  selectedInfluence: {
    disabled: boolean,
    isPreview: boolean,
    rows: Array<{ key: string }>,
    type: string
  },
  state: {
    influenceStep: number,
    influenceType: string
  },
  status: string,
  updateParameters: Function
};

function InfluenceControlSet(props: Props) {
  const { data, parameters, selectedInfluence, state, status, updateParameters } = props;
  const { isPreview, rows } = selectedInfluence;

  return (
    <div className="p-2" data-testid="influence-control-set">
      <InfluenceControlForm
        data={data}
        parameters={parameters}
        state={state}
        status={status}
        updateParameters={updateParameters}
      />
      {isPreview ? <InfluenceControlTable data={data} status={status} rows={rows} /> : null}
    </div>
  );
}

export default observer(InfluenceControlSet);
