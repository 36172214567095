import { Colors } from "@blueprintjs/core";

export const categories = {
  1: { color: Colors.BLUE2, key: "major-holiday" },
  2: { color: Colors.BLUE5, key: "regional-holiday" },
  3: { color: Colors.GREEN5, key: "school-break" },
  4: { color: Colors.INDIGO4, key: "conference" },
  5: { color: Colors.VERMILION4, key: "sporting-event" },
  6: { color: Colors.GOLD4, key: "concert-or-festival" },
  7: { color: Colors.GRAY1, key: "other" }
};

export const OTHER_CATEGORY_ID = 7;
