// @flow

import React from "react";
import { Card, Colors, TextArea } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { PriceLimit } from "../../models/Influence.model";

// $FlowFixMe
const CardStyled = styled(Card)`
  border: 1px solid ${Colors.LIGHT_GRAY2};
  box-shadow: none;
`;

const HeaderTitle = styled("p")`
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #182026;
`;

type Props = {
  parameters: {
    comment: string,
    inputValue: number | Array<PriceLimit>,
    value: number | Array<PriceLimit>
  },
  updateParameters: Function
};

function InfluenceControlDescription(props: Props) {
  const { parameters, updateParameters } = props;

  return (
    <CardStyled className="p-3 mb-2">
      <HeaderTitle>
        <strong>Description of changes</strong> (required)
      </HeaderTitle>
      <TextArea
        className="mb-2 bp3-fill bp3-large"
        data-testid="influence-control-textarea"
        growVertically
        onChange={e => updateParameters({ comment: e.target.value })}
        value={parameters.comment}
      />
    </CardStyled>
  );
}

export default InfluenceControlDescription;
