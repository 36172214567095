import { useEffect, useState } from "react";

import { Status } from "./Status";
import { useStores } from "../store/Store";

export const useApplicationInitializer = () => {
  const {
    analysisMappingsStore,
    appStore,
    mappingsStore,
    regionsStore,
    templatesStore,
    timeStore,
    systemUnitsStore
  } = useStores();

  const [dataStatus, setDataStatus] = useState(Status.LOADING);

  useEffect(() => {
    if (appStore.isLoggedIn) {
      analysisMappingsStore
        .getMarketAnalyst()
        .then(() =>
          Promise.all([
            analysisMappingsStore.getMappings(),
            appStore.auth.getCurrentUser(),
            mappingsStore.getMappings(),
            regionsStore.getRegionsAndSubregions(),
            systemUnitsStore.getDateFormat(),
            templatesStore.getSavedViews(),
            timeStore.getKPIsTimestamp()
          ])
        )
        .then(() => {
          setDataStatus(Status.DONE);
        })
        .catch(() => {
          setDataStatus(Status.ERROR);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return dataStatus;
};
