// @flow

import React from "react";
import { observer } from "mobx-react";

import SidebarSelect from "../SidebarSelect";
import SidebarFilter from "../SidebarFilter";
import SidebarNDOSelect from "../SidebarNDOSelect";
import SidebarDateRangeSelect from "../SidebarDateRangeSelect";
import SidebarDaySelect from "../SidebarDaySelect";
import type { AnalysisMappingsStore } from "../../models/AnalysisMappings.model";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  computedDateFormat: string,
  computedDateMonthFormat: string,
  mappings: AnalysisMappingsStore,
  pageContext: Object
};

function DepartureDateFilters(props: Props) {
  const { computedDateFormat, computedDateMonthFormat, mappings, pageContext } = props;
  const { filters, changeFilter, sidebar } = pageContext;

  const quarters = mappings.data.quarter.map(singleQuarter => {
    return {
      value: singleQuarter,
      label: `${singleQuarter.substring(0, 4)} ${singleQuarter.substring(4)}`
    };
  });

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("number of days to departure", "ndo", sidebar)}>
        <SidebarNDOSelect
          onChange={filterValue => changeFilter("ndo", filterValue)}
          title="Number of Days to Departure"
          values={filters.ndo}
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure quarter", "quarter", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={quarters}
          onChange={filterValue => changeFilter("quarter", filterValue)}
          selectedItems={filters.quarter}
          title="Quarter"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure month", "depMonth", sidebar)}>
        <SidebarSelect
          computedDateFormat={computedDateFormat}
          computedDateMonthFormat={computedDateMonthFormat}
          fuzzySearch
          items={mappings.data.depMonth}
          onChange={filterValue => changeFilter("depMonth", filterValue)}
          selectedItems={filters.depMonth}
          title="Departure Month"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure week", "depWeek", sidebar)}>
        <SidebarSelect
          computedDateFormat={computedDateFormat}
          fuzzySearch
          items={mappings.data.depWeek}
          onChange={filterValue => changeFilter("depWeek", filterValue)}
          selectedItems={filters.depWeek}
          title="Departure Week"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure date", "depDate", sidebar)}>
        <SidebarDateRangeSelect
          computedDateFormat={computedDateFormat}
          dates={filters.depDate}
          onChange={filterValue => changeFilter("depDate", filterValue)}
          title="Departure Date"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure day of week", "depDow", sidebar)}>
        <SidebarDaySelect
          onChange={filterValue =>
            changeFilter(
              "depDow",
              filterValue.sort((a, b) => a - b)
            )
          }
          selectedItems={filters.depDow}
          title="Departure Day of Week"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(DepartureDateFilters);
