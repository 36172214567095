// @flow

import React from "react";
import clsx from "clsx";
import kebabCase from "lodash.kebabcase";
import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";

import { renderTagSelect } from "../../helpers/renderTagSelect";

const StyledListOption = styled("li")`
  padding: 7px 10px;

  &.bp3-menu-item.bp3-intent-primary {
    &:hover,
    &.bp3-active {
      background-color: ${Colors.LIGHT_GRAY4};
      color: ${Colors.DARK_GRAY4};
    }
  }
`;

const StyledName = styled("div")`
  line-height: 1.28;
  margin-bottom: 2px;
`;

const StyledDates = styled("div")`
  color: ${Colors.GRAY1};
  font-size: 12px;
  line-height: 1.25;
  margin-top: 2px;
`;

export const eventItemRenderer = (
  item: {
    category: string,
    categoryId: number,
    dateRange: { end: string, start: string },
    label: string,
    type: string
  },
  itemProps: { handleClick: Function, query: string, modifiers: { active: boolean } }
) => {
  const { handleClick, query, modifiers } = itemProps;
  const text = item.label;
  const startDate = item?.dateRange?.start;
  const endDate = item?.dateRange?.end;

  const date = startDate === endDate ? startDate : `${startDate} - ${endDate}`;

  const queriedText = query
    ? text.replace(new RegExp(query, "ui"), match => {
        return `<strong>${match}</strong>`;
      })
    : text;

  const itemClassName = clsx("d-flex flex-column bp3-menu-item", {
    "bp3-active bp3-intent-primary": modifiers?.active
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <StyledListOption
      className={itemClassName}
      data-testid={`event-item-${item.value}`}
      key={`${item.value}-${kebabCase(text)}`}
      onClick={handleClick}
    >
      <StyledName
        className="bp3-text-overflow-ellipsis bp3-fill w-100"
        dangerouslySetInnerHTML={{ __html: queriedText }} // eslint-disable-line react/no-danger
        data-testid="event-item-name"
      />
      {renderTagSelect({ value: item.categoryId, label: item.category })}
      {date && (
        <StyledDates data-testid="event-item-dates">
          {item.type === "past" && "Last occurrence "}
          {date}
        </StyledDates>
      )}
    </StyledListOption>
  );
};
