// @flow
import React from "react";
import { Button, InputGroup } from "@blueprintjs/core";

type Props = {
  onChange: Function,
  placeholder: ?string,
  value: Array
};

function SearchInput(props: Props) {
  const { onChange, placeholder = "Search filters...", value = [] } = props;
  const isValue = value.length > 0;

  return (
    <InputGroup
      autoComplete="off"
      autoFocus
      className="m-2"
      data-testid="search-filters"
      id="text-input"
      leftIcon="search"
      onChange={({ target }) => onChange(target.value)}
      placeholder={placeholder}
      rightElement={
        isValue ? <Button icon="cross" minimal data-testid="search-filters-clear" onClick={() => onChange("")} /> : null
      }
      value={value}
    />
  );
}

export default SearchInput;
