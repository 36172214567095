// @flow

import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";
import { format } from "date-fns";

import columnTextWidths from "../helpers/ColumnTextWidths";
import DataTable from "./DataTable";
import Module from "./Module";
import { cellRenderers } from "../helpers/influenceHistoryCellsRenders";
import { Status } from "../helpers/Status";

type Props = {
  clearSelectedRows: Function,
  computedDateFormat: string,
  data: Array<Object>,
  deleteInfluences: Function,
  fetchData: Function,
  pagination: Object,
  selectedRows: Array<string>,
  setModal: Function,
  sortByConfig: Object,
  status: string,
  onRowToggle: Function
};

export const columnLabels = {
  influence: "Influence",
  influenceId: "Influence ID",
  status: "Status",
  type: "Type",
  value: "Value",
  updatedOn: `Last Modified (${format(new Date(), "O")})`,
  analystName: "Analyst",
  comment: "Comment",
  numberOfFlights: "Flt Count",
  minDepDate: "Min Dep Date",
  maxDepDate: "Max Dep Date",
  owMarkets: "OW Markets Applied"
};

const columnSortType = {
  comment: null,
  owMarkets: null,
  type: null,
  value: null
};

function InfluenceHistoryTable(props: Props) {
  const {
    deleteInfluences,
    onRowToggle,
    pagination,
    computedDateFormat,
    selectedRows = [],
    setModal,
    sortByConfig,
    status
  } = props;
  const influencesCountText = selectedRows.length === 1 ? "Influence" : "Influences";
  const isRowSelectionDisabled = props.data.every(({ status }) => status !== "active");

  const isLoaded = status === Status.DONE;
  const subtitle = isLoaded ? `${pagination.totalRows} rows` : null;

  const deleteButton = (
    <Button
      data-testid="delete-influence-button"
      disabled={!selectedRows.length}
      icon="trash"
      onClick={() => setModal("deleteInfluence", { deleteInfluences, selectedRows })}
    >
      Delete {selectedRows.length || "selected"} {influencesCountText}
    </Button>
  );

  const disabledRows = [];
  props.data.forEach(({ influenceId, status }) => {
    if (status !== "active") {
      disabledRows.push(influenceId);
    }
  });

  const columnTextWidth = useMemo(() => columnTextWidths(columnLabels), []);

  const tableProps = {
    ...props,
    columnConfig: {
      columnsAligned: { value: "right" }
    },
    columnAccessors: {
      value: "valueText"
    },
    columnWidths: { status: 65 },
    cellRenderers: cellRenderers(computedDateFormat),
    columnLabels,
    columnTextWidth,
    columnSortType: { ...columnSortType, ...sortByConfig },
    disabledRows,
    expandableRows: true,
    rowIdAccessor: "influenceId",
    onRowToggle,
    isPreviewActive: isRowSelectionDisabled
  };

  return (
    <Module
      childrenClassName="d-flex flex-column h-100"
      className="flex-shrink-1 mb-2"
      minHeight={props.data.length > 7 ? 315 : 0}
      subtitle={[subtitle && `· ${subtitle}`]}
      title="Influence History"
      titleTools={isLoaded && deleteButton}
    >
      <DataTable {...tableProps} />
    </Module>
  );
}

export default observer(InfluenceHistoryTable);
