// @flow

type ParentProps = {
  group: Object,
  parent: string,
  withoutDuplication: boolean
};

export default function metricWithParent(props: ParentProps) {
  const { group, parent, withoutDuplication } = props;
  const newGroup = group;

  Object.entries(group).forEach((props: Object) => {
    const [key: string, value: string] = props;

    if (key !== parent) {
      const parentSentence = group[parent].split(" ");

      newGroup[key] =
        withoutDuplication && parentSentence[parentSentence.length - 1] === value
          ? group[parent]
          : `${group[parent]} ${value}`;
    }
  });

  return newGroup;
}
