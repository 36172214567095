import isEmpty from "lodash.isempty";

export default function groupConditionalFilters(conditionalFilters) {
  if (!isEmpty(conditionalFilters)) {
    return conditionalFilters.reduce((result, item) => {
      const { func, name, value } = item;
      return {
        ...result,
        [name]: result[name] ? [...result[name], { func, value }] : [{ func, value }]
      };
    }, {});
  }
  return {};
}
