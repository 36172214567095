import React from "react";
import { observer } from "mobx-react";

import { startOfToday, subYears } from "date-fns";
import SidebarFilter from "../SidebarFilter";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";
import SidebarSelect from "../SidebarSelect";
import SidebarDateRangeSelect from "../SidebarDateRangeSelect";
import { useStores } from "../../store/Store";

type Props = {
  computedDateFormat: string,
  pageContext: Object
};

function EventFilters(props: Props) {
  const { pageContext, computedDateFormat } = props;
  const { changeFilter, filters, sidebar } = pageContext;
  const { eventsManagementStore } = useStores();

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("event category", "categoryIds", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={eventsManagementStore.eventCategories}
          onChange={filterValue => changeFilter("categoryIds", filterValue)}
          selectedItems={filters.categoryIds}
          title="Category"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("event date range", "dateRange", sidebar)}>
        <SidebarDateRangeSelect
          computedDateFormat={computedDateFormat}
          dates={filters.dateRange}
          minDate={subYears(startOfToday(), 20)}
          onChange={filterValue => changeFilter("dateRange", filterValue)}
          title="Date range"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("analyst", "userIds", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={eventsManagementStore.eventAnalysts}
          onChange={filterValue => changeFilter("userIds", filterValue)}
          selectedItems={filters.userIds}
          title="Analyst"
        />
      </SidebarFilter>
    </>
  );
}

export default observer(EventFilters);
