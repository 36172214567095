// @flow

import React from "react";
import { observer } from "mobx-react";

import CirrusTagList from "./CirrusTagList";
import FilterInteractionsWrapper from "./FilterInteractionsWrapper";
import TopBar from "./TopBar";
import { useStores } from "../store/Store";

function CirrusTopBar() {
  const { cirrusStore, mappingsStore } = useStores();

  const { setSidebarOpen } = cirrusStore;
  const { isLoading } = mappingsStore;

  return (
    <TopBar>
      <CirrusTagList />
      <FilterInteractionsWrapper
        buttonProps={{
          className: "ml-auto",
          testId: "filters-button",
          isLoading,
          onClick: setSidebarOpen
        }}
        filterSwitchProps={{ checked: cirrusStore.filtersEnabled, onChange: cirrusStore.toggleFiltersEnabled }}
      />
    </TopBar>
  );
}

export default observer(CirrusTopBar);
