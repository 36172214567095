import React from "react";
import Tag from "./Tag";
import filterNamesMap, { filterNamesOverrides } from "../helpers/filterNames";
import { ChipValue } from "./ChipValue";
import ChipContent from "./ChipContent";
import filterTitleValue from "../helpers/filterTitleValue";
import { useStores } from "../store/Store";

type Props = {
  additionalTagLabels: ?Object,
  disabled: boolean,
  handleRemove: ?Function,
  isPreviewActive: boolean,
  onValueClick: Function,
  onValueRemove: Function,
  tags: Array<Object>,
  transformToMile: boolean,
  valueRenderers: Object
};

export function OverflownTagList(props: Props) {
  const {
    additionalTagLabels,
    disabled,
    handleRemove,
    isPreviewActive,
    onValueClick,
    onValueRemove,
    tags,
    transformToMile = false,
    valueRenderers = {}
  } = props;

  const { systemUnitsStore } = useStores();
  const { computedDateFormat } = systemUnitsStore;

  const filterNames = filterNamesMap(transformToMile);
  const filterText = tags.length === 1 ? "more filter" : "more filters";

  return (
    <div>
      <p>
        {tags.length} {filterText}
      </p>
      {tags.map(tag => {
        const [name, value] = tag;

        const filterName =
          filterNamesOverrides[value.name] ||
          filterNamesOverrides[name] ||
          filterNames[value.name] ||
          filterNames[name] ||
          name;

        const popoverContent = (
          <ChipContent
            additionalTagLabels={additionalTagLabels}
            clearable={!isPreviewActive}
            customValueRenderer={valueRenderers[name]}
            name={name}
            onValueRemove={onValueRemove}
            onValueClick={onValueClick ? () => onValueClick(name) : undefined}
            title={filterNames[name] || name}
            value={value}
          />
        );

        const tagProps = {
          disabled,
          key: name,
          popoverContent,
          ...(!isPreviewActive && { onRemove: handleRemove(name) })
        };

        return (
          <Tag {...tagProps}>
            <span
              className="d-flex"
              title={`${filterName}: ${filterTitleValue(value, name, null, computedDateFormat)}`}
            >
              <span>{filterName}</span>
              <span className="font-weight-normal mx-1"> &middot; </span>
              <ChipValue
                name={name}
                value={value}
                customValueRenderer={valueRenderers[name]}
                additionalTagLabels={additionalTagLabels}
              />
            </span>
          </Tag>
        );
      })}
    </div>
  );
}
