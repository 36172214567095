// @flow

import React from "react";
import { observer } from "mobx-react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import ReactHeap from "reactjs-heap";
import styled from "@emotion/styled";
import { Spinner } from "@blueprintjs/core";
import { useIntercom } from "react-use-intercom";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import clsx from "clsx";

import AdminTools, { ADMIN_TOOLS_ROUTES } from "../views/AdminTools";
import Analysis from "../views/Analysis";
import Dashboard from "../views/Dashboard";
import EventsManagement from "../views/EventsManagement";
import Header from "./Header";
import InfluenceHistory from "../views/InfluenceHistory";
import isEnv from "../helpers/isEnv";
import LandingPage from "./LandingPage";
import Modal from "./Modal";
import Users from "../views/Users";
import { HEAP_ID } from "../config/config";
import { isLocalhost, isNotCypress } from "../helpers/constants";
import { SpyPathNames } from "../helpers/spyPathNames";
import { Status } from "../helpers/Status";
import { useApplicationInitializer } from "../helpers/useApplicationInitializer";
import { useStores } from "../store/Store";
import TemplatesSidebar from "../components/Templates/TemplatesSidebar";
import AuthFooter from "../components/AuthFooter";
import Share from "../components/Share";

export const StyledApp = styled("div")`
  height: 100%;
`;

const Application = () => {
  const { appStore, themeStore } = useStores();

  const { boot: bootIntercom } = useIntercom();

  const dataStatus = useApplicationInitializer();

  const { REACT_APP_ENV: env = "", REACT_APP_PARTNER: partner = "" } = process.env;
  const subpages = ["explore", "influence"];

  if (dataStatus === Status.LOADING) {
    return (
      <StyledApp className={clsx("d-flex", themeStore.themeClass)}>
        <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <h3 className="mb-3">Cirrus UI</h3>
          <Spinner />
          <div className="mt-2 bp3-text-small bp3-text-muted">Loading user preferences</div>
        </div>
        <AuthFooter />
      </StyledApp>
    );
  }

  if (dataStatus === Status.ERROR) {
    return (
      <StyledApp className={clsx("d-flex", themeStore.themeClass)}>
        <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <h3 className="mb-3">Cirrus UI</h3>
          <div className="mt-2 bp3-text-small bp3-text-muted">Something went wrong. Please reload and try again</div>
        </div>
      </StyledApp>
    );
  }

  if (!isLocalhost) {
    const { group, email, name } = appStore.auth.user;
    if (isEnv(["staging", "integration", "production"])) {
      // sentry
      Sentry.configureScope(scope => {
        scope.setTag("group", group);
        scope.setUser({ email, name });
      });
      if (isNotCypress) {
        // logrocket
        LogRocket.identify(email, { name, email, group, env, partner });
      }
    }
    if (isEnv(["integration", "production"])) {
      // heap
      ReactHeap.initialize(HEAP_ID[env]);
      if (window.heap) {
        window.heap.identify(email);
        window.heap.addUserProperties({ name, email, group, env, partner });
      }
    }
    if (isNotCypress) {
      // intercom
      bootIntercom({ email, name });
    }
  }

  return (
    <StyledApp className={`d-flex flex-column ${themeStore.themeClass}`}>
      <Router>
        <Header isAdmin={appStore.auth.isAdmin} />
        <SpyPathNames />
        <Modal />
        <TemplatesSidebar />
        <Switch>
          <Route exact path={subpages.map(name => `/analysis/:tabId/${name}`)}>
            <Analysis />
          </Route>
          <Route exact path="/analysis/:tabId">
            <Analysis />
          </Route>
          <Route exact path="/share/:shareId">
            <Share />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/events-management">
            <EventsManagement />
          </Route>
          <Route path="/influence-history">
            <InfluenceHistory />
          </Route>
          <Route
            path="/user-management"
            render={() => (appStore.auth.isAdmin ? <Users /> : <Redirect to="/dashboard" />)}
          />
          <Route
            path={ADMIN_TOOLS_ROUTES}
            render={() => (appStore.auth.isAdmin ? <AdminTools /> : <Redirect to="/dashboard" />)}
          />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Router>
    </StyledApp>
  );
};

export default observer(Application);
