// @flow

import difference from "lodash.difference";

type ScopingFilter = {
  destination?: Array<string>,
  origin?: Array<string>,
  owMarket?: Array<string>,
  regionId?: Array<string>,
  rtMarket?: Array<string>,
  subregionId?: Array<string>
};

const getGroupItemsForFilter = (scopingFilter: ScopingFilter, getterFunction: Function) => {
  const matchingItems = getterFunction(scopingFilter);
  const allItems = difference(getterFunction(null), matchingItems);
  return [allItems, matchingItems];
};

export default getGroupItemsForFilter;
