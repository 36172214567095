// @flow

import React from "react";
import { observer } from "mobx-react";
import { Button } from "@blueprintjs/core";

import { Status } from "../helpers/Status";
import { useStores } from "../store/Store";

import DataTable from "./DataTable";
import Module from "./Module";
import StatusIndicator from "./StatusIndicator";

type Props = {
  currentUser: string,
  userGroups: Array<Object>,
  columns: Array<Object>,
  data: Array<Object>,
  pagination: Object,
  status: string,
  fetchData: ?Function,
  onRowClick: ?Function
};

function UsersTable(props: Props) {
  const { columns, data, pagination, status, fetchData = null, onRowClick = null } = props;

  const { modalStore } = useStores();
  const isLoading = status === Status.LOADING;

  const columnLabels = {
    name: "Name",
    email: "Email",
    emailVerified: "Verified",
    group: "Role"
  };

  const sortBy = {
    direction: "asc",
    field: "name"
  };

  const columnSortType = {
    emailVerified: (rowA, rowB, id) => {
      const a = rowA.values[id];
      const b = rowB.values[id];

      return a > b ? -1 : 1;
    },
    name: (rowA, rowB, id) => {
      const a = rowA.values[id];
      const b = rowB.values[id];
      return a.localeCompare(b);
    }
  };

  const cellRenderers = {
    emailVerified: ({ value }: Cell) => <StatusIndicator isActive={value} text={value ? "Yes" : "No"} />,
    group: ({ value }) => value || "–"
  };

  const addUserButton = (
    <Button
      disabled={isLoading}
      icon="plus"
      text="Invite User"
      onClick={e => {
        e.stopPropagation();
        modalStore.setModal("addUser", data);
      }}
    />
  );

  const tableProps = {
    columnConfig: {},
    cellRenderers,
    columnLabels,
    columns,
    columnSortType,
    data,
    fetchData,
    onRowClick,
    pagination,
    rowIdAccessor: "userId",
    showPagination: false,
    sortable: "frontend",
    sortBy,
    status
  };

  return (
    <Module title="Users" titleTools={addUserButton} className="h-100">
      <DataTable {...tableProps} />
    </Module>
  );
}

export default observer(UsersTable);
