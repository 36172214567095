import React from "react";
import clsx from "clsx";
import kebabCase from "lodash.kebabcase";
import styled from "@emotion/styled";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { MenuItem, Colors, Position, Button, Icon } from "@blueprintjs/core";

import isNumber from "../helpers/isNumber";
import { renderTagSelect } from "../helpers/renderTagSelect";

const deleteItemFromArray = (items: [], itemToDelete) => items.filter(item => item.value !== itemToDelete);

type Props = {
  dataTestId: ?string,
  disabled: ?boolean,
  filterable: ?boolean,
  items: Array<{
    color: ?string,
    key: ?string,
    label: string,
    value: number
  }>,
  minimal: ?boolean,
  onChange: Function,
  placeholder: ?string,
  popoverProps: ?Object,
  portalClassName: ?string,
  renderItem: ?Function,
  renderSelect: Function,
  selectedItem: ?{ value: string | number, label: string },
  title: ?string
};

const selectDefaultSetup = {
  allowCreate: false,
  createFirst: false,
  disabled: false,
  disableItems: false,
  filterable: false,
  hasInitialContent: false,
  items: [],
  minimal: true,
  resetOnClose: false,
  resetOnQuery: true,
  resetOnSelect: false,
  selectedItem: {}
};

const StyledTextTagSelect = styled("div")`
  color: ${Colors.GRAY3};
`;

const StyledTagSelect = styled.div`
  display: flex;
  flex-direction: column;

  .bp3-popover-target,
  .bp3-button-text {
    width: 100%;
  }

  .bp3-button {
    &,
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background-color: ${Colors.WHITE};
      box-shadow: 0 0 0 0 ${Colors.BLUE3}00, 0 0 0 0 ${Colors.BLUE3}00, inset 0 0 0 1px ${Colors.BLACK}26,
        inset 0 1px 1px ${Colors.BLACK}33;
    }

    &:focus:not(:disabled) {
      outline: none;
      box-shadow: 0 0 0 1px ${Colors.BLUE3}, 0 0 0 3px ${Colors.BLUE3}4d, inset 0 1px 1px ${Colors.BLACK}33;
    }
  }
`;

const renderItem: ItemRenderer = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const itemClassName = clsx("bp3-menu-item", {
    "bp3-active bp3-intent-primary": modifiers.active
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li className={itemClassName} key={item.value} onClick={() => handleClick([item.value])}>
      {renderTagSelect(item)}
    </li>
  );
};

function TagSelect(props: Props) {
  const {
    dataTestId,
    items = [],
    onChange,
    popoverProps = {},
    portalClassName,
    renderSelect,
    selectedItem,
    title = ""
  } = props;

  const testId = dataTestId || kebabCase(`${title}-select`);
  const selectProps = { ...selectDefaultSetup, ...props };

  return (
    <StyledTagSelect className="mb-3" data-testid={testId}>
      <h6 className="bp3-heading">{title}</h6>
      <Select
        {...selectProps}
        fill
        itemRenderer={props.renderItem || renderItem}
        items={deleteItemFromArray(items, selectedItem)}
        noResults={<MenuItem disabled text="No results." />}
        onItemSelect={onChange}
        popoverProps={{
          fill: true,
          minimal: true,
          modifiers: {
            offset: 100
          },
          portalClassName,
          position: Position.BOTTOM,
          ...popoverProps
        }}
      >
        <Button className="bp3-fill" data-testid="button-tag-select" disabled={selectProps.disabled}>
          <div className="d-flex justify-content-between">
            {isNumber(selectedItem) || !!selectedItem ? (
              renderSelect(selectedItem)
            ) : (
              <StyledTextTagSelect>Select event category</StyledTextTagSelect>
            )}
            <Icon icon="caret-down" className="mb-auto ml-auto mr-2 mt-auto" color={Colors.GRAY1} />
          </div>
        </Button>
      </Select>
    </StyledTagSelect>
  );
}

export default TagSelect;
