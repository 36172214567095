// @flow

import { action, computed, observable } from "mobx";

import api from "../services/Api";
import { Status } from "../helpers/Status";

export class MappingsStore {
  @observable status = Status.INIT;
  @observable data = {};

  @action getMappings() {
    this.status = Status.LOADING;
    api
      .getMappings()
      .then(({ data }) => {
        this.data = data;
        this.status = Status.DONE;
      })
      .catch(() => {
        this.status = Status.ERROR;
      });
  }

  @action.bound
  getFlightNumbers(selectedODs: Array<string> = []) {
    const { regions = [] } = this.data;
    let filterByODs = odPair => odPair;
    if (selectedODs.length) {
      filterByODs = ({ id }) => selectedODs.includes(id);
    }

    const flightNumbers = regions
      .map(region => region.odPairs.filter(filterByODs).map(odPair => odPair.flights.map(flight => flight.number)))
      .flat(2);

    const naturalSort = (a, b) => a.localeCompare(b, undefined, { numeric: true });

    return [...new Set(flightNumbers)].sort(naturalSort);
  }

  @computed
  get regions() {
    const { regions = [] } = this.data;
    return regions.map(region => region.name);
  }

  @computed
  get rtMarkets() {
    const { regions = [] } = this.data;
    const newRegions = regions
      .map(region =>
        region.odPairs.map(odPair => {
          if (odPair.id && odPair.id.length === 6) {
            const firstOd = odPair.id.slice(0, 3);
            const secondOd = odPair.id.slice(-3);
            return [firstOd, secondOd].sort().join("");
          }
          return null;
        })
      )
      .flat()
      .filter(Boolean)
      .sort();

    return [...new Set(newRegions)];
  }

  @computed
  get ods() {
    const { regions = [] } = this.data;
    const newRegions = regions
      .map(region => region.odPairs.map(odPair => odPair.id))
      .flat()
      .sort();

    return [...new Set(newRegions)];
  }

  @computed
  get isLoading() {
    return this.status === Status.LOADING;
  }

  @computed
  get isError() {
    return this.status === Status.ERROR;
  }
}
