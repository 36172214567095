// @flow

import React from "react";
import { observer } from "mobx-react";
import { Callout } from "@blueprintjs/core";

type Props = {
  isSuccess: boolean,
  message: ?string
};

function VerifyEmailCallout(props: Props) {
  const { isSuccess, message } = props;

  const information = isSuccess ? (
    <Callout className="mt-4 text-center" intent="success" icon={null} title="Verification Success">
      <p className="pt-2">In a minute you will receive an email providing a link to set your new password.</p>
      <p>You can close this page.</p>
    </Callout>
  ) : (
    <Callout className="mt-4 text-center" intent="warning" icon={null} title={message} />
  );

  return information;
}

export default observer(VerifyEmailCallout);
