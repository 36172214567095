// @flow

import React from "react";
import isEmpty from "lodash.isempty";
import { Classes, Colors, Intent, Switch } from "@blueprintjs/core";
import styled from "@emotion/styled";

import SidebarSelect from "../components/SidebarSelect";

const StyledSelect = styled(SidebarSelect)`
  &.is-provenance-type-off .bp3-tag {
    background-color: ${Colors.LIGHT_GRAY2};
    color: ${Colors.GRAY3};
  }
`;

const StyledFormHeader = styled("h4")`
  margin-bottom: 14px;
`;

const StyledGlobalInfo = styled("div")`
  color: ${Colors.GRAY4};
`;

const StyledColumnWrapper = styled("div")`
  padding: 12px;
`;

export const selectTextDictionary = {
  airport: { title: "Airport", placeholder: "airports" },
  country: { title: "Country", placeholder: "countries" },
  metro: { title: "Metro", placeholder: "metro" }
};

const filterItems = (query, item) => {
  const normalizedQuery = query.toLowerCase();
  const normalizedTitle = `${item.label}${item.value}`.toLowerCase();

  return normalizedTitle.includes(normalizedQuery);
};

const sortItems = (items, listProps) => {
  items.sort((a, b) => {
    if (listProps.query === a.value && listProps.query !== b.value) {
      return -1;
    }
    if (listProps.query !== a.value && listProps.query === b.value) {
      return 1;
    }
    return 0;
  });
};

export const renderSelect = ({
  conflictList,
  isDisabled,
  isDisabledGlobally,
  items,
  onChange,
  parent,
  selectedItems,
  type,
  withoutTitle
}: {
  conflictList: ?Array<number>,
  isDisabled: ?boolean,
  isDisabledGlobally: ?boolean,
  items: Array<string>,
  onChange: Function,
  parent: string,
  selectedItems: Array<string>,
  type: string,
  withoutTitle: ?boolean
}) => {
  const selectedItemsCode =
    selectedItems?.length && typeof selectedItems[0] === "object" && selectedItems[0] !== null
      ? selectedItems.map(item => item.code)
      : selectedItems;

  const getTagIntent = (_value: React.ReactNode, index: number): ITagProps => ({
    intent: conflictList?.includes(index) ? Intent.WARNING : Intent.NONE
  });

  return (
    <StyledSelect
      className={isDisabled ? "is-provenance-type-off" : ""}
      dataTestId={`event-select-${parent}-${type}`}
      filterItems={filterItems}
      fuzzySearch
      items={items}
      onChange={onChange}
      placeholder={`Search ${selectTextDictionary[type]?.placeholder}`}
      popoverProps={{
        boundary: "viewport",
        popoverClassName: "-event-modal"
      }}
      tagInputProps={{
        disabled: isDisabledGlobally,
        tagProps: getTagIntent
      }}
      portalClassName={`event-select-${parent}-${type}`}
      selectedItems={selectedItemsCode}
      sortFn={sortItems}
      title={!withoutTitle && selectTextDictionary[type]?.title}
    />
  );
};

export const ColumnWrapper = ({ type, children }: { type: string, children: React.Node }) => (
  <StyledColumnWrapper
    className={`${Classes.DIALOG_BODY} m-0 overflow-auto event-column-${type}`}
    data-testid={`events-management-modal-${type}`}
  >
    {children}
  </StyledColumnWrapper>
);

export const renderToggleForm = ({
  isChecked,
  name,
  renderSelects,
  toggleFunction,
  type
}: {
  isChecked: boolean,
  name: string,
  renderSelects: React.Node,
  toggleFunction: Function,
  type: string
}) => (
  <ColumnWrapper type={type}>
    <StyledFormHeader className="mt-0">{name}</StyledFormHeader>
    <Switch
      checked={isChecked}
      className="mb-3"
      data-testid={`events-${type}-global-switch`}
      label="Global"
      onChange={() => toggleFunction(!isChecked)}
    />
    {!isChecked && renderSelects}
    {isChecked && <StyledGlobalInfo>Global events impact all airports in the network.</StyledGlobalInfo>}
  </ColumnWrapper>
);

export const prepareEventObject = (event: {
  isLocationGlobal?: boolean,
  isProvenanceGlobal: boolean,
  locations: {} | null,
  name: string,
  nonWorkingDay: boolean,
  provenances: {} | null
}) => {
  const {
    isLocationGlobal,
    locations,
    name = "",
    nonWorkingDay = false,
    isProvenanceGlobal,
    provenances,
    provenanceType,
    ...rest
  } = event;
  const isProvenancesExists = provenances && !isEmpty(Object.values(provenances).flat());
  const provenanceTypeCallback = isProvenancesExists
    ? Object.entries(provenances)
        .filter(([, value]) => value.length)
        ?.flat()?.[0]
    : "countries";

  return {
    ...rest,
    isLocationGlobal: typeof isLocationGlobal === "boolean" ? isLocationGlobal : !locations,
    locations: {
      airports: [],
      countries: [],
      metros: [],
      ...(locations && locations)
    },
    name,
    nonWorkingDay,
    isProvenanceGlobal: typeof isProvenanceGlobal === "boolean" ? isProvenanceGlobal : !provenances,
    provenances: {
      airports: [],
      countries: [],
      metros: [],
      ...(provenances && provenances)
    },
    provenanceType: provenanceType || provenanceTypeCallback
  };
};

export const displayConflictErrors = (
  { name, dateRange }: { name: ?string, dateRange: { end: ?string, start: ?string } },
  clonedModal: { name: ?string, dateRange: { end: ?string, start: ?string } }
) => {
  const erroredNameIsChanged = !isEmpty(clonedModal) && name && clonedModal.name === name;
  const erroredDateRangeIsChanged =
    !isEmpty(clonedModal) &&
    dateRange?.start &&
    dateRange?.end &&
    clonedModal.dateRange?.start === dateRange?.start &&
    clonedModal.dateRange?.end === dateRange?.end;
  return erroredNameIsChanged && erroredDateRangeIsChanged;
};

export const getPlaceCodes = eventPlace =>
  !isEmpty(eventPlace) && typeof eventPlace[0] === "object" && eventPlace[0] !== null
    ? eventPlace.map(place => place.code)
    : eventPlace;
