import React, { useEffect } from "react";
import { observer } from "mobx-react";

import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import SidebarSingleSelect from "../SidebarSingleSelect";
import { useStores } from "../../store/Store";
import { usePrevious } from "../../helpers/usePrevious";

type Props = {
  onChange: Function,
  selectedSubregion: { label: string, key: number } | null,
  selectedRegion: { label: string, key: number } | null
};

const unassignedItem = { value: null, label: "Unassigned" };

function MarketMappingsSubregionSelect(props: Props) {
  const { onChange, selectedSubregion, selectedRegion } = props;
  const { regionsStore } = useStores();
  const subregionsToSelect = regionsStore.getSubregionsByRegionId(selectedRegion?.value);
  const previousSelections = usePrevious({
    previouslySelectedRegion: selectedRegion,
    previouslySelectedSubregion: selectedSubregion
  });

  const subRegionItems = [
    unassignedItem,
    ...subregionsToSelect.map(({ subregionName: label, subregionId: value }) => ({ label, value }))
  ];

  useEffect(() => {
    if (previousSelections) {
      const { previouslySelectedRegion, previouslySelectedSubregion } = previousSelections;

      const isPreviousRegionNotSelected = !isEmpty(previouslySelectedRegion);
      const isPreviousRegionDifferent =
        isPreviousRegionNotSelected && !isEqual(previouslySelectedRegion, selectedRegion);

      if (isPreviousRegionDifferent || isEmpty(previouslySelectedSubregion)) {
        onChange(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  return (
    <div className="d-flex">
      <SidebarSingleSelect
        items={subRegionItems}
        selectedItem={selectedSubregion}
        selectItem={item => onChange(item)}
        title="Subregion"
      />
    </div>
  );
}

export default observer(MarketMappingsSubregionSelect);
