// @flow

import React from "react";
import { observer } from "mobx-react";
import { metricsTypes, metricUnitTypes } from "../../helpers/constants";

import SidebarFilter from "../SidebarFilter";
import SidebarConditionalFilter from "../SidebarConditionalFilter";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";

type Props = {
  filters: Array<Object>,
  labels: Object,
  pageContext: Object
};

function ConditionalFiltersGroup(props: Props) {
  const { pageContext, labels, filters } = props;
  const { conditionalFilters, addCondition, changeConditionalFilter, deleteCondition, sidebar } = pageContext;
  const { titleLabels, filterLabels } = labels;

  if (!filters) {
    return null;
  }

  return filters.map(({ key, isNonNegative }) => {
    const filterProps = {
      conditionalFilters,
      inputHint: metricUnitTypes[metricsTypes[key]],
      isNonNegative,
      name: key,
      onAddCondition: addCondition,
      onChangeCondition: changeConditionalFilter,
      onDeleteCondition: deleteCondition,
      title: titleLabels[key],
      type: "absolute"
    };

    return (
      <SidebarFilter
        name={filterLabels[key]}
        isVisible={isSidebarFilterVisible(filterLabels[key], key, sidebar)}
        key={key}
      >
        <SidebarConditionalFilter {...filterProps} />
      </SidebarFilter>
    );
  });
}

export default observer(ConditionalFiltersGroup);
