import React from "react";
import { observer } from "mobx-react";
import { Tab } from "../models/Tab.model";
import Influence from "../views/Influence";
import Explore from "../views/Explore";

type Props = {
  subpage: string,
  tab: Tab
};

function AnalysisSubpage({ subpage, tab }: Props) {
  switch (subpage) {
    case "influence":
      return <Influence tab={tab} />;
    case "explore":
    default:
      return <Explore tab={tab} />;
  }
}

export default observer(AnalysisSubpage);
