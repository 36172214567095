// @flow

import React from "react";
import styled from "@emotion/styled";
import { Colors } from "@blueprintjs/core";

import { ReactComponent as FlyrLogo } from "../assets/flyr-logotype.svg";

const StyledAuthFooter = styled("div")`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
`;

const StyledAuthText = styled("span")`
  color: ${Colors.DARK_GRAY5};
  font-weight: normal;
  font-size: 14px;
  line-height: 1.43;
`;

const AuthFooter = () => (
  <StyledAuthFooter className="d-flex pb-3 justify-content-center align-items-end">
    <FlyrLogo alt="Flyr" className="pr-3" height="33" />
    <StyledAuthText>© 2021 FLYR, INC.</StyledAuthText>
  </StyledAuthFooter>
);

export default AuthFooter;
