// @flow

import { format, parseISO } from "date-fns";

export const capitalize = (text: string): string => {
  return `${text[0].toUpperCase()}${text.slice(1)}`;
};

export const formatLastUpdatedText = (lastUpdated, computedDateFormat: string = "yyyy-MM-dd") => {
  if (!lastUpdated) {
    return "";
  }
  return `Last updated on ${format(parseISO(lastUpdated), `${computedDateFormat} HH:mm`)}`;
};
