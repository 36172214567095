/* eslint-disable react/jsx-no-target-blank */
// @flow

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import {
  Button,
  Checkbox,
  Collapse,
  Colors,
  Icon,
  InputGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position
} from "@blueprintjs/core";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/core";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import difference from "lodash.difference";
import intersection from "lodash.intersection";
import pluralize from "pluralize";

import { useDebounce } from "use-debounce";

import { FiltersHelpTexts } from "../helpers/filtersHelpTexts";
import MileTransformationContext from "../services/contexts/MileTransformationContext";
import SeriesLegend from "./SeriesLegend";
import SidebarFilter from "./SidebarFilter";
import ReadMoreButton from "./ReadMoreButton";

const StyledActionButton = styled(Button)`
  height: 30px;
  min-width: 72px;
  flex: 0 0 calc(100% / 3 - 4px);
`;

const StyledMenuFooter = styled("div")`
  border-top: 1px solid ${Colors.LIGHT_GRAY2};
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledMenu = styled(Menu)`
  max-height: 350px !important;
  max-width: 270px !important;
  width: 270px !important;
`;

const StyledMenuHeader = styled("div")`
  border-top: 1px solid ${Colors.LIGHT_GRAY2};
  border-bottom: ${({ isOpen }) => (isOpen ? `1px solid ${Colors.LIGHT_GRAY2}` : "1px solid transparent")};
  cursor: pointer;
  height: 36px;

  .bp3-menu-header {
    cursor: pointer;
    letter-spacing: 1px;
    margin: 0;
    padding: 0 8px;
    h6 {
      padding: 0;
    }
  }

  .bp3-control {
    align-items: center;
    display: flex;
    justify-content: center;

    .bp3-control-indicator {
      margin-top: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background: ${Colors.LIGHT_GRAY5};
  height: 30px;
`;

const StyledMenuDivider = styled(MenuDivider)`
  border-color: ${Colors.LIGHT_GRAY2};
`;

type Props = {
  allMetrics: Array<Array<string>>,
  baseMetrics: Array<Array<string>>,
  buttonIcon: string,
  buttonLabel: string,
  changeColumns: Function,
  changeGroupsStatus: Function,
  columns: Array<Array<string>>,
  columnLabels: Object,
  disabled: boolean,
  groupStatuses: Array<mixed>,
  onReset: ?Function,
  showLegend: boolean
};

function ColumnSelect(props: Props) {
  const {
    allMetrics = [],
    baseMetrics = [],
    buttonIcon,
    buttonLabel = "",
    changeColumns,
    changeGroupsStatus,
    columns = [],
    columnLabels = {},
    disabled = false,
    groupStatuses = [],
    onReset,
    showLegend = false
  } = props;

  const transformToMile = useContext(MileTransformationContext);

  const allMetricsKeys = allMetrics.map(([, metrics]) => metrics.map(metric => metric.key)).flat();

  const disabledKeys = allMetrics
    .flatMap(([, metrics]) => metrics)
    .filter(metric => metric.isDisabled)
    .map(metric => metric.key);

  const currentMetrics = columns
    .flatMap(([, metrics]) => metrics)
    .filter(metricKey => !disabledKeys.includes(metricKey));

  const columnsCount = currentMetrics.length;

  const [metricsState, setMetricsState] = useState(currentMetrics);
  const [filterInput, setFilterInput] = useState("");
  const [activeFilter] = useDebounce(filterInput, 400);

  const columnName = useCallback((title, key) => `${columnLabels[title]} ${columnLabels[key]} ${key}`, [columnLabels]);

  const filteredSeries = useMemo(
    () =>
      allMetrics
        .filter(
          ([title, metrics]) =>
            metrics.filter(metric => columnName(title, metric.key).toLowerCase().includes(activeFilter.toLowerCase()))
              .length
        )
        .map(([groupTitle]) => groupTitle),
    [activeFilter, allMetrics, columnName]
  );

  const updateGroupsStatuses = useCallback(
    selectedGroup => {
      const newGroupStatuses = groupStatuses.map(({ label, isOpen }) => {
        let isOpenValue = !isOpen;
        if (selectedGroup !== label) {
          isOpenValue = activeFilter ? filteredSeries.includes(label) : isOpen;
        }
        return {
          label,
          isOpen: isOpenValue
        };
      });
      changeGroupsStatus && changeGroupsStatus(newGroupStatuses);
    },
    [activeFilter, filteredSeries, groupStatuses, changeGroupsStatus]
  );

  useEffect(() => {
    updateGroupsStatuses();
  }, [activeFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const columnHelpUrls = FiltersHelpTexts(transformToMile);

  const addMetrics = (names: Array<string>) => {
    setMetricsState(
      [...new Set([...metricsState, ...names])].sort((a, b) =>
        allMetricsKeys.indexOf(a) > allMetricsKeys.indexOf(b) ? 1 : -1
      )
    );
  };

  const applyChanges = () => {
    const newColumnsSetting = metricsState.reduce(
      (accumulator, currentValue) => {
        const parentNode =
          allMetrics.find(([, metrics]) => metrics.map(metric => metric.key).includes(currentValue)) || [];

        return accumulator.map(([groupTitle, metrics]) =>
          groupTitle === parentNode[0] && currentValue
            ? [groupTitle, [...metrics, currentValue]]
            : [groupTitle, metrics]
        );
      },
      allMetrics.map(([groupTitle]) => [groupTitle, []])
    );
    setFilterInput("");
    changeColumns(newColumnsSetting);
  };

  const removeMetrics = (names: Array<string>) => setMetricsState(difference(metricsState, names));

  const renderMetric = (group: string, item: Object, index: number) => {
    const { key, isDisabled } = item;
    const isSelected = metricsState.includes(key);
    const legend = showLegend ? <SeriesLegend group={group} index={index} size={10} /> : null;

    const label = (
      <span className="d-inline-flex align-items-center justify-content-between w-100">
        {columnLabels[key] || key}
        {legend}
      </span>
    );

    const checkbox = (
      <Checkbox
        disabled={isDisabled}
        key={key}
        className="m-0 py-1"
        checked={isDisabled ? false : isSelected}
        label={label}
        onChange={e => (e.target.checked ? addMetrics([key]) : removeMetrics([key]))}
      />
    );

    const filterName = columnName(group, key).toLowerCase();

    return (
      <SidebarFilter key={key} name={filterName} filter={activeFilter}>
        <StyledMenuItem
          className="p-0 align-items-center"
          text={<div className="px-2">{checkbox}</div>}
          shouldDismissPopover={false}
          data-testid={`metric-checkbox-${key}`}
        />
      </SidebarFilter>
    );
  };

  const filterMetrics = () =>
    allMetrics
      .map(([groupTitle, groupMetrics]) => {
        return groupMetrics
          .filter(metric => {
            const { key } = metric;
            return columnName(groupTitle, key).toLowerCase().includes(activeFilter.toLowerCase());
          })
          .map(({ key }) => key);
      })
      .flat();

  const clearAllMetrics = () =>
    isEmpty(activeFilter)
      ? setMetricsState([])
      : setMetricsState(metricsState.filter(metric => !filterMetrics().includes(metric)));

  const selectAllMetrics = () =>
    isEmpty(activeFilter)
      ? setMetricsState(allMetricsKeys)
      : setMetricsState([...new Set([...metricsState, ...filterMetrics()])]);

  const buttons = (
    <div className="d-flex justify-content-between m-2">
      <StyledActionButton
        disabled={!isEmpty(activeFilter)}
        onClick={() => {
          setMetricsState(baseMetrics.map(([, metrics]) => metrics).flat());
          onReset && onReset();
        }}
      >
        Reset
      </StyledActionButton>
      <StyledActionButton className="d-flex p-0" onClick={clearAllMetrics}>
        Clear All
      </StyledActionButton>
      <StyledActionButton className="d-flex p-0" onClick={selectAllMetrics}>
        Select All
      </StyledActionButton>
    </div>
  );

  const metricGroupIsClicked = (target, name) =>
    target.parentElement.getAttribute(name) &&
    ["chevron-up", "chevron-down"].includes(target.parentElement.getAttribute(name));

  const metrics = allMetrics.map(([groupTitle, groupMetrics]) => {
    const groupExtraProps = columnHelpUrls[groupTitle];

    const { isOpen = false } = groupStatuses.find(el => el.label === groupTitle) || {};

    const groupMetricKeys = groupMetrics
      .map(item => item.key)
      .filter(metricKey => {
        const isDisabled = disabledKeys.includes(metricKey);
        const isFilteringActive = columnName(groupTitle, metricKey).toLowerCase().includes(activeFilter.toLowerCase());

        return isFilteringActive && !isDisabled;
      });

    const isGroupSelected = intersection(metricsState, groupMetricKeys).length;
    const isAllGroupMetricsSelected = isGroupSelected === groupMetricKeys.length;

    const groupCheckbox = (
      <Checkbox
        key={groupTitle}
        className="m-0 ml-2"
        checked={!!isGroupSelected}
        data-testid={`metric-group-checkbox-${groupTitle}`}
        indeterminate={isGroupSelected && !isAllGroupMetricsSelected}
        label={columnLabels[groupTitle]}
        onChange={() => (isAllGroupMetricsSelected ? removeMetrics(groupMetricKeys) : addMetrics(groupMetricKeys))}
      />
    );

    const menuTitle = (
      <StyledMenuHeader
        className="d-flex align-items-center"
        data-testid="metric-group-title-with-help-text"
        isOpen={isOpen}
        onClick={({ target, currentTarget }) => {
          if (
            target === currentTarget ||
            metricGroupIsClicked(target, "data-icon") ||
            metricGroupIsClicked(target, "icon")
          ) {
            updateGroupsStatuses(groupTitle, isOpen);
          }
        }}
      >
        {groupCheckbox}
        {groupExtraProps && <ReadMoreButton href={groupExtraProps.url} />}
        <Icon className="ml-auto mr-2" color={Colors.GRAY1} icon={isOpen ? "chevron-up" : "chevron-down"} />
      </StyledMenuHeader>
    );

    if (!activeFilter) {
      return (
        <React.Fragment key={`fragment-${groupTitle}`}>
          {menuTitle}

          <Collapse data-testid="metric-group" isOpen={isOpen}>
            {groupMetrics.map((metric: Object, index: number) => renderMetric(groupTitle, metric, index))}
          </Collapse>
        </React.Fragment>
      );
    }

    return filteredSeries.includes(groupTitle) ? (
      <React.Fragment key={`fragment-${groupTitle}`}>
        {menuTitle}

        <Collapse data-testid="metric-group" isOpen={isOpen}>
          {groupMetrics.map((metric: Object, index: number) => renderMetric(groupTitle, metric, index))}
        </Collapse>
      </React.Fragment>
    ) : null;
  });

  const numberOfMetrics = Array.isArray(metricsState) && metricsState.length;

  const menu = (
    <StyledMenu data-testid="columns-menu" className="mb-5 p-0">
      <InputGroup
        autoComplete="off"
        autoFocus
        className="m-2"
        data-testid="search-columns"
        id="text-input"
        leftIcon="search"
        onChange={({ target }) => setFilterInput(target.value.toLowerCase())}
        placeholder="Search..."
        rightElement={filterInput.length ? <Button icon="cross" minimal onClick={() => setFilterInput("")} /> : null}
        value={filterInput}
      />
      <StyledMenuDivider className="m-0" />
      {buttons}
      {activeFilter && isEmpty(filteredSeries) ? <div className="p-2">No results found.</div> : metrics}
      <StyledMenuFooter className="d-flex position-fixed">
        <Button
          className="m-2"
          disabled={isEqual(currentMetrics, metricsState)}
          intent="primary"
          fill
          onClick={applyChanges}
        >
          Apply ({pluralize("metric", numberOfMetrics, true )})
        </Button>
      </StyledMenuFooter>
    </StyledMenu>
  );

  const button = (
    <Button
      className="d-flex"
      icon={buttonIcon}
      disabled={disabled}
      data-testid="metric-selector"
      onClick={() => setMetricsState([...currentMetrics])}
    >
      <span>
        {buttonLabel}: <strong>{columnsCount}</strong>
      </span>
      <Icon className="ml-3" icon="caret-down" />
    </Button>
  );

  return (
    <>
      <Global
        styles={css`
          .bp3-popover .bp3-popover-content {
            height: 100%;
          }
        `}
      />
      <Popover content={menu} disabled={disabled} position={Position.BOTTOM} minimal>
        {button}
      </Popover>
    </>
  );
}

export default observer(ColumnSelect);
