// @flow

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import {
  Button,
  ButtonGroup,
  Classes,
  Colors,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  Popover,
  Position,
  Tooltip
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { ReactComponent as CaretMostLeftIcon } from "../assets/caret-most-left.svg";
import { ReactComponent as CaretMostRightIcon } from "../assets/caret-most-right.svg";
import { isMacOs } from "../helpers/isMacOs";

const StyledPagination = styled("div")`
  background-color: #fff;
  border: 1px solid ${Colors.LIGHT_GRAY2};
  display: flex;
  justify-content: center;
  margin: 0 -1px -1px -1px;
  position: relative;
`;

const StyledButton = styled(Button)`
  border: 1px solid ${Colors.LIGHT_GRAY2};
  border-bottom: none;
  border-top: none;
  border-radius: 0;
  font-size: 12px;

  .bp3-icon {
    color: ${Colors.GRAY2};
  }

  &.bp3-disabled {
    background-color: ${Colors.LIGHT_GRAY5} !important;
    color: ${Colors.DARK_GRAY4} !important;

    .bp3-icon {
      color: ${Colors.GRAY5} !important;
    }
  }
`;

const StyledGotoPageForm = styled("form")`
  width: 130px;
`;

const StyledGotoPageButton = styled(StyledButton)`
  min-width: 130px;

  .${Classes.POPOVER_OPEN} & {
    .bp3-icon {
      transform: rotate(180deg);
    }
  }
`;

const StyledPageSizeSelect = styled(Select)`
  position: absolute;
  right: 0;
  top: 0;
  min-width: 150px;

  .${Classes.POPOVER_OPEN} {
    .bp3-button {
      background-color: #d8e1e6;
    }

    .bp3-icon {
      transform: rotate(180deg);
    }
  }
`;

const StyledPageSizeButton = styled(StyledButton)`
  border-right: none;
`;

export const PAGE_SIZES = [25, 50, 75, 100];

const normalizeValue = (text: string, max: number): ?number => {
  const pageValue = parseInt(text, 10);
  const pageIndex = pageValue - 1;

  if (Number.isNaN(pageIndex) || pageIndex < 0 || pageIndex >= max) {
    return undefined;
  }

  return pageIndex;
};

function PageSelector({ gotoPage, pageCount }: { gotoPage: Function, pageCount: number }) {
  const [gotoValue, setGotoValue] = useState("");
  const [isValidationError, setIsValidationError] = useState(false);

  const gotoPageIndex = normalizeValue(gotoValue, pageCount);
  const isTooltipVisible = isValidationError && gotoPageIndex == null;

  const handleSubmit = event => {
    event.preventDefault();

    if (gotoPageIndex != null) {
      gotoPage(gotoPageIndex);
      setIsValidationError(false);
    } else {
      setIsValidationError(true);
    }
  };

  return (
    <Tooltip isOpen={isTooltipVisible} content={`Must be a number between 1 & ${pageCount}`}>
      <StyledGotoPageForm className="d-flex p-1" onSubmit={handleSubmit}>
        <InputGroup
          data-testid="page-input"
          placeholder="Page..."
          autoFocus
          value={gotoValue}
          onChange={({ target }) => {
            setGotoValue(target.value);
          }}
        />
        <Button className="ml-1" intent={Intent.PRIMARY} type="submit" data-testid="page-input-confirmation">
          Go
        </Button>
      </StyledGotoPageForm>
    </Tooltip>
  );
}

type Props = {
  pagination: Object,
  canPreviousPage: boolean,
  canNextPage: boolean,
  isPreviewActive: boolean,
  setPageSize: Function,
  gotoPage: Function,
  previousPage: Function,
  nextPage: Function
};

function DataTablePagination(props: Props) {
  const {
    pagination,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    previousPage,
    nextPage,
    isPreviewActive = false
  } = props;
  const { pageIndex, pageSize, pageCount } = pagination;

  useEffect(() => {
    gotoPage(pagination.pageIndex);
  }, [gotoPage, pagination]);

  // scrollbar fix for mac os
  const spacing = isMacOs() ? "" : "mt-2";

  return (
    <StyledPagination className={spacing} data-testid="table-pagination">
      <ButtonGroup>
        <StyledButton
          minimal
          icon={
            <span className="bp3-icon">
              <CaretMostLeftIcon />
            </span>
          }
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          data-testid="first-page-button"
        />
        <StyledButton
          minimal
          icon="caret-left"
          onClick={previousPage}
          disabled={!canPreviousPage}
          data-testid="previous-page-button"
        />

        <Popover
          position={Position.TOP}
          minimal
          disabled={pageCount <= 1}
          content={<PageSelector gotoPage={gotoPage} pageCount={pageCount} />}
        >
          <StyledGotoPageButton minimal rightIcon="caret-down" disabled={pageCount <= 1}>
            Page {pageIndex + 1} of {pageCount}
          </StyledGotoPageButton>
        </Popover>

        <StyledButton
          minimal
          icon="caret-right"
          onClick={nextPage}
          disabled={!canNextPage}
          data-testid="next-page-button"
        />
        <StyledButton
          minimal
          icon={
            <span className="bp3-icon">
              <CaretMostRightIcon />
            </span>
          }
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          data-testid="last-page-button"
        />
      </ButtonGroup>

      <StyledPageSizeSelect
        disabled={isPreviewActive}
        filterable={false}
        items={PAGE_SIZES}
        itemRenderer={(item, itemProps) => (
          <MenuItem
            key={item}
            onClick={itemProps.handleClick}
            text={item}
            labelElement={item === pageSize && <Icon icon="tick" />}
          />
        )}
        onItemSelect={setPageSize}
        popoverProps={{
          minimal: true,
          popoverClassName: "page-size-popover",
          modifiers: {
            preventOverflow: { enabled: false },
            hide: { enabled: false }
          }
        }}
      >
        <StyledPageSizeButton data-testid="page-size-button" disabled={isPreviewActive} minimal rightIcon="caret-down">
          Rows per page: {pageSize}
        </StyledPageSizeButton>
      </StyledPageSizeSelect>
    </StyledPagination>
  );
}

export default observer(DataTablePagination);
