// @flow

import React from "react";
import { AnchorButton, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";
import groupBy from "lodash.groupby";
import isEmpty from "lodash.isempty";
import styled from "@emotion/styled";
import StatusIndicator from "./StatusIndicator";
import { useStores } from "../store/Store";

type Props = {
  selectedRows: Array<string>,
  flightsCount: Object,
  disabled: boolean
};

type StatusButtonProps = {
  status: "on" | "off"
};

const ButtonLabelStyled = styled("span")`
  color: #000 !important;
`;

const statusesMap = {
  on: "enabled",
  off: "disabled"
};

function CirrusTableActions(props: Props) {
  const { cirrusStore, modalStore } = useStores();
  const { selectedRows, flightsCount, disabled } = props;
  const nothingIsSelected = isEmpty(selectedRows);

  const groupedFlights = groupBy(selectedRows, flightId => (flightId.includes("_on") ? "on" : "off"));

  const flightsCountByStatus = (status: string, switchStatus: ?boolean) => {
    let accessor = status;
    if (switchStatus) {
      accessor = status === "on" ? "off" : "on";
    }
    if (nothingIsSelected) {
      return disabled ? 0 : flightsCount[statusesMap[accessor]];
    }
    return groupedFlights[accessor] ? groupedFlights[accessor].length : 0;
  };

  const flightsTextByStatus = status => {
    const count = flightsCountByStatus(status, true);
    const text = count === 1 ? "flight" : "flights";
    return `(${count} ${text})`;
  };

  const shouldBeDisabled = status => {
    if (flightsCountByStatus(status, true) === 0) {
      return true;
    }
    if (nothingIsSelected) {
      return disabled;
    }
    return false;
  };

  const shouldHavePopover = status => {
    return disabled && nothingIsSelected && flightsCountByStatus(status, true) === 0;
  };

  const StatusButton = (props: StatusButtonProps) => {
    const { status } = props;
    const switchText = nothingIsSelected ? `Turn all ${status}` : `Turn ${status}`;

    const flightsCountText = flightsTextByStatus(status);

    return (
      <AnchorButton
        data-testid={`turn-${status}-button`}
        disabled={shouldBeDisabled(status)}
        key={`turn-${status}-button`}
        className="ml-2"
        onClick={() =>
          modalStore.setModal("fusionrm", {
            newStatus: status.toUpperCase(),
            totalRows: flightsCountByStatus(status, true),
            searchParams: {
              ...cirrusStore.searchParams,
              flightIds: cirrusStore.table.selectedRows
            }
          })
        }
      >
        <StatusIndicator
          text={
            <ButtonLabelStyled>
              <strong>{switchText}</strong> {flightsCountText}
            </ButtonLabelStyled>
          }
          isActive={status === "on"}
        />
      </AnchorButton>
    );
  };

  return ["on", "off"].map(status => {
    if (shouldHavePopover(status)) {
      return (
        <Popover
          key={`button-popover-${status}`}
          popoverClassName="bp3-popover-content-sizing"
          position={Position.BOTTOM}
          hoverCloseDelay={0}
          hoverOpenDelay={0}
          interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
          content={<div>Apply filters to enable bulk functionality.</div>}
        >
          <StatusButton status={status} />
        </Popover>
      );
    }
    return <StatusButton status={status} key={`status-button-${status}`} />;
  });
}

export default observer(CirrusTableActions);
