// @flow

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Keys, NumericInput, RangeSlider } from "@blueprintjs/core";
import clsx from "clsx";

type BoundNumericInputProps = {
  defaultValue: string,
  max: number,
  min: number,
  onValueChange: string => void,
  value: string
};

function BoundNumericInput({ defaultValue, max, min, onValueChange, value, ...rest }: BoundNumericInputProps) {
  const [rawValue, setRawValue] = useState("");

  useEffect(() => {
    if (String(value) !== rawValue) {
      setRawValue(String(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleCommit = committedValue => {
    const safeValue = parseInt((committedValue || "").trim(), 10);

    if (Number.isNaN(safeValue) || safeValue < min || safeValue > max) {
      setRawValue(String(value));
    } else {
      setRawValue(String(safeValue));
      onValueChange(safeValue);
    }
  };

  return (
    <NumericInput
      {...rest}
      fill
      max={max}
      min={min}
      minorStepSize={null}
      onButtonClick={(_: number, stringValue: string) => {
        handleCommit(stringValue);
      }}
      onValueChange={(_: number, stringValue: string) => {
        setRawValue(stringValue);
      }}
      onBlur={() => {
        handleCommit(rawValue);
      }}
      onKeyDown={event => {
        if (event.keyCode === Keys.ENTER) {
          handleCommit(rawValue);
        }
      }}
      rightElement={
        <Button
          className={clsx(value === defaultValue && "invisible")}
          icon="cross"
          minimal
          onClick={() => {
            setRawValue(String(defaultValue));
            onValueChange(defaultValue);
          }}
        />
      }
      value={rawValue}
    />
  );
}

type Props = {
  onChange: Function,
  title: string,
  values: Object
};

function SidebarNDOSelect(props: Props) {
  const { title = "", values = { start: 0, end: 360 }, onChange } = props;

  return (
    <div className="d-flex flex-column mb-3">
      <h6 className="bp3-heading">{title}</h6>
      <div className="d-flex mb-2">
        <BoundNumericInput
          defaultValue={0}
          max={values.end}
          min={0}
          onValueChange={(numberValue: number) => {
            onChange({ start: numberValue, end: values.end });
          }}
          value={values.start}
        />
        <BoundNumericInput
          className="ml-2"
          defaultValue={360}
          max={360}
          min={values.start}
          onValueChange={(numberValue: number) => {
            onChange({ start: values.start, end: numberValue });
          }}
          value={values.end}
        />
      </div>
      <div className="mx-3">
        <RangeSlider
          min={0}
          max={360}
          onChange={([start, end]) => {
            onChange({ start, end });
          }}
          value={[values.start, values.end]}
          labelStepSize={90}
        />
      </div>
    </div>
  );
}

export default observer(SidebarNDOSelect);
