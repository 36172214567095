import React from "react";
import { Colors, Menu, MenuItem } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

const AdminToolsNavigationStyled = styled("div")`
  background: white;
  box-shadow: 0 0 0 rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
  position: relative;
  width: 200px;

  h5 {
    color: ${Colors.GRAY3};
    font-weight: bold;
    font-size: 12px;
  }
`;

const AdminMenu = styled(Menu)`
  &.bp3-menu {
    width: auto;
    min-width: auto;
    padding: 0;

    .bp3-menu-item {
      color: ${Colors.DARK_GRAY4};
      font-weight: bold;
      font-size: 14px;

      &.bp3-active {
        background-color: ${Colors.LIGHT_GRAY4};
        color: inherit !important;
      }
    }
  }
`;

const NavigationHeader = styled("div")`
  height: 40px;
`;

function AdminToolsNavigation(props: Props) {
  const { currentPage } = props;
  const history = useHistory();
  const navItems = [
    { label: "System Settings", pathname: "/system-units" },
    { label: "Cirrus On/Off", pathname: "/cirrus-status" },
    { label: "Market Mappings", pathname: "/market-mappings" },
    { label: "Regions & Subregions", pathname: "/regions" }
  ];

  return (
    <AdminToolsNavigationStyled className="h-100 p-2">
      <NavigationHeader className="d-flex align-items-center">
        <h5 className="bp3-heading">ADMIN TOOLS</h5>
      </NavigationHeader>
      <AdminMenu>
        {navItems.map(item => {
          return (
            <MenuItem
              key={item.label}
              text={item.label}
              active={currentPage === item.pathname}
              onClick={() => history.push(item.pathname)}
            />
          );
        })}
      </AdminMenu>
    </AdminToolsNavigationStyled>
  );
}

export default AdminToolsNavigation;
