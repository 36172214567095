// @flow

import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { addYears, endOfYear, format, isValid, parseISO, startOfToday } from "date-fns";
import { Button, Spinner } from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";

const StyledDateRange = styled("div")`
  position: relative;
  .bp3-control-group > * {
    flex-shrink: 1;
  }
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  right: 0px;
  z-index: 2;
`;

const globalStyle = (
  <Global
    styles={css`
      .DayPicker-Day--today {
        font-weight: bold;
      }
      .bp3-input {
        padding: 0 5px;
      }
    `}
  />
);

type Props = {
  className: ?string,
  computedDateFormat: string,
  dates: Object,
  extraProps: Object,
  isLoading: boolean,
  maxDate: ?Date,
  minDate: ?Date,
  onChange: Function,
  popoverProps: ?Object,
  title: string,
  withResetDates: ?boolean
};

function SidebarDateSelect(props: Props) {
  const {
    className,
    computedDateFormat = "yyyy-MM-dd",
    dates = {},
    extraProps = {},
    isLoading = false,
    maxDate = addYears(endOfYear(new Date()), 5),
    minDate = startOfToday(),
    onChange,
    popoverProps = {},
    title = "",
    withResetDates = true
  } = props;

  function resetDates() {
    onChange({ start: null, end: null });
  }

  return (
    <StyledDateRange className={clsx("d-flex flex-column mb-3", className)} data-testid="date-range-input">
      <h6 className="bp3-heading">{title}</h6>
      {globalStyle}
      <div className="d-flex align-items-center">
        <DateRangeInput
          formatDate={(date: Date) => date && format(date, computedDateFormat)}
          onChange={([start, end]) => {
            onChange({
              start: start && isValid(start) && format(start, "yyyy-MM-dd"),
              end: end && isValid(end) && format(end, "yyyy-MM-dd")
            });
          }}
          parseDate={str => parseISO(str)}
          value={[dates.start ? parseISO(dates.start) : null, dates.end ? parseISO(dates.end) : null]}
          minDate={minDate}
          maxDate={maxDate}
          allowSingleDayRange
          shortcuts={false}
          popoverProps={{
            ...popoverProps,
            onClose: () => {
              if (dates.start && !dates.end) onChange({ start: dates.start, end: dates.start });
              if (!dates.start && dates.end) onChange({ start: dates.end, end: dates.end });
            }
          }}
          dayPickerProps={{
            // showWeekNumbers: true
            firstDayOfWeek: 1
          }}
          {...extraProps}
        />

        {isLoading && <StyledSpinner data-testid="event-suggest-spinner" className="mr-2" size={15} />}
        {withResetDates && <Button data-testid="date-range-reset-dates" icon="cross" minimal onClick={resetDates} />}
      </div>
    </StyledDateRange>
  );
}

export default observer(SidebarDateSelect);
