// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Classes, Colors, Dialog, FormGroup, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import clsx from "clsx";
import isEmpty from "lodash.isempty";

import { useStores } from "../store/Store";
import SidebarSingleSelect from "./SidebarSingleSelect";

const FormGroupStyled = styled("div")`
  .bp3-form-group {
    .bp3-label {
      min-width: 150px;
    }
    .bp3-form-content {
      flex-grow: 1;
    }
  }
`;

const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 ${Colors.BLACK}26;
`;

const SelectWrapper = styled("div")`
  width: 220px;
`;

function EditRegionsModal() {
  const { modalStore, regionsStore } = useStores();
  const { data } = modalStore;
  const { existingRegionNames, table } = regionsStore;

  const parentOptions = table.data.map(region => ({ value: region.regionId, label: region.regionName }));

  const isSubregion = data.subregionId;
  const originalName = isSubregion ? data.subregionName : data.regionName;
  const originalParent = isSubregion
    ? parentOptions.find(option => option.value === Number(data.regionId.split(".")[0]))
    : undefined;

  const [name, setName] = useState(originalName);
  const [parent, setParent] = useState(originalParent);

  const parentId = parent && parent.value ? Number(parent.value) : undefined;
  const trimmedName = name.trim();
  const isValidName = !existingRegionNames.filter(name => name !== originalName).includes(trimmedName);
  const isValid = !isEmpty(trimmedName) && isValidName && (!isSubregion || parentId);

  function submitForm(event: Event) {
    event.preventDefault();

    modalStore.setModal("");
    if (!isSubregion) {
      regionsStore.editRegion(data.regionId, { regionName: name });
    } else {
      regionsStore.editSubregion(data.subregionId, { regionId: parent.value, subregionName: name });
    }
  }

  return (
    <Dialog className="p-0" isOpen onClose={() => modalStore.setModal("")} title="Edit Item">
      <form className="p-0" data-testid="edit-regions-modal" onSubmit={event => submitForm(event)}>
        <div className={clsx(Classes.DIALOG_BODY, "m-0", "p-3")}>
          <FormGroupStyled className="d-flex flex-column w-100">
            <FormGroup className="font-weight-bold mb-4" label="Name" labelFor="name-input">
              <input
                autoFocus
                className={clsx(Classes.INPUT, "w-100")}
                id="name-input"
                onChange={e => setName(e.target.value)}
                placeholder="Enter name"
                required
                type="text"
                value={name}
              />
            </FormGroup>
            {isSubregion && (
              <SelectWrapper>
                <SidebarSingleSelect
                  items={parentOptions}
                  selectedItem={parent}
                  selectItem={setParent}
                  title="Region"
                />
              </SelectWrapper>
            )}
          </FormGroupStyled>
        </div>
        <DialogFooter className={clsx(Classes.DIALOG_FOOTER, "mx-0 px-3 py-2")}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              data-testid="confirm-regions-modal-button"
              disabled={!isValid}
              intent={Intent.PRIMARY}
              type="submit"
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </form>
    </Dialog>
  );
}

export default observer(EditRegionsModal);
