// @flow

import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Classes, ControlGroup, Dialog, FormGroup, HTMLSelect, InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";

const DialogFooter = styled("div")`
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.15);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;

type Props = {
  currentUserId: string,
  deleteUser: Function,
  editUser: Function,
  modal: Object,
  userGroups: Array<Object>
};

function EditUserModal(props: Props) {
  const { currentUserId = "", deleteUser, editUser, modal, userGroups = [] } = props;
  const rolesOptions = userGroups.map(group => group.name);
  const { data = {} } = modal;
  const { group = "", name: userFullName = "", email: userEmail = "", userId = "" } = data;

  const isSelfEdit = currentUserId === userId;

  const [name, setName] = useState(userFullName);
  const [email, setEmail] = useState(userEmail);
  const [role, setRole] = useState(group);

  function showDeleteConfirmation() {
    const config = {
      title: "Confirm Deleting User",
      text: `You are going to delete ${email}. It means that the user will not be able to use the FLYR ACP and all its data will be removed from the system database.`,
      buttonIcon: "trash",
      buttonText: "Delete User",
      action: () => {
        deleteUser(userId);
      }
    };
    modal.setModal("confirmation", config);
  }

  return (
    <Dialog
      className="p-0"
      isOpen={modal.modalActive === "editUser"}
      onClose={() => modal.setModal("")}
      title="Edit User"
    >
      <div className={`${Classes.DIALOG_BODY} m-0 p-3`} data-testid="em-user-details">
        <div className="d-flex flex-column w-100">
          <FormGroup className="font-weight-bold mb-4" label="Email" labelFor="em-email-input-details">
            <InputGroup
              id="em-email-input-details"
              type="email"
              value={email}
              disabled
              onChange={e => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="font-weight-bold mb-4" label="Full Name" labelFor="em-full-name-input-details">
            <InputGroup id="em-full-name-input-details" value={name} onChange={e => setName(e.target.value)} />
          </FormGroup>
          <FormGroup className="font-weight-bold mb-1" label="Role" labelFor="em-role-input">
            <ControlGroup>
              <HTMLSelect
                id="em-role-input"
                className="w-50 p-0"
                options={rolesOptions}
                value={role}
                disabled={isSelfEdit}
                onChange={e => setRole(e.currentTarget.value)}
              />
            </ControlGroup>
          </FormGroup>
        </div>
      </div>

      <DialogFooter className={`${Classes.DIALOG_FOOTER} mx-0 py-2`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {deleteUser && !isSelfEdit ? (
            <Button className="m-0" minimal icon="trash" onClick={() => showDeleteConfirmation()} text="Delete User" />
          ) : null}
          {editUser ? (
            <Button
              className="ml-auto px-3"
              data-testid="em-user-button"
              intent="primary"
              onClick={() => {
                editUser(userId, { name, group: role });
                modal.setModal("");
              }}
              text="Save"
            />
          ) : null}
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default observer(EditUserModal);
