import React from "react";
import { Button, Icon, MenuItem, Spinner } from "@blueprintjs/core";
import clsx from "clsx";

import { dateOptions } from "../../models/SystemUnits.model";
import { SystemSettingsControlLabel, SystemSettingsSelect } from "./SystemSettingsUI";

type Props = {
  dateFormat: string,
  isLoading: boolean,
  onChange: Function
};

const renderMenuItem = (item, itemProps, selected) => {
  const isSelected = selected === item.value;

  return (
    <MenuItem
      className={clsx({ "bp3-active": isSelected })}
      data-testid="date-format-menu-item"
      key={item.label}
      labelElement={isSelected ? <Icon icon="tick" /> : null}
      onClick={itemProps.handleClick}
      text={item.label}
    />
  );
};

function DateFormatControl({ dateFormat, onChange, isLoading }: Props) {
  const dateFormatLabel = dateOptions.find(option => option.value === dateFormat)?.label;
  const rightIcon = isLoading ? <Spinner size={12} /> : "caret-down";
  const buttonText = isLoading ? "Loading..." : dateFormatLabel;
  
  return (
    <div className="d-flex justify-content-end align-items-center mb-3 text-right">
      <SystemSettingsControlLabel className="m-0 mr-2">Format</SystemSettingsControlLabel>
      <SystemSettingsSelect
        disabled={isLoading}
        filterable={false}
        items={dateOptions}
        itemRenderer={(item, itemProps) => renderMenuItem(item, itemProps, dateFormat)}
        onItemSelect={option => onChange({ dateFormat: option.value })}
        popoverProps={{ minimal: true }}
      >
        <Button
          data-testid="system-units-date-format-select"
          disabled={isLoading}
          text={buttonText}
          rightIcon={rightIcon}
        />
      </SystemSettingsSelect>
    </div>
  );
}

export default DateFormatControl;
