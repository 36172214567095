// @flow

import React from "react";
import { observer } from "mobx-react";
import difference from "lodash.difference";

import SidebarSelect from "../SidebarSelect";
import SidebarFilter from "../SidebarFilter";
import SidebarHourSelect from "../SidebarHourSelect";
import ButtonSelector from "../ButtonSelector";
import isSidebarFilterVisible from "../../helpers/isSidebarFilterVisible";
import type { AnalysisMappingsStore } from "../../models/AnalysisMappings.model";

type Props = {
  mappings: AnalysisMappingsStore,
  pageContext: Object
};

function IHFlightFilters(props: Props) {
  const { mappings, pageContext } = props;
  const { filters, changeFilter, sidebar } = pageContext;

  const matchingFlightNumbers = mappings.getFlightNumbers(filters);
  const flightNumbers = difference(mappings.getFlightNumbers(null), matchingFlightNumbers);

  return (
    <>
      <SidebarFilter isVisible={isSidebarFilterVisible("flight number", "flightNumber", sidebar)}>
        <SidebarSelect
          fuzzySearch
          items={[
            { label: "Matching pre-selected", groupItems: matchingFlightNumbers },
            { label: "All flights", groupItems: flightNumbers }
          ]}
          onChange={filterValue => changeFilter("flightNumber", filterValue)}
          selectedItems={filters.flightNumber}
          title="Flight Number"
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure time", "depTime", sidebar)}>
        <SidebarHourSelect
          title="Departure Time"
          hours={filters.depTime}
          onChange={filterValue => changeFilter("depTime", filterValue)}
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("departure time bucket", "depTimeBucket", sidebar)}>
        <SidebarSelect
          title="Departure Time Bucket"
          items={mappings.data.depTimeBucket}
          fuzzySearch
          selectedItems={filters.depTimeBucket}
          onChange={filterValue => changeFilter("depTimeBucket", filterValue)}
        />
      </SidebarFilter>
      <SidebarFilter isVisible={isSidebarFilterVisible("cabin class", "cabinClass", sidebar)}>
        <ButtonSelector
          title="Cabin Class"
          items={mappings.getCabinClasses()}
          selectedItems={filters.cabinClass}
          onSelect={filterValue => changeFilter("cabinClass", filterValue)}
        />
      </SidebarFilter>
    </>
  );
}

export default observer(IHFlightFilters);
